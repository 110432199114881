export const laundry = {
  wash: 'Washing',
  iron: 'Ironing',
  schedule: 'You can bring your belongings to the laundry room, which is located near the tennis court on the terrace from 11:00 to 18:00',
  order: 'Order laundry',
  summary: 'Summary',
  article: 'Article',
  amount: 'Amount',
  price: 'Price',
  total: 'Total',
  blouse: 'Shirt/Silk blouse',
  sweater: 'Pullover/Sweater',
  trousers: 'Trousers',
  dress: 'Dress',
  jeans: 'Jeans',
  skirt: 'Skirt',
  t_shirt: 'T-shirt',
  nightgown: 'Nightgown/Bathrobe',
  scarf: 'Scarf/Necktie',
  socks: 'Socks/Panty',
  pajamas: 'Pajamas',
  jacket: 'Jacket',
  suit: 'Suit',
  sport_suit: 'Sports Suit',
  vest: 'Vest',
  shorts: 'Shorts',
  towel: 'Towel',
  child: 'Kids Clothes',
  order_received: 'Thank you, we received your order! You will get a notification when your order is ready.',
};
