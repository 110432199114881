<template>
  <div class="panel">
    <page-header :title="$t('service.booking')" icon="booking" back-link="/" />
    <loader-panel v-if="isLoading" />
    <div v-else class="panel__content">
      <div v-for="type in roomTypes" :key="type.id" class="room">
        <div class="room__top">
          <div class="room__top-left">
            <p class="room__top-name">{{ getTranslation(type.name) }}</p>
            <p class="room__top-price-label">{{ $t('booking.price_per_day') }}:</p>
            <p class="room__top-price-value">{{ type.price }} {{ $t(userStore.currency) }}</p>
          </div>
          <div class="room__top-right" :class="{ loading: !isImageLoaded[type.id] }">
            <img :src="type.imagesData?.[0]" alt="photo" @load="onImageLoad(type.id)" />
          </div>
        </div>
        <p v-html="getFullDescription(type)" class="room__description"></p>
        <base-button :text="$t('button.view_more')" @click="push(`/booking/rooms/${type.id}`)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { onMounted, ref } from "vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRouter } from "vue-router";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useTranslation } from "@/composable/useTranslation";
import { RoomType } from "@/types/RoomType";

const { push } = useRouter();
const userStore = useUserStore();
const roomTypeStore = useRoomTypeStore();
const { roomTypes } = storeToRefs(roomTypeStore);
const { checkFunction } = useHotelSetup();
const { getTranslation } = useTranslation();

const isLoading = ref(true);

// Створюємо об'єкт для відстеження завантаження зображень
const isImageLoaded = ref<Record<number, boolean>>({});

const getFullDescription = (type: RoomType) => type.articles.map(item => getTranslation(item.text)).join(' ');

const onImageLoad = (id: number) => {
  isImageLoaded.value[id] = true;
};

onMounted(async () => {
  checkFunction('booking');

  await roomTypeStore.getRoomTypes();
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.room {
  &:not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid white;
  }

  &__top {
    @extend .flex-row;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    &-left {
      @extend .flex-col;
    }

    &-right {
      width: 32.8vw;
      min-width: 32.8vw;
      height: 19vw;

      &.loading {
        background-image: url('@/assets/preload.png');
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    &-name {
      margin-bottom: 6px;
      @include font(18px, 22px, white, 600);
    }

    &-price-label {
      margin-bottom: 8px;
      @include font(16px, 20px, white);
    }

    &-price-value {
      @include font(18px, 22px, $primary, 600);
      text-transform: uppercase;
    }
  }

  &__description {
    margin-bottom: 1rem;
    @include font(18px, 22px, white, 600);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base-button {
    margin-bottom: 18px;
    color: white;
    background-color: #FFFFFF1A;
    border: 1.5px solid white;
    box-sizing: border-box;
  }
}
</style>
