export const notification = {
  active: {
    title: 'Accepted request',
    text: 'Your request was accepted',
    details: 'Details',
  },
  cancelled: {
    title: 'Denied request',
    text: 'Unfortunately, your request was denied.',
    details: 'Reason',
  },
  finished: {
    title: 'Finished request',
    text: 'Your request was finished',
    details: 'Details',
  }
};
