<template>
  <popup-container :title="$t('cancel_title')" @close="onClose">
    <p class="label">{{ $t('cancel_reason_label') }}</p>
    <form-textarea v-model="text" :placeholder="$t('form.enter_text')" />
    <base-button :class="{'disabled': !isButtonActive}" :text="$t('button.cancel_request')" @click="onSubmitClick" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { computed, ref } from "vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { RequestDetails } from "@/types/Request";

const emits = defineEmits(['close', 'submit']);

const text = ref('');

const isButtonActive = computed((): boolean => !!text.value);

const onClose = () => emits('close');

const onSubmitClick = () => {
  const data: RequestDetails = {
    cancelReason: text.value,
    cancelEmitter: 'client'
  };

  emits('submit', data);
};
</script>

<style scoped lang="scss">
:deep(.popup) {
  align-items: flex-start;
}

:deep(.popup__header) {
  margin-bottom: 1.5rem;
}

.label {
  margin-bottom: 1rem;
  @include font(16px, 20px, black, 500);
}

.field {
  margin-bottom: 0.5rem;
}

:deep(textarea) {
  color: black;
}

.base-button {
  margin: 2rem 0 0.5rem;
}
</style>
