<template>
  <div class="panel">
    <page-header back-link="/" />
    <template v-if="isWorking">
      <p class="panel__title">{{ $t('food.title', { places }) }}</p>
      <div class="panel__content menu-list">
        <div v-for="category in categories" :key="category.id" class="menu-list__item"
             @click="onCategoryClick(category)">
          <div class="menu-list__item-icon" :class="category.icon" />
          <p class="menu-list__item-name">{{ getTranslation(category.name) }}</p>
          <p v-if="category.workTime?.from" class="menu-list__item-time">
            ({{ $t('from') }} {{ category.workTime.from }} {{ $t('till') }} {{ category.workTime.to }})
          </p>
        </div>
      </div>
      <base-button v-if="menuCart.length" :text="$t('button.to_cart')" @click="push('/menu/details')" />
    </template>
    <p v-else class="empty">
      {{ $t('food.working_hours', { from: menuSetup?.workTime?.from, to: menuSetup?.workTime?.to }) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { useRouter } from "vue-router";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useRender } from "@/composable/useRender";
import { useI18n } from "vue-i18n";
import { HotelSetupFood, MenuCategory } from "@/types/HotelSetup";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { useTranslation } from "@/composable/useTranslation";

const i18n = useI18n();
const { push } = useRouter();
const appStore = useAppStore();
const { menuCart } = storeToRefs(appStore);
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { checkFunction } = useHotelSetup();
const { renderNotification } = useRender();
const { getTranslation } = useTranslation();

const isWorking = ref(false);
const timer = ref(0);

const menuSetup = computed((): HotelSetupFood => hotelSetup.value.food);

const places = computed((): string => {
  const places: string[] = menuSetup.value.delivery.map(i => getTranslation(i.name).toLowerCase());
  if (places.length === 1) {
    return places[0];
  } else if (places.length === 2) {
    return places.join(` ${ i18n.t('food.or') } `);
  } else {
    return `${ places.slice(0, -1).join(', ') } ${ i18n.t('food.or') } ${ places.slice(-1)[0] }`
  }
});

const categories = computed((): MenuCategory[] => hotelSetup.value.menuCategories
    .filter(c => !c.isSubcategory && c.type === 'dish')
    .sort((a, b) => a.id - b.id));

const onCategoryClick = (category: MenuCategory) => {
  if (category.signature === 'beverages') { // if beverage category
    push('/menu/beverage');
  } else if (category.signature?.includes('supplements')) { // if pizza supplement category
    const ids = menuCart.value.map(i => i.id);
    if (menuCart.value.find(i => ids.includes(i.id) && i.category?.signature === 'pizza')) { // if pizza in cart
      push(`/menu/list/${ category.id }`);
    } else {
      setTimeout(() => renderNotification('food.no_selected_pizza'), 0);
    }
  } else {
    push(`/menu/list/${ category.id }`);
  }
};

const setWorkingState = () => {
  const date = new Date();
  const hours = date.getHours();
  isWorking.value = !(hours < +menuSetup.value?.workTime?.from?.slice(0, 2) || hours >= +menuSetup.value?.workTime?.to?.slice(0, 2));
};

onMounted(() => {
  checkFunction('food');
  setWorkingState();
  timer.value = setInterval(setWorkingState, 60_000);
});

onBeforeUnmount(() => clearInterval(timer.value));
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/list";

.panel {
  &__title {
    margin-bottom: 1.5rem;
    @include font(18px, 22px, white, 500);
  }
}

.menu-list__item {
  &-time {
    margin-left: 0.25rem;
    @include font(12px, 14px, black, 500);
  }
}

.empty {
  margin: 30vh auto auto;
  @include font(24px, 30px, white, 500, center);
  text-wrap: balance;
}
</style>
