<template>
  <div class="services" :class="`services--${menuItems.length}`">
    <p class="services__title">{{ $t('service.title') }}</p>
    <div v-if="!isLoading" class="services__list">
      <div v-for="item in menuItems" :key="item.id" class="services__list-item"
           :class="{'disabled': !isLinkActive && !['chat', 'info', 'booking'].includes(item.icon)}"
           @click="() => onServiceClick(item)">
        <div class="services__list-item__icon" :class="`service-icon--${item.icon}`"></div>
        <p class="services__list-item__text">{{ getTranslation(item.name) }}</p>
      </div>
    </div>
    <loader-panel v-else />
  </div>

</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useHotelMenuStore } from "@/stores/hotelMenuStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { HotelMenuItem } from "@/types/HotelSetup";
import { useTranslation } from "@/composable/useTranslation";

const hotelMenuStore = useHotelMenuStore();
const { menuItems } = storeToRefs(hotelMenuStore);
const userStore = useUserStore();
const { isLinkActive } = storeToRefs(userStore);
const { push } = useRouter();
const { getTranslation } = useTranslation();

const isLoading = ref(true);

const onServiceClick = (service: HotelMenuItem) => {
  if (service.linkType === 'inner') {
    push(service.link);
  } else {
    window.open(service.link, '_blank');
  }
};

onMounted(async () => {
  await hotelMenuStore.getMenuItems();
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/service-icon";

.services {
  @extend .flex-col;
  height: 100%;
  min-height: max-content;

  &--4 .services__list-item__icon {
    width: 23vw;
    height: 23vw;
    background-size: 23vw;
  }

  &__title {
    margin-bottom: 1rem;
    @include font(5.6vw, 122%, white, 500);
  }

  &__list {
    @extend .flex-row;
    flex-wrap: wrap;
    height: 100%;

    &-item {
      @extend .col-container;
      justify-content: center;
      flex: 1;
      position: relative;
      min-width: calc((100dvw - 2rem * 2) / 2);
      width: calc((100dvw - 2rem * 2) / 2);
      max-width: calc((100dvw - 2rem * 2) / 2);
      padding: 0 0 1rem;
      box-sizing: border-box;

      &.disabled {
        pointer-events: none;

        .services__list-item__icon, .services__list-item__text {
          opacity: 0.5;
        }
      }

      &:not(:nth-child(1)):not(:nth-child(2)) {
        padding-top: 1rem;

        &:before {
          position: absolute;
          top: 0;
          @include before(calc(100%), 0);
          border-bottom: 1px solid #FFE50080;
        }
      }

      &:nth-child(2n - 1) {
        margin-right: 1rem;
      }

      &:nth-child(2n) {
        margin-left: 1rem;

        &:after {
          position: absolute;
          top: 0;
          left: -1rem;
          @include before(0, 100%);
          border-left: 1px solid #FFE50080;
        }
      }

      &__icon {
        width: 17.8vw;
        height: 17.8vw;
        margin-bottom: 0.75rem;
      }

      &__text {
        @include font(4.58vw, 122%, white, $align: center);
        text-wrap: balance;
      }
    }
  }

  .loader {
    min-height: calc(100% - 2.5rem);
  }
}

@media (max-height: 780px) {
  .services__list-item {
    padding-bottom: 1vh;

    &:not(:nth-child(1)):not(:nth-child(2)) {
      padding-top: 1vh;
    }

    &__icon {
      width: 7dvh;
      height: 7dvh;
      background-size: 7dvh;
    }

    &__text {
      font-size: 2dvh;
    }
  }

  .services--4 .services__list-item__icon {
    width: 11dvh;
    height: 11dvh;
    background-size: 11dvh;
  }

  .services--2 {
    .services__list-item__icon {
      width: 13dvh;
      height: 13dvh;
      margin-bottom: 1rem;
      background-size: 13dvh;
    }

    .services__list-item__text {
      font-size: 3dvh;
    }
  }
}
</style>
