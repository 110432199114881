export const sauna = {
  sauna_vat_title: 'Сауна и чаны',
  price: 'Прайслист',
  vat: 'Чан',
  about_vat: 'Про Чан',
  name: 'Название',
  price_per_hour: 'Цена\\время',
  max_person: 'Макс. кол-во человек',
  choose_service: 'Выберите услугу',
  choose_date: 'Укажите дату и время, на протяжении которого ви желаете сауну или чан',
  hours: 'Количество часов',
  free_time: 'Выберите свободное время',
  payment: '*Оплата бронирование осуществляется через администратора.',
  sauna_1: 'Финская сауна с гималайской солью',
  sauna_1_vat_1: 'Финская сауна + карпатский чан (один)',
  sauna_1_vat_2: 'Финская сауна + карпатские чаны (два)',
  vat_1: 'Карпатский чан (один) + зона барбекю',
  vat_2: 'Карпатские чаны (два) + зона барбекю',
  pool_jacuzzi_massage: 'Бассейн + джакузи + водный массаж',
};
