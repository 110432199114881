<template>
  <popup-container :title="data.serviceType" @close="onClose">
    <div v-if="data?.details" class="info">
      <div class="list-item__left">
        <p class="list-item__info">
          <span>{{ $t('reserve_time') }}:</span>
          <span>{{ moment(data.datetime).format('DD.MM.YYYY HH:mm') }}</span>
        </p>
        <p class="list-item__info">
          <span>{{ $t('date') }}:</span>
          <span>{{ moment(data.date).format('DD.MM.YYYY') }}</span>
        </p>
        <p class="list-item__info">
          <span>{{ $t('time') }}:</span>
          <span>{{ data.time }}</span>
        </p>
        <p v-if="data.details?.people" class="list-item__info">
          <span>{{ $t('people') }}:</span>
          <span>{{ data.details.people }}</span>
        </p>
      </div>
    </div>
    <p class="list-item__text">{{ data.serviceName }}</p>
    <template v-if="data.status !== 'cancelled'">
      <base-button :text="$t('button.cancel')" @click="$emit('cancel')" />
      <base-button :text="$t('info.contact_admin')" @click="redirectToAdminTg" />
    </template>
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { PropType } from "vue";
import { RequestDetails, RequestStatus } from "@/types/Request";
import moment from "moment/moment";
import { useButton } from "@/composable/useButton";
import { ReservationDetails, ReservationStatus } from "@/types/Reservation";

interface ListItem {
  id: number;
  status: ReservationStatus | RequestStatus;
  datetime: string;
  date: string;
  time?: string;
  type: 'request' | 'reservation';
  comment?: string;
  serviceType: string;
  serviceName?: string;
  details?: RequestDetails | ReservationDetails;
}

defineProps({
  data: {
    type: Object as PropType<ListItem>,
    default: () => ({})
  },
});
const emits = defineEmits(['close', 'cancel']);

const { redirectToAdminTg } = useButton();

const onClose = () => emits('close');
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve-list";
@import "../../../assets/styles/components/details-popup";

.info {
  @extend .flex-row;
  justify-content: space-between;
  margin-bottom: 18px;
}

.list-item {
  &__info {
    width: 100%;

    & > span:first-child {
      width: 4.5rem;
    }
  }

  &__left {
    gap: 18px;
  }

  &__right-top {
    flex-direction: row;
  }

  &__left + &__right-top {
    flex-direction: column;
  }

  &__text {
    width: 100%;
    margin-bottom: 2.25rem;
    @include font(18px, 22px, black, 500);
  }
}

.info {
  width: 100%;
}

.desk {
  .list-item__right-top p:first-child {
    margin-bottom: 0.75rem;
  }
}
</style>
