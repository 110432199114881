export const gazebo = {
  chs_title: 'За бажанням ви можете забронювати альтанку для сумісного відпочинку з родиною чи друзями',
  td_title: 'За бажанням ви можете забронювати альтанку для сумісного відпочинку з родиною чи друзями. В ціну бронювання альтанки також входить мангал та дрова.',
  work_time: 'Альтанки відкриті від {from} до {till}.',
  price_title: 'Ціна бронювання альтанки:',
  price_subtitle: '(оплата за бронювання альтанки передбачає собою будь-яку кількість годин в рамках графіку роботи альтанок)',
  choose_date: 'Вкажіть дату та час, протягом якого ви потребуєте альтанку',
  small_gazebo: 'Альтанка на 8-10 чол.',
  small_gazebo_2: 'Альтанка на 8-10 чол. № 2',
  small_gazebo_4: 'Альтанка на 8-10 чол. № 4',
  small_gazebo_6: 'Альтанка на 8-10 чол. № 6',
  large_gazebo: 'Альтанка на 15-30 чол.',
  large_gazebo_1: 'Альтанка на 15-30 чол. № 1',
  large_gazebo_3: 'Альтанка на 15-30 чол. № 3',
  large_gazebo_5: 'Альтанка на 15-30 чол. № 5',
  about_title: 'Про альтанки',
  time_from: 'З',
  time_to: 'До',
  td_small_gazebo_1: 'Альтанка на 10 чол. № 1',
  td_small_gazebo_2: 'Альтанка на 10 чол. № 2',
  td_small_gazebo_3: 'Альтанка на 10 чол. № 3',
  td_small_gazebo_4: 'Альтанка на 10 чол. № 4',
  td_small_gazebo_5: 'Альтанка на 10 чол. № 5',
  td_small_gazebo_6: 'Альтанка на 10 чол. № 6',
  td_small_gazebo_7: 'Альтанка на 10 чол. № 7',
  td_small_gazebo_8: 'Альтанка на 10 чол. № 8',
  td_small_gazebo_9: 'Альтанка на 10 чол. № 9',
  td_large_gazebo_1: 'Альтанка на 20 чол. № 1',
};
