export const advert = {
  chorna_skelya_sauna: {
    title: 'Сауна с элементами гималайской соли',
    text: 'Вважається надзвичайно корисною, так як при нагріванні приміщення повітря насичується наявними в солі барієм, залізом, магнієм, йодом, кальцієм, марганцем і ін. корисними мікроелементами. ',
  },
  chorna_skelya_gazebo: {
    title: 'Сумісний відпочинок з родиною і друзями в Альтанці',
    text: 'Відпочинок з друзями і родиною в альтанці комплексу "Чорна Скеля" - це чудова можливість провести час разом, насолоджуючись атмосферою природи та затишком цього мальовничого місця.',
  },
  chorna_skelya_vat: {
    title: 'Карпатський чан з травами',
    text: 'Відчуйте користь від оздоровчого чана, дозволяючи гідромасажу розслабити ваші м\'язи, покращити кровообіг та відновити енергію, щоб насолоджуватися повним відчуттям благополуччя та відпочинку разом з друзями і родиною.',
  },

  teremok_info: {
    title: 'Приватна садиба "Теремок"',
    text: 'Унікальний котеджний комплекс "Теремок" - затишні котеджі, мангал, баня, мальовничі краєвиди - усе на території комплексу для вас.',
  },

  astur_quad: {
    title: 'Катання на квадроциклах разом з Astur Spa',
    text: 'Відчуйте бурхливі емоції та перепливайте природні перешкоди нашими гірськими маршрутами на квадроциклах. '
  },
  astur_bicycle: {
    title: 'Оренда велосипедів в Готелі Astur',
    text: 'Оренда велосипедів в готелі АСТУР - це відмінна можливість розширити свої можливості для відпочинку та відкриття нових горизонтів'
  },
  astur_spa: {
    title: 'SPA - комплекс разом із Astur SPA',
    text: 'Тут ви знайдете все необхідне для максимального розслаблення: великий басейн з джерельною водою, дитячий басейн, сауну та джакузі.'
  },

  shepit_carpat_salt_room: {
    title: 'Соляная комната',
    text: 'Окунитесь в атмосферу чистоты и восстановления, где миллионы микроскопических частиц соли распыляются в воздухе, предоставляя вам возможность ощутить настоящий отдых для легких и кожи.',
  },
  shepit_carpat_bath: {
    title: 'Релакс в Бане',
    text: 'Ощутите атмосферу настоящего расслабления и восстановления сил в нашей сауне с деревянными стенами и природными ароматами.',
  },
  shepit_carpat_ski_eq: {
    title: 'Горнолыжное снаряжение',
    text: 'Гостиничный комплекс "Амико" рад предложить своим гостям удобную услугу по аренде горнолыжного снаряжения.',
  },
  shepit_carpat_rooms: {
    title: 'Ваш лучший отдых ждет вас',
    text: 'Насладитесь невероятным отдыхом в нашем комфортабельном отеле премиум класса.',
  },
  shepit_carpat_spa: {
    title: 'СПА центр - ваш путь к гармонии души и тела',
    text: 'Насладитесь спа процедурами в нашем комфортабельном отеле премиум класса.',
  },

  tur_dvir_gazebo: {
    title: 'Зумісний відпочинок з родиною і друзями в Альтанці',
    text: 'Відпочинок з друзями і родиною в альтанці - це чудова можливість провести час разом, насолоджуючись атмосферою природи та затишком цього мальовничого місця.',
  },
  tur_dvir_vat: {
    title: 'Неповторний досвід у наших лікувальних Печеринських чанах',
    text: 'Ці чани, сповнені чистої гірської води та спеціально підготовленими Карпатськими травами та секретними домішками, обіцяють вам незабутні відчуття розслаблення та оздоровлення.',
  },
  tur_dvir_fishing: {
    title: 'Риболовля у "Турянському Дворі"',
    text: 'Риболовля - чудова можливість провести час з рідними та друзями, ділитися враженнями та спільно насолоджуватися природою.',
  },
};
