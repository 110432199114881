import { defineStore } from 'pinia';
import { MenuCartItem } from "@/types/App/Cart";
import { ref } from "vue";
import { Notification } from "@/types/App/Notification";
//import { HotelSetup } from "@/types/Hotel/HotelSetup";

export const useAppStore = defineStore('appStore', () => {
  const menuCart = ref<MenuCartItem[]>([]);
  const unreadNotifications = ref<Notification[]>([]);
  //const settings = ref<HotelSetup>({} as HotelSetup);

  return {
    menuCart, unreadNotifications, /*settings*/
  };
}, {
  persist: true
});
