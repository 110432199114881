export const service = {
  title: 'Twoja przenośna usługa',
  cleaning: 'Sprzątanie',
  taxi: 'Taxi',
  desk: 'Recepcja',
  menu: 'Menu i Napoje',
  booking: 'Rezerwacja',
  info: 'Informacje o Hotelu',
  chat: 'Czat 24/7',
  gazebo: 'Altana',
  laundry: 'Usługi Pralnicze',
  sauna: 'Sauna',
  bath: 'Kąpiel',
  fishing: 'Wędkarstwo',
  spa: 'SPA',

  quad_instructor: 'Jazda po górach z instruktorem',
  bicycle_rent: 'Wypożycz rower',
  cottage_3_room: 'Domek 3-pokojowy',
  cottage_4_room: 'Domek 4-pokojowy',

  hookah_classic: 'Klasyczny',
  hookah_hard: 'Mocny',
  hookah_extra_hard: 'Bardzo Mocny',
  hookah_patriot: 'Patriota (Fajka wodna autora, sok cytrusowy w kolbie. Część środków jest przekazywana na potrzeby Sił Zbrojnych!)',
  hookah_death: 'Śmierć wrogom (Fajka wodna autora, czerwone wino w kolbie. Część środków jest przekazywana na potrzeby Sił Zbrojnych!)',
  hookah_orange_cup: 'Pomarańczowy Kubek',
  hookah_pina_colada: 'Pina Colada',
  hookah_add_1: 'Dodatek do kolby',
  hookah_add_2: 'Poduszka',
};
