export const info = {
  additional: 'Дополнительные услуги',
  general: 'Основная информация',
  massage: 'Массаж',
  gazebo: 'Альтанка',
  laundry: 'Прачечная',
  sauna: 'Сауна и чаны',
  about_chorna_skelya: 'Про готель "Чорна Скеля"',
  about_teremok: 'Про готель "Теремок"',
  about_astur: 'Про готель "Astur Spa"',
  about_beregynia: 'Про готель "Берегиня"',
  about_shepit_carpat: 'Про отель "Шепот Карпат"',
  about_shepit_carpat_r: 'Про отель "Aurora Luxe Resort"',
  about_melodia_hir: 'Про отель "Мелодия Гор"',
  leisure: 'Досуг',
  restaurant: 'Ресторан',
  spa: 'SPA зона',
  policy: 'Политика Отеля',
  faq: 'FAQ',
  booking_details: 'Детали бронирования',
  order_food: 'Заказать еду',
  contact_admin: 'Связаться с администратором',
  make_reservation: 'Зарезервировать',
  other: 'Другое',
  my_reserves: 'Мои бронирования и запросы',
  booking_history: 'История бронирований',
  chat: 'Чат 24/7',
  relax_in_vat: 'Отдых в чанах',
  quad_biking: 'Квадроциклы',
  bicycle_rental: 'Прокат велосипедов',
  about_spa_zone: 'Про СПА зону',
  about_entertainment: 'Про развлечение',
  about_cottage: 'Про котедж',
  about_hookah: 'Про кальяны',
  about_service: 'Про услугу',
  price_list: 'Прайслист',
  hookah: 'Кальян',
  bath: 'Баня',
  salt_room: 'Соляная комната',
  ski_eq: 'Горнолыжное снаряжение',
  tours: 'Tours',
  review: 'Отзыв',
  fishing: 'Рыбалка',
  tour_3d: '3D Тур',
  benefits: 'Преимущества процедур',
  time_from: 'с',
  time_to: 'До',
  choose_date: 'Выберите дату и время, в течении которого вы планируете воспользоваться услугой',
  people_amount: 'Укажите количество людей',
};
