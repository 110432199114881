export const chorna_skelya = {
  massage_title_1: 'Classic Massage',
  massage_text_1: 'If you feel pain, severe tension in the back or other parts of the body, constant discomfort - a general classic body massage is an excellent option!',
  massage_title_2: 'Lymphatic Drainage Massage',
  massage_text_2: 'Helps to remove excess fluid from the body. This is a special massage technique that improves blood circulation and triggers processes in the body responsible for metabolism, toxin elimination, etc. It is an effective procedure for weight loss, fighting cellulite, improving metabolism, and strengthening the immune system.',
  massage_title_3: 'Anti-Cellulite Massage',
  massage_text_3: 'A proven method for fighting cellulite. This massage improves blood circulation, activates the movement of lymph and fluid in the tissues. As a result, toxins, harmful substances, and excess fluid are removed from the body faster, and the orange peel effect is smoothed out.',
  massage_title_4: 'Health Massage',
  massage_text_4: 'This is an excellent means of restoring the entire body. It significantly improves well-being, gives a boost of energy, and has a beneficial effect on the condition of blood vessels, muscles, and internal organs.',
  massage_title_5: 'Relaxing Massage',
  massage_text_5: 'A very pleasant soft procedure that helps relieve psychophysiological tension and is a remedy for headaches.<br/>It is also recommended to perform this procedure for prolonged depression and sleep problems.',
  massage_title_6: 'MIOGYM Face Sculpting Massage',
  massage_text_6: 'A facial massage technique aimed at strengthening and toning the facial muscles, slowing down the process of muscle atrophy, smoothing wrinkles, and correcting the face contour.',
  massage_title_7: 'Kinesio Taping',
  massage_text_7: 'An effective method that provides fixation of muscles, tendons, and joints, reduces pain, and lowers the likelihood of injury. Kinesio taping helps reduce painful sensations, alleviate inflammatory processes, and relax tense and overworked muscles.<br/>It also provides lymphatic drainage and anti-cellulite effects.',
  massage_title_8: 'Back and Neck-Collar Zone Massage',
  massage_text_8: '- relieves muscle-tonic syndrome of the collar area;<br/>- improves posture;<br/>- normalizes the functioning of the respiratory organs;<br/>- improves blood supply, thereby saturating the brain with oxygen and eliminating headaches.',
}
