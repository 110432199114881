import { messages } from "@/locales/ua/messages";
import { button } from "@/locales/ua/button";
import { service } from "@/locales/ua/service";
import { cleaning } from "@/locales/ua/services/cleaning";
import { taxi } from "@/locales/ua/services/taxi";
import { desk } from "@/locales/ua/services/desk";
import { info } from "@/locales/ua/pages/info";
import { laundry } from "@/locales/ua/services/laundry";
import { food } from "@/locales/ua/pages/food";
import { beverage } from "@/locales/ua/pages/beverage";
import { menu } from "@/locales/ua/services/menu";
import { sauna } from "@/locales/ua/services/sauna";
import { gazebo } from "@/locales/ua/services/gazebo";
import { advert } from "@/locales/ua/advert";
import { error } from "@/locales/ua/error";
import { notification } from "@/locales/ua/notification";
import { infoPriceList } from "@/locales/ua/pages/info_price_list";
import { booking } from "@/locales/ua/services/booking";
import { chorna_skelya } from "@/locales/ua/hotels/chorna_skelya";
import { bath } from "@/locales/ua/services/bath";

export const ua = {
  room: 'Кімната',
  from: 'з',
  from_price: 'від',
  till: 'до',
  or: 'або',
  today: 'Сьогодні',
  tomorrow: 'Завтра',
  asap: 'Найближчим часом',
  uah: 'грн',
  usd: '$',
  notifications: 'Сповіщення',
  empty_notifications: 'Немає сповіщень',
  per_hour: 'година',
  reservation: 'Бронювання',
  date: 'Дата',
  new_messages: 'нових повідомлень',
  duration: 'Тривалість',
  minutes: 'хвилин',
  current: 'Поточні',
  finished: 'Завершені',
  time: 'Час',
  room_number: 'Номер кімнати',
  placed_time: 'Запит розміщено',
  taken_time: 'Запит підтверджено',
  preference: 'Бажаний час',
  no_clean: 'Прибирання не потрібне',
  comment: 'Коментар',
  cancel_reason_label: 'Введіть причину відмови',
  cancel_title: 'Скасування запиту',
  tech_cancel_message: 'Ваш запит було скасовано з технічних причин. Ви можете змінити дату та час запиту або зв\'язатись з адміністратором',
  message_404: 'Цієї сторінки не існує',
  request_empty: 'Ви ще не робили запитів та бронювань',
  oops: 'Упс',
  not_allowed: 'У Вас немає доступу до цієї сторінки',
  free_time: 'Вільний час',
  reserve_time: 'Час запиту',
  hotel: 'Готель',
  people: 'Людей',
  service_name: 'Послуга',

  ...messages,
  price_list: infoPriceList,
  button,
  service,
  cleaning,
  taxi,
  desk,
  booking,
  info,
  laundry,
  food,
  beverage,
  menu,
  sauna,  // contains texts for sauna, vat and bath services
  gazebo,
  bath,
  advert,
  error,
  notification,

  spa: {
    spa_1: 'Классический массаж всего тела с маслами',
    spa_2: 'Массаж шейного отдела',
    spa_3: 'Шоколадное обвертывание',
    choose_date: 'Укажите дату и время, когда Вы хотите воспользоваться СПА',
  },

  fishing: {
    fishing_1: 'Риболовля',
    work_time: 'Риболовля доступна з 9:00 до 22:00 без обмеження по тривалості бронювання',
    choose_date: 'Вкажіть час, протягом якого ви бажаєте риболовити',
    people_amount: 'Вкажіть кількість людей',
  },

  form: {
    enter_comment: 'Введіть коментар',
    enter_text: 'Введіть текст',
    enter_address: 'Введіть адресу',
  },

  adverts: {
    title: 'Урізноманітніть Вашу відпустку',
  },

  qr_room: {
    ust_table_1: 'Стіл 1',
    ust_table_2: 'Стіл 2',
    ust_table_3: 'Стіл 3',
    ust_table_4: 'Стіл 4',
    ust_table_5: 'Стіл 5',
    ust_table_6: 'Стіл 6',
    ust_table_7: 'Стіл 7',
    ust_table_8: 'Стіл 8',
    ust_table_9: 'Стіл 9',
    ust_table_10: 'Стіл 10',
    td_gazebo_1: 'Альтанка 1',
    td_gazebo_2: 'Альтанка 2',
    td_gazebo_3: 'Альтанка 3',
    td_gazebo_4: 'Альтанка 4',
    td_gazebo_5: 'Альтанка 5',
    td_gazebo_6: 'Альтанка 6',
    td_gazebo_7: 'Альтанка 7',
    td_gazebo_8: 'Альтанка 8',
    td_gazebo_9: 'Альтанка 9',
    td_gazebo_10: 'Альтанка 10',
    td_bath_1: 'Баня 1',
    td_vat_1: 'Чан 1',
    td_vat_3: 'Чан 2',
  },

  summary: {
    title: 'Підсумок',
    article: 'Назва',
    amount: 'Кіл-сть',
    price: 'Ціна',
    total: 'Всього',
    hours: 'Годин',
    h: 'Годин',
    m: 'Хвилин',
    d: 'Днів',
    u: 'Кіл-сть',
  },

  payment: {
    title: 'Оплата',
    details: 'Деталі оплати',
    choose_method: 'Оберіть метод оплати',
    apple: 'Apple Pay',
    google: 'Google Pay',
    card_number: 'Номер картки',
    card_date: 'Місяць/Рік',
    card_cvv: 'CVV',
    cvv_hint: 'Код на зворотній стороні картки',
    pay_success: 'Дякуємо! Ваше замовлення вже опрацьовується!\nВаш рахунок вже тут.',
    download_invoice: 'Завантажити чек',
    payment_info: '*Оплата здійснюється на місці.',
  },

  status: {
    new: 'Очікує на підтвердження',
    active: 'Підтверджений',
    cancelled: 'Скасований',
    finished: 'Завершений',
  },

  event: {
    success_title: 'Вітаємо!',
    error_title: 'Щось пішло не так',
    cancel_title: 'Ваш запит було скасовано',
    tech_cancel_title: 'Ваш запит було скасовано з технічних причин. Детальніше...',
    taken: 'Ми почали виконувати Ваш запит з відділу "{type}"!',
    cancelled: 'Ваш запит {type} було скасовано. Детальніше...',
    finished: 'Ваш запит {type} було завершено!',
    booking_cancel_title: 'Бронювання скасовано!',
    booking_cancel_text: 'Ваше бронювання кімнати було скасовано',
    booking_confirm_title: 'Бронювання підтверджено!',
    booking_confirm_text: 'Ваше бронювання кімнати було підтверджено',
  },

  unit: {
    gr: 'гр',
    prt: 'порція',
    pc: 'шт',
    l: 'л',
    ml: 'мл',
    bottle: 'пляшка',
  },

  data: {
    cancel_reason_1: 'Послуга недоступна',
    cancel_reason_2: 'Немає необхідного персоналу',
    cancel_reason_3: 'Немає необхідного обладнання',
    cancel_reason_4: 'Немає вільного часу',
    cancel_reason_5: 'Немає вільних столиків',
    tech: 'Технічні роботи'
  },

  // hotels' texts
  ch_sk: chorna_skelya,
};
