import { useI18n } from "vue-i18n";
import { FormLocalizedValue } from "@/types/App/Form";

export const useTranslation = () => {
  const i18n = useI18n();

  const getTranslation = (value: FormLocalizedValue) => {
    const locale = i18n.locale.value;
    if (value?.[locale]) {
      return value[locale];
    } else {
      return Object.values(value)?.[0] ?? '-';
    }
  };

  const hasTranslation = (value: FormLocalizedValue | null | undefined) => {
    return Object.keys(value ?? {})?.length > 0 && Object.values(value ?? {})?.find(v => !!v);
  };

  return { getTranslation, hasTranslation };
};
