<template>
  <loader-panel v-if="isLoading" />
  <div v-else class="panel">
    <page-header :title="$t(getTranslation(infoPage?.title))" :back-link="backLink" />
    <div class="panel__content">
      <images-panel v-if="infoPage.images?.length" :images="imagesSrc" />
      <panel-tabs v-if="infoPage.tabs?.length" :selected-tab="selectedTab" :tabs="infoPage.tabs"
                  @tab-click="v => selectedTab = v" />
      <template v-if="selectedTab === 0">
        <template v-for="(article, key) in infoPage.articles" :key="key">
          <p v-if="hasTranslation(article.title)" class="panel__title" :style="article?.titleStyle ?? ''">
            {{ getTranslation(article.title) }}
          </p>
          <p v-html="getTranslation(article.text)" class="panel__text" :style="article?.textStyle ?? ''"></p>
        </template>
      </template>

      <template v-else-if="infoPage.priceList">
        <p v-if="hasTranslation(infoPage.priceList?.title)" class="panel__prices-title">
          {{ getTranslation(infoPage.priceList.title) }}:
        </p>
        <p v-if="hasTranslation(infoPage.priceList?.subtitle)" class="panel__prices-subtitle">
          ({{ getTranslation(infoPage.priceList.subtitle) }})
        </p>
        <table v-if="infoPage.priceList?.cols && services?.length">
          <thead>
          <tr>
            <td v-for="col in infoPage.priceList.cols" :key="col">{{ getTranslation(col.name) }}</td>
          </tr>
          </thead>
          <tbody>
          <template v-for="(service, key) in services" :key="key">
            <tr>
              <td>{{ getTranslation(service.name) }}</td>
              <td>
                <span>{{ service.price }}{{ $t(userStore.currency) }}</span>
                <span v-if="service?.timeValue || service?.timeUnit">
                  / {{ service?.timeValue ? service.timeValue : '' }}{{ $t(`price_list.${service.timeUnit}`) }}
                </span>
              </td>
              <td v-if="infoPage.priceList?.cols?.length === 3">{{ service.people }}</td>
            </tr>
          </template>
          </tbody>
        </table>
        <p v-if="hasTranslation(infoPage.priceList?.bottomText)" class="panel__prices-bottom">
          {{ getTranslation(infoPage.priceList.bottomText) }}
        </p>
      </template>
    </div>
    <base-button v-for="(button, key) in infoPage.buttons" :key="key" :text="getTranslation(button.label)"
                 :color="button.color ?? 'main'" @click="onClick(button)" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import PanelTabs from "@/components/PanelTabs.vue";
import ImagesPanel from "@/components/ImagesPanel.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useTranslation } from "@/composable/useTranslation";
import { useApi } from "@/composable/useApi";
import { useServiceStore } from "@/stores/serviceStore";
import { FormButton } from "@/types/App/Form";

const route = useRoute();
const router = useRouter();
const hotelSetupStore = useHotelSetupStore();
const { infoPage, hotelSetup } = storeToRefs(hotelSetupStore);
const userStore = useUserStore();
const serviceStore = useServiceStore();
const { services } = storeToRefs(serviceStore);
const { getTranslation, hasTranslation } = useTranslation();
const { getApiUrl } = useApi();
const { onButtonClick } = useHotelSetup();

const imagesSrc = ref<string[]>([]);
const isLoading = ref(true);
const selectedTab = ref(0);

const backLink = computed((): string => {
  const prevRoute = router.options?.history?.state?.back as string;
  const slicedRoute = prevRoute?.slice(0, prevRoute.indexOf('#'));
  if (slicedRoute === '/') {
    return '/';
  } else {
    return infoPage.value.backLink ?? '/info';
  }
});

const onClick = (button: FormButton) => {
  if (button.link === '/menu' && hotelSetup.value.food.type === 'external' && hotelSetup.value.food.link) {
    window.open(hotelSetup.value.food.link, '_blank');
  } else {
    onButtonClick(button);
  }
};

onMounted(async () => {
  await hotelSetupStore.getInfoPage(+route.params.pageId);
  infoPage.value.images?.map(async (path) => {
    const image = new Image();
    image.src = getApiUrl() + path;
    image.addEventListener('load', () => {
      const src = image.getAttribute('src');
      src && imagesSrc.value.push(src);
    });
  });

  if (infoPage.value?.priceList?.services?.length) {
    await serviceStore.getServices(infoPage.value.priceList.services);
  }

  isLoading.value = false;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/info";

video {
  width: 100%;
  max-height: 60dvh;
  margin-bottom: 1rem;
}
</style>
