export const infoPriceList = {
  spa_zone_title: 'Ціна послуг СПА зони',
  spa_zone_subtitle: 'Бронювання сауни здійснюється мінімум на 2 години та мінімум для 4 чоловік',
  sauna_vat_title: 'Ціна бронювання сауни/чану',
  sauna_vat_subtitle: 'Бронювання можливе мінімум на 3 години, оплата бронювання відбувається через адміністратора',
  vat_title: 'Ціна бронювання чану',
  vat_subtitle: 'Оплата здійснюється через адміністратора',
  quad_title: 'Ціна бронювання катання на квадроциклі',
  bicycle_title: 'Ціна бронювання катання на велосипеді',
  cottage_title: 'Ціна бронювання котеджу',
  cottage_subtitle: 'Бронювання передбачає передоплату у розмірі 50%',
  gazebo_title: 'Ціна бронювання альтанки',
  gazebo_subtitle: 'Оплата за бронювання альтанки передбачає собою будь-яку кількість годин в рамках графіку роботи альтанок',
  hookah_title: 'Ціна кальянів',
  bath_title: 'Ціна бронювання бані',
  bath_subtitle: 'Оплата бронювання відбувається через адміністратора',
  spa_title: 'Ціна бронювання СПА',
  spa_subtitle: 'Оплата бронювання відбувається через адміністратора',
  fishing_title: 'Ціна бронювання рибалки',
  fishing_subtitle: 'Оплата за бронювання рибалки передбачає собою будь-яку кількість годин в рамках графіку роботи',

  hour: 'год',
  day: 'доба',
  minute: 'хв',
  unlimited: 'безліміт',
  h: 'год',
  d: 'доба',
  m: 'хв',
  u: 'безліміт',

  cols: {
    name: 'Назва',
    price_per_hour: 'Ціна\\год',
    price_per_day: 'Ціна\\доба',
    people_amount: 'Кіл-ть осіб',
    max_people_amount: 'Макс. кіл-ть осіб',
  }
};
