export const taxi = {
  location: 'Вкажіть Ваше розташування',
  where_to: 'Куди прямуємо?',
  when: 'Коли?',
  choose_time: 'Оберіть час',
  regular: 'Звичайний',
  comfort: 'Комфорт',
  comment: 'Якщо Ви маєте якісь додаткові потреби (дитяче крісло, бус, тощо), залиште коментар:',
  order_guide: 'Будь ласка, зателефонуйте за цим номером, якщо Ви хочете замовити таксі чи трансфер.',
  from: 'З',
  to: 'До',
  type: 'Тип',
};
