export const messages = {
  request_success: 'Запрос был успешно отправлен',
  reservation_received: 'Спасибо! Ми получили ваше бронирование! Скоро вы получите оповещение про подтверждено!',
  reservation_accepted: 'Ваше бронирование было подтверждено',
  success_cancel: 'Ваш запрос был успешно отменен',
  restaurant_repair: 'Заказ еды недоступен. Ресторан находится в процессе ремонта',
  booking_success: 'Запрос на бронирование успешно отправлен! Дальнейшие детали будут отправлены на указанную Вами электронную почту',
  review_success: 'Спасибо! Ваш отзыв был успешно отправлен',
  booking_cancel_success: 'Ваше бронирование было успешно отменено!',
};
