import InfoView from "@/views/Info/InfoView.vue";
import MassageView from "@/views/Info/MassageView.vue";
import LaundryView from "@/views/Info/Laundry/LaundryView.vue";
import LaundryPaymentView from "@/views/Info/Laundry/LaundryPaymentView.vue";
import ReservationsView from "@/views/Info/ReservationsView.vue";
import { RouteRecordRaw } from "vue-router";
import InfoPageView from "@/views/Info/InfoPageView.vue";
import ReviewView from "@/views/Info/ReviewView.vue";
import InfoReserveView from "@/views/Info/InfoReserveView.vue";

export const infoRoutes: RouteRecordRaw = {
  path: '/info',
  children: [{
    path: '',
    name: 'info',
    component: InfoView
  }, {
    path: 'massage',
    name: 'info-massage',
    component: MassageView
  }, {
    path: 'laundry',
    name: 'info-laundry',
    component: LaundryView
  }, {
    path: 'laundry/pay',
    name: 'info-laundry-pay',
    component: LaundryPaymentView
  }, {
    path: 'reserves',
    name: 'info-reserves',
    component: ReservationsView
  }, {
    path: 'page/:pageId',
    name: 'info-page',
    component: InfoPageView
  },{
    path: 'page/:pageId/reserve',
    name: 'info-page-reserve',
    component: InfoReserveView,
  },{
    path: 'review/:requestId',
    name: 'info-review',
    component: ReviewView
  },],
};
