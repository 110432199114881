<template>
  <div class="panel">
    <page-header :title="$t(isSummary ? 'summary.title' : 'service.booking')" :is-custom-handler="true"
                 @back="onBackClick" />
    <loader-panel v-if="isLoading" />
    <room-reserve-form v-else-if="!isSummary" :initial="data" @submit="setData" />
    <room-reserve-summary v-else :data="data" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useBookingStore } from "@/stores/bookingStore";
import { useRoomStore } from "@/stores/roomStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import RoomReserveForm from "@/components/Booking/RoomReserveForm.vue";
import { BookingCreateData } from "@/types/Booking";
import RoomReserveSummary from "@/components/Booking/RoomReserveSummary.vue";
import { storeToRefs } from "pinia";

const { params } = useRoute();
const { push } = useRouter();
const roomStore = useRoomStore();
const bookingStore = useBookingStore();

const data = ref<BookingCreateData>({} as BookingCreateData);
const isLoading = ref(true);
const isSummary = ref(false);

const setData = (v: BookingCreateData) => {
  data.value = v;
  isSummary.value = true;
};

const onBackClick = () => {
  if (isSummary.value) {
    isSummary.value = false;
  } else {
    push(`/booking/rooms/${ params.roomTypeId }`);
  }
};

onMounted(async () => {
  await roomStore.getRoomByType(+params.roomTypeId);
  await bookingStore.getRoomBookingSlots(+params.roomTypeId);
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.panel {
  :deep(.panel__content) {
    @extend .flex-col;
    gap: 1rem;
  }
}

.loader {
  margin-top: -4rem;
}
</style>
