export const sauna = {
  sauna_vat_title: 'Sauna i Vat',
  about_vat: 'O Vacie',
  vat: 'Vat',
  price: 'Cennik',
  name: 'Nazwa',
  price_per_hour: 'Cena\\godzina',
  max_person: 'Maks. liczba osób',
  choose_service: 'Wybierz usługę',
  choose_date: 'Określ datę i godzinę, kiedy potrzebujesz sauny lub vat',
  hours: 'Godziny',
  free_time: 'Wybierz dostępny czas',
  payment: '*Płatność za rezerwację odbywa się przez administratora.',
  sauna_1: 'Sauna fińska z solą himalajską',
  sauna_1_vat_1: 'Sauna fińska + Vat Karpacki (jeden)',
  sauna_1_vat_2: 'Sauna fińska + Vaty Karpackie (dwa)',
  vat_1: 'Vat Karpacki (jeden) + strefa do grillowania',
  vat_2: 'Vaty Karpackie (dwa) + strefa do grillowania',
  pool_jacuzzi_massage: 'Basen + jacuzzi + masaż wodny',
};
