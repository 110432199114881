import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { Service } from "@/types/Service";
import { request } from "@/stores/request";

export const useServiceStore = defineStore('serviceStore', () => {
  const { getErrorMessage } = useApi();

  const services = ref<Service[]>([]);
  const error = ref('');

  const getServices = async (ids: number[]) => {
    try {
      services.value = await request({ url: `service/list`, method: 'post', data: ids });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    services, error,
    getServices,
  };
});
