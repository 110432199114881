<template>
  <div class="panel">
    <page-header back-link="/" />
    <div class="panel__content">
      <form-select v-if="serviceSetup.category" v-model="data.category" :options="categoriesOptions" :is-index="true"
                   :label="$t('desk.category_label')" color="white" :placeholder="$t('desk.category_placeholder')" />
      <template v-if="serviceSetup.comment">
        <p class="label">{{ $t('desk.text') }}</p>
        <form-textarea v-model="data.comment" :placeholder="$t('form.enter_text')" color="white" />
      </template>
      <base-button class="send" :class="{'disabled': !isButtonActive}" :text="$t('button.send_request')"
                   @click="onSendClick" />
      <div class="panel__or">
        <div class="panel__or-line panel__or-line--left"></div>
        <p class="panel__or-text">{{ $t('or') }}</p>
        <div class="panel__or-line panel__or-line--right"></div>
      </div>
      <base-button class="chat" :text="$t('button.chat_whit_us')" @click="onContactClick" />
      <banners-panel />
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import BannersPanel from "@/components/Banner/BannersPanel.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { computed, onMounted, ref } from "vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { SelectOption } from "@/types/App/Form";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { useButton } from "@/composable/useButton";
import { RequestDetails } from "@/types/Request";
import { useRouter } from "vue-router";
import { useRequestStore } from "@/stores/requestStore";
import { storeToRefs } from "pinia";
import { useRender } from "@/composable/useRender";
import { useUserStore } from "@/stores/userStore";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { HotelSetupReception } from "@/types/HotelSetup";
import { useTranslation } from "@/composable/useTranslation";

const { redirectToTg } = useButton();
const { push } = useRouter();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const userStore = useUserStore();
const requestStore = useRequestStore();
const { error } = storeToRefs(requestStore);
const { renderNotification } = useRender();
const { checkFunction } = useHotelSetup();
const { getTranslation } = useTranslation();

const data = ref({
  category: -1,
  comment: '',
});

const serviceSetup = computed((): HotelSetupReception => hotelSetup.value.reception ?? {});

const categoriesOptions = computed((): SelectOption[] => serviceSetup.value?.categories?.map((c, index) => ({
  value: index,
  label: getTranslation(c),
})))

const isButtonActive = computed((): boolean => {
  if (serviceSetup.value?.comment && serviceSetup.value?.category) {
    return !!data.value.comment || data.value.category >= 0;
  } else if (serviceSetup.value?.comment) {
    return !!data.value.comment;
  } else {
    return data.value.category >= 0;
  }
});

const onSendClick = async () => {
  const details: RequestDetails = {};
  if (data.value.category >= 0) {
    details.category = data.value.category;
  }

  await requestStore.createRequest({
    type: 'desk',
    linkId: userStore.clientLinkData.id,
    comment: data.value.comment,
    details
  });
  if (!error.value) {
    setTimeout(() => renderNotification('request_success'), 100);
    push('/');
  }
};

const onContactClick = () => {
  if (hotelSetup.value?.adminPhone) {
    window.open(`https://t.me/${ hotelSetup.value.adminPhone }`, '_blank');
  } else {
    redirectToTg();
  }
};

onMounted(() => checkFunction('reception'));
</script>

<style scoped lang="scss">
.base-button.send {
  margin: 1.5rem 0 0.75rem;
}

.base-button.chat {
  margin-bottom: 1.5rem;
  background-color: white;
}

.label {
  margin: 1.5rem 0 0.5rem;
}
</style>
