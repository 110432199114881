export const infoPriceList = {
  spa_zone_title: 'Ceny usług strefy SPA',
  spa_zone_subtitle: 'Rezerwacja sauny odbywa się na minimum 2 godziny i dla co najmniej 4 osób',
  sauna_vat_title: 'Cena rezerwacji sauny/waty',
  sauna_vat_subtitle: 'Rezerwacja możliwa na minimum 3 godziny, płatność odbywa się przez administratora',
  vat_title: 'Cena rezerwacji waty',
  vat_subtitle: 'Płatność odbywa się przez administratora',
  quad_title: 'Cena rezerwacji jazdy na quadzie',
  bicycle_title: 'Cena wynajmu roweru',
  cottage_title: 'Cena rezerwacji domku',
  cottage_subtitle: 'Rezerwacja wymaga wpłaty zaliczki w wysokości 50%',
  gazebo_title: 'Cena rezerwacji altany',
  gazebo_subtitle: 'Płatność za rezerwację altany obejmuje dowolną liczbę godzin w ramach godzin pracy altan',
  hookah_title: 'Ceny shishy',
  bath_title: 'Cena rezerwacji sauny',
  bath_subtitle: 'Rezerwacja płatna przez administratora',
  spa_title: 'Cena rezerwacji spa',
  spa_subtitle: 'Rezerwacja płatna przez administratora',
  fishing_title: 'Cena rezerwacji wędkowania',
  fishing_subtitle: 'Płatność za rezerwację wędkowania obejmuje dowolną liczbę godzin w ramach godzin pracy wędkowania',

  hour: 'h',
  day: 'd',
  minute: 'min',
  h: 'h',
  d: 'd',
  m: 'min',
  unlimited: 'bez limitu',

  cols: {
    name: 'Nazwa',
    price_per_hour: 'Cena/godz',
    price_per_day: 'Cena/dzień',
    people_amount: 'Liczba osób',
    max_people_amount: 'Maks. liczba osób',
  }
};
