import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { Request, RequestDetails } from "@/types/Request";

export const useRequestStore = defineStore('requestStore', () => {
  const { getErrorMessage } = useApi();

  const requests = ref<Request[]>([]);
  const error = ref('');

  const createRequest = async (data: Partial<Request>) => {
    try {
      await request({ url: 'request', method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const cancelRequest = async (requestId: number, data: RequestDetails) => {
    try {
      await request({ url: `request/cancel/${ requestId }`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getCustomerRequests = async (linkId: number) => {
    try {
      requests.value = await request({ url: `request/customer/${ linkId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    error, requests,
    createRequest, getCustomerRequests, cancelRequest,
  };
});
