<template>
  <div class="panel">
    <page-header :title="$t('info.massage')" back-link="/info" />
    <div class="panel__content">
      <div v-for="item in items" :key="item.id" class="panel__item" :class="{'panel__item--expanded': item.isExpanded}">
        <div class="panel__item-header" @click="item.isExpanded = !item.isExpanded">
          <p class="panel__item-name">{{ $t(item.name) }}</p>
          <div class="panel__item-arrow"></div>
        </div>
        <p v-html="$t(item.description)" class="panel__item-text"></p>
        <p v-if="item.duration" class="panel__item-duration">
          {{ $t('duration') }} - {{ item.duration }} {{ $t('minutes') }}
        </p>
        <p class="panel__item-price">{{ $t('summary.price') }} - <b>{{ item.price }} {{ $t(userStore.currency) }}</b>
        </p>
      </div>
    </div>
    <base-button :text="$t('button.order_massage')" @click="redirectToContact" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { ref } from "vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { HotelMassageItem } from "@/types/Hotel/HotelService";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();

const items = ref<(HotelMassageItem & { isExpanded: boolean })[]>([
  {
    id: 1,
    name: 'ch_sk.massage_title_1',
    description: 'ch_sk.massage_text_1',
    duration: 60,
    price: 700,
    isExpanded: false,
  },
  {
    id: 2,
    name: 'ch_sk.massage_title_2',
    description: 'ch_sk.massage_text_2',
    duration: '60-70',
    price: 800,
    isExpanded: false,
  },
  {
    id: 3,
    name: 'ch_sk.massage_title_3',
    description: 'ch_sk.massage_text_3',
    duration: 60,
    price: 700,
    isExpanded: false,
  },
  {
    id: 4,
    name: 'ch_sk.massage_title_4',
    description: 'ch_sk.massage_text_4',
    duration: 60,
    price: 700,
    isExpanded: false,
  },
  {
    id: 5,
    name: 'ch_sk.massage_title_5',
    description: 'ch_sk.massage_text_5',
    duration: 60,
    price: 700,
    isExpanded: false,
  },
  {
    id: 6,
    name: 'ch_sk.massage_title_6',
    description: 'ch_sk.massage_text_6',
    duration: '24',
    price: 400,
    isExpanded: false,
  },
  {
    id: 7,
    name: 'ch_sk.massage_title_7',
    description: 'ch_sk.massage_text_7',
    duration: '',
    price: `від 200`,
    isExpanded: false,
  },
  {
    id: 8,
    name: 'ch_sk.massage_title_8',
    description: 'ch_sk.massage_text_8',
    duration: 30,
    price: 400,
    isExpanded: false,
  }
]);

const redirectToContact = () => window.open('https://t.me/+380982348206', '_blank');
</script>

<style scoped lang="scss">
.panel {
  &__content {
    @extend .flex-col;

  }

  &__item {
    @extend .flex-col;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #FFFFFF80;
    }

    &--expanded {
      .panel__item-arrow {
        transform: rotate(0.5turn);
      }
    }

    &:not(.panel__item--expanded) {
      .panel__item-text {
        @extend .clip-line--2;
      }

      .panel__item-duration, .panel__item-price {
        display: none;
      }
    }

    &-header {
      @extend .flex-row;
      justify-content: space-between;
    }

    &-name {
      @include font(24px, 28px, white, 700);
    }

    &-arrow {
      margin-top: 4px;
      margin-left: 0.5rem;
      @include icon($select-arrow, 1.5rem, 1.5rem);
      transition-duration: 0.2s;
    }

    &-text {
      @include font(18px, 22px, white, 600);
    }

    &-duration, &-price {
      @include font(16px, 19px, white, 500);
    }
  }
}
</style>
