export const menu = {
  //subcategories
  cold_appetizers: 'Холодные закуски',
  hot_appetizers: 'Горячие закуски',
  poultry_dishes: 'Блюда с птицы',
  veal_dishes: 'Блюда с телятины',
  pork_dishes: 'Блюда с свинины',
  chief_specialties: 'Фирменные блюда от шефа',
  ust_cold_appetizers: '"Куркульські запаси" (холодні закуски)',
  ust_salads: '"Від душі"',
  ust_soups: '"Полуденок опришка" (перші страви)',
  ust_bread: 'Хлібний кошик',
  ust_main_dishes: '"З пилу-жару" (другі страви)',
  ust_caucasian_guest: '"Кавказький гість"',
  ust_fish: '"Зі ставу до столу"',
  ust_hot: '"Рідненьке"',
  ust_sides: '"Не м\'ясом єдиним"',
  ust_dessert_with_tea: '"Біля чаю не скучаю..."',
  ust_homemade_pastry: '"Випічка домашня"',
  ust_cold_desserts: '"Солодка прохолода"',
  banosh_with_cream: 'Банош на вершках',
  cremeshnit: 'Кремзлики',

  // supplement categories
  sauces_supp: 'Соуси',
  vegetables_supp: 'Овочеві',
  meats_supp: 'М\'ясні',
  cheeses_supp: 'Сирні',

  caesar_chicken: 'Салат "Цесар" с куркою',
  caesar_salmon: 'Салат "Цесар" с лососем',
  caesar_shrimp: 'Салат "Цесар" с креветками',
  warm_salad: 'Теплий салат с курячою печінкою ',
  cabbage_salad: 'Салат с капусти',
  veg_assorted: 'Асорті овочеве',
  greek_salad: 'Салат "Грецький"',
  marinated_assorted: 'Мариноване асорті',
  caesar_chicken_details: 'Салат айсберг, філе курки, перепелине яйце, помідори, пармезан, сухарики',
  caesar_salmon_details: 'Салат айсберг, помідори, пармезан, сухарики,лосось, перепелине яйце',
  caesar_shrimp_details: 'Салат айсберг, креветки, чері, огірок, пармезан, кунжут, оливкова олія, лимон',
  warm_salad_details: 'Мікс салатів, печінка куряча, спеції, оливкова олія',
  veg_assorted_details: 'Помідори, огірки, перець, капуста',

  broth: 'Бульйон с локшиною',
  broth_details: 'Куряча грудинка, овочі',
  cheese_creamy_soup: 'Сирний крем суп с грінками',
  borsch: 'Борщ',
  cold_soup: 'Холодний літній суп',
  bograch: 'Бограч',
  mushroom_soup: 'Грибова юшка',
  minestrone: 'Мінестроне',

  barbecue_pork: 'Шашлик сі свинини',
  barbecue_chicken: 'Шашлик с курятини',
  veg_grill: 'Овочі печені на грилі',
  stake_pork: 'Стейк іс свинячого ошийка',
  stake_chicken: 'Стейк с курячої грудинки',
  sausage_grill: 'Ковбаска домашня сапечена',
  ribs_grill: 'Ребра сапечені',
  potato_grill: 'Картопля печена на плоті',
  meat_set: 'М′ясна дошка для гурту (на 6 осіб)',
  barbecue_chicken_details: 'Куряче бедро',
  potato_grill_details: 'З цибулею та домашньою олією',
  meat_set_details: 'Шашлик зі свинини, шашлик з курятини, ребра, ковбаски',

  eggs: 'Яєчня',
  bacon_eggs: 'Яєчня с беконом',
  omelet_cheese: 'Омлет с сиром',
  omelet_veg: 'Омлет с овочевим соте',
  euro: 'Європейський сніданок',
  euro_details: 'Яйця, сосиски, квасоля, масло, грінки',
  crepes: 'Млинці в асортименті',
  oatmeal: 'Вівсяна каша с фруктами',
  cheesecakes: 'Сирники с повидлом\\сметаною',
  pancakes: 'Панкейки',

  meat_antipasti: 'М\'ясне антипасті',
  cheese_plate: 'Сирна тарілка (на 4 осіб)',
  cheese_plate_details: 'Камамбер, пармезан, моцарелла міні, дорблю, горіховий',
  trio_salo: 'Тріо сала',
  salmon_bruschetta: 'Брускети с лососем',
  avocado_bruschetta: 'Брускети с авокадо та в\'яленим томатом',
  prosciutto_bruschetta: 'Брускети с прошуто та крем-сиром',

  tenderloin: 'Виріска по "Королівськи"',
  chicken_caprese: 'Курка "Капресе"',
  meat_lozzi: 'М\'ясо "Лоці печене"',

  carp: 'Короп смажений на мангалі',
  trout: 'Форель по-селянськи (на пательні)',
  trout_details: 'Цибуля, кукурудзяна крупа',
  trout_grill: 'Форель на грилі',
  salmon_spinach: 'Філе лосося на шпинатній подушці',
  dorado_grill: 'Дорадо на грилі',
  dorado_veg: 'Філе дорадо с овочами та лаймовим соусом',

  varenyky_potato: 'Вареники с картоплею та сметаною',
  varenyky_meat: 'Вареники с шкварками та цибулею',
  home_dumplings: 'Пельмені по-домашньому с маслом',
  pasta: 'Паста с креветками та цукіні',
  spaghetti_carbonara: 'Спагетті "Карбонара"',
  spaghetti_fettuccine: 'Спагетті "Фетучіні"',
  spaghetti_carbonara_details: 'Бекон, вершки, пармезан',
  spaghetti_fettuccine_details: 'Гриби, вершки, пармезан',

  deruny_meat: 'Деруни с м′ясом',
  deruny_cream: 'Деруни с сметаною',
  deruny_mushroom: 'Деруни с грибами та сметаною',
  risotto_mushroom: 'Рісотто с грибами',
  risotto_shrimp: 'Рісотто с креветками',
  brynza_banosh: 'Банош с бринсою та шкварками',
  pan: 'Сковорідка по-домашньому',
  pan_chicken: 'Сковорідка с курячими сердечками та печінкою',
  pan_details: 'Картопля, ковбаса домашня, цибуля, сир, яйця, вершки',
  pan_chicken_details: 'Сердечка, печінка, печериці, сир, вершки',

  satsebeli: 'Соус сацебелі',
  ketchup: 'Кетчуп',
  pishta: 'Пішта',

  oatmeal_kids: 'Вівсяна каша с маслом',
  choco_balls: '"ШоКо кульки" с молоком',
  buckwheat: 'Каша гречана с маслом',
  potato_kids: 'Картопля відварна с вершковим маслом',
  rice_butter: 'Рис с маслом',

  espresso_oriental: 'Еспресо "Oriental"',
  americano_oriental: 'Американо "Oriental"',
  viennese_coffee: 'Кава по-віденськи',
  cappuccino: 'Капучіно натуральне',
  latte: 'Лате',
  perfetto: 'Перфетто',
  iced_coffee: 'Ice coffee',
  cacao: 'Какао',
  tea: 'Чай заварний',
  tea_fruit: 'Чай фруктовий',
  cappuccino_details: 'Еспресо, молоко',
  latte_details: 'Еспресо, молоко',
  perfetto_details: 'Еспресо, молоко, морозиво, бейліз, вершки',
  cacao_details: 'Шоколад, збите молоко',

  pilsner: 'Pilsner',
  kellerbier: 'Kellerbier',
  non_alcohol_beer: 'Бесалкогольне пиво',
  staropramen: 'Staropramen',
  heineken: 'Heineken',
  carpathian: 'Закарпатське',
  no_filter: 'Пиво нефільтроване',
  staropramen_details: 'ж\\б',

  citro: 'Росливний лимонад, квас',
  juice: 'Соки',
  hell: 'Hell',
  lu: 'Лужанська',
  morshyn: 'Моршинська',
  coca: 'Кола',
  pepsi: 'Пепсі',
  glass: 'стекло',

  metaxa_brandy: '"Метакса"',
  old_kakheti_brandy: '"Старий Кахеті"',
  zakarpatsky_brandy: '"Закарпатський"',
  tisa_brandy: '"Тиса"',
  uzhgorod_brandy: '"Ужгород"',
  carpati_brandy: '"Карпати"',
  atenos_brandy: '"Атенос"',
  riga_balsam: 'Бальсам "Ріга"',
  tequila: 'Напій "Текіла"',
  absinthe: 'Напій "Абсент"',
  becherovka: 'Напій "Бехеровка"',
  campari: 'Напій "Кампарі"',
  captain_morgan: 'Ром "Captain Morgan"',
  jagermaster: 'Бальсам "Єгермастер"',
  soplitsa: 'Настоянка "Сопліца"',
  starka_reserv: 'Настоянка "Starka Reserv"',
  tyorkina: 'Наливка "Тьоркіна"',
  finland: 'Фінляндія',
  zubrovka_charna: 'Зубровка Чарна',
  zubrovka: '"Зубровка"',
  lvivska: '"Львівська"',
  lviv_gold: '"Lvoff Gold"',
  jameson: '"Джеймсон"',
  chivas_regal: '"Чівас Регал"',
  jack_daniels: '"Джек Деніелс"',
  red_label: '"Ред Лейбл"',
  sambuca: '"Самбука"',
  baileys: '"Бейліс"',
  amaretto: '"Амаретто"',
  wine_chevalier_de_france: '"Chevalier de France"',
  wine_carpathian: '"Закарпатське"',
  wine_villa_puccini: '"Villa Puccini"',
  wine_sarsitano: '"Сарсітано"',
  champagne_fragolino: '"Фраголіно"',
  champagne_martini_asti: '"Мартіні Асті"',
  champagne_prosecco: '"Proseco"',
  pc_3: '3 шт',
  pc_4: '4 шт',
  pc_5: '5 шт',

  mojito_non_alcohol: 'Мохито',
  citro_fruit: 'Лимонад фруктовий',
  milk_shake: 'Мілк шейк',
  in_assortment: 'в ассортименте',

  pina_collada: 'Піна Колада',
  hirosima: 'Хіросіма',
  sunrise: 'Схід',
  envy: 'Засдрість',
  green_coast: 'Зелений берег',
  rome_sunset: 'Ромовий сахід',
  cuba_libre: 'Куба лібре',
  tom_collins: 'Том Коллінс',
  pink_cat: 'Рожева кішечка',
  garibaldi: 'Гарибальді',
  tequila_boom: 'Текіла бум',
  man_size: 'Чоловічий росмір',
  mojito: 'Мохито',
  aperol_syringe: 'Апероль Шприц',
  hurricane: 'Ураган',
  green_fairy: 'Зелена фея',
  long_island: 'Лонг айленд',
  daiquiri_bella_donna: 'Дайкірі Белла Донна',
  pina_collada_details: 'ананасовый сок, белый ром, кокосовый ликер',
  hirosima_details: 'гренаденский сироп, самбука, ликер Baileys, абсент',
  sunrise_details: 'orange juice, grenadine syrup, tequila',
  envy_details: 'ананасовый сок, текила, ликер "Blu curacao"',
  green_coast_details: 'lemon juice, lemon, absinthe, tequila',
  rome_sunset_details: 'вишневый сок, ром, апельсин',
  cuba_libre_details: 'rum, cola, lime',
  tom_collins_details: 'gin, лимонный сок, сироп, содовая',
  pink_cat_details: 'ананасовый сок, джин, гренадин, грейпфрутовый сок',
  garibaldi_details: 'апельсиновый сок, настойка кампари, оранжевый',
  tequila_boom_details: 'tequila, sprite',
  man_size_details: 'absinthe, grenadine, ананасовый сок, ликер Pisang ambon',
  mojito_details: 'rum, sprite, lime, syrup, mint',
  aperol_syringe_details: 'champagne dry, aperol, sprite, orange',
  hurricane_details: 'rum white, rum black, настойка Campari, сок: лимон, ананас, апельсин; сироп ',
  green_fairy_details: 'текила, абсент, водка, белый ром, ликер "синий curacao", сок, энергия, дынный ликер',
  long_island_details: 'gin, vodka, rum white, tequila, TripleSec liqueur, juice, Coca-Cola',
  daiquiri_bella_donna_details: 'liqueur "Amaretto", черный ром, лимонный сок',

  meat_in_french: 'М\'ясо по-француськи',
  chop: 'Відбивна',
  shank: 'Голяшка',
  egg: 'Яєшня',
  omelet: 'Омлет',

  dumplings: 'Пельмені',
  potato_varenyky: 'Вареники іс картоплею',
  cheese_crepes: 'Млинці с творогом',
  sweet_crepes: 'Млинці іс сгущеним молоком',
  bread: 'Хліб',
  banosh: 'Банош',

  veg_soup: 'Суп овочевий',
  mug_broth: 'Бульйон у кружці',
  chicken_broth: 'Бульйон с курячого філе',
  uha: 'Уха',
  borshch: 'Борщ',
  solyanka: 'Солянка',

  assorted_salad: 'Салат "Асорті"',
  caesar: 'Салат "Цесар"',
  salted_cucumbers: 'Солені огірки',
  sauerkraut: 'Квашена капуста',

  assorted_meat: 'М\'ясна наріска',
  assorted_cheese: 'Сирна наріска',
  salo: 'Сало',
  ham: 'Шинка',

  potato_french: 'Картопля фрі',
  potato_mashed: 'Картопляне пюре',
  potato_salo: 'Картопля с салом',
  potato_grilled: 'Картопля на мангалі',
  rice: 'Рис',
  kremzlyky: 'Кремслики сі сметаною',

  sea_bass_fish: '"Сібас"',
  dorado_fish: '"Дорадо"',
  trout_fish: '"Форель"',
  carp_fish: '"Короп"',
  salmon_stake: 'Стейк лосося',
  pork_kebab: 'Шашлик свинячий',
  pork_stake: 'Стейк свинячий',
  pork_rib: 'Ребра свинячі',
  chicken_kebab: 'Шашлик курячий',
  chicken_stake: 'Стейк курячий',
  sausages: 'Ковбаски',
  sturgeon_stake: 'Стейк осетра',
  sturgeon_fish: '"Осетр"',

  kebab_sauce: 'Соус до шашлику',
  brynza: 'Бринса',
  mayo: 'Майонес',
  mustard: 'Гірчиця',

  ice_cream: 'Моросиво',
  fruit_dessert: 'Десерт фруктовий',
  fruit_assorted: 'Фруктова наріска',
  lemon: 'Лимон',

  cheese_pigtail: 'Сир коса',
  chips: 'Чипси',
  salted_nut: 'Горішки солоні',

  fanta: 'Фанта',
  sprite: 'Спрайт',
  mineral_water_glass: 'Минеральная вода (стекло)',
  sandora_juice: 'Сік "Сандора"',
  uzvar: 'Усвар',
  sitro: 'Ситро',

  espresso: 'Кава еспресо',
  cappuccino_natural: 'Капучино натуральне',
  americano: 'Американо',
  cappuccino_lafesta: 'Капучино "Лафеста',
  coffee_3_in_1: 'Кава "3 в 1"',
  custard_tea: 'Чай саварний',
  cream: 'Вершки',

  brandy_zakarpatsky: '"Закарпатський"',
  brandy_shereuli: '"Шереулі"',
  whiskey: 'Віскі',
  horilka_hemiroff_premium: '"Німіров Преміум"',
  horilka_davnya_legenda: '"Давня Легенда"',
  champagne_asti: '"Асті"',
  wina_old_carpatia: '"Олд Карпатія"',
  wina_alaverdi: '"Алаверді"',
  wina_homemade: 'Вино домашнє',
  beer_lvivske_svitle: '"Львівське Світле"',
  beer_tubourg: '"Туборг"',
  beer_non_alcohol: 'Бесалкогольне',
  beer_carlsberg: '"Карлсберг"',

  taistra_hutsula: 'Тайстра гуцула',
  cheese_plateau: 'Сирне плато',
  vegetable_palety: 'Овочева палітра',
  meat_taire: 'М\'ясний таїр',
  homemade_marinada: 'Домашні маринади',
  croutons_in_transcarpathian: 'Грінки по-сакарпатськи',
  assorted_pickled_mushrooms: 'Асорті маринованих грибів',
  white_mushroom_with_bowl: 'Гриб білий с цибулею',
  herring_at_home: 'Оселедець по-домашньому',
  machantsi: 'Мачанці',
  tongue_in_cream_sauce: 'Ясик у вершковому соусі',
  transcarpathian_cabbage_rolls: 'Голубці по-сакарпатськи',
  banosh_with_bryndza: 'Банош с бринсою',
  banosh_with_cep_mushrooms: 'Банош с білими грибами',
  pancakes_with_meat_and_mushroom_sauce: 'Млинці с м\'ясом та грибним соусом',
  lavash_with_cheese_and_tomatoes: 'Лаваш іс сиром та помідорами',
  lavash_with_chicken: 'Лаваш с куркою',
  taistra_hutsula_details: 'сало мариноване, сало копчене, сало поприкаж, грінки, огірки мариновані',
  cheese_plateau_details: 'сир «Моцарела бейбі», сир «Голандський», сир «Фета», бринза, мед',
  vegetable_palety_details: 'огірок, помідор, болгарський перець, капуста',
  meat_taire_details: 'буженина, язик яловичий, домашня ковбаса, хрін, соус',
  homemade_marinada_details: 'маринований огірок, маринований помідор, маринована слива, квашена капуста',
  croutons_in_transcarpathian_details: 'паштет курячий, паштет печінковий, хумус, грінки, цибулевий маринад',
  assorted_pickled_mushrooms_details: 'опеньки, лисички, білий гриб, корбан',
  herring_at_home_details: 'картопля (100гр), оселедець (100гр), цибуля (30гр)',
  machantsi_details: 'білі гриби у сметані',
  tongue_in_cream_sauce_details: '100/100гр',
  transcarpathian_cabbage_rolls_details: '200/50гр',
  pancakes_with_meat_and_mushroom_sauce_details: '200/50гр',

  caesar_default: 'Цесар',
  royal: 'Королівський',
  bukovinian: 'По-буковинськи',
  verkhovynskyi: 'Верховинський',
  caprese: 'Капресе',
  salad_with_grilled_vegetables_and_chicken: 'Салат с овочами гриль та курятиною',
  warm_beef_salad: 'Теплий салат с телятиною',
  gracia: 'Грація',
  caesar_default_details: 'мікс салату, куряче філе, помідори, сир «Пармезан»',
  royal_details: 'опеньки, шинка, яйце, майонез, цибуля',
  bukovinian_details: 'буряк, мікс салату, сир «Фета», соус «Песто», грічично-медова заправка',
  verkhovynskyi_details: 'огірки, помідори, перець болгарський, бринза, часник, сметана',
  caprese_details: 'помідори, сир «Моцарела», мікс салату, соус «Песто»',
  salad_with_grilled_vegetables_and_chicken_details: 'мікс салату, овочі гриль, курятина, медово-гірчичний соус',
  warm_beef_salad_details: 'мікс салату, смажений болгарський перець, телятина',
  gracia_details: 'огірки, помідори, куряче філе, шампіньйони, майонез, грецький горіх',

  bogrash: 'Бограш',
  bogrash_in_bread_pot: 'Бограш в хлібному глечику',
  fish_soup: 'Юшка рибна',
  chicken_broth_2: 'Бульйон курячий',
  carpathian_mushroom_soup: 'Карпатська грибна юшка',

  whisper_of_the_carpathians_with_gnocchi: '"Шепіт Карпат" с ньокі',
  chicken_fillet_in_egg: 'Куряче філе в яйці',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce: 'Куряча грудка на овочевій подушці під сирним соусом',
  chicken_in_verkhovynskyi_style: 'Чірке по-верховинськи',
  chicken_steak: 'Курячий стейк',
  chicken_steak_details: 'на вагу',
  medallions_with_mushroom_sauce_and_vegetable_puree: 'Медальйони с грибним соусом та овочевим пюре',
  beef_stroganoff: 'Бефстроганов',
  veal_with_chanterelles_in_sour_cream_sauce: 'Телятина с лисичками в сметанному соусі',
  cutlet: 'Відбивна',
  meat_in_poloninsky_style: 'М\'ясо по-полонинськи',
  cossack_power: '"Косацька сила"',
  loci_baked: 'Лоці печене',
  shashlik_by_weight: 'Шашлик на вагу',
  gazdivski_ribs_by_weight: 'Гасдівські ребра на вагу',
  meat_set_for_company: 'М\'ясний сет на компанію',
  shank_by_weight: 'Голяшка на вагу',
  lamb_leg_by_weight: 'Бараняча ніжка на вагу',
  duck_with_apples_and_orange_sauce_by_weight: 'Качка с яблуками та апельсиновим соусом на вагу',
  whisper_of_the_carpathians_with_gnocchi_details: 'курка (150гр), гриби (100гр), сир (50гр)',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce_details: '300/50гр',
  chicken_in_verkhovynskyi_style_details: 'куряче філе, гриби, сметана',
  medallions_with_mushroom_sauce_and_vegetable_puree_details: '150/100/50гр',
  beef_stroganoff_details: 'телятина (100гр), цибуля та сметана (75гр)',
  veal_with_chanterelles_in_sour_cream_sauce_details: '150/100гр',
  cutlet_details: '100/50гр',
  meat_in_poloninsky_style_details: 'свинина під овочево-вершковим соусом, 100/100гр',
  cossack_power_details: 'стейк свинний на вагу',
  loci_baked_details: 'свинина під цибулевою шубою, 100/50гр',
  gazdivski_ribs_by_weight_details: 'свинні ребра під кисло-солодким соусом',
  meat_set_for_company_details: 'мисливські ковбаски, ковбаски гриль, стейк свинний, стейк курячий, ребра, запечена картопля, 1000/150гр',
  shank_by_weight_details: 'голяшка та домашні маринади, 1000/600гр',
  lamb_leg_by_weight_details: 'маринована в травах та запечена у тісті',
  duck_with_apples_and_orange_sauce_by_weight_details: '1000/150гр',

  baked_trout_by_weight: 'Форель сапечена на вагу',
  trout_in_cream_sauce_by_weight: 'Форель у вершковому соусі на вагу',

  skillet_with_vegetables_and_chicken: 'Сковорідка с овочами та куркою',
  skillet_with_vegetables_and_veal: 'Сковорідка с овочами та телятиною',
  vegetable_skillet: 'Сковорідка овочева',
  skillet_with_homemade_sausage_and_stewed_cabbage: 'Сковорідка с домашньою ковбасою та тушкованою капустою',

  kremzlyky_with_sour_cream: 'Кремслики с сметаною',
  kremzlyky_with_mushrooms: 'Кремслики с грибами',
  kremzlyky_hutsul_style: 'Кремслики по-гуцульськи',
  dumplings_with_potatoes_and_sour_cream: 'Вареники с картоплею та сметаною',
  homemade_dumplings: 'Пельмені по-домашньому',
  kremzlyky_with_mushrooms_details: '200/100гр',
  homemade_dumplings_details: '200/50гр',

  mashed_potatoes: 'Картопля пюре',
  homestyle_potatoes: 'Картопля по-домашньому',
  baked_potatoes_with_bacon_and_brynza: 'Картопля сапечена, с салом та бринсою',
  creole_potatoes: 'Картопля креольська',
  broccoli_with_butter: 'Брокколі с маслом',
  buckwheat_with_porcini_mushrooms: 'Гречка с білими грибами',
  assorted_roasted_vegetables_by_weight: 'Печені овочі в асортименті на вагу',
  baked_potatoes_with_bacon_and_brynza_details: '200/50гр',

  slalom_pizza: 'Slalom',
  athens_pizza: 'Афінська',
  vegetarian_pizza: 'Вегетаріанська',
  hawaiian_pizza: 'Гавайська',
  cheese_pizza: 'Сирна',
  mushroom_pizza: 'Грибна',
  spicy_pizza: 'Гостра',
  beer_pizza: 'До пива',
  calzone_sausage_pizza: 'Кальцоне с ковбасою',
  bechamel_pizza: 'Бешамель',
  carbonara_pizza: 'Карбонара',
  kyiv_pizza: 'Київська',
  cossack_pizza: 'Косацька',
  margherita_pizza: 'Маргарита',
  milanese_pizza: 'Міланська',
  four_seasons_pizza: '4-сесони',
  meat_pizza: 'М\'ясна',
  pepperoni_pizza: 'Пепероні',
  assorted_roasted_vegetables_by_weight_details: 'помідори, перець, цукіні, цибуля, печериці',
  slalom_pizza_details: 'томатний соус, сир "Моцарелла", свинина, ковбаса, буженина, печериці, яйце, кукурудза, перець болгарський, бекон, орегано',
  athens_pizza_details: 'томатний соус, сир "Моцарелла", сир "Фета", шинка, бекон, куряче філе, салямі, помідори, печериці, часник, зелень',
  vegetarian_pizza_details: 'томатний соус, сир "Моцарелла", овочі, орегано',
  hawaiian_pizza_details: 'томатний соус, сир "Моцарелла", сир "Моцарелла", ананаси, ковбаса, шинка, кукурудза, орегано',
  cheese_pizza_details: 'томатний соус, сир "Моцарелла", сир "Пармезан", сир "Рокфор", сир "Мисливський"',
  mushroom_pizza_details: 'томатний соус, сир "Моцарелла", печериці, яйце, цибуля, орегано',
  spicy_pizza_details: 'томатний соус, сир "Моцарелла", сир, перець болгарський, майонез, часник, перець чилі, салямі, печериці, цибуля маринована, орегано',
  beer_pizza_details: 'томатний соус, сир "Моцарелла", орегано',
  calzone_sausage_pizza_details: 'томатний соус, сир "Моцарелла", шинка, ковбаса, помідори, кукурудза, орегано',
  bechamel_pizza_details: 'вершковий соус, шинка, філе куряче, бекон, буженина, шампіньйони, сир "Моцарела"',
  carbonara_pizza_details: 'томатний соус, сир "Моцарелла", помідори, яйце, бекон, орегано',
  kyiv_pizza_details: 'томатний соус, сир "Моцарелла", свинина, куряче філе, перець болгарський, помідори, печериці, орегано',
  cossack_pizza_details: 'томатний соус, сир "Моцарелла", буженина, бекон, часник, печериці, кукурудза, зелень',
  margherita_pizza_details: 'томатний соус, сир "Моцарелла", помідори, орегано',
  milanese_pizza_details: 'томатний соус, сир "Моцарелла", куряче філе, помідори, кукурудза, яйце, орегано',
  four_seasons_pizza_details: 'томатний соус, шинка, куряче філе, бекон, шампіньони, салямі "Пепероні", ковбаски мисливські, сир "Моцарелла"',
  meat_pizza_details: 'томатний соус, сир "Моцарелла", буженина, бекон, ковбаски мисливські, перець болгарський, кукурудза, орегано',
  pepperoni_pizza_details: 'томатний соус, сир "Моцарелла", салямі пепероні, печериці, перець чилі, каперси, орегано',

  basturma: 'Бастурма',
  mahan: 'Махан',
  beer_tray: 'Пивний танір',
  spicy_ears: 'Вуха пікантні',
  wings_in_honey_soy_sauce: 'Крильця в медово-соєвому соусі',
  peanut: 'Арахіс',
  beer_tray_details: 'махан, бастурма, філе золотисте, буженина',

  pancakes_with_creme_and_chocolate: 'Млинці с вершками та шоколадом',
  pancakes_with_nuts_and_chocolate: 'Млинці с горіхом та шоколадом',
  pancakes_with_apples: 'Млинці с яблуками',
  pancakes_with_cottage_cheese_and_sour_cream: 'Млинці с творогом та сметаною',
  syrniki_with_jam_and_sour_cream: 'Сирники с джемом та сметаною',
  prunes_with_condensed_milk: 'Чорнослив іс сгущеним молоком',
  tiramisu: 'Тірамісу',
  cheesecake: 'Чіскейк',
  chocolate_fondant: 'Шоколадний фондан',
  apple_strudel_with_ice_cream: 'Штрудель с яблуком та моросивом',
  jam: 'Варення',
  natural_honey: 'Мед натуральний',
  ice_cream_cone: 'Стаканчик до моросива',
  assorted_fruits: 'Фрукти в асортименті',
  student_chocolate: 'Студентська шоколадка',
  dark_chocolate: 'Чорний шоколад',
  pancakes_with_creme_and_chocolate_details: '100/100гр',
  pancakes_with_nuts_and_chocolate_details: '200/50гр',
  pancakes_with_apples_details: '200/50гр',
  pancakes_with_cottage_cheese_and_sour_cream_details: '200/50гр',
  syrniki_with_jam_and_sour_cream_details: '150/50/50гр',
  prunes_with_condensed_milk_details: '150гр',
  tiramisu_details: '150гр',
  cheesecake_details: '150гр',
  chocolate_fondant_details: '100/50гр',
  apple_strudel_with_ice_cream_details: '100/50гр',

  hot_chocolate: 'Гарячий шоколад',
  milk: 'Молоко',
  matcha: 'Матча',
  green_tea: 'Зелений чай',
  black_tea: 'Чорний чай',
  herbal_tea: 'Трав\'яний чай',
  fruit_tea: 'Фруктовий чай',
  tea_with_ginger_and_raspberry: 'Чай с імбиром та малиною',
  tea_with_sea_buckthorn_ginger_and_orange: 'Чай с обліпихою, імбирем та апельсином',
  tea_with_blackberry_and_blueberry: 'Чай с ожиною та чорницею',

  chernigivske_bottle: 'Чернігівське пляшка',
  stella_bottle: 'Стелла пляшка',
  stella_non_alcoholic: 'Стелла б/а',
  corona_0_3l: 'Корона 0.3 л',
  stella_draft_0_5l: 'Стела бочка 0.5 л',

  tequila_sunrise: 'Текила санрайс',
  long_island_ice_tea: 'Лонг айленд айс ти',
  sex_on_the_beach: 'Секс на пляже',
  aperol: 'Апероль',
  green_mexican: 'Зеленый мексиканец',
  merzavchik: 'Мерpавчик',
  meduza: "Медуза",
  control_shot: "Контрольный выстрел",
  northern_hunter: "Северный охотник",
  b_52: "Б-52",
  b_53: "Б-53",
  bmw: "BMW",
  sambuka: "Самбука",
  margarita: "Маргарита",
  malibu: "Малибу",
  whiskey_cola: "Виски-кола",
  gin_tonic: "Джин-тоник",
  aperol_details: 'апероль, шампанское, газированная вода',
  tequila_sunrise_details: 'текила, апельсиновый сок, гренадин',
  long_island_ice_tea_details: 'водка, ром, джин, текила, триплсек, круги',
  sex_on_the_beach_details: 'водка, персиковый ликер, апельсиновый сок',
  mojito_details_2: 'белый ром, лайм, мята, спрайт, сахар',
  green_mexican_details: 'пизан, текила, лимонный фреш',
  merzavchik_details: 'водка, сок, гренадин',
  meduza_details: 'малибу, триплсек, белый ром, бейлис',
  control_shot_details: 'калуа, самбука, абсент',
  northern_hunter_details: 'калуа, самбука, егермейстер, водка',
  b_52_details: 'калуа, бейлис, триплсек',
  b_53_details: 'калуа, бейлис, абсент',
  bmw_details: 'бейлис, молибу, виски',
  tequila_boom_details_2: 'текила, лимонный сок, спрайт',
  sambuka_details: 'самбука, корица, кофейные зерна',
  margarita_details: 'текила, триплсек, лимонный фреш',
  malibu_details: 'малибу, сливки, апельсиновый сок',
  whiskey_cola_details: 'ред лейбл, кока-кола',
  gin_tonic_details: 'джин, тоник, лимон',

  milk_cocktail: "Молочный коктейль",
  coffee_cocktail: "Кофейный коктейль",
  milk_berry_cocktail: "Молочно-ягодный коктейль",
  banana_cocktail: "Банановый коктейль",

  artemivske_champagne: "Артемовское в ассорт.",
  asti_pl_italia_champagne: "Asti пл. Italia",
  lambrusco_italia_champagne: "Lambrusco Italia",
  prosecco_champagne: "Prosecco",
  chilean_wine_wine: "Чилийское вино 100г",
  italian_wine_wine: "Итальянское вино 100г",
  georgian_wine_wine: "Грузинское вино 100г",
  chateau_chizay_wine: "Шато Чизай выдр. 100г",
  mulled_wine_wine: "Глинтвейн 200г",
  tisa_cognac: "Тиса 50г",
  uzhgorod_cognac: "Ужгород 50г",
  karpaty_cognac: "Карпаты 50г",
  zakarpatskyi_cognac: "Закарпатский 50г",
  ararat_cognac: 'Арарат',
  hennessy_cognac: 'Hennessy',
  martell_cognac: 'Martell',
  khortytsia_vodka: "Хортица 50г",
  nemiroff_vodka: "Nemiroff 50г",
  finlandia_vodka: "Финляндия 50г",
  absolut_vodka: "Absolut 50г",
  lvivska_vodka: "Львовская 50г",
  baileys_liqueurs: "Baileys 50г",
  malibu_liqueurs: "Malibu 50г",
  sheridans_liqueurs: "Sheridans 50г",
  jim_beam_whiskey: "Jim Beam 50г",
  jack_daniels_whiskey: "Jack Daniels 50г",
  jameson_whiskey: "Jameson 50г",
  medleys_whiskey: "Medley's 50г",
  ballantines_whiskey: "Ballantine's 50г",
  jagermeister_infusions: "Егермейстер 50г",
  becherovka_infusions: "Бехеровка 50г",
  tequila_infusions: "Текила 50г",
  absinthe_infusions: "Абсент 50г",
  malinivka_infusions: "Малиновка 50г",
  zubrovka_infusions: "Зубровка 50г",
  kalganyvka_infusions: "Калгановка 50г",
  khrinivka_infusions: "Хреновуха 50г",
  beefeater_gin: "Beefeater 50г",
  black_rum: "Black 50г",
  gold_rum: "Gold 50г",
  martini_martini: "Мартини 50г",

  mirinda: "Миринда",
  seven_up: "7up",
  shweppes: "Швепс",
  shepit_karpat_water: "Вода \"Шепот Карпат\"",
  morshynska: "Моршинская 1.5л",
  juice_assorted: "Сок в ассортименте",
  mojito_non_alcoholic: "Мохито безалкогольный",
  orange_fresh: "Апельсиновый 200гр",
  carrot_apple_fresh: "Морковно-яблочный 200гр",
  apple_fresh: "Яблочный 200гр",
  grapefruit_fresh: "Грейпфрутовый 200гр",
  mix_vegetable_fruit_fresh: "Микс (овощи-фрукты) 200гр",
  beetroot_fresh: "Свекольный 200гр",
  lemon_fresh: "Лимонный 200гр",
  lemon_lemonade: "Лимонный лимонад 200гр",
  mojito_lemonade: "Мохито лимонад 200гр",
  orange_sea_buckthorn_lemonade: "Апельсин-облепиха лимонад 200гр",
  forest_berries_lemonade: "Лесные ягоды лимонад 200гр",
  maracuja_strawberry_raspberry_lemonade: "Маракуйя-клубника-малина лимонад 200гр",

  mayonnaise: 'Майонез',
  yerosh_pishto: 'Єрош Пішто',
  sour_cream: 'Сметанний',
  spicy_garlic: 'Гострий часниковий',
  tomatoes: 'Помідори',
  mushrooms: 'Печериці',
  bell_pepper: 'Перець болгарський',
  corn: 'Кукурудса',
  canned_pineapple: 'Ананас (консерв.)',
  olives: 'Маслини',
  onion: 'Цибуля',
  chili_pepper: 'Перець чилі',
  garlic: 'Часник',
  one_egg: 'Яйце',
  bacon: 'Бекон',
  buzhenina: 'Буженина',
  sausage: 'Ковбаса',
  hunting_sausages: 'Мисливські ковбаски',
  salami_sausage: 'Ковбаса "Салямі"',
  salami_pepperoni: 'Салямі пепероні',
  chicken_fillet: 'Філе куряче',
  mozzarella_cheese: 'Сир "Моцарелла"',
  parmesan_cheese: 'Сир "Пармезан"',
  feta_cheese: 'Сир "Фета"',
  roquefort_cheese: 'Сир "Рокфор"',
  hunting_cheese: 'Сир "Мисливський"',

  homemade_salo: 'Salce homemade',
  sausage_figurines: 'Sausage figli',
  gazda_s_cut: 'Slicing "In Gazdovsky"',
  cheese_cut: 'Cheese slicing',
  herring_do_charochky: 'Herring "To the glass"',
  salted_mushrooms: 'Salt cellar',
  basket_of_mushrooms: 'White Basket',
  gazda_s_cut_details: 'homemade sausage, smoked bacon, baked pork',
  salted_mushrooms_details: 'pickles and cabbage, pickled tomatoes',
  basket_of_mushrooms_details: 'white mushrooms pickled',

  zvernyhora: 'Zvernigora',
  beet_salad: 'Beetroot',
  carrot_salad: 'Carrot',
  zvernyhora_details: 'white cabbage, carrots, greens, oil',

  gutsul_soup: '"Hutsul soup" from white mushrooms',
  zakarpatskyi_bograch: 'Bograch "Transcarpathian"',
  homemade_borscht_with_smetana: 'Home borschik with sour cream',
  kogut_dance: 'Claw Dance',
  vegetable_soup: '"You girls" vegetable soup',
  solyanka_set: 'Solyanka national team',
  white_bread: 'White bread',
  grey_bread: 'Bread gray',
  rye_bread: 'Rye bread',
  kogut_dance_details: 'chicken broth with meat and noodles',
  vegetable_soup_details: 'vegetable soup',

  wild_dances: 'Wild Dances',
  gutsul_taistra: '"Hutsul Taistra"',
  fried_homemade_sausage: 'Fried homemade sausage',
  pork_steak_on_bone: 'Pork loin on the bone',
  bear_paw: 'Bear paw',
  pork_ribs: 'Pork ribs',
  beef_steak_with_mushroom_sauce: 'Beef steak with mushroom sauce',
  chicken_steak_with_tartar_sauce: 'Chicken steak with Tar-Tar sauce',
  pork_shashlik_with_sauce: 'Pork skewers with sauce',
  wild_dances_details: 'roast pork with onions and mushrooms',
  gutsul_taistra_details: 'chicken fillet with mushrooms and cheese',
  bear_paw_details: 'roasted pork in cheese breading',
  pork_shashlik_with_sauce_details: '200/50/50gr',

  baked_trout: 'Trout baked',
  baked_mackerel: 'Mackerel baked on fire',

  dumplings_with_meat_and_mushroom_sauce: 'Nalichniki with meat and mushroom sauce',
  dumplings_with_potatoes_bryndza_and_sour_cream: 'Dumplings with potatoes, cheese, cracklings and sour cream',
  dumplings_with_potatoes_and_butter_with_sour_cream: 'Dumplings with potatoes, butter and sour cream',
  dumplings_with_potatoes_and_mushroom_sauce: 'Dumplings with potatoes and mushroom sauce',
  homemade_dumplings_2: 'Homemade dumplings',
  deruny_with_sour_cream: 'Pancakes with sour cream',
  deruny_with_mushroom_sauce: 'Deruny with mushroom sauce',
  puzo_gutsula: 'Puzo gutsula',
  poloninsky_kulesha: 'Kulesha in Polonynsky',
  banosh_with_bryndza_and_shkvarky: 'Banosh with cheese and cracklings',
  puzo_gutsula_details: 'deruni with a mushroom sauce',
  poloninsky_kulesha_details: 'kukuruzian porridge 3 brinzoe ta pellets',

  dragobrat_potatoes: 'Potato "In Dragobratsky"',
  mashed_potato: 'Mashed potatoes',
  boiled_rice_with_vegetables: 'Rice boiled with vegetable assorted',
  dragobrat_potatoes_details: 'baked slices potatoes with lard and garlic',

  mushroom_sauce: 'Mushroom',
  pepper_sauce: 'Pepper',
  sour_cream_sause: 'Sour cream',
  tartar: 'Tar-tar',
  shashlik_sauce: 'Barbecue',
  horseradish: 'Horseradish',
  mushroom_sauce_details: 'porcini mushrooms in sour cream',
  pepper_sauce_details: 'cream, mixture of peppers',

  pancakes_with_honey: 'Nalichniki with honey',
  pancakes_with_jam: 'Nalichniki with jam',
  pancakes_with_cottage_cheese_and_sour_cream_2: 'Nalistniki with curd and sour cream',
  pancakes_with_chocolate: 'Nalichniki with chocolate',
  pancakes_with_condensed_milk: 'Nalichniki with condensed milk',
  apple_strudel_with_ice_cream_and_caramel: 'Strudel apple with ice cream and caramel',
  ice_cream_with_nuts_and_honey: 'Ice cream with nuts and honey',
  ice_cream_with_nuts_and_chocolate: 'Ice cream with nuts and chocolate',
  ice_cream_with_jam: 'Ice cream with jam',

  mors: 'Mors',

  appetizer_1: 'Тарталетки с лососем',
  appetizer_1_details: 'Тарталетки, крем-сыр, лосось, зелень',
  appetizer_2: 'Сырная тарелка',
  appetizer_2_details: 'Моцарела, пармезан, дор-блю, виноград, орехи',
  appetizer_3: 'Тартар из телятины',
  appetizer_3_details: 'Рубленная телятина, каперсы, лук, оливковое масло',
  appetizer_4: 'Карпачо из лосося',
  appetizer_4_details: 'Лосось, авокадо, лимон, кедровый орех',
  appetizer_5: 'Антипасти мясные',
  appetizer_5_details: 'Ассорти мяса, вяленые томаты, оливки',

  salad_1: 'Цезарь с курицей',
  salad_1_details: 'Микс салата, курица, помидоры черри, пармезан, соус Цезарь',
  salad_2: 'Салат Нисуаз',
  salad_2_details: 'Тунец, картофель, лист салата, оливки, помидоры черри, анчоусы',
  salad_3: 'Салат с авокадо и креветками',
  salad_3_details: 'Креветки, авокадо, помидоры черри, огурец, горчично-медовая заправка',
  salad_4: 'Салат с морепродуктами',
  salad_4_details: 'Бейби осьминоги, кальмары, креветки, микс салата',
  salad_5: 'Цезарь с лососем',
  salad_5_details: 'Лосось, микс салата, помидоры черри, пармезан, соус Цезарь',

  soup_1: 'Куриный бульйон с лапшой',
  soup_1_details: 'Куриное филе, лапша, морковь, зелень',
  soup_2: 'Грибной крем - суп',
  soup_2_details: 'Шампиньоны, сливки, морковь, лук, картофель, зелень',
  soup_3: 'Сырний крем - суп',
  soup_3_details: 'Микс сыров, сливки, морковь, лук, картофель, зелень',
  soup_4: 'Рыбная уха',
  soup_4_details: 'Дорадо, лосось, палтус, морковь, картофель, лук, зелень',
  soup_5: 'Буябез',
  soup_5_details: 'Мидии, креветки, лосось, томаты, картофель, специи',

  meat_1: 'Филе миньон',
  meat_1_details: 'Телятина',
  meat_2: 'Гуляш',
  meat_2_details: 'Телятина, картофель, морковь, томаты',
  meat_3: 'Курица на гриле',
  meat_3_details: 'Куриное филе, картофельное пюре',
  meat_4: 'Шашлык из баранины',
  meat_4_details: 'Баранина, лук, перец болгарский',
  meat_5: 'Шницель',
  meat_5_details: 'Свинина, картофельное пюре, микс салата',

  fish_1: 'Дорадо на гриле',
  fish_1_details: 'Дорадо, микс овощей',
  fish_2: 'Запеченый лосось',
  fish_2_details: 'Филе лосося, микс овощей',
  fish_3: 'Рыбный рулет',
  fish_3_details: 'Лосось, картофель, моцарелла, морковь, помидоры',
  fish_4: 'Мидии в винном соусе',
  fish_4_details: 'Мидии, чеснок, лук, морковь, соус винный',
  fish_5: 'Палтус на гриле',
  fish_5_details: 'Палтус, микс овощей',

  flour_1: 'Паста Карбонара',
  flour_1_details: 'Сливки, пармезан, бекон, яйцо',
  flour_2: 'Паста с креветками',
  flour_2_details: 'Креветки, помидоры черри, винный соус, пармезан',
  flour_3: 'Паста Арабиатта',
  flour_3_details: 'Помидоры, перец, базилик, чеснок, томатный соус',
  flour_4: 'Паста Болоньезе',
  flour_4_details: 'Говядина, томаты, лук, чеснок, сельдерей',
  flour_5: 'Паста с Лососем',
  flour_5_details: 'Лосось, помидоры черри, пармезан, соус сливочный',

  sides_1: 'Картофель фри',
  sides_1_details: 'картофель',
  sides_2: 'Картофельное пюре',
  sides_2_details: 'Картофель, сливочное масло, молоко',
  sides_3: 'Рис',
  sides_3_details: 'Рис, сливочное масло',
  sides_4: 'Овощи на гриле',
  sides_4_details: 'Перец, морковь, томаты, баклажан, цукини',
  sides_5: 'Свежие овощи',
  sides_5_details: 'Огурец, перец, томаты, морковь, зелень',

  desserts_1: 'Торт "Наполеон" ',
  desserts_1_details: 'Слоеное тесто, заварной крем',
  desserts_2: 'Тирамису',
  desserts_2_details: 'Печенье савоярди пропитанное сливочно кофейным кремом',
  desserts_3: 'Три молока',
  desserts_3_details: 'Сливочный, карамельный и шоколадный крем',
  desserts_4: 'Торт "Красный Бархат"',
  desserts_4_details: 'Бисквит пропитанный сливочным кремом',
  desserts_5: 'Штрудель с яблоками',
  desserts_5_details: 'Бездрожжевое тесто, яблоки, орехи',

  coffee_1: 'Эспрессо',
  coffee_1_details: '',
  coffee_2: 'Латте',
  coffee_2_details: '',
  coffee_3: 'Айс - Латте',
  coffee_3_details: 'Латте, лед',
  coffee_4: 'Американо',
  coffee_4_details: '',
  coffee_5: 'Флэт Вайт',
  coffee_5_details: 'Двойной эспрессо с молочной пеной',

  tea_1: 'Черный Ассам',
  tea_1_details: '',
  tea_2: 'Бергамот',
  tea_2_details: '',
  tea_3: 'Альпийский Луг',
  tea_3_details: '',
  tea_4: 'Ягодный Микс',
  tea_4_details: '',
  tea_5: '1001 Ночь',
  tea_5_details: '',

  beer_1: 'Corona',
  beer_1_details: '',
  beer_2: 'Stella Artois',
  beer_2_details: '',
  beer_3: 'Estrella',
  beer_3_details: '',
  beer_4: 'Bud',
  beer_4_details: '',
  beer_5: 'Calsberg',
  beer_5_details: '',

  appetizer_for_vodka: 'Закуска до наливочки',
  cheese_plate_td: 'Сирна тарілка',
  cheese_plate_td_details: 'сири власного виробництва',
  meat_plate: 'М’ясна нарізка',
  meat_plate_details: 'ковбаски та м’ясо власного виробництва',
  toast_with_lard: 'Тости з шкварками',
  toast_with_lard_details: 'мангалиця',
  assorted_salo: 'Нарізка сала',
  lavash: 'Лаваш',

  tyranochka: 'Туряночка',
  tyranochka_details: 'листя салату, сир власного виробництва, помідори чері, медово-гірчична заправка',
  caesar_salad_details: 'листя салату, куряче філе, помідори, яйце, сухарики, соус',
  fresh_vegetable_plate: 'Свіжа овочева нарізка',
  greek_salad_2: 'Грецький',

  chicken_broth_homemade: 'Бульйон з домашньої курки',
  mushroom_soup_td: 'Грибна юшка',

  homemade_potatoes: 'Картопля по-домашньому з салом і цибулею',
  baked_potatoes: 'Печена картопля по-Турянськи',
  french_fries: 'Картопля фрі',
  assorted_garnish: 'Гарнір в асортименті',

  mangalica_steak: 'Стейк з мангалиці',
  mangalica_steak_details: 'попереднє замовлення',
  pork_steak: 'Стейк',
  pork_steak_details: 'свинячий ошийок',
  pork_shashlik: 'Шашлик',
  pork_shashlik_details: 'свинячий ошийок',
  pork_ribs_td: 'Ребра свині',
  chicken_steak_td: 'Стейк курячий',
  chicken_shashlik: 'Шашлик курячий',
  lamb_leg: 'Ніжка ягня',
  lamb_leg_details: 'попереднє замовлення',
  lamb_rack: 'Каре ягня',
  homemade_sausages: 'Ковбаски власного виробництва',
  grilled_vegetables: 'Овочі на мангалi',

  banosh_with_mushrooms_and_pork_rind: 'З бринзою і шкварками',
  banosh_with_mushroom_sauce: 'З грибною мачанкою',
  banosh_with_mozzarella_and_greens: 'З моцарелою і зеленню',
  cremeshnit_from_chef: 'Від шефа',
  cremeshnit_with_sour_cream: 'Зі сметаною',
  hutsul_cremeshnit_with_meat: 'По-Гуцульськи з мясом',
  cremeshnit_with_mushrooms: 'З грибами',

  fresh_pork: 'Свіжина',
  chicken_nuggets: 'Нагетси курячі',
  wings_in_honey_sauce: 'Крила в медовому соусі',
  chicken_cutlet: 'Відбивна куряча',

  grilled_trout: 'Форель на мангалi',
  carp_steaks: 'Стейки коропа',
  grilled_carp: 'Короп на мангалi',

  metanit_dumplings_with_pork_rind_and_sour_cream: 'Метанi галушки з шкварками і сметаною',
  dumplings_with_potato_and_sour_cream: 'Вареники з картоплею власного виробництва з сметаною',

  turianska_family_pizza: 'Турянська сімейна',

  assorted_desserts: 'В асортиментi',

  cocoa: 'Какао',
  cocoa_details: 'какао',
  juice_assortment: 'Соки в асортименті',
  water_assortment: 'Вода в асортименті',
  mineral_water: 'Мінеральна вода',
  lemonade: 'Лимонад',
};
