<template>
  <div class="panel">
    <page-header :title="$t('payment.title')" />
    <div class="panel__content">
      <!--      <cart-summary v-if="laundryCart.length" :items="laundryCart" :title="$t('payment.details')" />-->
      <p class="panel__help">{{ $t('payment.payment_info') }}</p>
      <!--      <p class="panel__title">{{ $t('payment.choose_method') }}</p>
            <div class="panel__buttons">
              <div class="panel__buttons-item">
                <div class="panel__buttons-item__icon apple"></div>
                <p class="panel__buttons-item__text">{{ $t('payment.apple') }}</p>
              </div>
              <div class="panel__buttons-item">
                <div class="panel__buttons-item__icon google"></div>
                <p class="panel__buttons-item__text">{{ $t('payment.google') }}</p>
              </div>
            </div>
            <div class="panel__or">
              <div class="panel__or-line panel__or-line&#45;&#45;left"></div>
              <p class="panel__or-text">{{ $t('or') }}</p>
              <div class="panel__or-line panel__or-line&#45;&#45;right"></div>
            </div>
            <form-input v-model="data.number" :label="$t('payment.card_number')" />
            <div class="panel__row">
              <form-input v-model="data.date" :label="$t('payment.card_date')" />
              <form-input v-model="data.cvv" :label="$t('payment.card_cvv')" />
              <p class="panel__row-hint">{{ $t('payment.cvv_hint') }}</p>
            </div>-->
    </div>
    <!--    <base-button :class="{'disabled': !data.number || !data.date || !data.cvv}" :text="$t('button.pay')"
                     @click="isPay = true" />-->
    <base-button :text="$t('button.order')" @click="onOrderClick" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
/*import CartSummary from "@/components/CartSummary.vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";*/
import BaseButton from "@/components/Button/BaseButton.vue";
//import FormInput from "@/components/Form/FormInput.vue";
import { useRouter } from "vue-router";
//import { ref } from "vue";
//import InvoicePopup from "@/components/Popup/InvoicePopup.vue";
import { useRender } from "@/composable/useRender";
import { onMounted } from "vue";
import { useHotelSetup } from "@/composable/useHotelSetup";

const { push } = useRouter();
//const appStore = useAppStore();
//const { laundryCart } = storeToRefs(appStore);
const { renderNotification } = useRender();
const { checkFunction } = useHotelSetup();

const onOrderClick = () => {
  renderNotification('laundry.order_received');
  setTimeout(() => {
    //laundryCart.value = [];
    push('/');
  }, 200);
};

onMounted(() => checkFunction('laundry'));
</script>

<style scoped lang="scss">
.panel {
  &__title {
    margin: 1rem 0 1.5rem;
    @include font(22px, 26px, white, 600);
  }

  &__help {
    @include font(14px, 17px, white, 600);
  }

  &__buttons {
    @extend .flex-row;
    justify-content: space-between;
    gap: 1.25rem;
    margin-bottom: 1rem;

    &-item {
      @extend .row-container;
      justify-content: center;
      flex: 1;
      height: 4.5rem;
      background-color: #FFFFFFCC;
      border-radius: 10px;

      &__icon {
        margin-right: 10px;

        &.google {
          @include icon($google, 1.5rem);
        }

        &.apple {
          @include icon($apple, 1.5rem);
        }
      }

      &__text {
        @include font(18px, 20px, black, 600);
      }
    }
  }

  &__row {
    @extend .flex-row;
    align-items: flex-end;
    gap: 10px;
    margin-top: 1rem;

    &-hint {
      @include font(12px, 16px, white, 500);
    }

    .field:nth-child(2) {
      width: 9rem;
    }
  }

  :deep(.field__label) {
    @include font(16px, 20px, white, 500);
  }

  :deep(.field__input) {
    padding: 0.75rem 1rem;
    background-color: #FFFFFFCC;
  }

  :deep(input) {
    color: black;
    font-weight: 500;
  }
}
</style>
