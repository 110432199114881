export const button = {
  send_request: 'Отправить запрос',
  chat_whit_us: 'Свяжитесь с нами',
  add: 'Добавить',
  pay: 'Оплатить',
  clear_all: 'Очистить',
  order: 'Заказать',
  reserve: 'Забронировать',
  select: 'Выбрать',
  selected: 'Выбрано',
  to_cart: 'В корзину',
  cancel: 'Отменить',
  clear: 'Очистить',
  details: 'Детали',
  leave_review: 'Оставить отзыв',
  ok: 'OK',
  order_massage: 'Заказать массаж',
  cancel_request: 'Отменить запрос',
  book_again: 'Перезаказать',
  go_home_page: 'На главную',
  edit_reserve: 'Изменить бронирование',
  view_more: 'Просмотреть детали',
  order_hookah: 'Заказать кальян',
  rent_equipment: 'Арендовать снаряжение',
  continue: 'Продолжить',
  contact: 'Связаться',
  book_tour: 'Забронировать тур',
};
