<template>
  <div class="panel__summary">
    <div class="panel__summary-title" @click="isExpanded = isExpandable ? !isExpanded : true">
      <p class="panel__summary-title__text">{{ title }}</p>
      <div v-if="isExpandable" class="panel__summary-title__arrow" :class="{'turned': isExpanded}"></div>
    </div>
    <p v-if="subtitle" class="panel__summary-subtitle">{{ subtitle }}</p>
    <div class="panel__summary-table" :class="{'expanded': isExpanded}">
      <table>
        <thead>
        <tr>
          <td>{{ $t(`summary.${ cols[0] }`) }}</td>
          <td>{{ $t(`summary.${ cols[1] }`) }}</td>
          <td>{{ $t(`summary.${ cols[2] }`) }}</td>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in list" :key="item.id">
          <tr v-if="item?.amount">
            <td>
              <p v-if="isNameTranslated">
                {{ item.name }}
                <span v-if="item.index >= 0">№{{ item.index + 1 }}</span>
              </p>
              <p v-else-if="nameCategory === 'menu'">
                {{ item.name }}
                <span v-if="item.index >= 0">№{{ item.index + 1 }}</span>
              </p>
              <small v-if="item.price.unit !== 'none'">
                ({{ item.price.quantity }} {{ $t(`unit.${ item.price.unit }`) }})
              </small>
            </td>
            <td>
              <form-number-buttons v-if="isAmountEditable" :value="item.amount" :min-value="0" color="white"
                                   @plus="onPlusClick(item)" @minus="onMinusClick(item)" />
              <p v-else>{{ item.amount }}</p>
            </td>
            <td>{{ item.price.price }} {{ $t(userStore.currency) }}</td>
          </tr>
          <tr v-for="supp in item?.supplements" :key="supp.id" class="panel__summary-supp">
            <td>
              <p>+ {{ getTranslation(supp.name) }}</p>
              <small>
                ({{ supp.prices[supp.priceIndex].quantity }} {{ $t(`unit.${ supp.prices[supp.priceIndex].unit }`) }})
              </small>
            </td>
            <td>
              <form-number-buttons :value="supp.amount" :min-value="0" color="white" @plus="supp.amount++"
                                   @minus="onSuppMinusClick(item, supp)" />
            </td>
            <td>{{ supp.prices[supp.priceIndex].price }} {{ $t(userStore.currency) }}</td>
          </tr>
          <tr v-if="fee > 0">
            <td>
              <p>{{ $t('food.fee_label') }}</p>
            </td>
            <td></td>
            <td>{{ fee }} {{ $t(userStore.currency) }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div class="panel__summary-total">
      <p>{{ $t('summary.total') }}</p>
      <p>{{ sum }} {{ $t(userStore.currency) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { MenuCartItem, MenuListItem } from "@/types/App/Cart";
import FormNumberButtons from "@/components/Form/FormNumberButtons.vue";
import { useUserStore } from "@/stores/userStore";
import { useTranslation } from "@/composable/useTranslation";

const props = defineProps({
  items: {
    type: Array as PropType<MenuCartItem[]>,
    default: () => []
  },
  cols: {
    type: Array as PropType<string[]>,
    default: () => ['article', 'amount', 'price']
  },
  title: String,
  subtitle: String,
  nameCategory: String,
  isExpandable: Boolean,
  isNameTranslated: Boolean,
  isAmountEditable: Boolean,
  isAmountMultiplies: {
    type: Boolean,
    default: true,
  },
  fee: {
    type: Number,
    default: 0,
  }
});
const emits = defineEmits(['delete-item']);

const userStore = useUserStore();
const { getTranslation } = useTranslation();

const list = ref<MenuCartItem[]>(props.items);

const isExpanded = ref(true);

const sum = computed((): number => {
  let sum = 0;
  list.value.map(item => {
    let itemPrice = item.price.price;
    if (item?.supplements?.length) {
      item.supplements.map(supp => itemPrice += supp.prices[supp.priceIndex].price * supp.amount);
    }
    if (item?.multiplier) {
      itemPrice *= item.multiplier;
    }

    sum += itemPrice * (props.isAmountMultiplies ? item.amount : 1);
  });
  if (props.fee > 0) {
    sum += props.fee;
  }
  return sum;
});

const onPlusClick = (item: MenuCartItem) => item.amount++;

const onMinusClick = (item: MenuCartItem) => {
  if (item.amount === 1) {
    emits('delete-item', item);
  } else {
    item.amount--;
  }
};

const onSuppMinusClick = (item: MenuCartItem, supp: MenuListItem) => {
  if (supp.amount === 1) {
    item.supplements = item.supplements?.filter(i => i.id !== supp.id);
  } else {
    supp.amount--;
  }
};
</script>

<style scoped lang="scss">
.panel__summary {
  @extend .flex-col;

  &-title {
    @extend .row-container;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    &__text {
      @include font(20px, 24px, white, 600);
    }

    &__arrow {
      @include icon($back-arrow, 2.5rem, 1.5rem);
      background-position: center;
      transition-duration: 0.25s;

      &.turned {
        transform: rotate(0.25turn);
        background-position-x: 55% !important;
      }

      &:not(.turned) {
        transform: rotate(-0.25turn);
        background-position-x: 30% !important;
      }
    }
  }

  &-subtitle {
    margin: 0.5rem 0 1rem;
    @include font(12px, 14px, white, 500);
  }

  &-total {
    @extend .row-container;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #AEAAAA;

    p {
      @include font(22px, 26px, white, 600);

      &:last-child {
        text-transform: uppercase;
      }
    }
  }

  &-supp {
    td:first-child p, {
      padding-left: 0.5rem;
    }

    td:first-child small {
      padding-left: 1.25rem;
    }
  }

  &-table {
    @extend .no-scroll;
    max-height: 0;
    overflow-y: auto;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    &.expanded {
      max-height: 35dvh;
    }
  }

  table {
    width: 100%;

    td:nth-child(2) {
      text-align: center;
    }

    td:last-child {
      text-align: right;

    }
  }

  thead td {
    height: 2rem;
    @include font(12px, 15px, white, 500);
  }

  thead tr {
    /* width: 100%;
     position: sticky;
     top: 0;*/
  }

  tbody {
    @extend .custom-scroll;

    max-height: 10dvh;
    width: 100%;
    overflow-y: auto;
  }

  tbody tr {
    height: 3.25rem;
  }

  tbody td {
    @include font(16px, 20px, white, 600);

    &:first-child {
      min-width: 48vw;
      max-width: 48vw;
    }

    &:last-child {
      text-transform: uppercase;
    }
  }

  /*td p {
    @extend .flex-col;

    span {
      font-size: 12px;
      line-height: 15px;
      text-transform: lowercase;
    }
  }*/
}

:deep(.amount__text) {
  min-width: 1rem;
  color: white !important;
}
</style>
