<template>
  <div
      :class="['banner', `banner--${advert.type}`, { loading: !advert.imageSrc }]"
      @click="advert?.link && push(advert.link)"
      :style="`background-image: url('${advert.imageSrc}')`"
  >
    <svg :width="bannerSize" :height="bannerSize * 0.515" :viewBox="`0 0 ${bannerSize} ${bannerSize * 0.515}`"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :d="path" fill="white" fill-opacity="0.8" />
    </svg>

    <div class="banner__left">
      <p class="banner__left-title">{{ getTranslation(advert.title) }}</p>
      <p class="banner__left-text">{{ getTranslation(advert.text) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { useTranslation } from "@/composable/useTranslation";
import { Banner } from "@/types/Banner";

defineProps({
  advert: {
    type: Object as PropType<Banner>,
    default: () => ({})
  }
});

const { push } = useRouter();
const { getTranslation } = useTranslation();

const bannerSize = document.documentElement.clientWidth - 32;

const path = computed((): string => {
  return `M 0 0 H ${ bannerSize * 0.6 } L ${ bannerSize * 0.45 } ${ bannerSize * 0.27 } L ${ bannerSize * 0.68 } ${ bannerSize * 0.7 } H 0 V 0 Z`;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/banner";

.banner {

  &.loading {
    background-image: url('@/assets/preload.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &__left {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    align-items: center;
    justify-content: center;
    width: 43%;
    height: calc(100% - 1.5rem);
    z-index: 10;

    &-title {
      @include font(19px, 22px, black, 600);
    }

    &-text {
      @include font(11px, 14px, black);
      text-wrap: balance;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
</style>
