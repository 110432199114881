export const taxi = {
  location: 'Укажите ваше место расположение',
  where_to: 'Куда направляемся?',
  when: 'Когда?',
  choose_time: 'Выберите время',
  regular: 'Стандарт',
  comfort: 'Комфорт',
  comment: 'Если у Вас есть дополнительные потребности (детское кресло, бус, и т.д.), оставьте комментарий:',
  order_guide: 'Пожалуйста, позвоните по этому номеру, если Вы хотите заказать такси или трансфер.',
  from: 'С',
  to: 'До',
  type: 'Тип',
};
