<template>
  <div class="header">
    <project-logo />
    <div class="header__right">
      <div class="header__right-info">
        <p v-if="linkType === 'room'" class="header__right-room">{{ $t('room') }} {{ clientLinkData.room.number }}</p>
        <p v-else-if="linkType === 'menu'" class="header__right-room">
          {{ getTranslation(clientLinkData.room?.type?.name) }}
        </p>
        <form-select v-model="language" color="black" :options="languages" @update:model-value="onLanguageChange" />
      </div>
      <notification-button />
    </div>
  </div>
</template>

<script setup lang="ts">
import ProjectLogo from "@/components/ProjectLogo.vue";
import { computed, ref } from "vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { useI18n } from "vue-i18n";
import { SelectOption } from "@/types/App/Form";
import NotificationButton from "@/components/Button/NotificationButton.vue";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";

const texts: { [key: string]: string } = {
  ua: 'УКР',
  en: 'ENG',
  pl: 'PLN',
  ru: 'РУС',
};

const i18n = useI18n();

const userStore = useUserStore();
const { client, clientLinkData, linkType } = storeToRefs(userStore);
const { getTranslation } = useTranslation();

const language = ref(client.value.settings?.language ?? i18n.locale.value ?? 'ua');

const languages = computed((): SelectOption[] => {
  const list: SelectOption[] = [];
  client.value.organization?.settings?.languages?.map(item => list.push({ value: item, label: texts[item] }));
  return list;
});

const onLanguageChange = async (value: string) => {
  await userStore.updateUserSettings({ language: value });
  language.value = value;
  i18n.locale.value = value;
};
</script>

<style scoped lang="scss">
.header {
  @extend .row-container;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &__right {
    @extend .row-container;
    height: 100%;

    &-info {
      @extend .flex-col;
      align-items: flex-end;
    }

    &-room {
      margin-bottom: 10px;
      @include font(4.58vw, 125%, white, $align: center);
    }
  }
}

.field {
  width: 4.5rem;

  :deep(.select) {
    @extend .row-container;
    max-height: 1.5rem;
    min-height: 1.5rem;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  :deep(.select__arrow) {
    top: 0;
    right: 0;
    transform: scaleX(1.25);
  }

  :deep(.select__arrow--turned) {
    transform: scaleX(1.25) rotate(0.5turn);
  }

  :deep(.select__value) {
    color: white;
  }

  :deep(.select__options) {
    min-width: 5rem;
    margin-top: 0.75rem;
    margin-left: -0.25rem;
    padding: 0;
    background-color: #0000001A;
    backdrop-filter: blur(2px);
  }

  :deep(.select__options-item__text) {
    @include font(20px, 24px, white, 600);
  }
}

.notifications {
  height: 100%;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid #FFFFFF80;

  :deep(.notifications__badge) {
    bottom: 0;
  }
}
</style>
