export const info = {
  additional: 'Usługi dodatkowe',
  general: 'Informacje ogólne',
  massage: 'Masaż',
  gazebo: 'Altana',
  laundry: 'Usługi pralnicze',
  sauna: 'Sauna i Wata',
  vat: 'Wata',
  about_chorna_skelya: 'O Hotelu Chorna Skelya',
  about_teremok: 'O Hotelu Teremok',
  about_astur: 'O Hotelu Spa Astur',
  about_beregynia: 'O Hotelu Beregynia',
  about_shepit_carpat: 'O Hotelu Shepit Carpat',
  about_shepit_carpat_r: 'O Hotelu Aurora Luxe Resort',
  about_u_stozi: 'O Hotelu U Stozi',
  about_tur_dvir: 'O Hotelu Turiansky Dvir',
  about_melodia_hir: 'O Hotelu Melodia Hir',
  leisure: 'Czas wolny',
  restaurant: 'Restauracja',
  spa: 'Strefa SPA',
  policy: 'Polityka hotelowa',
  faq: 'FAQ',
  booking_details: 'Szczegóły rezerwacji',
  order_food: 'Zamów menu',
  contact_admin: 'Skontaktuj się z administratorem',
  make_reservation: 'Dokonaj rezerwacji',
  other: 'Inne',
  my_reserves: 'Moje rezerwacje i prośby',
  booking_history: 'Historia rezerwacji',
  chat: 'Czat 24/7',
  relax_in_vat: 'Relaks w Wacie',
  quad_biking: 'Jazda na quadzie',
  bicycle_rental: 'Wynajem rowerów',
  about_spa_zone: 'O Strefie SPA',
  about_entertainment: 'O rozrywce',
  about_cottage: 'O domku',
  about_hookah: 'O shishy',
  about_service: 'O usłudze',
  price_list: 'Cennik',
  hookah: 'Shisha',
  bath: 'Sauna',
  salt_room: 'Pokój solny',
  ski_eq: 'Sprzęt narciarski',
  tours: 'Wycieczki',
  review: 'Recenzja',
  fishing: 'Wędkowanie',
  tour_3d: 'Wycieczka 3D',
  benefits: 'Korzyści',
  time_from: 'Od',
  time_to: 'Do',
  choose_date: `Określ datę i godzinę, w której chcesz skorzystać z usługi`,
  people_amount: 'Określ liczbę osób',
};
