<template>
  <div class="tabs">
    <p v-for="(tab, key) in tabs" :key="tab.name" class="tabs__item"
       :class="{'tabs__item--selected': key === selectedTab}" @click="() => onTabClick(key)">
      {{ isTranslation ? getTranslation(tab.name) : $t(tab.name as string) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { FormTab } from "@/types/App/Form";
import { useTranslation } from "@/composable/useTranslation";

defineProps({
  tabs: {
    type: Array as PropType<FormTab[]>,
    default: () => []
  },
  selectedTab: Number,
  isTranslation: {
    type: Boolean,
    default: true
  },
});
const emits = defineEmits(['tab-click']);

const { getTranslation } = useTranslation();

const onTabClick = (index: number) => emits('tab-click', index);
</script>

<style scoped lang="scss">
.tabs {
  @extend .flex-row;
  justify-content: space-between;
  gap: 0.25rem;
  margin-bottom: 1.5rem;

  &__item {
    @extend .row-container;
    justify-content: center;
    position: relative;
    flex: 1;
    padding-bottom: 4px;
    margin-bottom: 4px;
    @include font(18px, 22px, #686868, 600, center);

    &:after {
      position: absolute;
      bottom: -2px;
      @include before(100%, 3px);
      border-radius: 1px;
      transition-duration: 0.1s;
    }

    &--selected {
      color: white;

      &:after {
        background-color: $primary;
      }
    }
  }
}
</style>
