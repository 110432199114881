export const service = {
  title: 'Ваш портативный сервис',
  cleaning: 'Уборка',
  taxi: 'Такси',
  desk: 'Ресепшн',
  menu: 'Еда и напитки',
  booking: 'Бронирование',
  info: 'Информация',
  chat: 'Чат 24/7',
  gazebo: 'Альтанка',
  laundry: 'Стирка',
  sauna: 'Сауна',
  bath: 'Баня',
  fishing: 'Рыбалка',
  spa: 'СПА',

  quad_instructor: 'Катание по горах с инструктором',
  bicycle_rent: 'Прокат велосипеда',
  cottage_3_room: 'Котедж 3-комнатный',
  cottage_4_room: 'Котедж 4-комнатный',

  hookah_classic: 'Classic',
  hookah_hard: 'Hard',
  hookah_extra_hard: 'Extra Hard',
  hookah_patriot: 'Патриот (Авторский кальян, в колбе цитрусовый сок. Часть прибыли перечисляется на нужды ВСУ!)',
  hookah_death: 'Смерть врагам (Авторский кальян, в колбе красное вино. Часть прибыли перечисляется на нужды ВСУ!)',
  hookah_orange_cup: 'Orange Cup',
  hookah_pina_colada: 'Pina Colada',
  hookah_add_1: 'Добавка в колбу',
  hookah_add_2: 'Подушка',
};
