<template>
  <div class="header" @click="onBackClick">
    <div v-if="hasTitle" class="header__left">
      <div class="header__arrow"></div>
      <div v-if="hasIcon && (!title || icon)" class="header__icon"
           :class="`service-icon--${icon ?? serviceName}`"></div>
      <p class="header__text">{{ title ?? $t(`service.${ serviceName }`) }}</p>
    </div>
    <form-select v-if="userStore.linkType === 'menu' && route.path === '/menu/start'" v-model="language"
                 class="header__left" color="black" :options="languages" @update:model-value="onLanguageChange" />
    <div class="header__right">
      <div v-if="userStore.linkType === 'menu'" class="service-icon--contact" @click.stop="redirectToAdminTg"></div>
      <notification-button />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import NotificationButton from "@/components/Button/NotificationButton.vue";
import { useUserStore } from "@/stores/userStore";
import { useButton } from "@/composable/useButton";
import FormSelect from "@/components/Form/FormSelect.vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { SelectOption } from "@/types/App/Form";

const props = defineProps({
  title: String,
  hasIcon: {
    type: Boolean,
    default: true,
  },
  hasTitle: {
    type: Boolean,
    default: true,
  },
  icon: String,
  backLink: {
    type: String,
    default: null,
  },
  isCustomHandler: Boolean,
});
const emits = defineEmits(['back']);

const route = useRoute();
const { push, back } = useRouter();
const userStore = useUserStore();
const { redirectToAdminTg } = useButton();

const serviceName = computed((): string => route.name as string);

const onBackClick = () => {
  if (props.isCustomHandler) {
    emits('back');
  } else {
    if (props.backLink) {
      push(props.backLink);
    } else {
      back();
    }
  }
};

const texts: { [key: string]: string } = {
  ua: 'УКР',
  en: 'ENG',
  pl: 'PLN',
  ru: 'РУС',
};

const i18n = useI18n();

const { client } = storeToRefs(userStore);

const language = ref(client.value.settings?.language ? client.value.settings?.language : (i18n.locale.value ?? 'ua'));

const languages = computed((): SelectOption[] => {
  const list: SelectOption[] = [];
  client.value.organization?.settings?.languages?.map(item => list.push({ value: item, label: texts[item] }));
  return list;
});

const onLanguageChange = async (value: string) => {
  await userStore.updateUserSettings({ language: value });
  language.value = value;
  i18n.locale.value = value;
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/service-icon";

.header {
  @extend .row-container;
  justify-content: space-between;
  height: 2.5rem;
  margin-bottom: 1.5rem;

  &__left, &__right {
    @extend .row-container;
  }

  &__arrow {
    margin-right: 0.5rem;
    @include icon($back-arrow, 1.5rem);
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    background-size: 1.5rem !important;
  }

  &__text {
    @extend .clip-line--2;
    @include font(6.1vw, 120%, white, 500);
    text-wrap: balance;
  }
}

.notifications {
  margin-left: 1rem;
}

.service-icon--contact {
  @extend .circle;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: -0.25rem;
  background-color: #FFFFFFCC;
  background-size: 1.5rem;
}

.field {
  width: 4.5rem;
  margin-left: 0.5rem;

  :deep(.select) {
    @extend .row-container;
    max-height: 1.5rem;
    min-height: 1.5rem;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  :deep(.select__arrow) {
    top: 0;
    right: 0;
    transform: scaleX(1.25);
  }

  :deep(.select__arrow--turned) {
    transform: scaleX(1.25) rotate(0.5turn);
  }

  :deep(.select__value) {
    color: white;
  }

  :deep(.select__options) {
    min-width: 5rem;
    margin-top: 0.75rem;
    margin-left: -0.25rem;
    padding: 0;
    background-color: #0000001A;
    backdrop-filter: blur(2px);
  }

  :deep(.select__options-item__text) {
    @include font(20px, 24px, white, 600);
  }
}
</style>
