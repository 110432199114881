import axios, { AxiosError, AxiosResponse } from 'axios';

interface Config {
  withCredentials?: boolean;
  headers?: {
    uid: string;
    authorization: string;
  };
}

interface Arguments {
  url: string;
  method: string;
  data?: any;
  headers?: {
    value?: string;
  };
}

const uid = '_' + Math.random().toString(36).substring(2, 9);

axios.interceptors.request.use((response: any) => {
  const accessToken = localStorage.getItem('jwt_client');
  if (accessToken) {
    response.headers = Object.assign({
      Authorization: `Bearer ${ accessToken }`
    }, response.headers);
  }

  return response;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    /*if ([401, 504, 502].includes(error?.response?.status as number)) {
      localStorage.removeItem('jwt_client');
      if (!['/login'].includes(window.location.href)) {
        window.location.href = '/login';
      }
    }*/
    return Promise.reject(error);
  }
);

export async function request({
                                url,
                                method,
                                data = {},
                                headers = {},
                              }: Arguments): Promise<any> {
  const config: Config = {
    headers: {
      authorization: `${ localStorage.getItem('jwt_client') }`,
      uid,
      ...headers,
    },
    withCredentials: true
  };

  const fullUrl = process.env.NODE_ENV === 'development' ? `http://localhost:3000/${ url }` : `https://demochat.vipmanage.org/api/${ url }`;
  //const fullUrl = `https://demochat.vipmanage.org/api/${ url }`;

  let response;
  switch (method) {
    case 'get':
      response = await axios.get(fullUrl, config);
      break;
    case 'post':
      response = await axios.post(fullUrl, data, config);
      break;
    case 'put':
      response = await axios.put(fullUrl, data, config);
      break;
    case 'delete':
      response = await axios.delete(fullUrl, config);
      break;
    case 'patch':
      response = await axios.patch(fullUrl, data, config);
      break;
  }

  return response?.data;
}
