export const infoPriceList = {
  spa_zone_title: 'Цена услуг СПА зоны',
  spa_zone_subtitle: 'Бронирование сауны осуществляется минимум на 2 часа и минимум для 4 человек',
  sauna_vat_title: 'Цена бронирования сауны/чана',
  sauna_vat_subtitle: 'Бронирование возможно минимум на 3 часа, оплата бронирования осуществляется через администратора',
  vat_title: 'Цена бронирования чана',
  vat_subtitle: 'Оплата осуществляется через администратора',
  quad_title: 'Цена бронирования катания на квадроцикле',
  bicycle_title: 'Цена бронирования проката велосипеда',
  cottage_title: 'Цена бронирования котеджа',
  cottage_subtitle: 'Бронирование предполагает предоплату в размере 50%',
  gazebo_title: 'Цена бронирования альтанки',
  gazebo_subtitle: 'Оплата за бронирование альтанки включает в себя любое количество часов в рамках графика работы альтанок',
  hookah_title: 'Цена кальянів',
  bath_title: 'Цена бронирования бани',
  bath_subtitle: 'Оплата бронирования осуществляется через администратора',
  spa_title: 'Цена бронирования СПА',
  spa_subtitle: 'Оплата бронирования осуществляется через администратора',
  fishing_title: 'The price of booking fishing',
  fishing_subtitle: 'Payment for booking a fishing involves any number of hours within the schedule of work of fishing',

  hour: 'год',
  day: 'сутки',
  minute: 'мин',
  unlimited: 'безлимит',
  h: 'год',
  d: 'сутки',
  m: 'мин',
  u: 'безлимит',

  cols: {
    name: 'Название',
    price_per_hour: 'Цена\\час',
    price_per_day: 'Цена\\сутки',
    people_amount: 'Кол-во человек',
    max_people_amount: 'Макс. кол-во человек',
  }
};
