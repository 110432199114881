export const food = {
  categories_title: 'You can order food and beverage to your room, to eat at the restaurant, or to go.',
  breakfast: 'Breakfast',
  appetizers: 'Appetizers',
  salads: 'Salads',
  soups: 'Soups',
  meat: 'Meat dishes',
  fish: 'Fish dishes',
  hot: 'Hot dishes',
  grill: 'Dishes on Grill',
  drinks: 'Drinks',
  kids: 'Kids Menu',
  sauces: 'Sauces',
  flour: 'Flour dishes',
  sides: 'Sides',
  baked: 'Baked',
  desserts: 'Desserts',
  'to-beer': 'To beer',
  pizza: 'Pizza',
  working_hours: 'The restaurant working hours are from {from} till {to}',
  details: 'Order Details',
  order_type: 'Where do you want to eat?',
  in_restaurant: 'In the restaurant',
  in_room: 'In the room',
  to_go: 'Pack to go',
  in_bath: 'In the bath',
  in_vat: 'In the vat',
  in_gazebo: 'In the gazebo',
  work_time: '*Delivery "{type}" is available from {from} till {to}',
  ai_generated: '*The pictures of the dishes where generated by AI and do not correspond to the real appearance of the dishes',
  clear_question: 'Are you sure you want to clear all selected items?',
  items: 'Items',
  pizza_supplements: 'Pizza supplements',
  no_selected_pizza: 'First, add the pizza to the basket',
  supplements_title: 'Choose the pizza to which you want to add {name}',
  fee: '**Additional fee: {fee} {currency}',
  fee_label: 'Fee',

  title: 'You can order food and drinks {places}.',
  places: {
    in_restaurant: 'at the restaurant',
    in_room: 'to your room',
    in_bath: 'to bath',
    in_vat: 'to vat',
    to_go: 'takeaway',
  },
  or: 'or',

  start: {
    title_1: 'Ordering your favorite dishes has never been so easy!',
    title_2: 'Take a few easy steps:',
    step_1: 'Start by selecting the food category you are interested in.',
    step_2: 'Choose a dish from the list and click "Order".',
    step_3: 'Click the "and" button to read detailed information about the dish.',
    step_4: 'Go to the confirmation page where you can view and edit the number of selected items.',
  }
};
