export const button = {
  send_request: 'Надіслати запит',
  chat_whit_us: 'Зв\'яжіться з нами',
  add: 'Додати',
  pay: 'Оплатити',
  clear_all: 'Очистити',
  order: 'Замовити',
  reserve: 'Забронювати',
  select: 'Обрати',
  selected: 'Обрано',
  to_cart: 'В кошик',
  clear: 'Очистити',
  cancel: 'Скасувати',
  details: 'Деталі',
  leave_review: 'Залишити відгук',
  ok: 'OK',
  order_massage: 'Замовити масаж',
  cancel_request: 'Відмінити запит',
  book_again: 'Перезамовити',
  go_home_page: 'На головну',
  edit_reserve: 'Змінити бронювання',
  view_more: 'Переглянути деталі',
  order_hookah: 'Замовити кальян',
  rent_equipment: 'Орендувати спорядження',
  continue: 'Продовжити',
  contact: 'Зв\'язатись',
  book_tour: 'Замовити екскурсію',
};
