<template>
  <div class="field">
    <div class="field__radio" :class="{'field__radio--selected': value}" @click="onToggleClick">
      <div class="field__radio-icon"></div>
      <p class="field__radio-text">{{ label }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  label: String,
  modelValue: Boolean
});
const emits = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);

const onToggleClick = () => {
  value.value = !value.value;
  emits('update:modelValue', value.value);
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/form/radio";

.field__radio {
  &--selected {
    .field__radio-text {
      color: white;
    }
  }

  &-text {
    color: #AEAAAA;
  }
}
</style>
