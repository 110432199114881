<template>
  <div class="panel">
    <page-header :title="$t('food.details')" />
    <div class="panel__content">
      <cart-summary v-if="menuCart.length" :items="list" :title="$t('summary.title')" :is-amount-editable="true"
                    :subtitle="orderType > 0 ? getTranslation(currentOrderType.name) : ''" name-category="menu"
                    :key="listKey" :fee="currentOrderType?.fee ?? 0" @delete-item="onDeleteItem" />
      <text-button :text="$t('button.clear_all')" :is-underlined="true" @click="isClear = true" />
      <div v-if="orderType < 0">
        <p class="panel__question">{{ $t('food.order_type') }}</p>
        <template v-for="(type, key) in orderTypes" :key="key">
          <base-button :class="{'disabled': !workingState[key]}" :text="getTranslation(type.name)"
                       @click="orderType = key" />
          <p v-if="!workingState[key]" class="panel__help">
            {{
              $t('food.work_time', { from: type.workTime.from, to: type.workTime.to, type: getTranslation(type.name) })
            }}
          </p>
          <p v-if="type?.fee > 0" class="panel__help">
            {{ $t('food.fee', { fee: type.fee, currency: $t(currency) }) }}
          </p>
        </template>
      </div>
    </div>
    <base-button v-if="orderType >= 0" :text="$t('button.order')" @click="onOrderClick" />
  </div>
  <clear-cart-popup v-if="isClear" @close="isClear = false" @submit="onClearClick" />
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import CartSummary from "@/components/CartSummary.vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRender } from "@/composable/useRender";
import { useRouter } from "vue-router";
import { useRequestStore } from "@/stores/requestStore";
import TextButton from "@/components/Button/TextButton.vue";
import ClearCartPopup from "@/components/Popup/ClearCartPopup.vue";
import { useUserStore } from "@/stores/userStore";
import { MenuCartItem } from "@/types/App/Cart";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useMenu } from "@/composable/useMenu";
import { useI18n } from "vue-i18n";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { HotelSetupFood, HotelSetupFoodDelivery } from "@/types/HotelSetup";
import { useTranslation } from "@/composable/useTranslation";

const i18n = useI18n();
const { push } = useRouter();
const appStore = useAppStore();
const { menuCart } = storeToRefs(appStore);
const userStore = useUserStore();
const { linkType, clientLinkData, currency } = storeToRefs(userStore);
const requestStore = useRequestStore();
const { error } = storeToRefs(requestStore);
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { renderNotification } = useRender();
const { checkFunction } = useHotelSetup();
const { getMenuPrintItems } = useMenu();
const { getTranslation } = useTranslation();

const orderType = ref(-1);
const timer = ref(0);
const isClear = ref(false);
const listKey = ref(0);
const workingState = ref<{ [key: string]: boolean }>({});

const menuSetup = computed((): HotelSetupFood => hotelSetup.value.food as HotelSetupFood);

const orderTypes = computed((): HotelSetupFoodDelivery[] => menuSetup.value.delivery);

const currentOrderType = computed((): HotelSetupFoodDelivery => orderTypes.value?.[orderType.value]);

const list = computed((): MenuCartItem[] => {
  const list: MenuCartItem[] = [];
  menuCart.value.map(item => {
    if (item?.supplements && item.supplements.length > 1) {
      item.supplements.map((s => {
        const existing = list.findIndex(i => i.id === item.id && i.index === s.itemIndex);
        if (existing >= 0) {
          list[existing].supplements?.push(s);
        } else {
          list.push({ ...item, index: s.itemIndex, amount: 1, supplements: [s] });
        }
      }));
      if (item.amount > item.supplements.length) {
        list.push({
          ...item,
          amount: item.amount - item.supplements.length,
          index: item.supplements.length,
          supplements: []
        });
      }
    } else {
      list.push(item);
    }
  });
  return list;
});

const setWorkingState = () => {
  const date = new Date();
  const hours = date.getHours();
  menuSetup.value.delivery?.map((item, key) => {
    const from = +item.workTime.from.slice(0, 2);
    const to = +item.workTime.to.slice(0, 2);
    workingState.value[key] = !(hours < from || hours >= to);
  });
  console.log(workingState.value)
};

const onDeleteItem = (item: MenuCartItem) => {
  const index = menuCart.value.findIndex(i => i.id === item.id);
  if (index >= 0) {
    if (menuCart.value.length === 1) {
      isClear.value = true;
    } else {
      if (Number.isInteger(item?.index)) {
        menuCart.value[index].supplements = menuCart.value[index].supplements?.filter(i => i.itemIndex !== item.index);
        menuCart.value[index].amount--;
      } else {
        menuCart.value.splice(index, 1);
      }
      listKey.value++;
    }
  }
};

const onClearClick = () => {
  menuCart.value = [];
  push('/menu');
};

const onOrderClick = async () => {
  await requestStore.createRequest({
    type: 'menu',
    linkId: clientLinkData.value.id,
    comment: '',
    details: {
      orderType: orderType.value,
      items: list.value,
      printItems: getMenuPrintItems(currentOrderType.value, list.value),
      printOrderType: getTranslation(currentOrderType.value.name),
      printRoom: linkType.value === 'menu' ? i18n.t(`qr_room.${ clientLinkData.value.room }`) : `${ i18n.t('room') } ${ clientLinkData.value.room }`,
      fee: orderTypes.value[orderType.value]?.fee ?? 0
    }
  });
  if (!error.value) {
    setTimeout(() => renderNotification('laundry.order_received'), 100);
    menuCart.value = [];
    push('/');
  }
};

onMounted(() => {
  checkFunction('food');
  setWorkingState();
  timer.value = setInterval(setWorkingState, 1000);

  if (orderTypes.value.length === 1) {
    orderType.value = 0;
  } else if (linkType.value === 'menu') {
    const { room } = clientLinkData.value;
    // TODO review
    console.log(room);
    /*if (room.includes('table')) {
      orderType.value = 'in_restaurant';
    } else if (room.includes('gazebo')) {
      orderType.value = 'in_gazebo';
    } else if (room.includes('bath')) {
      orderType.value = 'in_bath';
    } else if (room.includes('vat')) {
      orderType.value = 'in_vat';
    }*/
  }
});

onBeforeUnmount(() => clearInterval(timer.value));
</script>

<style scoped lang="scss">
.panel {
  &__question {
    margin: 1.5rem 0;
    @include font(20px, 24px, white, 600, center);
  }

  &__help {
    margin: 0.5rem 0 1rem;
    @include font(14px, 17px, white, 600);
  }
}

.panel__help + .panel__help {
  margin-top: -0.75rem;
}

.panel__content .base-button {
  background-color: white;

  &.disabled {
    background-color: $grey;
  }
}

.base-button + .base-button {
  margin-top: 1.5rem;
}

.panel:has(.panel__summary-subtitle):deep(.panel__summary-table.expanded) {
  max-height: 57vh;
}

.text-button {
  width: max-content;
  margin: auto;

  :deep(.text-button__text) {
    padding-bottom: 2px;
    @include font(18px, 20px, white, 500);
  }
}
</style>
