export const sauna = {
  sauna_vat_title: 'Sauna&Vat',
  about_vat: 'About Vat',
  vat: 'Vat',
  price: 'Price list',
  name: 'name',
  price_per_hour: 'Price\\hour',
  max_person: 'Max people',
  choose_service: 'Choose service',
  choose_date: 'Specify the date and time during which you need a sauna or vat',
  hours: 'Hours',
  free_time: 'Choose free time',
  payment: '*Payment for booking is made through the administrator.',
  sauna_1: 'Finnish sauna with Himalayan salt',
  sauna_1_vat_1: 'Finnish sauna + Carpathian vat (one)',
  sauna_1_vat_2: 'Finnish sauna + Carpathian vats (two) ',
  vat_1: 'Carpathian vat (one) + barbecue area',
  vat_2: 'Carpathian vats (two) + barbecue area',
  pool_jacuzzi_massage: 'Pool + jacuzzi + water massage',
};
