<template>
  <div class="panel">
    <page-header :title="$t('info.review')" back-link="/info/reserves" />
    <div class="panel__content">

      <loader-panel v-if="isLoading" />
      <div v-else-if="survey?.questions?.length" class="panel__content">
        <div v-for="(question, index) in survey.questions" :key="question.id" class="panel__item">
          <p class="panel__item-text">{{ index + 1 }}. {{ getQuestionText(question.translations) }}?</p>
          <form-stars v-if="question.type === QuestionType.RATE_5" :stars-amount="5" :value="answers[question.id] ?? ''"
                      @set-answer="v => answers[question.id] = v" />
          <form-stars v-else-if="question.type === QuestionType.RATE_10" :stars-amount="10"
                      :value="answers[question.id] ?? ''" @set-answer="v => answers[question.id] = v" />
          <form-textarea v-else-if="question.type === QuestionType.QUESTION_TEXT" v-model="answers[question.id]"
                         @update:modelValue="v => answers[question.id] = v" />
        </div>
      </div>
    </div>
    <base-button v-if="!isLoading" :class="{'disabled': !isButtonActive}" :text="$t('button.leave_review')"
                 @click="onButtonClick" />
    <error-popup v-if="isError" :text="error" @close="isError = false" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useQuestionnaireStore } from "@/stores/questionnaireStore";
import { storeToRefs } from "pinia";
import PageHeader from "@/components/Header/PageHeader.vue";
import LoaderPanel from "@/components/LoaderPanel.vue";
import FormStars from "@/components/Form/FormStars.vue";
import { QuestionTranslations, QuestionType } from "@/types/Survey";
import BaseButton from "@/components/Button/BaseButton.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { useRoute, useRouter } from "vue-router";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useRender } from "@/composable/useRender";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/userStore";

type Answers = { [key: number]: number | string };

const i18n = useI18n();
const { params } = useRoute();
const { push } = useRouter();
const userStore = useUserStore();
const questionnaireStore = useQuestionnaireStore();
const { survey, error } = storeToRefs(questionnaireStore);
const { renderNotification } = useRender();

const answers = ref<Answers>({});
const isLoading = ref(true);
const isError = ref(false);

const isButtonActive = computed(() => {
  let isEntered = true;
  survey.value?.questions?.map(i => {
    if (i.required && !answers.value[i.id]) {
      isEntered = false;
      return;
    }
  });

  return isEntered;
});

const onButtonClick = async () => {
  await questionnaireStore.setAnswers(
      +params.requestId,
      Object.entries(answers.value).map(([key, value]) => ({
        questionId: +key,
        answer: value
      }))
  );
  if (error.value) {
    isError.value = true;
  } else {
    setTimeout(() => renderNotification('review_success'));
    push('/info/reserves');
  }
};

const getQuestionText = (texts: QuestionTranslations) => {
  const keys = Object.keys(texts);
  let key = '';
  if (keys.includes(i18n.locale.value)) {
    key = i18n.locale.value;
  } else {
    const entry = keys.find(item => userStore.client.organization?.settings?.languages?.find(i => i === item));
    if (entry) {
      key = entry;
    } else {
      key = keys[0];
    }
  }

  return texts[key].title;
};

/*await store.dispatch('setAnswers', {
  surveyId: 1,
  data: {
    answers: answers.value.map(item => ({ questionId: item.questionId, answer: item.value })),
    userData: route.params?.userData
    //'6AxKqP4q9Gzzintf-1ojumT1IYBGFv2N0I5XFupZ7VEe0LTTsJE2O656FnODbue2',
  }
});*/
/*export interface Answer {
  questionId: number;
  value: string | boolean;
}*/

onMounted(async () => {
  await questionnaireStore.getSurvey();
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.panel {
  &__content {
    @extend .flex-col, .custom-scroll, .custom-scroll-container;
    gap: 1rem;
  }

  &__item {
    @extend .flex-col;
    gap: 0.5rem;

    &-text {
      @include font(18px, 22px, white, 600);
    }
  }
}

.field {
  margin-top: 0.5rem;
}
</style>
