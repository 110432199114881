export const messages = {
  request_success: 'Запит було успішно надіслано',
  reservation_received: 'Дякуємо! Ми отримали Ваше бронювання! Скоро ви отримаєте сповіщення про підтвердження!',
  reservation_accepted: 'Ваше бронювання було підтверджено',
  success_cancel: 'Ваш запит було успішно скасовано',
  restaurant_repair: 'Замовлення їжі недоступне. Ресторан знаходиться на ремонті',
  booking_success: 'Запит на бронювання успішно надіслано! Подальші деталі будуть надіслані на вказану Вами електронну пошту',
  review_success: 'Дякуємо! Ваш відгук було успішно надіслано!',
  booking_cancel_success: 'Ваше бронювання було успішно скасовано!',
};
