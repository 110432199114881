import { useI18n } from "vue-i18n";
import { computed } from "vue";

export const useTime = () => {
  const i18n = useI18n();
  const locales: { [key: string]: string } = {
    ua: 'uk-UA',
    en: 'en-US',
    ru: 'ru-RU',
    pl: 'pl-PL'
  };

  const currentLocale = computed((): string => locales[i18n.locale.value]);

  const getTimeDifference = (endDate: Date, startDate: Date) => {
    const diff = Math.floor((+endDate - +startDate) / 1000);

    const hours = Math.floor(diff / 3_600);
    const minutes = Math.floor((diff - hours * 3_600) / 60);
    const seconds = diff - hours * 3_600 - minutes * 60;

    const res: string[] = [];
    if (hours > 0) {
      res.push(`0${ hours }`.slice(-2));
    }
    res.push(`0${ minutes }`.slice(-2));
    res.push(`0${ seconds }`.slice(-2));
    return res.join(':');
  };

  const getTimeStr = (value: number) => `0${ value }`.slice(-2);

  const getTimePeriodStr = (timeA: number, timeB: number) => `${getTimeStr(timeA)}:00 - ${getTimeStr(timeB)}:00`;

  const getTimeCombinations = (arr: number[]) => {
    const comb = [[arr[0]]];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - arr[i - 1] === 1) {
        comb[comb.length - 1].push(arr[i]);
      } else {
        comb.push([arr[i]]);
      }
    }

    return comb;
  }

  return { locales, currentLocale, getTimeDifference, getTimeStr, getTimePeriodStr, getTimeCombinations };
}
