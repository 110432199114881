export const laundry = {
  wash: 'Pranie',
  iron: 'Prasowanie',
  schedule: 'Możesz przynieść swoje rzeczy do pralni, która znajduje się obok kortu tenisowego na tarasie w godzinach 11:00-18:00',
  order: 'Zamów pranie',
  summary: 'Podsumowanie',
  article: 'Artykuł',
  amount: 'Ilość',
  price: 'Cena',
  total: 'Łącznie',
  blouse: 'Koszula/Bluzka jedwabna',
  sweater: 'Sweter/Pulower',
  trousers: 'Spodnie',
  dress: 'Sukienka',
  jeans: 'Jeansy',
  skirt: 'Spódnica',
  t_shirt: 'Koszulka',
  nightgown: 'Koszula nocna/Ręcznik kąpielowy',
  scarf: 'Szalik/Krawat',
  socks: 'Skarpety/Podkolanówki',
  pajamas: 'Piżama',
  jacket: 'Kurtka',
  suit: 'Garnitur',
  sport_suit: 'Strój sportowy',
  vest: 'Kamizelka',
  shorts: 'Szorty',
  towel: 'Ręcznik',
  child: 'Ubrania dziecięce',
  order_received: 'Dziękujemy, otrzymaliśmy Twoje zamówienie! Otrzymasz powiadomienie, gdy zamówienie będzie gotowe.',
};
