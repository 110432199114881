<template>
  <div class='field' :class="`field--${color}`">
    <p v-if="label" class="field__label">{{ label }}</p>
    <div class='field__input select' ref='item' :class='selectClass' @click.stop='onClick'>
      <p v-if="selected?.hours >= 0" class='select__value'>
        {{ getHourValue(selected.hours) }}:{{ getMinuteValue(selected.minutes) }}
      </p>
      <p v-else-if="placeholder" class='field__placeholder'>{{ placeholder }}</p>
      <div class='select__arrow' :class="{'select__arrow--turned': isActive}"></div>
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
    <transition :name="`show-${direction}`">
      <div v-if='isActive' class='select__options' v-bind='optionsParams'>
        <div class="select__left" @wheel="onHoursWheel" @touchstart="onTouchStart" @touchmove="onHoursTouchMove">
          <p v-for="n in [-2, -1, 0, 1, 2]" :key="n">{{ getHourValue(startHour + hourOffset + n) }}</p>
        </div>
        <div class="select__right" @wheel="onMinutesWheel" @touchstart="onTouchStart" @touchmove="onMinutesTouchMove">
          <p v-for="n in [-2, -1, 0, 1, 2]" :key="n">{{ getMinuteValue(startMinute + minuteOffset + n) }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { OptionParams } from "@/types/App/Form";
import { useFormItem } from "@/composable/useFormItem";

const props = defineProps({
  label: String,
  placeholder: String,
  hint: String,
  selected: {
    type: [Object, String],
    default: null,
  },
  initialDate: {
    type: Date,
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const { isActive, item, getFloatingPosition, setListeners, deleteListeners } = useFormItem();

const direction = ref('down');
const optionsParams = ref<OptionParams>({} as OptionParams);
const hourOffset = ref(0);
const minuteOffset = ref(0);
const startHour = ref(0);
const startMinute = ref(0);
const startY = ref(0);

const selectClass = computed((): { [key: string]: boolean } => {
  return {
    'select--active': isActive.value,
  };
});

const onClick = () => {
  optionsParams.value = getFloatingPosition(5, 34);
  direction.value = optionsParams.value?.class ? optionsParams.value.class : direction.value;
  isActive.value = !isActive.value;
};

const getTimeValue = (v: number, max: number) => {
  let value = v > max ? v - max : v;
  if (value === max) {
    value = 0;
  }

  if (value < 0) {
    return '';
  }

  return `0${ value }`.slice(-2);
};

const getHourValue = (v: number) => getTimeValue(v, 24);

const getMinuteValue = (v: number) => getTimeValue(v, 60);

const onHoursWheel = (e: Event) => {
  if ((e as WheelEvent).deltaY > 0) {
    hourOffset.value = Math.min(24, hourOffset.value + 1);
  } else {
    hourOffset.value = Math.max(0, hourOffset.value - 1);
  }
};

const onTouchStart = (e: Event) => startY.value = (e as TouchEvent).touches[0].clientY;

const onHoursTouchMove = (e: Event) => {
  const diff = (e as TouchEvent).touches[0].clientY - startY.value;
  if (diff < -25) {
    hourOffset.value = Math.min(24, hourOffset.value + 1);
    startY.value = (e as TouchEvent).touches[0].clientY;
  } else if (diff > 25) {
    hourOffset.value = Math.max(0, hourOffset.value - 1);
    startY.value = (e as TouchEvent).touches[0].clientY;
  }
};

const onMinutesWheel = (e: Event) => {
  if ((e as WheelEvent).deltaY > 0) {
    minuteOffset.value = Math.min(60, minuteOffset.value + 1);
  } else {
    minuteOffset.value = Math.max(0, minuteOffset.value - 1);
  }
};

const onMinutesTouchMove = (e: Event) => {
  const diff = (e as TouchEvent).touches[0].clientY - startY.value;
  if (diff < -25) {
    minuteOffset.value = Math.min(60, minuteOffset.value + 1);
    startY.value = (e as TouchEvent).touches[0].clientY;
  } else if (diff > 25) {
    minuteOffset.value = minuteOffset.value - 1;
    startY.value = (e as TouchEvent).touches[0].clientY;
  }
};

onMounted(() => {
  setListeners();

  const startDate = props.initialDate ?? new Date();
  startHour.value = startDate.getHours();
  startMinute.value = startDate.getMinutes();
});

onUnmounted(deleteListeners);

watch([() => hourOffset.value, () => minuteOffset.value], () => {
  emits('update:modelValue', {
    hours: +getHourValue(startHour.value + hourOffset.value) ?? '',
    minutes: +getMinuteValue(startMinute.value + minuteOffset.value) ?? '',
  });
});
</script>

<style lang='scss' scoped>
@import "@/assets/styles/components/form/select";

.field.disabled {
  opacity: 0.75;
  pointer-events: none;
}

.select {
  &__options {
    flex-direction: row !important;
    backdrop-filter: blur(4px);

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      @include before(calc(100% - 1rem), 34px);
      margin: auto;
      border-radius: 5px;
      box-shadow: 0 4px 4px 0 #00000033;
    }
  }

  &__left, &__right {
    @extend .flex-col;
    justify-content: flex-end;
    flex: 1;

    p {
      width: 4rem;
      @include font(20px, 34px, black, 500, center);
      z-index: 100;

      &:nth-child(1), &:nth-child(5) {
        color: #0000008C;
      }

      &:nth-child(2), &:nth-child(4) {
        color: #000000B2;
      }
    }
  }

  &__left {
    align-items: flex-end;
  }
}
</style>
