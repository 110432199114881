<template>
  <teleport to=".container">
    <popup-container @close="$emit('close')">
      <p class="popup__text">{{ $t('tech_cancel_message') }}</p>
      <base-button :text="$t('button.edit_reserve')" @click="onEditClick" />
      <base-button :text="$t('info.contact_admin')" color="white" @click="redirectToAdminTg" />
    </popup-container>
  </teleport>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { PropType } from "vue";
import { Request } from "@/types/Request";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";
import { useRouter } from "vue-router";

const props = defineProps({
  request: {
    type: Object as PropType<Request>,
    default: () => ({})
  }
});
defineEmits(['close']);

const { push } = useRouter();
const { redirectToAdminTg } = useButton();

const onEditClick = () => {
  if (props.request?.type === 'gazebo') {
    push('/info/gazebo/reserve');
  } else if (props.request?.type === 'sauna') {
    push('/info/sauna/reserve');
  }
};
</script>

<style scoped lang="scss">
.base-button {
  margin-top: 1.25rem;
}

.popup__text {
  @include font(18px, 24px, black, 500);
}
</style>
