export const button = {
  send_request: 'Wyślij prośbę',
  chat_whit_us: 'Czat z nami',
  add: 'Dodaj',
  pay: 'Zapłać',
  clear_all: 'Wyczyść wszystko',
  order: 'Zamów',
  reserve: 'Zarezerwuj',
  select: 'Wybierz',
  selected: 'Wybrane',
  to_cart: 'Przejdź do koszyka',
  cancel: 'Anuluj',
  clear: 'Wyczyść',
  details: 'Szczegóły',
  leave_review: 'Zostaw opinię',
  ok: 'OK',
  order_massage: 'Zamów masaż',
  cancel_request: 'Anuluj prośbę',
  book_again: 'Zarezerwuj ponownie',
  go_home_page: 'Przejdź do strony głównej',
  edit_reserve: 'Edytuj rezerwację',
  view_more: 'Zobacz więcej',
  order_hookah: 'Zamów shishę',
  rent_equipment: 'Wynajmij sprzęt',
  continue: 'Kontynuuj',
  contact: 'Kontakt',
  book_tour: 'Zarezerwuj wycieczkę',
};
