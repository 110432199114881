<template>
  <div class="panel">
    <page-header back-link="/" />
    <div class="panel__content">
      <loader-panel v-if="isLoading" />

      <template v-else>
        <p v-if="hasTranslation(serviceSetup.message)" class="panel__message">
          {{ getTranslation(serviceSetup.message) }}
        </p>
        <template v-if="serviceSetup.date || serviceSetup.time">
          <p class="label">{{ $t('cleaning.select_datetime') }}</p>
          <div class="panel__fields" :class="{'disabled': data.isNoCleaning}">
            <form-calendar-date v-if="serviceSetup.date" v-model="data.date" :label="$t('cleaning.select_date')"
                                :min="minDate" />
            <form-select v-if="serviceSetup.time" v-model="data.time" :label="$t('cleaning.select_time')"
                         :options="currentTimes" />
          </div>
          <form-toggle v-model="data.isNoCleaning" :label="$t('cleaning.toggle')" />
        </template>

        <template v-if="serviceSetup.comment">
          <p class="label">{{ $t('cleaning.comment') }}</p>
          <form-textarea v-model="data.comment" :placeholder="$t('form.enter_comment')" />
        </template>

        <base-button :class="{'disabled': !isButtonActive}" :text="$t('button.send_request')" @click="onSendClick" />
      </template>
      <banners-panel />
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import BannersPanel from "@/components/Banner/BannersPanel.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { computed, onMounted, ref, watch } from "vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import { FormTimeValue, SelectOption } from "@/types/App/Form";
import FormToggle from "@/components/Form/FormToggle.vue";
import { useRequestStore } from "@/stores/requestStore";
import { storeToRefs } from "pinia";
import { useRender } from "@/composable/useRender";
import { useRouter } from "vue-router";
import { RequestDetails } from "@/types/Request";
import { useUserStore } from "@/stores/userStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useHotelSetup } from "@/composable/useHotelSetup";
import FormCalendarDate from "@/components/Form/FormCalendarDate.vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { HotelSetupCleaning } from "@/types/HotelSetup";
import { useTranslation } from "@/composable/useTranslation";

const { push } = useRouter();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const userStore = useUserStore();
const requestStore = useRequestStore();
const { error } = storeToRefs(requestStore);
const { renderNotification } = useRender();
const { checkFunction } = useHotelSetup();
const { getTranslation, hasTranslation } = useTranslation();

const data = ref({
  date: '',
  time: '',
  comment: '',
  isNoCleaning: false,
});
const minDate = ref('');
const isLoading = ref(true);

const times = ref<SelectOption[]>([]);
const currentTimes = ref<SelectOption[]>(times.value);

const serviceSetup = computed((): HotelSetupCleaning => hotelSetup.value.cleaning);

const isButtonActive = computed((): boolean => {
  if (serviceSetup.value.date && serviceSetup.value.comment) {
    return data.value.isNoCleaning ? !!data.value.comment : !!(data.value.date && (!serviceSetup.value.time || data.value.time));
  } else if (serviceSetup.value.date) {
    return !!(data.value.date && data.value.time);
  } else if (serviceSetup.value.time) {
    return !!data.value.time;
  } else {
    return !!data.value.comment;
  }
});

const onSendClick = async () => {
  const details: RequestDetails = { needClean: !data.value.isNoCleaning };
  if (details.needClean) {
    details.date = data.value.date;
    details.time = data.value.time;
  }

  await requestStore.createRequest({
    type: 'cleaning',
    linkId: userStore.clientLinkData.id,
    comment: data.value.comment,
    details
  });
  if (!error.value) {
    setTimeout(() => renderNotification('request_success'), 100);
    push('/');
  }
};

const getTimeStr = (time: FormTimeValue) => `${ time.from } - ${ time.to }`;

onMounted(() => {
  checkFunction('cleaning');

  if (serviceSetup.value.times?.length) {
    times.value = serviceSetup.value.times.map(option => ({ value: getTimeStr(option), label: getTimeStr(option) }));
    currentTimes.value = times.value;
  }

  if (serviceSetup.value.date) {
    const today = new Date();
    if (today.getHours() > 18) {
      minDate.value = new Date(today.setDate(today.getDate() + 1)).toISOString().slice(0, 10);
    } else {
      minDate.value = today.toISOString().slice(0, 10);
    }

    data.value.date = minDate.value;
  } else {
    data.value.isNoCleaning = !serviceSetup.value.time;
  }

  isLoading.value = false;
});

watch(() => data.value.date, () => {
  if (serviceSetup.value.date && data.value.date === minDate.value) {
    const hours = new Date().getHours();
    let index = -1;
    for (let i = 0; i < times.value.length; i++) {
      if (+(times.value[i].value as string)?.slice(0, 2) > hours) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      currentTimes.value = [...times.value].splice(index);
      data.value.time = times.value[index].value as string;
    }
  } else {
    currentTimes.value = times.value;
    data.value.time = currentTimes.value[0].value as string;
  }
});
</script>

<style scoped lang="scss">
.panel {
  &__fields {
    @extend .flex-row;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.75;
    }

    :deep(.field) {
      min-width: calc((100% - 1rem) / 2);
    }
  }

  &__message {
    @extend .rounded;
    margin-bottom: 1rem;
    padding: 6px 12px;
    @include font(18px, 22px, white, 600);
    background-color: #{$primary}33;
  }
}

.field:has(.field__radio) {
  margin-bottom: 1.5rem;
}

.base-button {
  margin: 1.5rem 0;
}

.label {
  margin-bottom: 1rem;
}
</style>
