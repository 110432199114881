<template>
  <div class="notifications" id="notifications" @click.stop="isShown = true">
    <div class="notifications__icon"></div>
    <p v-if="notifications.length > 0" class="notifications__badge">+{{ notifications.length }}</p>

    <popup-container v-if="isShown" :title="title" @click.stop @close="onCloseClick">
      <template v-if="active === -1 || isTechShown">
        <p v-if="!notifications.length" class="notifications__empty">{{ $t('empty_notifications') }}</p>
        <div v-else class="notifications__list">
          <div v-for="(item, key) in notifications" :key="key" class="notifications__list-item"
               @click="onNotificationClick(item, key)">
            <div class="notifications__list-item__close" @click.stop="onDeleteClick(key)"></div>
            <p class="notifications__list-item__title">{{ item.title }}</p>
            <p class="notifications__list-item__text">
              {{ item.data?.details?.cancelCategory === 'tech' ? $t('event.tech_cancel_title') : item.text }}
            </p>
          </div>
        </div>
        <text-button :text="$t('button.clear_all')" :is-underlined="true" @click="onClearClick" />
      </template>
      <template v-else>
        <div class="info">
          <p class="info__text">{{ $t(`notification.${ notifications[active].data.status }.text`) }}</p>
          <p class="info__title">{{ $t(`notification.${ notifications[active].data.status }.details`) }}:</p>
          <p class="info__text">{{ details }}</p>
          <base-button :text="$t('info.contact_admin')" @click="redirectToAdminTg" />
        </div>
      </template>
    </popup-container>
    <tech-cancel-popup v-if="isTechShown" :request="notifications[active].data"
                       @close="isTechShown = false; active = -1;" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import TextButton from "@/components/Button/TextButton.vue";
import { Notification } from "@/types/App/Notification";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { Request } from "@/types/Request";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";
import TechCancelPopup from "@/components/Popup/TechCancelPopup.vue";
import { useRouter } from "vue-router";
import { Reservation } from "@/types/Reservation";
import { useTranslation } from "@/composable/useTranslation";

const i18n = useI18n();
const { push } = useRouter();
const appStore = useAppStore();
const { unreadNotifications } = storeToRefs(appStore);
const { redirectToAdminTg } = useButton();
const { getTranslation } = useTranslation();

const isShown = ref(false);
const isTechShown = ref(false);
const notifications = computed((): Notification[] => [...unreadNotifications.value].sort((a, b) => +b.date - +a.date));
const active = ref(-1);

const title = computed((): string => {
  if (active.value >= 0) {
    return i18n.t(`notification.${ notifications.value[active.value]?.data?.status }.title`);
  } else {
    return i18n.t('notifications');
  }
});

const details = computed((): string => {
  if (active.value >= 0) {
    const data = (notifications.value[active.value]?.data ?? {}) as Request | Reservation;
    if (data.status === 'active' || data.status === 'finished') {
      if (data?.serviceId > 0) {
        return getTranslation(data.service?.name ?? {});
      } else {
        return i18n.t(`service.${ data.type }`);
      }
    } else if (data.status === 'cancelled') {
      return [
        data.details?.cancelCategory ? i18n.t(`data.${ data.details.cancelCategory }`) : '',
        data.details?.cancelReason ?? ''
      ].filter(item => !!item).join('. ');
    } else {
      return '';
    }
  } else {
    return '';
  }
});

const onNotificationClick = (notification: Notification, index: number) => {
  if (notification.data?.details?.cancelCategory === 'tech') {
    isTechShown.value = true;
    active.value = index;
  } else if (notification.type === 'request') {
    active.value = index;
  } else if (notification.type === 'booking') {
    onDeleteClick(index);
    push('/booking/history');
  }
};

const onDeleteClick = (index: number) => unreadNotifications.value.splice(index, 1);

const onClearClick = () => {
  unreadNotifications.value = [];
  isShown.value = false;
};

const onCloseClick = () => {
  if (active.value >= 0) {
    active.value = -1;
  } else {
    isShown.value = false;
  }
};
</script>

<style scoped lang="scss">
.notifications {
  @extend .row-container;
  position: relative;

  &__icon {
    @extend .circle;
    position: relative;
    @include icon($notification, 2.5rem, 1.5rem);
    background-color: #FFE500CC;
  }

  &__badge {
    @extend .circle;
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;
    @include font(10px, 1.25rem, #D12121, 600, center);
    background-color: white;
  }

  &__empty {
    max-width: 40vw;
    margin: auto;
    @include font(15px, 18px, black, 500, center);
    text-wrap: balance;
  }

  &__list {
    @extend .flex-col, .custom-scroll, .custom-scroll-container;
    gap: 1rem;
    width: 100%;
    max-height: calc(100% - 2.25rem);
    height: 100%;
    margin-bottom: 1rem;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: white;
    }

    &-item {
      @extend .flex-col;
      position: relative;
      padding: 10px 1rem;
      background-color: #FFE500B2;
      border-radius: 10px;

      &__title {
        max-width: calc(100% - 1.5rem);
        margin-bottom: 0.5rem;
        @include font(18px, 22px, black, 600);
      }

      &__text {
        @include font(16px, 20px, #00000080, 600);
      }

      &__close {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        @include icon($close, 1.5rem, 1.5rem);
        background-color: #FFFFFF99;
        border-radius: 4px;
      }
    }
  }

  :deep(.wrapper) {
    position: absolute;
    left: unset;
    right: -1rem;
    top: calc(100% + 0.5rem);
    height: max-content;
    backdrop-filter: unset;
    background-color: transparent;
  }

  :deep(.popup) {
    top: unset;
    bottom: unset;
    height: 50dvh;
    margin: 0 1rem;
    backdrop-filter: blur(4px);
  }

  :deep(.popup__content) {
    align-items: center;
    height: 100%;
    max-height: calc(100% - 3rem) !important;
  }

  .text-button {
    margin-top: auto;
    justify-self: flex-end;

    :deep(.text-button__text) {
      @include font(18px, 22px, black, 600);
    }

    :deep(.text-button__text:after) {
      bottom: 1px;
      border-color: black;
      border-width: 2px;
    }
  }
}

.info {
  @extend .flex-col;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__title {
    margin: 1.5rem 0 1rem;
    @include font(18px, 22px, black, 600);
  }

  &__text {
    @include font(18px, 22px, black, 500);
  }

  .base-button {
    margin-top: auto;
  }
}
</style>
