import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { HotelMenuItem } from "@/types/HotelSetup";
import { request } from "@/stores/request";

export const useHotelMenuStore = defineStore('hotelMenuStore', () => {
  const { getErrorMessage } = useApi();
  const hotelSetupStore = useHotelSetupStore();

  const menuItems = ref<HotelMenuItem[]>([]);
  const error = ref('');

  const getMenuItems = async () => {
    try {
      menuItems.value = await request({
        url: `hotel-menu/hotel/${ hotelSetupStore.hotelSetup.id }`,
        method: 'get'
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    error, menuItems,
    getMenuItems,
  };
}, { persist: true });
