export const button = {
  send_request: 'Send a request',
  chat_whit_us: 'Chat with us',
  add: 'Add',
  pay: 'Pay',
  clear_all: 'Clear all',
  order: 'Order',
  reserve: 'Reserve',
  select: 'Select',
  selected: 'Selected',
  to_cart: 'Go to cart',
  cancel: 'Cancel',
  clear: 'Clear',
  details: 'Details',
  leave_review: 'Leave a review',
  ok: 'OK',
  order_massage: 'Order massage',
  cancel_request: 'Cancel the request',
  book_again: 'Book again',
  go_home_page: 'Go to Home page',
  edit_reserve: 'Edit Reservation',
  view_more: 'View more',
  order_hookah: 'Order hookah',
  rent_equipment: 'Rent equipment',
  continue: 'Continue',
  contact: 'Contact',
  book_tour: 'Book tour',
};
