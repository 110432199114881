<template>
  <div class="images">
    <transition :name="`slide-${transition}`">
      <div class="images__wrapper" :class="{ loading: !images[currentImage] }" :key="currentImage">
        <img class="images__item" :src="images[currentImage]" alt="img" @load="onImageLoad" @error="onImageError" />
      </div>
    </transition>
    <div v-if="images.length > 1" class="images__buttons">
      <div class="slide-button slide-button--prev" @click="onPrevClick"></div>
      <div class="slide-button slide-button--next" @click="onNextClick"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";

const props = defineProps({
  images: {
    type: Array as PropType<string[]>,
    default: () => []
  }
});

const currentImage = ref(0);
const transition = ref('left');

const onImageLoad = (event: Event) => {
  (event.target as HTMLImageElement).classList.remove('loading');
};

const onImageError = (event: Event) => {
  (event.target as HTMLImageElement).classList.remove('loading');
};

const onPrevClick = () => {
  transition.value = 'right';
  currentImage.value = currentImage.value > 0 ? currentImage.value - 1 : props.images.length - 1;
};

const onNextClick = () => {
  transition.value = 'left';
  currentImage.value = currentImage.value < props.images.length - 1 ? currentImage.value + 1 : 0;
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/utils/transitions";

.images {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 62vw;
  margin: 1rem 0 1.5rem;

  &__wrapper {
    width: 100%;
    height: 62vw;
    border-radius: 10px;
    overflow: hidden;

    &.loading {
      background-image: url('@/assets/preload.png');
      background-size: 40%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__item {
    width: 100%;
    height: 62vw;
    border-radius: 10px;
    object-fit: cover;
  }

  &__buttons {
    @extend .flex-row;
    gap: 1rem;
    position: absolute;
    right: 0.75rem;
    bottom: 0.75rem;
  }
}

.slide-button {
  background-color: #FFFFFFB2;
}
</style>
