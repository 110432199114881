<template>
  <loader-panel v-if="isLoading" />

  <div v-else class="panel">
    <page-header :title="getTranslation(roomType.name)" back-link="/booking/rooms" />

    <div class="panel__content">
      <images-panel v-if="roomType.imagesData?.length" :images="roomType.imagesData" />

      <template v-for="(article, key) in roomType.articles" :key="key">
        <p v-if="hasTranslation(article.title)" class="panel__title">{{ getTranslation(article.title) }}</p>
        <p v-html="getTranslation(article.text)" class="panel__text"></p>
      </template>

      <div class="panel__price">
        <p class="panel__price-label">{{ $t('booking.price_per_day') }}:</p>
        <p class="panel__price-value">{{ roomType.price }} {{ $t(userStore.currency) }}</p>
        <p v-if="hasTranslation(roomType.priceText)" class="panel__price-info">{{ getTranslation(roomType.priceText) }}</p>
      </div>
    </div>
    <base-button :text="getTranslation(bookingButton.label)" :color="bookingButton.color ?? 'main'"
                 @click="onReserveClick" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import ImagesPanel from "@/components/ImagesPanel.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHotelSetup } from "@/composable/useHotelSetup";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useUserStore } from "@/stores/userStore";
import { useRoomTypeStore } from "@/stores/roomTypeStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { FormButton } from "@/types/App/Form";

const { push } = useRouter();
const { params } = useRoute();
const userStore = useUserStore();
const roomTypeStore = useRoomTypeStore();
const { roomType } = storeToRefs(roomTypeStore);
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { getTranslation, hasTranslation } = useTranslation();
const { onButtonClick, checkFunction } = useHotelSetup();

const isLoading = ref(true);

const bookingButton = computed((): FormButton => hotelSetup.value.booking.buttons[0]);

const onReserveClick = () => {
  if (hotelSetup.value.booking.type === 'in_app') {
    push(`/booking/rooms/${ +params.roomTypeId }/reserve`)
  } else {
    onButtonClick(hotelSetup.value.booking?.buttons?.[0]);
  }
};

onMounted(async () => {
  checkFunction('booking');

  await roomTypeStore.getRoomType(+params.roomTypeId);
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/info";

.panel {
  &__price {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid white;

    &-label {
      margin-bottom: 6px;
      @include font(18px, 22px, white, 500);
    }

    &-value {
      @include font(18px, 22px, $primary, 600);
      text-transform: uppercase;
    }

    &-info {
      margin-top: 0.25rem;
      @include font(16px, 20px, white, 400);
    }
  }
}
</style>
