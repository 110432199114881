export const advert = {
  chorna_skelya_sauna: {
    title: 'Sauna with Himalayan salt elements',
    text: 'It is considered extremely useful, as when heating the room, the air is saturated with barium, iron, magnesium, iodine, calcium, manganese, etc. present in the salt. useful trace elements. ',
  },
  chorna_skelya_gazebo: {
    title: 'Spent great time with friends and family in our Gazebo',
    text: 'Vacationing with friends and family at the gazebo in the "Chorna Skelya" complex is a wonderful opportunity to spend time together, enjoying the natural atmosphere and coziness of this picturesque place.',
  },
  chorna_skelya_vat: {
    title: '"Carpathian Vat" with herbs',
    text: 'Experience the benefits of a healthful cauldron, allowing hydro massage to relax your muscles, improve circulation, and restore energy, so you can fully enjoy the feeling of well-being and relaxation together with friends and family.',
  },

  teremok_info: {
    title: 'Private Estate "Teremok"',
    text: 'The unique cottage complex "Teremok" - cozy cottages, barbecue, sauna, picturesque views - all on the premises for you.'
  },

  astur_quad: {
    title: 'Quad Biking with Astur Spa',
    text: 'Experience thrilling emotions and overcome natural obstacles on our mountain routes with quad bikes.'
  },
  astur_bicycle: {
    title: 'Bicycle Rental at Astur Hotel',
    text: 'Bicycle rental at Astur Hotel is an excellent opportunity to expand your leisure possibilities and discover new horizons.'
  },
  astur_spa: {
    title: 'SPA Complex with Astur SPA',
    text: 'Here you will find everything necessary for maximum relaxation: a large pool with spring water, a children\'s pool, a sauna, and a jacuzzi.'
  },

  shepit_carpat_salt_room: {
    title: 'Salt Room',
    text: 'Immerse yourself in an atmosphere of cleanliness and restoration, where millions of microscopic salt particles are sprayed in the air, giving you the opportunity to feel a real rest for the lungs and skin.',
  },
  shepit_carpat_bath: {
    title: 'Relax in the Bath',
    text: 'Feel the atmosphere of real relaxation and recuperation in our sauna with wooden walls and natural aromas.',
  },
  shepit_carpat_ski_eq: {
    title: 'Ski equipment',
    text: 'Hotel complex "Whisper of the Carpathians" is pleased to offer its guests a convenient service of renting ski equipment.',
  },
  shepit_carpat_rooms: {
    title: 'Your Best Relaxation Awaits',
    text: 'Enjoy an incredible stay in our comfortable premium-class hotel.',
  },
  shepit_carpat_spa: {
    title: 'SPA Center - Your Path to Harmony of Soul and Body',
    text: 'Enjoy spa treatments in our comfortable premium-class hotel.',
  },

  u_stozi_tours_1: {
    title: 'Hiking in the mountains with "In the Stack"',
    text: 'Enjoy the incredible nature of the Ukrainian Carpathians thanks to the variety of our excursions.',
  },
  u_stozi_tours_2: {
    title: 'Incredible adventures with "In the Stack',
    text: 'Conquer the tops of the mountains, meet the dawn and stroll to the high mountain lake with us.',
  },

  tur_dvir_gazebo: {
    title: 'Zooming holiday with family and friends in Altanka',
    text: 'Rest with friends and family in the gazebo is a great opportunity to spend time together, enjoying the atmosphere of nature and the comfort of this picturesque place.',
  },
  tur_dvir_vat: {
    title: 'Unique experience in our therapeutic Pecherinsky vats',
    text: 'These vats, full of pure mountain water and specially prepared Carpathian herbs and secret impurities, promise you an unforgettable feeling of relaxation and recovery.',
  },
  tur_dvir_fishing: {
    title: 'Fishing in the "Turyansky Dvir"',
    text: 'Fishing is a great opportunity to spend time with family and friends, share experiences and enjoy nature together.',
  },
};
