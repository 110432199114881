<template>
  <popup-container class="calendar-popup" :title="title ? `${title}:` : ''" @close="$emit('close')">
    <div class="table__top">
      <div class="table__top-arrow table__top-arrow--prev" :class="{'disabled': !isPrevActive}"
           @click="onPrevClick"></div>
      <p class="table__top-text">{{ moment(monthYear).format('MMMM YYYY') }}</p>
      <div class="table__top-arrow table__top-arrow--next" :class="{'disabled': !isNextActive}"
           @click="onNextClick"></div>
    </div>
    <div class="table">
      <div class="table__row table__row--header">
        <p v-for="day in days" :key="day" class="table__row-item">{{ day }}</p>
      </div>
      <div v-for="(item, key) in dates" :key="key" class="table__row table__row--body">
        <p v-for="date in item" :key="date.fullDate" class="table__row-item" :class="getDateClass(date)"
           @click="onDateClick(date)">
          {{ date.date }}
        </p>
      </div>
    </div>
    <div class="table__row table__row--footer">
      <div class="table__row-block">
        <p class="table__row-item active">1</p>
        <p class="table__row-text">{{ $t('booking.available_date') }}</p>
      </div>
      <div class="table__row-block">
        <p class="table__row-item excluded">1</p>
        <p class="table__row-text">{{ $t('booking.unavailable_date') }}</p>
      </div>
    </div>
    <base-button :text="$t('button.select')" :class="{'disabled': !isButtonActive}" @click="onButtonClick" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { computed, onMounted, PropType, ref, watch } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { useTime } from "@/composable/useTime";
import { CalendarItem, useCalendar } from "@/composable/useCalendar";
import BaseButton from "@/components/Button/BaseButton.vue";
import { FormPeriod } from "@/types/App/Form";

const props = defineProps({
  title: String,
  initialDate: String,
  initialRange: {
    type: Object as PropType<FormPeriod>,
    default: () => ({})
  },
  min: String,
  max: String,
  exclude: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  isRange: Boolean,
});
const emits = defineEmits(['update:modelValue', 'close']);

const i18n = useI18n();
const { locales } = useTime();
const { dates, days, setDates } = useCalendar();

const selectedDate = ref(props.initialDate ?? '');
const valueFrom = ref(props.initialRange?.dateFrom ?? '');
const valueTo = ref(props.initialRange?.dateTo ?? '');
const monthYear = ref('');

const isPrevActive = computed((): boolean => props.min ? monthYear.value > props.min : true);
const isNextActive = computed((): boolean => props.max ? monthYear.value < props.max : true);
const isButtonActive = computed((): boolean => {
  if (props.isRange) {
    return !!(valueFrom.value && valueTo.value);
  } else {
    return !!selectedDate.value;
  }
});

const getDateClass = (date: CalendarItem) => {
  let isSelected;
  if (props.isRange) {
    if (!valueTo.value) {
      isSelected = date.fullDate === valueFrom.value;
    } else {
      isSelected = date.fullDate >= valueFrom.value && date.fullDate <= valueTo.value;
    }
  } else {
    isSelected = date.fullDate === selectedDate.value;
  }
  console.log(props.min, date)
  return [
    date.status,
    {
      'selected': isSelected,
      'excluded': props.exclude.includes(date.fullDate),
      'inactive': (props.min ? date.fullDate < props.min : false) || (props.max ? date.fullDate > props.max : false)
    }
  ];
};

const onPrevClick = () => monthYear.value = moment(monthYear.value).subtract(1, 'month').format('YYYY-MM-DD');

const onNextClick = () => monthYear.value = moment(monthYear.value).add(1, 'month').format('YYYY-MM-DD');

const onDateClick = (date: CalendarItem) => {
  if (props.isRange) {
    if (!valueFrom.value) { // if first choice
      valueFrom.value = date.fullDate;
    } else if (!valueTo.value) {
      if (valueFrom.value === date.fullDate) { // if clicked on the same item
        valueFrom.value = '';
      } else { // if choose date to
        const min = valueFrom.value < date.fullDate ? valueFrom.value : date.fullDate;
        const max = valueFrom.value < date.fullDate ? date.fullDate : valueFrom.value;
        if (props.exclude.find(item => item >= min && item <= max)) { // if excluded dates are in the range
          valueFrom.value = date.fullDate;
        } else {
          valueFrom.value = min;
          valueTo.value = max;
        }
      }
    } else {
      valueFrom.value = date.fullDate;
      valueTo.value = '';
    }
  } else {
    selectedDate.value = date.fullDate;
  }
};

const onButtonClick = () => {
  if (props.isRange) {
    emits('update:modelValue', { dateFrom: valueFrom.value, dateTo: valueTo.value });
  } else {
    emits('update:modelValue', selectedDate.value);
  }
};

onMounted(() => {
  moment.locale(locales[i18n.locale.value]);

  if (props.initialDate) {
    monthYear.value = moment(props.initialDate).date(1).format('YYYY-MM-DD');
  } else {
    monthYear.value = moment().date(1).format('YYYY-MM-DD');
  }
});

watch(() => monthYear.value, () => setDates(monthYear.value));
</script>

<style scoped lang="scss">
.table {
  @extend .flex-col;
  width: 100%;

  &__top {
    @extend .row-container;
    justify-content: center;
    gap: 1.5rem;

    &-text {
      @include font(16px, 20px, black, 500);
    }

    &-arrow {
      @extend .pointer;
      @include icon($select-arrow, 2rem, 1.5rem);
      filter: invert(1);

      &--prev {
        transform: rotate(0.25turn);
      }

      &--next {
        transform: rotate(0.75turn);
      }

      &.disabled {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }

  &__row {
    @extend .row-container;
    justify-content: center;
    width: 100%;
    height: 2.5rem;

    &--header .table__row-item {
      @include font(16px, 20px, #616161, 500, center);
      text-transform: uppercase;
    }

    &--footer {
      justify-content: flex-start;
      margin-top: 1.5rem;
      gap: 1.5rem;
    }

    &-item {
      @extend .pointer;
      flex: 1;
      margin: 0 0.25rem;
      @include font(16px, 20px, black, 500, center);
      border-radius: 3px;
      transition-duration: 0.2s;

      &.inactive {
        opacity: 0.25;
        pointer-events: none;
        background-color: transparent !important;
      }

      &.active {
        background-color: white;
      }

      &.selected {
        background-color: $primary;
      }

      &.excluded {
        background-color: #8F8F8F;
        opacity: 0.25;
        pointer-events: none;
      }
    }

    &-block {
      @extend .row-container;

      .table__row-item {
        width: 3rem;
        margin-right: 0.75rem;
      }
    }

    &-text {
      @include font(14px, 16px, black);
    }
  }
}

.base-button {
  margin-top: 1rem;
}

:deep(.popup__close) {
  top: -0.75rem;
  right: -0.75rem;
  background-size: 1.5rem !important;
}

:deep(.popup__header) {
  margin-bottom: 1.5rem;
}

:deep(.popup__title) {
  @include font(18px, 22px, black, 500);
}

.desk {
  .table__top {
    margin-bottom: 1rem;

    &-text {
      font-size: 18px;
    }
  }

  .table__row-item {
    line-height: 2rem;
  }
}
</style>
