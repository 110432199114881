<template>
  <div class="panel">
    <page-header back-link="/" />
    <div class="panel__content">
      <template v-if="infoSetup.additional?.length">
        <p class="panel__content-title">{{ $t('info.additional') }}</p>
        <div class="panel__tiles" :class="{'disabled': !userStore.isLinkActive}">
          <div v-for="item in infoSetup.additional" :key="item.id" class="panel__tiles-item" @click="onItemClick(item)">
            <div v-if="!imagesSrc[item.id]" class="panel__tiles-item__loader"></div>
            <img v-else :src="imagesSrc[item.id]" alt="img" class="panel__tiles-item__image" />
            <p class="panel__tiles-item__text">{{ getTranslation(item.title) }}</p>
          </div>
        </div>
      </template>

      <template v-if="infoSetup.general?.length">
        <p class="panel__content-title">{{ $t('info.general') }}</p>
        <div v-for="item in infoSetup.general" :key="item.id" class="menu-list__item" @click="onItemClick(item)">
          <p class="menu-list__item-name">{{ getTranslation(item.title) }}</p>
        </div>
        <div class="menu-list__item" @click="redirectToTg">
          <p class="menu-list__item-name">{{ $t('info.chat') }}</p>
        </div>
      </template>

      <p class="panel__content-title">{{ $t('info.other') }}</p>
      <div v-for="item in other" :key="item.name" class="menu-list__item" @click="push(item.link)">
        <p class="menu-list__item-name">{{ $t(item.title) }}</p>
      </div>

      <banners-panel />
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import BannersPanel from "@/components/Banner/BannersPanel.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { useButton } from "@/composable/useButton";
import { computed, onMounted, ref } from "vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";
import { HotelSetupInformation } from "@/types/HotelSetup";
import { InfoPage } from "@/types/InfoPage";
import { useTranslation } from "@/composable/useTranslation";
import { useApi } from "@/composable/useApi";

const userStore = useUserStore();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { push } = useRouter();
const { redirectToTg } = useButton();
const { getTranslation } = useTranslation();
const { getApiUrl } = useApi();

const other = ref([
  { name: 'reserves', title: 'info.my_reserves', link: '/info/reserves' },
  { name: 'booking', title: 'info.booking_history', link: '/booking/history' },
]);
const imagesSrc = ref<{ [key: number]: string }>({});

const infoSetup = computed((): HotelSetupInformation => hotelSetup.value.information);

const onItemClick = (item: InfoPage) => push(item.pageLink ? item.pageLink : `/info/page/${ item.id }`);

onMounted(() => {
  hotelSetup.value.information.additional.map(async (page) => {
    const image = new Image();
    image.src = getApiUrl() + page.mainImage;
    image.addEventListener('load', () => {
      imagesSrc.value[page.id] = image.getAttribute('src') ?? '';
    });
  });
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/list";

.panel {
  @extend .flex-col;
  height: 100%;

  &__content {
    @extend .flex-col;

    &-title {
      margin-bottom: 0.75rem;
      @include font(20px, 24px, white, 600);
    }
  }

  &__tiles {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1rem 0.75rem;
    margin: 1rem 0 2.75rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &-item {
      @extend .flex-col;
      position: relative;
      min-width: calc((100% - 1rem) / 2);
      width: calc((100% - 1rem) / 2);

      &__loader, &__image {
        width: 100%;
        height: 8rem;
      }

      &__loader {
        background-image: url('@/assets/preload.png');
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &__image {
        margin-bottom: 0.5rem;
        border-radius: 10px;
        object-fit: cover;
      }

      &__text {
        @include font(18px, 22px, white, 600);
      }
    }
  }
}

.menu-list__item {
  margin-bottom: 1rem;
}
</style>
