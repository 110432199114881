import { createApp, h, ref, render } from 'vue';
import { useI18n } from 'vue-i18n';
import NotificationPopup from "@/components/Popup/NotificationPopup.vue";
import NewMessagePopup from "@/components/Popup/NewMessagePopup.vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";

export const useRender = () => {
  const i18n = useI18n();
  const { push } = useRouter();
  const appStore = useAppStore();
  const { unreadNotifications } = storeToRefs(appStore);

  const timeout = ref(0);

  const renderNotification = (text: string, isLocalized = false) => {
    const container = document.createElement('div');
    render(h(NotificationPopup, { text: `${ isLocalized ? text : i18n.t(text) }` }), container);
  };

  const renderNewMessage = async (message: string, title: string | null = null, requestId = null) => {
    const messagePopup = createApp({
      setup() {
        return () => h(NewMessagePopup, {
          text: message,
          title,
          onClick: () => {
            clearTimeout(timeout.value);
            document.body.removeChild(wrapper);
            if (requestId) {
              const item = document.getElementById('notifications');
              item && item.click();
              //unreadNotifications.value = unreadNotifications.value.filter(item => item.request?.id !== requestId);
            }
          }
        });
      },
    });
    const wrapper = document.createElement('div');
    messagePopup.mount(wrapper);
    document.body.appendChild(wrapper);

    timeout.value = setTimeout(() => document.body.removeChild(wrapper), 5000);
  };

  return { renderNotification, renderNewMessage };
};
