import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { HotelSetup } from "@/types/HotelSetup";
import { request } from "@/stores/request";
import { useUserStore } from "@/stores/userStore";
import { InfoPage } from "@/types/InfoPage";

export const useHotelSetupStore = defineStore('hotelSetupStore', () => {
  const { getErrorMessage, getApiUrl } = useApi();
  const userStore = useUserStore();

  const hotelSetup = ref<HotelSetup>({} as HotelSetup);
  const infoPage = ref<InfoPage>({} as InfoPage);
  const error = ref('');

  const getHotelSetup = async () => {
    try {
      hotelSetup.value = await request({
        url: `hotel/${ userStore.client?.organizationId }?organization=true&categories=true&banners=true&services=true`,
        method: 'get'
      });
      hotelSetup.value.banners.map(async (banner) => {
        const image = new Image();
        image.src = getApiUrl() + banner.image;
        image.addEventListener('load', () => {
          banner.imageSrc = image.getAttribute('src') ?? '';
        });
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getInfoPage = async (pageId: number) => {
    try {
      infoPage.value = await request({
        url: `hotel/info-pages/${ pageId }`,
        method: 'get',
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    hotelSetup, error, infoPage,
    getHotelSetup, getInfoPage
  };
}, { persist: true });
