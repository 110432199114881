import { AxiosError } from 'axios';
import { useI18n } from "vue-i18n";

export const useApi = () => {
  const i18n = useI18n();
  const getApiUrl = () => process.env.NODE_ENV === 'development' ? `http://localhost:3000` : `https://demochat.vipmanage.org/api`;

  const getErrorMessage = (error: any) => {
    const data = (error as AxiosError).response?.data as { message: string; name: string; };
    return data?.name?.startsWith('ERROR') ? i18n.t(`error.${data.name}`) : (data?.name ?? data?.message);
  }

  return { getApiUrl, getErrorMessage };
};
