import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { computed, ref } from 'vue';
import { request } from "@/stores/request";
import { User, UserSettings } from "@/types/User";
import { Link } from "@/types/Link";

export const useUserStore = defineStore('userStore', () => {
  const { getErrorMessage } = useApi();

  const client = ref<User>({} as User);
  const clientLinkData = ref<Link>({} as Link);
  const error = ref('');

  const clientId = computed(() => client.value?.id);

  const linkType = computed(() => clientLinkData.value?.type);

  const currency = computed((): string => client.value.organization?.settings?.currency ?? 'uah');

  const isLinkActive = computed((): boolean => {
    if (clientLinkData.value?.id) {
      return clientLinkData.value.isActive && clientLinkData.value.dateTo >= new Date().toISOString().slice(0, 10);
    } else {
      return false;
    }
  });

  const updateUser = async ( data: Partial<User>) => {
    try {
      const response = await request({ url:`user/${clientId.value}`,method: 'put', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const login = async ( hash: string) => {
    try {
      const response = await request({ url: 'auth/login/hash', method: 'post', data: { hash } });
      if (response?.id) {
        localStorage.setItem('jwt_client', response.token);
        client.value = response;
        error.value = '';
      }
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };


  const updateUserSettings = async (data: Partial<UserSettings>) => {
    try {
      client.value = await request({ url: `user/${ clientId.value }/settings`, method: 'put', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    client, error, clientId, clientLinkData, isLinkActive, currency, linkType,
    login, updateUserSettings,updateUser
  };
}, {
  persist: true
});


