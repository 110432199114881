export const infoPriceList = {
  spa_zone_title: 'Price of SPA zone services',
  spa_zone_subtitle: 'Sauna booking is carried out for at least 2 hours and at least for 4 people',
  sauna_vat_title: 'The price of booking a sauna/vat',
  sauna_vat_subtitle: 'Booking is possible for at least 3 hours, the booking is paid through the administrator',
  vat_title: 'The price of booking vat',
  vat_subtitle: 'Payment is made through the administrator',
  quad_title: 'The price of booking a quad bike ride',
  bicycle_title: 'The price of booking a cycling',
  cottage_title: 'The price of booking cottage',
  cottage_subtitle: 'Booking involves an advance payment of 50%',
  gazebo_title: 'The price of booking gazebo',
  gazebo_subtitle: 'Payment for booking a gazebo involves any number of hours within the schedule of work of gazebos',
  hookah_title: 'Hookah prices',
  bath_title: 'The price of booking bath',
  bath_subtitle: 'The booking is paid through the administrator',
  spa_title: 'The price of booking spa',
  spa_subtitle: 'The booking is paid through the administrator',
  fishing_title: 'The price of booking fishing',
  fishing_subtitle: 'Payment for booking a fishing involves any number of hours within the schedule of work of fishing',

  hour: 'h',
  day: 'd',
  minute: 'min',
  unlimited: 'unlimited',
  h: 'h',
  d: 'd',
  m: 'min',
  u: 'unlimited',

  cols: {
    name: 'Name',
    price_per_hour: 'Price\\hour',
    price_per_day: 'Price\\day',
    people_amount: 'People',
    max_people_amount: 'Max people',
  }
};
