export const sauna = {
  sauna_vat_title: 'Сауна та чани',
  price: 'Прайсліст',
  vat: 'Чан',
  about_vat: 'Про Чан',
  name: 'Назва',
  price_per_hour: 'Ціна\\год',
  max_person: 'Макс. кіл-ть осіб',
  choose_service: 'Оберіть послугу',
  choose_date: 'Вкажіть дату та час, протягом якого ви потребуєте сауну або чан',
  hours: 'Кількість годин',
  free_time: 'Оберіть вільний час',
  payment: '*Оплата бронювання відбувається через адміністратора.',
  sauna_1: 'Фінська сауна з гімалайською сіллю',
  sauna_1_vat_1: 'Фінська сауна + карпатський чан (один)',
  sauna_1_vat_2: 'Фінська сауна + карпатські чани (два)',
  vat_1: 'Карпатський чан (один) + зона барбекю',
  vat_2: 'Карпатські чани (два) + зона барбекю',
  pool_jacuzzi_massage: 'Басейн + джакузі + водний масаж',
};
