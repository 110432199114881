export const service = {
  title: 'Your portable service',
  cleaning: 'Housekeeping',
  taxi: 'Taxi',
  desk: 'Front desk',
  menu: 'Menu & Beverage',
  booking: 'Booking',
  info: 'Hotel Info',
  chat: '24/7 Chat',
  gazebo: 'Gazebo',
  laundry: 'Laundry Services',
  sauna: 'Sauna',
  bath: 'Bath',
  fishing: 'Fishing',
  spa: 'SPA',

  quad_instructor: 'Riding in the mountains with an instructor',
  bicycle_rent: 'Rent a bike',
  cottage_3_room: '3-room cottage',
  cottage_4_room: '4-room cottage',

  hookah_classic: 'Classic',
  hookah_hard: 'Hard',
  hookah_extra_hard: 'Extra Hard',
  hookah_patriot: 'Patriot (Author\'s hookah, citrus juice in a flask. Part of the funds is transferred to the needs of the Armed Forces!)',
  hookah_death: 'Death to enemies (Author\'s hookah, red wine in a flask. Part of the funds is transferred to the needs of the Armed Forces!',
  hookah_orange_cup: 'Orange Cup',
  hookah_pina_colada: 'Pina Colada',
  hookah_add_1: 'Addition to flask',
  hookah_add_2: 'Pillow',
};
