export const notification = {
  active: {
    title: 'Запрос принят',
    text: 'Ваш запрос был принят в обработку',
    details: 'Детали',
  },
  cancelled: {
    title: 'Запрос отменен',
    text: 'К сожалению, вам запрос был отменен',
    details: 'Причина',
  },
  finished: {
    title: 'Запрос выполнен',
    text: 'Ваш запрос выполнен',
    details: 'Детали',
  }
};
