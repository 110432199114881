export const booking = {
  price_per_day: 'Цена за сутки',
  select_room: 'Выберите комнату',
  per_night: 'за ночь',
  dates: 'Даты',
  guests: 'Гостей',
  client_info: 'Информация про клиента',
  status_label: 'Статус',
  choose_dates: 'Выберите даты',
  choose_date: 'Выберите дату',
  name_surname: 'Фамилия и Имя',
  phone_number: 'Номер телефона',
  phone_number_hint: 'Формат: +9959312345',
  email: 'Email',
  guests_amount: 'Количество гостей',
  arrival_time: 'Приблизительное время Вашого приезда',
  available_date: 'Доступные',
  unavailable_date: 'Занятые',
  book_title: 'Пожалуйста, заполните форму для того, чтобы забронировать номер',
  your_info: 'Ваша информация',
  comment_hint: 'Если Вы желаете получить дополнительную кровать, имеете ребенка или дополнительные потребности - напишите, пожалуйста, детальнее в комментарии снизу',
  cancel_question: 'Вы уверены, что хотите отменить бронирование комнаты?',
  no_booking_available: 'К сожалению нет свободных комнат на выбранный Вами период',

  status: {
    confirmed: 'Подтвержденный',
    active: 'Подтвержденный',
    pending: 'Ожидает',
    finished: 'Завершенный',
    cancelled: 'Отменен',
    checked: 'Сейчас проживает',
    all: 'Все',
  },

  room: {
    standard: 'Стандарт',
    demilux: 'Полу люкс',
    demilux_plus: 'Полу люкс +',
    economy: 'Эконом',
    lux: 'Люкс',

    double_room_wooden: 'Двухместный номер (деревянный корпус)',
    double_room_plus_wooden: 'Двухместный + (деревянный корпус)',
    triple_room_wooden: 'Трехместный номер (деревянный корпус)',
    family_room_improved_wooden: 'Семейный улучшенный (деревянный корпус)',
    family_room_added_entrance_wooden: 'Семейный з дополнительным входом (деревянный корпус)',
    double_room_main: 'Двухместный номер (основной корпус)',
    double_room_plus_main: 'Двухместный номер + (основной корпус)',
    triple_room_main: 'Трехместный номер (основной корпус)',
    family_room_improved_main: 'Семейный улучшенный (над баней)',

    r_standard: 'Стандартный номер',
    r_improved: 'Улучшенный номер',
    r_demilux: 'Полулюкс',
    r_lux: 'Люкс',
    r_junior: 'Джуниор Сьют',
    r_family: 'Семейный номер',
    r_business: 'Бизнес-номер',
    r_president: 'Президентский Люкс',

    mountain_double: 'Двухместный номер с видом на горы',
    mountain_quadruple: 'Четырехместный номер с видом на горы',
    mountain_triple: 'Трехместный номер',
    mountain_large_double: 'Большой двухместный номер',
  }
};
