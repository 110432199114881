import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { request } from "@/stores/request";
import { MenuDish } from "@/types/MenuDish";

export const useMenuDishStore = defineStore('menuDishStore', () => {
  const { getErrorMessage } = useApi();

  const dishes = ref<MenuDish[]>([]);
  const error = ref('');

  const getMenuDishes = async (categoryId: number) => {
    try {
      const response = await request({
        url: `hotel/menu-dishes/category/${ categoryId }?page=1&limit=100`,
        method: 'get'
      });
      dishes.value = response?.dishes?.length ? response.dishes : [];
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    dishes, error,
    getMenuDishes,
  };
});
