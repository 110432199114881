<template>
  <div class='field' :class="`field--${color}`">
    <p v-if="label" class="field__label">{{ label }}</p>
    <div class='field__input'>
      <textarea v-model='value' :placeholder="placeholder" @input='onInput' />
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
    <p v-if="maxLength > 0" class="field__limit">{{ value.length }}/{{ maxLength }}</p>
  </div>
</template>

<script lang='ts' setup>
import { ref, watchEffect } from 'vue';

const props = defineProps({
  modelValue: String,
  label: String,
  placeholder: String,
  hint: String,
  maxLength: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue', 'enter']);

const value = ref('');
const onInput = () => {
  if (props.maxLength > 0 && value.value.length > props.maxLength) {
    value.value = value.value.slice(0, props.maxLength);
  }

  emits('update:modelValue', value.value);
};

watchEffect(() => value.value = props.modelValue ?? '');
</script>

<style lang='scss' scoped>
@import "@/assets/styles/components/form/form";

.field {
  &__input {
    min-height: max-content;
    height: max-content;
    transition-duration: 0.2s;
    cursor: text;

    &:focus, &:has(textarea:focus) {
      border-color: $primary;
    }
  }
}
</style>
