export const food = {
  categories_title: 'Możesz zamówić jedzenie i napoje do swojego pokoju, zjeść w restauracji lub na wynos.',
  breakfast: 'Śniadanie',
  appetizers: 'Przystawki',
  salads: 'Sałatki',
  soups: 'Zupy',
  meat: 'Dania mięsne',
  fish: 'Dania rybne',
  hot: 'Dania gorące',
  grill: 'Dania z grilla',
  drinks: 'Napoje',
  kids: 'Menu dla dzieci',
  sauces: 'Sosy',
  flour: 'Dania mączne',
  sides: 'Dodatki',
  baked: 'Pieczenie',
  desserts: 'Desery',
  'to-beer': 'Do piwa',
  pizza: 'Pizza',
  working_hours: 'Godziny otwarcia restauracji: od {from} do {to}',
  details: 'Szczegóły zamówienia',
  order_type: 'Gdzie chcesz zjeść?',
  in_restaurant: 'W restauracji',
  in_room: 'W pokoju',
  to_go: 'Na wynos',
  in_bath: 'W saunie',
  in_vat: 'W wacie',
  in_gazebo: 'W altance',
  work_time: '*Dostawa "{type}" dostępna od {from}:00 do {to}:00',
  ai_generated: '*Zdjęcia potraw zostały wygenerowane przez AI i mogą nie odpowiadać rzeczywistemu wyglądowi dań',
  clear_question: 'Czy na pewno chcesz usunąć wszystkie wybrane pozycje?',
  items: 'Pozycje',
  pizza_supplements: 'Dodatki do pizzy',
  no_selected_pizza: 'Najpierw dodaj pizzę do koszyka',
  supplements_title: 'Wybierz pizzę, do której chcesz dodać {name}',
  fee: '**Dodatkowa opłata: {fee} {currency}',
  fee_label: 'Opłata',

  title: 'Możesz zamówić jedzenie i napoje {places}.',
  places: {
    in_restaurant: 'w restauracji',
    in_room: 'do pokoju',
    in_bath: 'do sauny',
    in_vat: 'do waty',
    to_go: 'na wynos',
  },
  or: 'lub',

  start: {
    title_1: 'Zamawianie ulubionych dań nigdy nie było tak proste!',
    title_2: 'Wykonaj kilka prostych kroków:',
    step_1: 'Zacznij od wybrania kategorii jedzenia, która Cię interesuje.',
    step_2: 'Wybierz danie z listy i kliknij "Zamów".',
    step_3: 'Kliknij przycisk "i", aby przeczytać szczegółowe informacje o daniu.',
    step_4: 'Przejdź do strony potwierdzenia, gdzie możesz zobaczyć i edytować liczbę wybranych pozycji.',
  }
};
