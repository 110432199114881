export const error = {
  ERROR_ALREADY_LOGGED_IN: 'Пользователь уже авторизован',
  ERROR_DO_NOT_HAVE_ACCESS: 'Вы не имеете доступ к этому действию',
  ERROR_INCORRECT_AUTH_DATA: 'Неверный логин или пароль',
  ERROR_INCORRECT_OLD_PASSWORD: 'Неверный старый пароль',
  ERROR_UNAUTHORIZED: 'Пользователь не авторизован',
  ERROR_USER_DOES_NOT_EXIST: 'Пользователя не существует',
  ERROR_BOT_ALREADY_EXISTS: 'Такой бот уже существует',
  ERROR_BOT_DOES_NOT_EXIST: 'Такого бота не существует',
  ERROR_USER_IS_NOT_SUBSCRIBED: 'Этот Пользователь не подписанный на чат-бот',
  ERROR_LINK_NOT_FOUND: 'Ссылку не найдено',
  ERROR_ORGANIZATION_DOES_NOT_EXIST: 'Такой организации не существует',
  ERROR_REQUEST_NOT_FOUND: 'Запрос не найден',
  ERROR_TELEGRAM: 'Невозможно обработать уведомление. Ошибка Телеграма.',
  ERROR_USER_ALREADY_EXISTS: 'Такой Пользователь уже существует',
  ERROR_DUPLICATE_ANSWER: "Вы уже оставили отзыв на этот запрос. Вы не можете сделать это дважды",
  ERROR_MAIL_TRANSLATION: 'Отсутствует текстовый перевод',
  ERROR_INACTIVE_ORGANIZATION: 'Приложение больше не обслуживается для данной организации',
};
