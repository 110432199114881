<template>
  <popup-container :title="$t('service.taxi')" @close="$emit('close')">
    <p class="list-item__help">{{ request.details?.isAsap ? $t('asap') : request.details?.wantedTime }}</p>
    <div class="info">
      <div class="list-item__left">
        <p class="list-item__info">
          <span>{{ $t('date') }}:</span>
          <span>{{ moment(request.datetime).format('DD.MM.YYYY') }}</span>
        </p>
        <p class="list-item__info">
          <span>{{ $t('taxi.from') }}:</span>
          <span>{{ request.details?.pickup }}</span>
        </p>
      </div>
    </div>
    <div class="info">
      <div class="list-item__left">
        <p class="list-item__info">
          <span>{{ $t('taxi.to') }}:</span>
          <span>{{ request.details?.destination }}</span>
        </p>
        <p class="list-item__info">
          <span>{{ $t('taxi.type') }}:</span>
          <span>{{ $t(`taxi.${ request.details?.carType?.toLowerCase() }`) }}</span>
        </p>
        <p v-if="request.comment" class="list-item__info">
          <span>{{ $t('comment') }}:</span>
          <span>{{ request.comment }}</span>
        </p>
      </div>
    </div>
    <template v-if="request.status !== 'cancelled'">
      <base-button :text="$t('button.cancel')" color="white" @click="$emit('cancel')" />
      <base-button :text="$t('info.contact_admin')" @click="redirectToAdminTg" />
    </template>
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";
import { PropType } from "vue";
import { Request } from "@/types/Request";
import moment from "moment";

defineProps({
  request: {
    type: Object as PropType<Request>,
    default: () => ({})
  },
});
defineEmits(['close', 'cancel']);

const { redirectToAdminTg } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve-list";
@import "../../../assets/styles/components/details-popup";

:deep(.popup__content) {
  gap: 1rem;
}

.info {
  @extend .flex-row;
  justify-content: space-between;

  & + & {
    margin-top: 18px;
    margin-bottom: 0.5rem;
  }

  &__help {
    margin-bottom: 1.5rem;
  }
}

.list-item {
  &__help {
    margin-bottom: 0.5rem;
  }

  &__left {
    gap: 18px;
  }

  &__info > span:first-child {
    width: 4.5rem;
    min-width: 4.5rem;
  }
}

.base-button {
  //margin: 1.5rem 0 0.5rem;
}

.info {
  width: 100%;
}

.desk {
  .list-item__right-top p:first-child {
    margin-bottom: 0.75rem;
  }
}
</style>
