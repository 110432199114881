export const error = {
  ERROR_ALREADY_LOGGED_IN: 'User is already logged in',
  ERROR_DO_NOT_HAVE_ACCESS: 'Do not have access to this action',
  ERROR_INCORRECT_AUTH_DATA: 'Incorrect login or password',
  ERROR_INCORRECT_OLD_PASSWORD: 'Incorrect old password',
  ERROR_UNAUTHORIZED: 'User is unauthorized',
  ERROR_USER_DOES_NOT_EXIST: 'User does not exist',
  ERROR_BOT_ALREADY_EXISTS: 'Bot already exists',
  ERROR_BOT_DOES_NOT_EXIST: 'Bot does not exist',
  ERROR_USER_IS_NOT_SUBSCRIBED: 'This user didn\'t subscribe our bot',
  ERROR_LINK_NOT_FOUND: 'Link not found',
  ERROR_ORGANIZATION_DOES_NOT_EXIST: 'Organization doesn\'t exist',
  ERROR_REQUEST_NOT_FOUND: 'Request not found',
  ERROR_TELEGRAM: 'Can\'t process message. Telegram error.',
  ERROR_USER_ALREADY_EXISTS: 'User already exists',
  ERROR_DUPLICATE_ANSWER: 'You have already done this review. You cannot do it twice',
  ERROR_MAIL_TRANSLATION: 'Cannot translate email text',
  ERROR_INACTIVE_ORGANIZATION: 'The application is no longer served for this organization',
};
