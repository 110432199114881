<template>
  <popup-container :title="request.details?.category ? $t(`desk.${ request.details.category }`) : $t('service.desk')"
                   @close="onClose">
    <div class="list-item__left">
      <p class="list-item__info list-item__info--last">
        <span>{{ $t('date') }}:</span>
        <span>{{ moment(request.datetime).format('DD.MM.YYYY') }}</span>
      </p>
      <p v-if="request.details?.needClean" class="list-item__info">
        <span>{{ $t('preference') }}:</span>
        <span>{{ request.details.date }}, {{ request.details.time }}</span>
      </p>
      <p class="list-item__info list-item__info--last">
        <span>{{ $t('comment') }}:</span>
        <span>{{ request.comment }}</span>
      </p>
      <p class="list-item__info">
        <span>{{ $t('placed_time') }}:</span>
        <span>{{ moment(request.datetime).format('HH:mm') }}</span>
      </p>
      <p v-if="request.details?.startDate" class="list-item__info">
        <span>{{ $t('taken_time') }}:</span>
        <span>{{ moment(request.details.startDate).format('HH:mm') }}</span>
      </p>
      <p v-if="request.status === 'cancelled'" class="list-item__info">
        <span>{{ $t('cancel_reason') }}:</span>
        <span class="flex-col">
            <strong v-if="request.details?.cancelCategory">{{ $t(`data.${ request.details.cancelCategory }`) }}</strong>
            <small v-if="request.details?.cancelReason">{{ request.details.cancelReason }}</small>
          </span>
      </p>
    </div>
    <template v-if="request.status !== 'cancelled'">
      <base-button :text="$t('button.cancel')" @click="$emit('cancel')" />
      <base-button :text="$t('info.contact_admin')" @click="redirectToAdminTg" />
    </template>
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { PropType } from "vue";
import { Request } from "@/types/Request";
import moment from "moment";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";

defineProps({
  request: {
    type: Object as PropType<Request>,
    default: () => ({})
  },
});
const emits = defineEmits(['close', 'cancel']);

const { redirectToAdminTg } = useButton();

const onClose = () => emits('close');
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve-list";
@import "../../../assets/styles/components/details-popup";
</style>
