export const gazebo = {
  chs_title: 'Możesz zarezerwować altankę na wspólne wakacje z rodziną lub przyjaciółmi',
  td_title: 'Możesz zarezerwować altankę na wspólne wakacje z rodziną lub przyjaciółmi. Cena rezerwacji altanki obejmuje również grilla i drewno na ognisko.',
  work_time: 'Altanka jest otwarta od {from} do {till}.',
  price_title: 'Cena rezerwacji altanki:',
  price_subtitle: '(płatność za rezerwację altanki obejmuje dowolną liczbę godzin w ramach harmonogramu pracy altanki)',
  choose_date: 'Określ datę i godzinę, podczas której potrzebujesz altanki',
  small_gazebo: 'Altanka dla 8-10 osób',
  small_gazebo_2: 'Altanka dla 8-10 osób nr 2',
  small_gazebo_4: 'Altanka dla 8-10 osób nr 4',
  small_gazebo_6: 'Altanka dla 8-10 osób nr 6',
  large_gazebo: 'Altanka dla 15-30 osób',
  large_gazebo_1: 'Altanka dla 15-30 osób nr 1',
  large_gazebo_3: 'Altanka dla 15-30 osób nr 3',
  large_gazebo_5: 'Altanka dla 15-30 osób nr 5',
  about_title: 'O altance',
  time_from: 'Od',
  time_to: 'Do',
  td_small_gazebo_1: 'Altanka dla 10 osób nr 1',
  td_small_gazebo_2: 'Altanka dla 10 osób nr 2',
  td_small_gazebo_3: 'Altanka dla 10 osób nr 3',
  td_small_gazebo_4: 'Altanka dla 10 osób nr 4',
  td_small_gazebo_5: 'Altanka dla 10 osób nr 5',
  td_small_gazebo_6: 'Altanka dla 10 osób nr 6',
  td_small_gazebo_7: 'Altanka dla 10 osób nr 7',
  td_small_gazebo_8: 'Altanka dla 10 osób nr 8',
  td_small_gazebo_9: 'Altanka dla 10 osób nr 9',
  td_large_gazebo_1: 'Altanka dla 20 osób nr 1',
};
