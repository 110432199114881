<template>
  <div class="panel">
    <page-header :title="$t('beverage.title')" back-link="/menu" />
    <div class="panel__content menu-list">
      <div v-for="category in categories" :key="category.id" class="menu-list__item"
           @click="push(`/menu/beverage/${category.id}`)">
        <div class="menu-list__item-icon" :class="category.icon"></div>
        <p class="menu-list__item-name">{{ getTranslation(category.name) }}</p>
      </div>
    </div>
    <base-button v-if="menuCart.length" :text="$t('button.to_cart')" @click="push('/menu/details')" />
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { MenuCategory } from "@/types/HotelSetup";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { useTranslation } from "@/composable/useTranslation";

const { push } = useRouter();
const appStore = useAppStore();
const { menuCart } = storeToRefs(appStore);
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { checkFunction } = useHotelSetup();
const { getTranslation } = useTranslation();

const categories = computed((): MenuCategory[] => hotelSetup.value.menuCategories.filter(c => !c.isSubcategory && c.type === 'beverage'));

onMounted(() => checkFunction('food'));
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/list";

.panel {
  &__title {
    margin-bottom: 1.5rem;
    @include font(18px, 22px, white, 500);
  }
}

.menu-list__item {
  &-time {
    margin-left: 0.25rem;
    @include font(12px, 14px, black, 500);
  }
}

.empty {
  margin: 30vh auto auto;
  @include font(24px, 30px, white, 500, center);
  text-wrap: balance;
}
</style>
