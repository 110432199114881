<template>
  <div class="panel">
    <page-header :title="$t('info.my_reserves')" back-link="/info" />
    <panel-tabs :selected-tab="selectedTab" :tabs="tabs" :is-translation="false" @tab-click="v => selectedTab = v" />
    <div class="panel__content">
      <loader-panel v-if="isLoading" />
      <div v-for="(item, key) in list" :key="item.id" class="list-item">
        <div class="list-item__left">
          <p class="list-item__title">
            {{ item.type === 'request' ? $t(`service.${ item.serviceType }`) : item.serviceType }}
          </p>
          <p v-if="item.type === 'reservation'" class="list-item__text">
            {{ item.serviceName }}
          </p>
          <p class="list-item__datetime">
            <span>{{ moment(item.date).format('DD.MM.YYYY') }}</span>
            <span>{{ item.time ?? moment(item.datetime).format('HH:mm') }}</span>
          </p>
          <div v-if="item.status === 'cancelled'" class="list-item__text flex-col">
            <p v-if="item?.details?.cancelCategory">{{ $t(`data.${ item.details.cancelCategory }`) }}</p>
            <p v-if="item?.details?.cancelReason">{{ item.details.cancelReason }}</p>
          </div>

          <p v-else-if="item.serviceType === 'cleaning' && item.details?.needClean" class="list-item__datetime">
            <span v-if="item.details?.date">{{ moment(item.details?.date).format('DD.MM.YYYY') }}</span>
            <span v-if="item.details?.time">{{ item.details?.time }}</span>
          </p>
          <p v-else-if="item.type === 'taxi'" class="list-item__text">{{ item.details?.destination }}</p>
          <p v-else-if="item.type === 'menu'" class="list-item__text">
            {{ $t('food.items') }}: {{ item.details?.items?.length }}
          </p>
          <p v-else-if="item.comment" class="list-item__text">{{ item.comment }}</p>
        </div>
        <div class="list-item__right">
          <div class="list-item__right-top">
            <p class="list-item__right-status" :class="item.status">{{ $t(`status.${ item.status }`) }}</p>
          </div>
          <p v-if="selectedTab === 0" class="list-item__right-button" @click="onButtonClick(+key)">
            {{ $t('button.details') }}
          </p>
          <a v-else href="#" class="list-item__right-button" @click="onButtonClick(+key)">
            {{ $t('button.leave_review') }}</a>
        </div>
      </div>

      <p v-if="!list.length" class="empty">{{ $t('request_empty') }}</p>
    </div>

    <template v-if="detailsIndex >= 0 && cancelIndex < 0">
      <reserve-details-popup v-if="list[detailsIndex].type === 'reservation'" :data="list[detailsIndex]"
                             @close="detailsIndex = -1" @cancel="cancelIndex = detailsIndex" />
      <component v-else :is="components[list[detailsIndex].serviceType]" :request="list[detailsIndex]"
                 @close="detailsIndex = -1" @cancel="cancelIndex = detailsIndex" />
    </template>

    <cancel-popup v-if="cancelIndex >= 0" @close="cancelIndex = -1" @submit="onCancel" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRequestStore } from "@/stores/requestStore";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import PageHeader from "@/components/Header/PageHeader.vue";
import PanelTabs from "@/components/PanelTabs.vue";
import moment from "moment";
import CleaningDetailsPopup from "@/components/Popup/DetailsPopup/CleaningDetailsPopup.vue";
import DeskDetailsPopup from "@/components/Popup/DetailsPopup/DeskDetailsPopup.vue";
import ReserveDetailsPopup from "@/components/Popup/DetailsPopup/ReserveDetailsPopup.vue";
import TaxiDetailsPopup from "@/components/Popup/DetailsPopup/TaxiDetailsPopup.vue";
import MenuDetailsPopup from "@/components/Popup/DetailsPopup/MenuDetailsPopup.vue";
import LoaderPanel from "@/components/LoaderPanel.vue";
import CancelPopup from "@/components/Popup/CancelPopup.vue";
import { RequestDetails, RequestStatus } from "@/types/Request";
import { useRender } from "@/composable/useRender";
import { useRouter } from "vue-router";
import { useReservationStore } from "@/stores/reservationStore";
import { ReservationDetails, ReservationStatus } from "@/types/Reservation";
import { useTranslation } from "@/composable/useTranslation";

const components = {
  cleaning: CleaningDetailsPopup,
  desk: DeskDetailsPopup,
  taxi: TaxiDetailsPopup,
  menu: MenuDetailsPopup,
};

interface ListItem {
  id: number;
  status: ReservationStatus | RequestStatus;
  datetime: string;
  date: string;
  time?: string;
  type: 'request' | 'reservation';
  comment?: string;
  serviceType: string;
  serviceName?: string;
  details?: RequestDetails | ReservationDetails;
}

const { push } = useRouter();
const requestStore = useRequestStore();
const { requests, error } = storeToRefs(requestStore);
const reservationStore = useReservationStore();
const { reservations } = storeToRefs(reservationStore);
const userStore = useUserStore();
const { clientLinkData } = storeToRefs(userStore);
const { getTranslation } = useTranslation();
const { renderNotification } = useRender();

const tabs = ref([{ name: 'current' }, { name: 'finished' }]);
const selectedTab = ref(0);
const detailsIndex = ref(-1);
const cancelIndex = ref(-1);
const isLoading = ref(false);

const list = computed((): ListItem[] => {
  let filteredList: ListItem[] = [];
  requests.value.map(r => {
    const { id, status, datetime, details, type, comment } = r;
    filteredList.push({
      id, status, datetime, details, comment,
      date: datetime,
      type: 'request',
      serviceType: type,
    });
  });
  reservations.value.map(r => {
    const { id, status, datetime, date, time, details, service } = r;
    filteredList.push({
      id, status, datetime, date, time, details,
      type: 'reservation',
      serviceType: getTranslation(service?.type?.name ?? {}),
      serviceName: getTranslation(service.name),
    });
  });

  if (selectedTab.value === 1) {
    filteredList = filteredList.filter(item => ['finished', 'cancelled'].includes(item.status));
  } else {
    filteredList = filteredList.filter(item => ['new', 'active'].includes(item.status));
  }

  return filteredList.sort((a, b) => a.datetime > b.datetime ? -1 : 1);
});

const onButtonClick = (index: number) => {
  if (selectedTab.value === 0) {
    detailsIndex.value = index;
  } else {
    push(`/info/review/${ list.value[index].id }`);
  }
};

const onCancel = async (data: RequestDetails) => {
  if (list.value[cancelIndex.value].type === 'reservation') {
    await reservationStore.cancelReservation(list.value[cancelIndex.value].id, data);
    if (!error.value) {
      await reservationStore.getCustomerReservations(clientLinkData.value.id);
      renderNotification('success_cancel');
      detailsIndex.value = -1;
      cancelIndex.value = -1;
    }
  } else {
    await requestStore.cancelRequest(list.value[cancelIndex.value].id, data);
    if (!error.value) {
      await requestStore.getCustomerRequests(clientLinkData.value.id);
      renderNotification('success_cancel');
      detailsIndex.value = -1;
      cancelIndex.value = -1;
    }
  }
};

onMounted(async () => {
  isLoading.value = true;
  await requestStore.getCustomerRequests(clientLinkData.value.id);
  await reservationStore.getCustomerReservations(clientLinkData.value.id);
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve-list";

.panel__content {
  @extend .flex-col;
  gap: 1rem;
}

a {
  all: unset;
}
</style>
