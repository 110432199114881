export const messages = {
  request_success: 'Żądanie zostało pomyślnie wysłane',
  reservation_received: 'Dziękujemy, otrzymaliśmy Twoją rezerwację! Wkrótce otrzymasz potwierdzenie.',
  reservation_accepted: 'Twoja rezerwacja została zaakceptowana',
  success_cancel: 'Twoje żądanie zostało pomyślnie anulowane',
  restaurant_repair: 'Zamawianie jedzenia jest niedostępne. Restauracja jest w remoncie',
  booking_success: 'Żądanie rezerwacji pomyślnie wysłane! Szczegóły zostaną wysłane na podany adres e-mail',
  review_success: 'Dziękujemy! Twoja recenzja została pomyślnie wysłana!',
  booking_cancel_success: 'Twoja rezerwacja została pomyślnie anulowana!',
};
