<template>
  <popup-container :has-close-button="false" @close="onClose">
    <p class="popup__message">
      <span>{{ $t('oops') }}!</span>
      {{ text }}
    </p>
    <base-button :text="$t(buttonText)" @click="onClose" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import BaseButton from "@/components/Button/BaseButton.vue";

defineProps({
  text: String,
  buttonText: {
    type: String,
    default: 'button.ok'
  },
});
const emits = defineEmits(['close']);

const onClose = () => {
  console.log(1);
  emits('close');
}
</script>

<style scoped lang="scss">
.popup {
  &__message {
    margin-bottom: 2rem;
    @include font(18px, 22px, black, 500, center);

    & > span {
      color: $error;
    }
  }
}
</style>
