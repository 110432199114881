import { button } from "@/locales/pl/button";
import { service } from "@/locales/pl/service";
import { cleaning } from "@/locales/pl/services/cleaning";
import { taxi } from "@/locales/pl/services/taxi";
import { desk } from "@/locales/pl/services/desk";
import { info } from "@/locales/pl/pages/info";
import { laundry } from "@/locales/pl/services/laundry";
import { food } from "@/locales/pl/pages/food";
import { beverage } from "@/locales/pl/pages/beverage";
import { menu } from "@/locales/pl/services/menu";
import { sauna } from "@/locales/pl/services/sauna";
import { gazebo } from "@/locales/pl/services/gazebo";
import { advert } from "@/locales/pl/advert";
import { error } from "@/locales/pl/error";
import { notification } from "@/locales/pl/notification";
import { messages } from "@/locales/pl/messages";
import { infoPriceList } from "@/locales/pl/pages/info_price_list";
import { booking } from "@/locales/pl/services/booking";
import { chorna_skelya } from "@/locales/pl/hotels/chorna_skelya";
import { bath } from "@/locales/pl/services/bath";

export const pl = {
  room: 'Pokój',
  from: 'od',
  from_price: 'od',
  till: 'do',
  or: 'lub',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
  asap: 'Jak najszybciej',
  uah: 'uah',
  usd: '$',
  notifications: 'Powiadomienia',
  empty_notifications: 'Brak powiadomień',
  per_hour: 'na godzinę',
  reservation: 'Rezerwacja',
  date: 'Data',
  new_messages: 'nowe wiadomości',
  duration: 'Czas trwania',
  minutes: 'minuty',
  current: 'Bieżący',
  finished: 'Zakończony',
  time: 'Czas',
  room_number: 'Numer pokoju',
  placed_time: 'Złożenie prośby',
  taken_time: 'Przyjęcie prośby',
  preference: 'Preferencje',
  no_clean: 'Nie wymaga sprzątania',
  comment: 'Komentarz',
  cancel_reason_label: 'Wprowadź powód anulowania',
  cancel_title: 'Anuluj prośbę',
  tech_cancel_message: 'Twoja prośba została anulowana z powodów technicznych. Możesz wybrać inny termin lub skontaktować się z administratorem',
  message_404: 'Ta strona nie istnieje',
  request_empty: 'Nie złożyłeś jeszcze żadnych próśb ani rezerwacji',
  oops: 'Ups',
  not_allowed: 'Nie masz dostępu do tej strony',
  free_time: 'Wolny czas',
  reserve_time: 'Zarezerwuj czas',
  hotel: 'Hotel',
  people: 'Osoby',
  service_name: 'Usługa',

  ...messages,
  price_list: infoPriceList,
  button,
  service,
  cleaning,
  taxi,
  desk,
  booking,
  info,
  laundry,
  food,
  beverage,
  menu,
  sauna, // zawiera teksty dla sauny, vat i usług kąpielowych
  gazebo,
  bath,
  advert,
  error,
  notification,

  fishing: {
    fishing_1: 'Wędkowanie',
    work_time: 'Wędkowanie dostępne od 9:00 do 22:00 bez ograniczeń co do długości rezerwacji',
    choose_date: 'Określ czas, w którym chcesz wędkować',
    people_amount: 'Podaj liczbę osób',
  },

  spa: {
    spa_1: 'Klasyczny masaż całego ciała z olejkami',
    spa_2: 'Masaż szyi',
    spa_3: 'Okład czekoladowy',
    choose_date: 'Określ datę i godzinę, w której chcesz skorzystać ze SPA',
  },

  form: {
    enter_comment: 'Wprowadź komentarz',
    enter_text: 'Wprowadź tekst',
    enter_address: 'Wprowadź adres',
  },

  adverts: {
    title: 'Znajdź najlepszy sposób na spędzenie wakacji',
  },

  qr_room: {
    ust_table_1: 'Stół 1',
    ust_table_2: 'Stół 2',
    ust_table_3: 'Stół 3',
    ust_table_4: 'Stół 4',
    ust_table_5: 'Stół 5',
    ust_table_6: 'Stół 6',
    ust_table_7: 'Stół 7',
    ust_table_8: 'Stół 8',
    ust_table_9: 'Stół 9',
    ust_table_10: 'Stół 10',
    td_gazebo_1: 'Altanka 1',
    td_gazebo_2: 'Altanka 2',
    td_gazebo_3: 'Altanka 3',
    td_gazebo_4: 'Altanka 4',
    td_gazebo_5: 'Altanka 5',
    td_gazebo_6: 'Altanka 6',
    td_gazebo_7: 'Altanka 7',
    td_gazebo_8: 'Altanka 8',
    td_gazebo_9: 'Altanka 9',
    td_gazebo_10: 'Altanka 10',
    td_bath_1: 'Kąpiel 1',
    td_vat_1: 'Wanna 1',
    td_vat_3: 'Wanna 2',
  },

  summary: {
    title: 'Podsumowanie',
    article: 'Artykuł',
    amount: 'Ilość',
    price: 'Cena',
    total: 'Razem',
    hours: 'Godziny',
    h: 'Godziny',
    m: 'Minuty',
    d: 'Dni',
    u: 'Kwota',
  },

  payment: {
    title: 'Płatność',
    details: 'Szczegóły płatności',
    choose_method: 'Wybierz metodę płatności',
    apple: 'Apple Pay',
    google: 'Google Pay',
    card_number: 'Numer karty',
    card_date: 'Miesiąc/Rok',
    card_cvv: 'CVV',
    cvv_hint: 'Kod z tyłu karty',
    pay_success: 'Dziękujemy, otrzymaliśmy twoje zamówienie!\nTwoja faktura jest tutaj.',
    download_invoice: 'Pobierz fakturę',
    payment_info: '*Płatność zostanie dokonana na miejscu.',
  },

  status: {
    new: 'Oczekiwanie na potwierdzenie',
    active: 'Potwierdzone',
    cancelled: 'Anulowane',
    finished: 'Zakończone',
  },

  event: {
    success_title: 'Gratulacje!',
    error_title: 'Coś poszło nie tak',
    cancel_title: 'Twoja prośba została odrzucona',
    tech_cancel_title: 'Twoja prośba została anulowana z powodów technicznych. Zobacz więcej...',
    taken: 'Rozpoczęliśmy realizację twojej prośby dotyczącej {type}!',
    cancelled: 'Twoja prośba {type} została anulowana. Zobacz więcej...',
    finished: 'Twoja prośba {type} została zakończona!',
  },

  unit: {
    gr: 'g',
    prt: 'prt',
    pc: 'szt',
    l: 'l',
    ml: 'ml',
    bottle: 'butelka',
  },

  data: {
    cancel_reason_1: 'Usługa jest niedostępna',
    cancel_reason_2: 'Wymagany personel jest niedostępny',
    cancel_reason_3: 'Wymagany sprzęt jest niedostępny',
    cancel_reason_4: 'Brak wolnego czasu',
    cancel_reason_5: 'Brak dostępnych stołów',
    tech: 'Prace techniczne',
  },

  // teksty hoteli
  ch_sk: chorna_skelya,
};
