import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { Room } from "@/types/Room";

export const useRoomStore = defineStore('roomStore', () => {
  const { getErrorMessage } = useApi();

  const room = ref<Room>({} as Room);
  const error = ref('');

  const getRoom = async (id: number) => {
    try {
      room.value = await request({ url: `room/${id}`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getRoomByType = async (typeId: number) => {
    try {
      room.value = await request({ url: `room/type/${typeId}`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    room, error,
    getRoom, getRoomByType
  };
});
