<template>
  <div class="page">
    <div class="page__content">
      <p class="page__content-text">{{ $t('oops') }}</p>
      <p class="page__content-message">{{ $t('not_allowed') }}</p>
      <base-button :text="$t('button.go_home_page')" @click="push('/')"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRouter } from "vue-router";

const { push } = useRouter();
</script>

<style scoped lang="scss">
.page {
  &__content {
    @extend .col-container;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #0000001A;
    backdrop-filter: blur(12px);
    z-index: 1000;
    box-sizing: border-box;

    &-text {
      margin-left: 2rem;
      @include font(120px, 196px, $primary);
      font-weight: 700;
      text-shadow: -40px -33px 0 #{$primary}33;
      -webkit-text-fill-color: $primary;
    }

    &-message {
      margin-bottom: 3rem;
      @include font(24px, 34px, white, 500, center);
      text-wrap: balance;
    }
  }
}

.base-button {
  position: absolute;
  bottom: 1.5rem;
  width: calc(100% - 1rem * 2);
}
</style>
