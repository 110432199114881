export const taxi = {
  location: 'Miejsce odbioru',
  where_to: 'Dokąd?',
  when: 'Kiedy?',
  choose_time: 'Wybierz godzinę',
  regular: 'Standardowy',
  comfort: 'Komfortowy',
  comment: 'Jeśli masz dodatkowe życzenia (fotelik dziecięcy, van, autobus itp.), zostaw komentarz:',
  order_guide: 'Jeśli potrzebujesz zamówić transfer/taxi, proszę skontaktować się pod ten numer.',
  from: 'Skąd',
  to: 'Dokąd',
  type: 'Typ',
};
