export const info = {
  additional: 'Додаткові послуги',
  general: 'Основна інформація',
  massage: 'Масаж',
  gazebo: 'Альтанка',
  laundry: 'Пральня',
  sauna: 'Сауна та чани',
  vat: 'Чани',
  about_chorna_skelya: 'Про готель "Чорна Скеля"',
  about_teremok: 'Про готель "Теремок"',
  about_astur: 'Про готель "Astur Spa"',
  about_beregynia: 'Про готель "Берегиня"',
  about_shepit_carpat: 'Про готель "Шепіт Карпат"',
  about_shepit_carpat_r: 'Про готель "Aurora Luxe Resort"',
  about_u_stozi: 'Про готель "У Стозі"',
  about_tur_dvir: 'Про готель "Турянський Двір"',
  about_melodia_hir: 'Про готель "Мелодія Гір"',
  leisure: 'Дозвілля',
  restaurant: 'Ресторан',
  spa: 'SPA зона',
  policy: 'Політика готелю',
  faq: 'FAQ',
  booking_details: 'Деталі бронювання',
  order_food: 'Замовити їжу',
  contact_admin: 'Зв\'язатись з адміністратором',
  make_reservation: 'Зарезервувати',
  other: 'Інше',
  my_reserves: 'Мої бронювання та запити',
  booking_history: 'Історія бронювань',
  chat: 'Чат 24/7',
  relax_in_vat: 'Відпочинок у чанах',
  quad_biking: 'Квадроцикли',
  bicycle_rental: 'Оренда велосипедів',
  about_spa_zone: 'Про СПА зону',
  about_entertainment: 'Про розвагу',
  about_cottage: 'Про котедж',
  about_hookah: 'Про кальяни',
  about_service: 'Про послугу',
  price_list: 'Прайсліст',
  hookah: 'Кальян',
  bath: 'Баня',
  salt_room: 'Соляна кімната',
  ski_eq: 'Гірськолижне спорядження',
  tours: 'Екскурсії',
  review: 'Відгук',
  fishing: 'Риболовля',
  tour_3d: '3D Тур',
  benefits: 'Переваги процедур',
  time_from: 'З',
  time_to: 'До',
  choose_date: 'Вкажіть дату та час, протягом якого ви бажаєте скористатись послугою',
  people_amount: 'Вкажіть кількість людей',
};
