<template>
  <popup-container :has-close-button="false">
    <loader-panel v-if="isLoading" />
    <div v-html="hotelSetup.agreementText.text" class="popup__data"></div>
    <base-button text="Я надаю згоду на обробку моїх персональних даних" @click="onButtonClick" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { ref } from "vue";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useLinkStore } from "@/stores/linkStore";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";

defineProps({
  text: String,
  buttonText: {
    type: String,
    default: 'button.ok'
  },
});

const linkStore = useLinkStore();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);

const isLoading = ref(false);

const onButtonClick = async () => {
  isLoading.value = true;
  await linkStore.updateLink({ isAgreed: true });
  isLoading.value = false;
};
</script>

<style scoped lang="scss">
.popup {
  &__data {
    @extend .flex-col, .custom-scroll, .custom-scroll-container;
    max-height: calc(100dvh - 5rem);
    overflow-y: auto;
  }

  .base-button {
    margin-top: 1rem;
    padding: 0.5rem 0;
    line-height: 125%;
    text-wrap: balance;
  }

  .loader {
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    background-color: #0003;
  }
}
</style>
