export const error = {
  ERROR_ALREADY_LOGGED_IN: 'Користувач вже авторизований',
  ERROR_DO_NOT_HAVE_ACCESS: 'Ви не маєте доступу до цієї дії',
  ERROR_INCORRECT_AUTH_DATA: 'Невірний логін чи пароль',
  ERROR_INCORRECT_OLD_PASSWORD: 'Невірний старий пароль',
  ERROR_UNAUTHORIZED: 'Користувач не авторизований',
  ERROR_USER_DOES_NOT_EXIST: 'Користувача не існує',
  ERROR_BOT_ALREADY_EXISTS: 'Такий бот вже існує',
  ERROR_BOT_DOES_NOT_EXIST: 'Такого бота не існує',
  ERROR_USER_IS_NOT_SUBSCRIBED: 'Цей користувач не підписаний на чат-бот',
  ERROR_LINK_NOT_FOUND: 'Посилання не знайдено',
  ERROR_ORGANIZATION_DOES_NOT_EXIST: 'Такої організації не існує',
  ERROR_REQUEST_NOT_FOUND: 'Запит не знайдено',
  ERROR_TELEGRAM: 'Неможливо обробити повідомлення. Помилка Телеграму.',
  ERROR_USER_ALREADY_EXISTS: 'Такий користувач вже існує',
  ERROR_DUPLICATE_ANSWER: 'Ви вже залишали відгук для цього запиту. Ви не можете зробити це двічі',
  ERROR_MAIL_TRANSLATION: 'Не вдалось перекласти текст листа',
  ERROR_INACTIVE_ORGANIZATION: 'Додаток більше не обслуговується для даної організації',
};
