import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { Survey } from "@/types/Survey";
import { useI18n } from "vue-i18n";

export const useQuestionnaireStore = defineStore('questionnaireStore', () => {
    const { getErrorMessage } = useApi();
    const i18n = useI18n();

    const survey = ref<Survey>({} as Survey);
    const questionnaireLink = ref('');
    const error = ref('');

    const getSurvey = async () => {
      try {
        const result = await request({ url: `questionnaire/survey/2`, method: 'get' });
        console.log(result)
        if (result?.error) {
          error.value = getErrorMessage(i18n.t(result.error));
        } else {
          console.log(1)
          survey.value = result;
          console.log(survey.value)
          error.value = '';
        }
      } catch (err) {
        error.value = getErrorMessage(err);
      }
    };

    const setAnswers = async (requestId: number, answers: { questionId: number; answer: number | string; }[]) => {
      try {
        await getQuestionnaireLink(requestId);
        if (questionnaireLink.value) {
          const hash = questionnaireLink.value?.slice(questionnaireLink.value.lastIndexOf('/') + 1);
          const result = await request({
            url: `questionnaire/answers/2`,
            method: 'post',
            data: {
              answers,
              userData: hash,
            }
          });
          console.log(result)
          if (result?.error) {
            error.value = i18n.t(`error.${result.error}`);
          } else {
            error.value = '';
          }
        }
      } catch (err) {
        error.value = getErrorMessage(err);
      }
    };

    const getQuestionnaireLink = async (requestId: number) => {
      try {
        questionnaireLink.value = await request({ url: `questionnaire/${ requestId }`, method: 'get' });
        error.value = '';
      } catch (err) {
        error.value = getErrorMessage(err);
      }
    };

    return {
      questionnaireLink, error, survey,
      getQuestionnaireLink, getSurvey, setAnswers
    };
  })
;
