export const laundry = {
  wash: 'Прання',
  iron: 'Прасування',
  schedule: 'Ви можете занести свої речі до пральні ,яка знаходиться біля тенісного корту на терасі з 11:00 до 18:00',
  order: 'Замовити послуги',
  summary: 'Підсумок',
  article: 'Назва',
  amount: 'К-сть',
  price: 'Ціна',
  total: 'Загалом',
  blouse: 'Сорочка/Шовкова блуза',
  sweater: 'Пуловер/Светер',
  trousers: 'Штани',
  dress: 'Сукня',
  jeans: 'Джинси',
  skirt: 'Спідниця',
  t_shirt: 'Футболка',
  nightgown: 'Нічна сорочка/Халат',
  scarf: 'Краватка/Шарф',
  socks: 'Шкарпетки/Панчохи',
  pajamas: 'Піжама',
  jacket: 'Жакет',
  suit: 'Костюм',
  sport_suit: 'Спортивний костюм',
  vest: 'Майка',
  shorts: 'Шорти',
  towel: 'Рушник',
  child: 'Дитячі речі',
  order_received: 'Мы получили Ваш запрос! Вы получите уведомление сразу после его исполнения.',
};
