export const gazebo = {
  chs_title: 'You can book a gazebo for a joint holiday with family or friends',
  td_title: 'You can book a gazebo for a joint holiday with family or friends. The price of booking a gazebo also includes a barbecue and firewood.',
  work_time: 'Gazebo is opened from {from} till {till}.',
  price_title: 'The price of booking a gazebo:',
  price_subtitle: '(payment for booking a gazebo involves any number of hours as part of the gazebo work schedule)',
  choose_date: 'Specify the date and time during which you need a gazebo',
  small_gazebo: 'Gazebo for 8-10 people',
  small_gazebo_2: 'Gazebo for 8-10 people № 2',
  small_gazebo_4: 'Gazebo for 8-10 people № 4',
  small_gazebo_6: 'Gazebo for 8-10 people № 6',
  large_gazebo: 'Gazebo for 15-30 people',
  large_gazebo_1: 'Gazebo for 15-30 people № 1',
  large_gazebo_3: 'Gazebo for 15-30 people № 3',
  large_gazebo_5: 'Gazebo for 15-30 people № 5',
  about_title: 'About Gazebo',
  time_from: 'From',
  time_to: 'To',
  td_small_gazebo_1: 'Gazebo for 10 people № 1',
  td_small_gazebo_2: 'Gazebo for 10 people № 2',
  td_small_gazebo_3: 'Gazebo for 10 people № 3',
  td_small_gazebo_4: 'Gazebo for 10 people № 4',
  td_small_gazebo_5: 'Gazebo for 10 people № 5',
  td_small_gazebo_6: 'Gazebo for 10 people № 6',
  td_small_gazebo_7: 'Gazebo for 10 people № 7',
  td_small_gazebo_8: 'Gazebo for 10 people № 8',
  td_small_gazebo_9: 'Gazebo for 10 people № 9',
  td_large_gazebo_1: 'Gazebo for 20 people № 1',
};
