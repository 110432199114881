<template>
  <div class="wrapper">
    <div class="popup">
      <div v-if="title || hasCloseButton" class="popup__header">
        <p v-if="title" class="popup__title">{{ title }}</p>
        <div v-if="hasCloseButton" class="popup__close" @click="onCloseClick"></div>
      </div>
      <div class="popup__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  title: String,
  hasCloseButton: {
    type: Boolean,
    default: true,
  }
});
const emits = defineEmits(['close']);

const onCloseClick = () => emits('close');
</script>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: #00000080;
  backdrop-filter: blur(2px);
  z-index: 100;
}

.popup {
  @extend .col-container, .rounded;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 2rem);
  height: max-content;
  max-height: calc(100dvh - 2rem);
  margin: auto;
  padding: 1rem;
  background-color: #FFFFFFCC;
  z-index: 110;
  box-sizing: border-box;

  &:has(.popup__header) .popup__content {
    max-height: calc(100dvh - 6.75rem);
  }

  &:has(.popup__close) .popup__title {
    max-width: calc(100% - 1.5rem);
    text-wrap: balance;
  }

  &__header {
    @extend .row-container;
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__title {
    @include font(18px, 22px, black, 600);
  }

  &__close {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    @include icon($close, 2.25rem, 1.5rem);
  }

  &__content {
    @extend .flex-col, .custom-scroll, .custom-scroll-container;
    position: relative;
    width: 100%;
    max-height: calc(100dvh - 4rem);
  }
}
</style>
