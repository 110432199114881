export const booking = {
  price_per_day: 'Ціна за добу',
  select_room: 'Оберіть кімнату',
  per_night: 'за ніч',
  dates: 'Дати',
  guests: 'Гостей',
  client_info: 'Інформація про клієнта',
  status_label: 'Статус',
  choose_dates: 'Оберіть дати',
  choose_date: 'Оберіть дату',
  name_surname: 'Прізвище та Ім\'я',
  phone_number: 'Номер телефону',
  phone_number_hint: 'Формат: +380931234567',
  email: 'Email',
  guests_amount: 'Кількість гостей',
  arrival_time: 'Орієнтовний час Вашого приїзду',
  available_date: 'Доступні',
  unavailable_date: 'Зайняті',
  book_title: 'Будь ласка, заповніть форму для того, щоб забронювати номер',
  your_info: 'Ваша інформація',
  comment_hint: 'Якщо Ви бажаєте отримати додаткове ліжко, маєте дитину чи додаткові потреби - напишіть, будь ласка, детальніше в коментарі знизу',
  cancel_question: 'Ви впевнені, що хочете відмінити бронювання кімнати?',
  no_booking_available: 'На жаль, немає вільних кімнат на обраний Вами період',

  status: {
    confirmed: 'Підтверджений',
    active: 'Підтверджений',
    pending: 'Очікує',
    finished: 'Завершений',
    cancelled: 'Скасований',
    checked: 'Зараз проживає',
    all: 'Всі',
  },

  room: {
    standard: 'Стандарт',
    demilux: 'Напівлюкс',
    demilux_plus: 'Напівлюкс +',
    economy: 'Економ',
    lux: 'Люкс',

    double_room_wooden: 'Двомісний номер (дерев\'яний корпус)',
    double_room_plus_wooden: 'Двомісний + (дерев\'яний корпус)',
    triple_room_wooden: 'Тримісний номер (дерев\'яний корпус)',
    family_room_improved_wooden: 'Сімейний покращений (дерев\'яний корпус)',
    family_room_added_entrance_wooden: 'Сімейний з додатковим входом (дерев\'яний корпус)',
    double_room_main: 'Двомісний номер (основний корпус)',
    double_room_plus_main: 'Двомісний номер + (основний корпус)',
    triple_room_main: 'Тримісний номер (основний корпус)',
    family_room_improved_main: 'Сімейний покращений (над банею)',

    td_cottage_1: 'Котедж 1',
    td_cottage_2: 'Котедж 2',
    td_cottage_3: 'Котедж 3',
    td_cottage_4: 'Котедж 4',
    td_cottage_5_1: 'Котедж 5. Номер 5/1',
    td_cottage_5_2: 'Котедж 5. Номер 5/2',
    td_cottage_5_3: 'Котедж 5. Номер 5/3',
    td_cottage_5_5: 'Котедж 5. Номер 5/5',
    td_cottage_5_6: 'Котедж 5. Номер 5/6',
    td_cottage_5_7: 'Котедж 5. Номер 5/7',
    td_cottage_5_8: 'Котедж 5. Номер 5/8',

    r_standard: 'Стандартний номер',
    r_improved: 'Покращений номер',
    r_demilux: 'Напівлюкс',
    r_lux: 'Люкс',
    r_junior: 'Джуніор Сьют',
    r_family: 'Сімейний номер',
    r_business: 'Бізнес-номер',
    r_president: 'Президентський Люкс',

    mountain_double: 'Двомісний номер з видом на гори',
    mountain_quadruple: 'Чотиримісний номер з видом на гори',
    mountain_triple: 'Тримісний номер',
    mountain_large_double: 'Великий двомісний номер',
  }
};
