export const booking = {
  price_per_day: 'Price per day',
  select_room: 'Select room',
  per_night: 'per night',
  dates: 'Dates',
  guests: 'Guests',
  client_info: 'Client Info',
  status_label: 'Status',
  choose_dates: 'Choose dates',
  choose_date: 'Choose date',
  name_surname: 'Name and Surname',
  phone_number: 'Phone number',
  phone_number_hint: 'Format: +380931234567',
  email: 'Email',
  guests_amount: 'Guests amount',
  arrival_time: 'Arrival time',
  available_date: 'Available',
  unavailable_date: 'Unavailable',
  book_title: 'Please, fill in the blank to reserve a room',
  your_info: 'Your info',
  comment_hint: 'If you want to get an extra bed, have a child or have additional needs - please, write more in the comments below',
  cancel_question: 'Are you sure you want to cancel your booking request?',
  no_booking_available: 'Unfortunately, there are no free rooms for chosen period',

  status: {
    confirmed: 'Confirmed',
    active: 'Confirmed',
    pending: 'Pending',
    finished: 'Finished',
    cancelled: 'Cancelled',
    checked: 'Checked In',
    all: 'All',
  },

  room: {
    standard: 'Standard',
    demilux: 'Demilux',
    demilux_plus: 'Demilux +',
    economy: 'Economy',
    lux: 'Lux',

    double_room_wooden: 'Double room (wooden case)',
    double_room_plus_wooden: 'Double+ (wooden case)',
    triple_room_wooden: 'Triple room (wooden case)',
    family_room_improved_wooden: 'Family improved (wooden case)',
    family_room_added_entrance_wooden: 'Family with additional entrance (wooden building)',
    double_room_main: 'Double room (main building)',
    double_room_plus_main: 'Double room+ (main building)',
    triple_room_main: 'Triple room (main building)',
    family_room_improved_main: 'Family improved (over the bath)',

    td_cottage_1: 'Cottage 1',
    td_cottage_2: 'Cottage 2',
    td_cottage_3: 'Cottage 3',
    td_cottage_4: 'Cottage 4',
    td_cottage_5_1: 'Cottage 5. Number 5/1',
    td_cottage_5_2: 'Cottage 5. Number 5/2',
    td_cottage_5_3: 'Cottage 5. Number 5/3',
    td_cottage_5_5: 'Cottage 5. Number 5/5',
    td_cottage_5_6: 'Cottage 5. Number 5/6',
    td_cottage_5_7: 'Cottage 5. Room 5/7',
    td_cottage_5_8: 'Cottage 5. Number 5/8',

    r_standard: 'Standard Room',
    r_improved: 'Superior Room',
    r_demilux: 'Junior Suite',
    r_lux: 'Suite',
    r_junior: 'Junior Suite',
    r_family: 'Family Room',
    r_business: 'Business Room',
    r_president: 'Presidential Suite',

    mountain_double: 'Double room with mountain view',
    mountain_quadruple: 'Quadruple room with mountain view',
    mountain_triple: 'Triple Room',
    mountain_large_double: 'Large double room',
  }
};
