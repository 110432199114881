export const gazebo = {
  chs_title: 'По желанию вы можете забронировать беседку для совместного отдыха с семьей или друзьями',
  td_title: 'По желанию вы можете забронировать беседку для совместного отдыха с семьей или друзьями. В цену бронирования альтанки также входят мангал и дрова.',
  work_time: 'Альтанки открытые с {from} до {till}.',
  price_title: 'Цена бронирования альтанки:',
  price_subtitle: '(оплата за бронирования альтанки включает в себе любое количество часов в рамках графика работы альтанок)',
  choose_date: 'Укажите дату и время, на протяжении которого вам нужна альтанка',
  small_gazebo: 'Альтанка на 8-10 чел.',
  small_gazebo_2: 'Альтанка на 8-10 чел. № 2',
  small_gazebo_4: 'Альтанка на 8-10 чел. № 4',
  small_gazebo_6: 'Альтанка на 8-10 чел. № 6',
  large_gazebo: 'Альтанка на 15-30 чел.',
  large_gazebo_1: 'Альтанка на 15-30 чел. № 1',
  large_gazebo_3: 'Альтанка на 15-30 чел. № 3',
  large_gazebo_5: 'Альтанка на 15-30 чел. № 5',
  about_title: 'Про альтанки',
  time_from: 'с',
  time_to: 'До',
};
