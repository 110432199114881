export const info = {
  additional: 'Additional Services',
  general: 'General Information',
  massage: 'Massage',
  gazebo: 'Gazebo',
  laundry: 'Laundry Services',
  sauna: 'Sauna and Vat',
  vat: 'Vat',
  about_chorna_skelya: 'About Chorna Skelya Hotel',
  about_teremok: 'About Teremok Hotel',
  about_astur: 'About Astur Spa Hotel',
  about_beregynia: 'About Beregynia Hotel',
  about_shepit_carpat: 'About Shepit Carpat Hotel',
  about_shepit_carpat_r: 'About Aurora Luxe Resort Hotel',
  about_u_stozi: 'About U Stozi Hotel',
  about_tur_dvir: 'About Turiansky Dvir Hotel',
  about_melodia_hir: 'About Melodia Hir Hotel',
  leisure: 'Leisure',
  restaurant: 'Restaurant',
  spa: 'SPA Zone',
  policy: 'Hotel Policy',
  faq: 'FAQ',
  booking_details: 'Booking details',
  order_food: 'Order Menu',
  contact_admin: 'Contact administrator',
  make_reservation: 'Make a reservation',
  other: 'Other',
  my_reserves: 'My reservations & requests',
  booking_history: 'Booking history',
  chat: '24/7 Chat',
  relax_in_vat: 'Relaxing in Vat',
  quad_biking: 'Quad Biking',
  bicycle_rental: 'Bicycle Rental',
  about_spa_zone: 'About SPA Zone',
  about_entertainment: 'About entertainment',
  about_cottage: 'About cottage',
  about_hookah: 'About hookah',
  about_service: 'About service',
  price_list: 'Price List',
  hookah: 'Hookah',
  bath: 'Bath',
  salt_room: 'Salt room',
  ski_eq: 'Skiing equipment',
  tours: 'Tours',
  review: 'Review',
  fishing: 'Fishing',
  tour_3d: '3D Tour',
  benefits: 'Benefit',
  time_from: 'From',
  time_to: 'To',
  choose_date: `Specify the date and time during which you want to get service`,
  people_amount: 'Specify the number of people',
};
