export const advert = {
  chorna_skelya_sauna: {
    title: 'Sauna z elementami soli himalajskiej',
    text: 'Uważana jest za niezwykle korzystną, ponieważ podczas ogrzewania pomieszczenia powietrze nasyca się barium, żelazem, magnezem, jodem, wapniem, manganem itp. zawartymi w soli. przydatne mikroelementy.',
  },
  chorna_skelya_gazebo: {
    title: 'Spędź wspaniały czas z przyjaciółmi i rodziną w naszej altanie',
    text: 'Wakacje z przyjaciółmi i rodziną w altanie w kompleksie "Czarna Skała" to wspaniała okazja do spędzenia czasu razem, ciesząc się naturalną atmosferą i przytulnością tego malowniczego miejsca.',
  },
  chorna_skelya_vat: {
    title: '"Karpacka Bania" z ziołami',
    text: 'Doświadcz korzyści zdrowotnych, jakie daje kąpiel w kotle, pozwalając hydromasażowi zrelaksować mięśnie, poprawić krążenie krwi i przywrócić energię, abyś mógł w pełni cieszyć się uczuciem dobrego samopoczucia i relaksu razem z przyjaciółmi i rodziną.',
  },

  teremok_info: {
    title: 'Prywatna Posiadłość "Teremok"',
    text: 'Unikalny kompleks domków "Teremok" - przytulne domki, grill, sauna, malownicze widoki - wszystko na miejscu dla Ciebie.'
  },

  astur_quad: {
    title: 'Jazda na quadach z Astur Spa',
    text: 'Przeżyj ekscytujące emocje i pokonaj naturalne przeszkody na naszych górskich trasach na quadach.'
  },
  astur_bicycle: {
    title: 'Wypożyczalnia rowerów w Hotelu Astur',
    text: 'Wypożyczenie roweru w Hotelu Astur to doskonała okazja do poszerzenia możliwości rekreacji i odkrywania nowych horyzontów.'
  },
  astur_spa: {
    title: 'Kompleks SPA w Astur SPA',
    text: 'Znajdziesz tu wszystko, co potrzebne do maksymalnego relaksu: duży basen z wodą źródlaną, basen dla dzieci, saunę i jacuzzi.'
  },

  shepit_carpat_salt_room: {
    title: 'Pokój solny',
    text: 'Zanurz się w atmosferze czystości i regeneracji, gdzie miliony mikroskopijnych cząsteczek soli są rozpylane w powietrzu, dając ci możliwość prawdziwego odpoczynku dla płuc i skóry.',
  },
  shepit_carpat_bath: {
    title: 'Relaks w saunie',
    text: 'Poczuj atmosferę prawdziwego relaksu i regeneracji w naszej saunie z drewnianymi ścianami i naturalnymi aromatami.',
  },
  shepit_carpat_ski_eq: {
    title: 'Sprzęt narciarski',
    text: 'Kompleks hotelowy "Szept Karpat" z przyjemnością oferuje swoim gościom wygodną usługę wypożyczania sprzętu narciarskiego.',
  },
  shepit_carpat_rooms: {
    title: 'Twój najlepszy relaks czeka',
    text: 'Ciesz się niesamowitym pobytem w naszym komfortowym hotelu klasy premium.',
  },
  shepit_carpat_spa: {
    title: 'Centrum SPA - Twoja ścieżka do harmonii duszy i ciała',
    text: 'Ciesz się zabiegami spa w naszym komfortowym hotelu klasy premium.',
  },

  u_stozi_tours_1: {
    title: 'Wędrówki po górach z "Na Stozi"',
    text: 'Ciesz się niesamowitą przyrodą ukraińskich Karpat dzięki różnorodności naszych wycieczek.',
  },
  u_stozi_tours_2: {
    title: 'Niezapomniane przygody z "Na Stozi"',
    text: 'Zdobądź szczyty gór, przywitaj świt i spaceruj do wysokogórskiego jeziora z nami.',
  },

  tur_dvir_gazebo: {
    title: 'Zorganizuj wakacje z rodziną i przyjaciółmi w altance',
    text: 'Odpoczynek z przyjaciółmi i rodziną w altance to doskonała okazja do spędzenia czasu razem, ciesząc się atmosferą natury i komfortem tego malowniczego miejsca.',
  },
  tur_dvir_vat: {
    title: 'Unikalne doświadczenie w naszych leczniczych kotłach Pecherinsky',
    text: 'Te kotły, wypełnione czystą wodą górską i specjalnie przygotowanymi karpackimi ziołami oraz sekretnymi składnikami, obiecują niezapomniane uczucie relaksu i regeneracji.',
  },
  tur_dvir_fishing: {
    title: 'Wędkowanie w "Turyansky Dvir"',
    text: 'Wędkowanie to doskonała okazja do spędzenia czasu z rodziną i przyjaciółmi, dzielenia się doświadczeniami i cieszenia się wspólnie przyrodą.',
  },
};
