<template>
  <div class="panel">
    <page-header :title="$t('info.booking_history')" back-link="/info" />
    <div class="panel__content">
      <loader-panel v-if="isLoading" />
      <div v-for="(link, key) in linkList" :key="link.id" class="list-item">
        <div class="list-item__left">
          <p class="list-item__title">{{ $t('room') }} {{ link.room?.number }}</p>
          <p class="list-item__text">
            {{ moment(link.dateFrom).format('DD.MM.YY') }} - {{ moment(link.dateTo).format('DD.MM.YY') }}
          </p>
        </div>
        <div class="list-item__right">
          <p v-if="link.booking" class="list-item__right-status"
             :class="`list-item__right-status--${link.booking.status}`">
            {{ $t(`booking.status.${ link.booking.status }`) }}
          </p>
          <div class="list-item__right-button" @click="onButtonClick(key)">
            {{ link.dateFrom < moment().format('YYYY-MM-DD') ? $t('button.book_again') : $t('button.details') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <booking-details-popup v-if="detailsItem >= 0" :booking="linkList[detailsItem].booking" @close="onClose" />
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLinkStore } from "@/stores/linkStore";
import { useUserStore } from "@/stores/userStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import moment from "moment";
import { Link } from "@/types/Link";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useRouter } from "vue-router";
import BookingDetailsPopup from "@/components/Popup/DetailsPopup/BookingDetailsPopup.vue";
import { useBookingStore } from "@/stores/bookingStore";
import { Booking } from "@/types/Booking";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";

type LinkList = (Link & { booking: Booking | undefined }) [];

const { push } = useRouter();
const userStore = useUserStore();
const bookingStore = useBookingStore();
const { bookingList } = storeToRefs(bookingStore);
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const linkStore = useLinkStore();
const { links } = storeToRefs(linkStore);
const { checkFunction } = useHotelSetup();

const linkList = computed((): LinkList => {
  const list: LinkList = [...links.value].map(item => ({ ...item, booking: undefined }));
  list.map(item => item.booking = bookingList.value.find(b => b.linkId === item.id));
  return list.sort((a, b) => a.dateFrom < b.dateFrom ? 1 : -1)
});

const isLoading = ref(true);
const detailsItem = ref(-1);

const onButtonClick = async (index: number) => {
  if (linkList.value[index].dateFrom >= moment().format('YYYY-MM-DD')) {
    detailsItem.value = index;
  } else if (hotelSetup.value.booking?.isActive) {
    const button = hotelSetup.value.booking.buttons?.[0];
    if (hotelSetup.value.booking.type === 'by_admin') {
      window.open(button.link, '_blank');
    } else {
      push(button.link);
    }
  }
};

const onClose = async (isCancel = false) => {
  detailsItem.value = -1;
  if (isCancel) {
    isLoading.value = true;
    await linkStore.getLinks(userStore.clientId);
    await bookingStore.getLinksBookingList(links.value.map(item => item.id));
    isLoading.value = false;
  }
}

onMounted(async () => {
  checkFunction('booking-history');
  await linkStore.getLinks(userStore.clientId);
  await bookingStore.getLinksBookingList(links.value.map(item => item.id));
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/reserve-list";

.panel__content {
  @extend .flex-col;
  gap: 1rem;
}

.list-item__right-button {
  min-width: 131px;
  margin-top: auto;
  margin-bottom: -0.25rem;
}

.list-item__right-status {
  @extend .row-container;
  justify-content: flex-end;
  gap: 0.25rem;
  width: 100%;
  max-width: 100%;
  @include font(14px, 18px, black, 500, right);

  &:before {
    @extend .circle;
    @include before(0.75rem, 0.75rem);
  }

  &--confirmed:before {
    background-color: $green;
  }

  &--cancelled:before {
    background-color: $red;
  }

  &--pending:before {
    background-color: $orange;
  }

  &--finished:before {
    background-color: $grey;
  }
}
</style>
