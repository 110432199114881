<template>
  <div class="container" :style="`height: ${size}px;`">
    <template v-if="!isWrongLink && !isLoading">
      <template v-if="isAgreed">
        <router-view />
        <audio ref="eventAudio" hidden src="../../audio/event-notification.wav" />
        <home-button v-if="!isHomePage && linkType !== 'menu'" />
      </template>
      <agreement-popup v-else />
    </template>
    <p v-if="isWrongLink" class="error">WRONG LINK</p>
    <loader-panel v-if="isLoading" />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRender } from "@/composable/useRender";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/userStore";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import HomeButton from "@/components/Button/HomeButton.vue";
import { useLinkStore } from "@/stores/linkStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { VueCookies } from "vue-cookies";
import { useRequestStore } from "@/stores/requestStore";
import AgreementPopup from "@/components/Popup/AgreementPopup.vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { useTranslation } from "@/composable/useTranslation";
//import {useFirebase} from "@/composable/useFirebase";

const $cookies = inject<VueCookies>('$cookies');

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const { renderNewMessage } = useRender();
const userStore = useUserStore();
const { clientId, clientLinkData, linkType } = storeToRefs(userStore);
const linkStore = useLinkStore();
const requestStore = useRequestStore();
const appStore = useAppStore();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { unreadNotifications } = storeToRefs(appStore);
const { getTranslation } = useTranslation();

const eventSource = ref<EventSource>({} as EventSource);
const isConnectionOpened = ref(false);
const connectTimer = ref(0);
const eventAudio = ref();
const isHomePage = ref(false);
const isWrongLink = ref(false);
const isLoading = ref(false);
const size = ref(0);
/*const { setToken } = useFirebase();*/
const isAgreed = computed((): boolean => !hotelSetup.value?.agreementText?.text?.length || (clientLinkData.value?.userInfo?.isAgreed ?? false));

const connect = () => {
  clearTimeout(connectTimer.value);

  isConnectionOpened.value = true;

  eventSource.value = new EventSource(process.env.NODE_ENV === 'development'
      ? `http://localhost:3000/request/sse/${ clientId.value }`
      : `https://demochat.vipmanage.org/api/request/sse/${ clientId.value }`,
  );

  eventSource.value.onopen = () => isConnectionOpened.value = true;

  eventSource.value.onmessage = async ({ data }) => {
    const parsedData = JSON.parse(data);
    const { event, type, data: eventData } = parsedData;
    let title = '', text = '', eventType: 'request' | 'reserve' | 'booking' = 'request';
    if (event) {
      eventType = type as 'request' | 'reserve';

      const serviceName = type === 'request' ? i18n.t(`service.${ eventData.type }`) : getTranslation(eventData.service?.type?.name ?? {});
      if (event === 'take') {
        title = i18n.t('event.success_title');
        text = i18n.t(type === 'request' ? 'event.taken' : 'reservation_accepted', { type: serviceName });
      } else if (event === 'cancel' && eventData.details?.cancelEmitter !== 'client') {
        title = i18n.t('event.cancel_title');
        text = i18n.t('event.cancelled', { type: serviceName });

        if (route.path === '/info/reserves') {
          await requestStore.getCustomerRequests(clientLinkData.value.id);
        }
      } else if (event === 'finish') {
        title = i18n.t('event.success_title');
        text = i18n.t('event.finished', { type: serviceName });
      } else if (event === 'cancel-booking') {
        eventType = 'booking';
        title = i18n.t('event.booking_cancel_title');
        text = i18n.t('event.booking_cancel_text');
      } else if (event === 'confirm-booking') {
        eventType = 'booking';
        title = i18n.t('event.booking_confirm_title');
        text = i18n.t('event.booking_confirm_text');
      }
    }

    if (event && title) {
      await renderNewMessage(text, title, eventData?.id);
      unreadNotifications.value.push({ type: eventType, title, text, data: eventData, date: new Date() });
      if (!userStore.client.settings?.sound || userStore.client.settings.sound === 'on') {
        try {
          if (eventAudio.value) {
            eventAudio.value.playbackRate = 2;
            await eventAudio.value?.play();
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    console.log('NEW MESSAGE', parsedData);
  };

  eventSource.value.onerror = (error) => {
    clearTimeout(connectTimer.value);
    console.log('SSE ERROR', error);
    isConnectionOpened.value = false;
    eventSource.value?.close();
    connectTimer.value = setTimeout(connect, 3000);
  };
};

const init = async () => {
  isLoading.value = true;
  isHomePage.value = route.path === '' || route.path === '/';
  await userStore.login(route.hash);
  if (userStore.error) {
    isWrongLink.value = true;
  } else {
    await hotelSetupStore.getHotelSetup();
    await linkStore.getLink(route.hash);
    await setLanguage();
    unreadNotifications.value = unreadNotifications.value.filter(item => !item.data || item.data?.linkId === clientLinkData.value.id);
    //i18n.locale.value = userStore.client.settings?.language ?? userStore.client?.organization?.settings?.defaultLanguage ?? 'ua';
    connect();

    if (linkType.value === 'menu' && !route.path.includes('menu')) {
      await router.push('/menu/start');
    }
  }
  isLoading.value = false;
};

const setSize = () => size.value = document.documentElement.clientHeight;

const getLang = (value: string) => {
  const langs: { [key: string]: string; } = {
    uk: 'ua',
    'uk-UA': 'ua',
    'ru-RU': 'ru',
    'en-US': 'en',
    'pl-PL': 'pl'
  };
  return langs?.[value] ?? value;
};

const setLanguage = async () => {
  if (userStore.client.settings?.language) {
    i18n.locale.value = userStore.client.settings.language;
  } else {
    let lang = getLang(navigator.language || navigator?.userLanguage);
    if (['ua', 'en', 'ru', 'pl'].includes(lang)) {
      i18n.locale.value = lang;
    } else {
      i18n.locale.value = userStore.client?.organization?.settings?.defaultLanguage ?? 'en';
    }

    await userStore.updateUserSettings({ language: i18n.locale.value });
  }
};

onMounted(() => {
  setSize();
  window.addEventListener('resize', setSize);

  if (localStorage.getItem('user_hash')) {
    $cookies?.set('hash', localStorage.getItem('user_hash'));
  }

  if (!route.hash && route.path !== 'not-found') {
    return;
  }

  if (route.hash) {
    $cookies?.set('hash', route.hash);
    localStorage.setItem('user_hash', route.hash);
    init();
  }
});

onBeforeUnmount(() => window.removeEventListener('resize', setSize));

watch(() => route.path, () => {
  isHomePage.value = route.path === '' || route.path === '/';
  if (linkType.value === 'menu' && !route.path.includes('menu')) {
    router.push('/menu/start');
  }
});

watch(() => route.hash, (next, prev) => {
  if (!prev && next) {
    $cookies?.set('hash', route.hash);
    localStorage.setItem('user_hash', route.hash);
    init();
  }
});

/*watch([() => client.value, () => hotelSetupStore.hotelSetup], async () => {
  //console.log(client.value?.organizationId, hotelSetup.value)
  /!*if (client.value?.organizationId && (!hotelSetup.value?.organizationId || hotelSetup.value?.organizationId !== client.value?.organizationId)) {
    isLoading.value = true;
    await hotelSetupStore.getHotelSetup();
    isLoading.value = false;
  }*!/
}, { deep: true });*/
</script>

<style lang="scss">
@import "src/assets/styles/base/core";

.container {
  background: url("@/assets/images/bg.jpg") center no-repeat;
  background-size: cover;

  &:after {
    @include before(100%, 100%);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000A6;
    backdrop-filter: blur(4px);
    z-index: -1;
  }

  .error {
    margin: auto;
    @include font(22px, 30px, white, 600, center);
  }
}
</style>
