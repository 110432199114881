export const beverage = {
  title: 'Napoje',
  coffee_tea: 'Kawa i herbata',
  coffee: 'Kawa',
  tea: 'Herbata',
  beer: 'Piwo',
  cocktails: 'Koktajle',
  non_alcohol_cocktails: 'Koktajle bezalkoholowe',
  alcohol: 'Napoje alkoholowe',
  non_alcohol_drinks: 'Napoje bezalkoholowe',
  bottle: 'W butelce',
  glass: 'W szklance',
  brandy: 'Brandy',
  horilka: 'Horilka',
  whiskey: 'Whisky',
  liqueur: 'Likier',
  wine: 'Wino',
  champagne: 'Szampan',
  other: 'Inne',
  hot_drinks: 'Napoje gorące',
  cold_drinks: 'Napoje zimne',
  long_drink: 'Długi drink',
  shooters: 'Shootery',
  extreme: 'Ekstremalne',
  short_drink: 'Krótki drink',
  cognac: 'Koniak',
  vodka: 'Wódka',
  liqueurs: 'Likier',
  infusions: 'Nalewki',
  gin: 'Gin',
  rum: 'Rum',
  martini: 'Martini',
  soft_drinks: 'Napoje bezalkoholowe',
  fresh: 'Świeży sok',
  lemonades: 'Lemoniada',
};
