import { messages } from "@/locales/ru/messages";
import { button } from "@/locales/ru/button";
import { service } from "@/locales/ru/service";
import { cleaning } from "@/locales/ru/services/cleaning";
import { taxi } from "@/locales/ru/services/taxi";
import { desk } from "@/locales/ru/services/desk";
import { info } from "@/locales/ru/pages/info";
import { laundry } from "@/locales/ru/services/laundry";
import { food } from "@/locales/ru/pages/food";
import { beverage } from "@/locales/ru/pages/beverage";
import { menu } from "@/locales/ru/services/menu";
import { sauna } from "@/locales/ru/services/sauna";
import { gazebo } from "@/locales/ru/services/gazebo";
import { advert } from "@/locales/ru/advert";
import { error } from "@/locales/ru/error";
import { notification } from "@/locales/ru/notification";
import { infoPriceList } from "@/locales/ru/pages/info_price_list";
import { booking } from "@/locales/ru/services/booking";
import { chorna_skelya } from "@/locales/ru/hotels/chorna_skelya";
import { bath } from "@/locales/ru/services/bath";

export const ru = {
  room: 'Комната',
  from: 'с',
  from_price: 'до',
  till: 'до',
  or: 'или',
  today: 'сегодня',
  tomorrow: 'Завтра',
  asap: 'В ближайшее время',
  uah: 'грн',
  usd: '$',
  notifications: 'Уведомление',
  empty_notifications: 'Нет уведомлений',
  per_hour: 'час',
  reservation: 'Бронирование',
  date: 'Дата',
  new_messages: 'новых уведомлений',
  duration: 'Продолжительность',
  minutes: 'минут',
  current: 'Текущие',
  finished: 'Завершенные',
  time: 'Время',
  room_number: 'Номер комнаты',
  placed_time: 'Запрос размещен',
  taken_time: 'Запрос подтвержден',
  preference: 'Желаемое время',
  no_clean: 'Уборка не нужна',
  comment: 'Коммент',
  cancel_reason_label: 'Введите причину отказа',
  cancel_title: 'Отмена запроса',
  tech_cancel_message: 'Ваш запрос был отменен по техническим причинам. Можно изменить дату и время запроса или связаться с администратором.',
  message_404: 'Этой страницы не существует',
  request_empty: 'Вы еще не делали запросов и бронирований',
  oops: 'Упс',
  not_allowed: 'У Вас нет доступа к этой странице',
  free_time: 'Свободное время',
  reserve_time: 'Время запроса',
  hotel: 'Отель',
  service_name: 'Услуга',

  ...messages,
  price_list: infoPriceList,
  button,
  service,
  cleaning,
  taxi,
  desk,
  booking,
  info,
  laundry,
  food,
  beverage,
  menu,
  sauna,  // contains texts for sauna, vat and bath services
  gazebo,
  bath,
  advert,
  error,
  notification,

  spa: {
    spa_1: 'Классический массаж всего тела с маслами',
    spa_2: 'Массаж шейного отдела',
    spa_3: 'Шоколадное обвертывание',
    choose_date: 'Укажите дату и время, когда Вы хотите воспользоваться СПА',
  },

  form: {
    enter_comment: 'Введите комментарий',
    enter_text: 'Введите текст',
    enter_address: 'Введите адрес',
  },

  adverts: {
    title: 'Разнообразьте Ваш отпуск',
  },

  qr_room: {
    ust_table_1: 'Стол 1',
    ust_table_2: 'Стол 2',
    ust_table_3: 'Стол 3',
    ust_table_4: 'Стол 4',
    ust_table_5: 'Стол 5',
    ust_table_6: 'Стол 6',
    ust_table_7: 'Стол 7',
    ust_table_8: 'Стол 8',
    ust_table_9: 'Стол 9',
    ust_table_10: 'Стол 10',
    td_gazebo_1: 'Альтанка 1',
    td_gazebo_2: 'Альтанка 2',
    td_gazebo_3: 'Альтанка 3',
    td_gazebo_4: 'Альтанка 4',
    td_gazebo_5: 'Альтанка 5',
    td_gazebo_6: 'Альтанка 6',
    td_gazebo_7: 'Альтанка 7',
    td_gazebo_8: 'Альтанка 8',
    td_gazebo_9: 'Альтанка 9',
    td_gazebo_10: 'Альтанка 10',
    td_bath_1: 'Баня 1',
    td_vat_1: 'Чан 1',
    td_vat_3: 'Чан 2',
  },

  summary: {
    title: 'Итог',
    article: 'Название',
    amount: 'Кол-во',
    price: 'Цена',
    total: 'Всего',
    hours: 'Часов',
    h: 'Часов',
    m: 'Минут',
    d: 'Дней',
    u: 'Кол-во',
  },

  payment: {
    title: 'Оплата',
    details: 'Детали оплати',
    choose_method: 'Выберите метод оплаты',
    apple: 'Apple Pay',
    google: 'Google Pay',
    card_number: 'Номер карты',
    card_date: 'Месяц/Год',
    card_cvv: 'CVV',
    cvv_hint: 'Код на обратной стороне карты',
    pay_success: 'Спасибо! Ваш заказ уже обрабатывается!\nВаш счет уже тут.',
    download_invoice: 'Загрузить чек',
    payment_info: '*Оплата осуществляется на месте.',
  },

  status: {
    new: 'Ожидает подтверждения',
    active: 'Подтвержден',
    cancelled: 'Отменен',
    finished: 'Завершен',
  },

  event: {
    success_title: 'Поздравляем!',
    error_title: 'Что то пошло не так',
    cancel_title: 'Ваш запрос был отм',
    tech_cancel_title: 'Ваш запрос был отменен по техническим причинам. Детальнее...',
    taken: 'Мы начали выполнять Ваш запрос с отдела "{type}"!',
    cancelled: 'Ваш запрос {type} был отменен. Детальнее...',
    finished: 'Ваш запрос {type} был завершен!',
    booking_cancel_title: 'Бронирование отменено!',
    booking_cancel_text: 'Ваше Бронирование комнаты было отменено',
    booking_confirm_title: 'Бронирование подтверждено!',
    booking_confirm_text: 'Ваше Бронирование комнаты было подтверждено',
  },

  unit: {
    gr: 'гр',
    prt: 'порция',
    pc: 'шт',
    l: 'л',
    ml: 'мл',
    bottle: 'бутылка',
  },

  data: {
    cancel_reason_1: 'Услуга недоступна',
    cancel_reason_2: 'Нет необходимого персонала',
    cancel_reason_3: 'Немає необходимого оборудования',
    cancel_reason_4: 'Нет свободного времени',
    cancel_reason_5: 'Нет свободных столиков',
    tech: 'Технические работы'
  },

  // hotels' texts
  ch_sk: chorna_skelya,
};
