<template>
  <div class="home-button" :class="{'home-button--opened': isOpened}" @click.stop="onClick"></div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

const { push } = useRouter();

const isOpened = ref(false);
const timer = ref(0);

const onClick = () => {
  if (isOpened.value) {
    isOpened.value = false;
    clearTimeout(timer.value);
    push('/');
  } else {
    isOpened.value = true;
    timer.value = setTimeout(() => isOpened.value = false, 2500);
  }
}
</script>

<style scoped lang="scss">
.home-button {
  @extend .pointer, .circle;
  position: fixed;
  bottom: 0.75rem;
  left: -40px;
  @include square(70px);
  @include svgBg($slide-arrow, 2rem, 35px);
  background-color: #FFFFFF99 !important;
  z-index: 10;
  transition-duration: 0.25s;

  &--opened {
    left: 0.75rem;
    @include svgBg($home, 2rem);
  }
}
</style>
