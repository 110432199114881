import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import CleaningView from "@/views/CleaningView.vue";
import DeskView from "@/views/DeskView.vue";
import TaxiView from "@/views/TaxiView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import { useUserStore } from "@/stores/userStore";
import NotAllowedView from "@/views/NotAllowedView.vue";
import { inject } from "vue";
import { VueCookies } from "vue-cookies";
import { infoRoutes } from "@/router/info";
import { menuRoutes } from "@/router/menu";
import { bookingRoutes } from "@/router/booking";

const routes: Array<RouteRecordRaw> = [{
  path: '/',
  name: 'home',
  component: HomeView
}, {
  path: '/cleaning',
  name: 'cleaning',
  component: CleaningView
}, {
  path: '/desk',
  name: 'desk',
  component: DeskView
}, {
  path: '/taxi',
  name: 'taxi',
  component: TaxiView
},
  infoRoutes,
  menuRoutes,
  bookingRoutes,
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFoundView
  }, {
    path: '/not-allowed',
    name: 'not-allowed',
    component: NotAllowedView
  }];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const restrictedPages = [
    'cleaning', 'menu', 'menu-list', 'menu-details', 'desk', 'taxi',
    'info-massage', 'info-gazebo', 'info-laundry', 'info-sauna'
  ];
  if (restrictedPages.includes(to.name as string ?? '')) {
    const userStore = useUserStore();
    if (!userStore.isLinkActive) {
      next('/not-allowed');
      return;
    }
  }


  if (!to.hash) {
    const $cookies = inject<VueCookies>('$cookies');
    if ($cookies?.isKey('hash')) {
      next(to.path + $cookies?.get('hash'));
      return;
    }

    const storedHash = localStorage.getItem('user_hash');
    if (storedHash) {
      next(to.path + storedHash);
      return;
    }
  }

  if (!from.hash && !to.hash && to.path !== '/not-found') {
    next('/not-found');
    return;
  }

  next();
});

export default router
