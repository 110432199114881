<template>
  <div v-if="adverts?.length" class="adv">
    <div class="adv__header">
      <p class="adv__header-text">{{ $t('adverts.title') }}</p>
      <template v-if="adverts.length > 1">
        <div class="slide-button slide-button--prev" @click="onPrevClick"></div>
        <div class="slide-button slide-button--next" @click="onNextClick"></div>
      </template>
    </div>
    <div class="adv__list">
      <transition :name="`slide-${transition}`">
        <component :is="banners[currentAdvert.type]" :key="currentIndex" :advert="currentAdvert" />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Banner_1 from "@/components/Banner/Banner_1.vue";
import Banner_2 from "@/components/Banner/Banner_2.vue";
import Banner_3 from "@/components/Banner/Banner_3.vue";
import Banner_4 from "@/components/Banner/Banner_4.vue";
import Banner_5 from "@/components/Banner/Banner_5.vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";
import { Banner } from "@/types/Banner";

const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);

const banners = {
  'type-1': Banner_1,
  'type-2': Banner_2,
  'type-3': Banner_3,
  'type-4': Banner_4,
  'type-5': Banner_5,
};

const adverts = computed((): Banner[] => hotelSetup.value.banners);

const currentAdvert = computed((): Banner => adverts.value[currentIndex.value]);

const currentIndex = ref(0);
const timer = ref(0);
const transition = ref('left');

const onPrevClick = () => {
  transition.value = 'right';
  currentIndex.value = currentIndex.value > 0 ? currentIndex.value - 1 : adverts.value.length - 1;
  renewTimer();
};

const onNextClick = () => {
  transition.value = 'left';
  currentIndex.value = currentIndex.value < adverts.value.length - 1 ? currentIndex.value + 1 : 0;
  renewTimer();
};

const setTimer = () => timer.value = setTimeout(onNextClick, 5000);

const renewTimer = () => {
  clearTimeout(timer.value);
  setTimer();
};

onMounted(setTimer);
onBeforeUnmount(() => clearTimeout(timer.value));
</script>

<style scoped lang="scss">
@import "../../assets/styles/utils/transitions";

.adv {
  @extend .flex-col;

  &__header {
    @extend .row-container;
    min-height: 42px;
    margin-bottom: 0.75rem;

    &-text {
      width: 100%;
      @include font(4.58vw, 122%, white, 600);
    }
  }

  &__list {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 47vw;
  }
}

.slide-button {
  margin-left: 0.5rem;
  background-color: #FFE50099;
}

@media (max-height: 780px) {
  .adv__list {
    height: 26dvh;
  }
}
</style>
