<template>
  <div class="panel">
    <page-header :title="$t('info.laundry')" back-link="/info" />
    <loader-panel v-if="isLoading" />
    <template v-else>
      <panel-tabs :selected-tab="selectedTab" :tabs="tabs" @tab-click="v => selectedTab = v" />
      <p class="panel__help">{{ $t('laundry.schedule') }}</p>
      <div class="panel__content">
        <template v-for="item in items" :key="item.id">
          <div v-if="item[selectedTab === 0 ? 'wash' : 'iron'] > 0" class="panel__item">
            <p class="panel__item-name">{{ $t(`laundry.${ item.name }`) }}</p>
            <p class="panel__item-price">{{ item[selectedTab === 0 ? 'wash' : 'iron'] }} {{ $t('uah') }}</p>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import PanelTabs from "@/components/PanelTabs.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { PanelTabItem } from "@/types/App/Tab";
import { FormTab } from "@/types/App/Form";

interface LaundryCartItem {
  id: number;
  name: string;
  wash: number;
  iron: number;
}

const i18n = useI18n();

const services: string[] = ['wash', 'iron'];
const items = [
  { id: 1, name: 'blouse', wash: 70, iron: 50 },
  { id: 2, name: 'sweater', wash: 70, iron: 30 },
  { id: 3, name: 'trousers', wash: 100, iron: 70 },
  { id: 4, name: 'dress', wash: 100, iron: 70 },
  { id: 5, name: 'jeans', wash: 70, iron: 40 },
  { id: 6, name: 'skirt', wash: 60, iron: 40 },
  { id: 7, name: 't_shirt', wash: 50, iron: 30 },
  { id: 8, name: 'nightgown', wash: 50, iron: 30 },
  { id: 9, name: 'scarf', wash: 30, iron: 15 },
  { id: 10, name: 'socks', wash: 20, iron: 10 },
  { id: 11, name: 'pajamas', wash: 50, iron: 30 },
  { id: 12, name: 'jacket', wash: 0, iron: 90 },
  { id: 13, name: 'suit', wash: 150, iron: 15, },
  { id: 14, name: 'sport_suit', wash: 100, iron: 70 },
  { id: 15, name: 'vest', wash: 30, iron: 20 },
  { id: 16, name: 'shorts', wash: 30, iron: 20 },
  { id: 17, name: 'towel', wash: 50, iron: 30 },
  { id: 18, name: 'child', wash: 50, iron: 30 },
];

const tabs = ref<FormTab[]>([]);
const selectedTab = ref(0);
const data = ref<LaundryCartItem[]>([]);
const isLoading = ref(true);

onMounted(() => {
  tabs.value = [
    { name: { en: 'Wash', ua: 'Прання' } },
    { name: { en: 'Iron', ua: 'Прасування' } },
  ];
  selectedTab.value = 0;
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.panel {
  &__content {
    @extend .flex-col;
    gap: 1.5rem;
  }

  &__item {
    @extend .row-container;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background-color: #FFFFFFCC;
    border-radius: 10px;

    &-name {
      flex: 1;
      @include font(18px, 20px, black, 600);
      word-break: break-all;
      text-wrap: balance;
    }

    &-price {
      width: min-content;
      margin: 0 1rem;
      @include font(16px, 20px, black, 600, center);
      text-transform: uppercase;
    }

    &-amount {
      @extend .row-container;

      &__plus, &__minus {
        background-color: $primary !important;
        border-radius: 3px;
      }

      &__plus {
        @include icon($plus, 2.25rem, 1.5rem);
      }

      &__minus {
        @include icon($minus, 2.25rem, 1.5rem);
      }

      &__text {
        width: 2rem;
        margin: 0 0.25rem;
        @include font(18px, 22px, black, 600, center);
      }
    }

    &-button {
      min-width: 3.75rem;
      height: 2.5rem;
      padding: 0 0.5rem;
      @include font(18px, 2.5rem, black, 600, center);
      background-color: $primary;
      border-radius: 5px;
    }
  }

  &__help {
    margin-bottom: 1rem;
    @include font(14px, 17px, white);
  }
}

.loader {
  height: calc(100% - 4rem);
  min-height: calc(100% - 4rem);
}
</style>
