export const beverage = {
  title: 'Drinks',
  coffee_tea: 'Coffee and Tea',
  coffee: 'Coffee',
  tea: 'Tea',
  beer: 'Beer',
  cocktails: 'Cocktails',
  non_alcohol_cocktails: 'Non alcohol Cocktails',
  alcohol: 'Alcohol drinks',
  non_alcohol_drinks: 'Non alcohol drinks',
  bottle: 'Bottled',
  glass: 'By the glass',
  brandy: 'Brandy',
  horilka: 'Horilka',
  whiskey: 'Whiskey',
  liqueur: 'Liqueur',
  wine: 'Wine',
  champagne: 'Champagne',
  other: 'Other',
  hot_drinks: 'Hot drinks',
  cold_drinks: 'Cold drinks',
  long_drink: 'Long Drink',
  shooters: 'Shooters',
  extreme: 'Extreme',
  short_drink: 'Short Drink',
  cognac: 'Cognac',
  vodka: 'Gorilka',
  liqueurs: 'Liqueurs',
  infusions: 'Infusions',
  gin: 'Gin',
  rum: 'Rum',
  martini: 'Martini',
  soft_drinks: 'Alcohol free drinks',
  fresh: 'Fresh juice',
  lemonades: 'Lemonade',
};
