<template>
  <div
      :class="['banner', `banner--${advert.type}`, { loading: !advert.imageSrc }]"
      @click="advert?.link && push(advert.link)"
      :style="`background-image: url('${advert.imageSrc}')`"
  >
    <svg :width="bannerSize" :height="bannerSize * 0.42" fill="none"
         :viewBox="`0 0 ${bannerSize} ${bannerSize * 0.42}`" xmlns="http://www.w3.org/2000/svg">
      <path :d="path" fill="#DEE2E6" />
    </svg>
    <div class="banner__left">
      <p class="banner__left-title">{{ getTranslation(advert.title) }}</p>
      <p class="banner__left-text">{{ getTranslation(advert.text) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { useTranslation } from "@/composable/useTranslation";
import { Banner } from "@/types/Banner";

defineProps({
  advert: {
    type: Object as PropType<Banner>,
    default: () => ({})
  }
});

const { push } = useRouter();
const { getTranslation } = useTranslation();

const bannerSize = document.documentElement.clientWidth - 32;

const path = computed((): string => {
  return `M 0 0 H ${ bannerSize * 0.75 } C ${ bannerSize * 0.75 } 0 ${ bannerSize * 0.5 } ${ bannerSize * 0.068 } ` +
      `${ bannerSize * 0.56 } ${ bannerSize * 0.1 } C ${ bannerSize * 0.47 } ${ bannerSize * 0.21 } ${ bannerSize * 0.52 } ` +
      `${ bannerSize * 0.42 } ${ bannerSize * 0.23 } ${ bannerSize * 0.41 } C ${ bannerSize * 0.06 } ${ bannerSize * 0.4 } ` +
      `0 ${ bannerSize * 0.35 } 0 ${ bannerSize * 0.33 } V 0 Z`;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/banner";

.banner {
  &.loading {
    background-image: url('@/assets/preload.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &__left {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    width: 42%;
    z-index: 10;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
</style>
