export const menu = {
  //subcategories
  cold_appetizers: 'Cold snacks',
  hot_appetizers: 'Hot snacks',
  poultry_dishes: 'Poultry dishes',
  veal_dishes: 'Veal dishes',
  pork_dishes: 'Pork dishes',
  chief_specialties: 'Specialties from the chef',
  ust_cold_appetizers: '"Kulak stocks" (cold snacks)',
  ust_salads: 'From the soul',
  ust_soups: '"Midday opryshka" (first courses)',
  ust_bread: 'Bread basket',
  ust_main_dishes: '"From dust-heat" (second courses)',
  ust_caucasian_guest: 'Caucasian Guest',
  ust_fish: '"From the pond to the table"',
  ust_hot: '"Native"',
  ust_sides: 'Not meat alone',
  ust_dessert_with_tea: '"I do not miss tea...',
  ust_homemade_pastry: 'Homemade pastries',
  ust_cold_desserts: 'Sweet coolness',
  banosh_with_cream: 'Banosh on strings',
  cremeshnit: 'Silica',

  // supplement categories
  sauces_supp: 'Sauces',
  vegetables_supp: 'Vegetable',
  meats_supp: 'Meat',
  cheeses_supp: 'Cheese',

  caesar_chicken: 'Caesar Salad with Chicken',
  caesar_salmon: 'Caesar Salad with Salmon',
  caesar_shrimp: 'Caesar Salad with Shrimp',
  warm_salad: 'Warm Salad with Chicken Liver',
  cabbage_salad: 'Cabbage Salad',
  veg_assorted: 'Vegetable Assortment',
  greek_salad: 'Greek Salad',
  marinated_assorted: 'Marinated Assortment',
  caesar_chicken_details: 'Iceberg lettuce, chicken fillet, quail egg, tomatoes, parmesan, croutons',
  caesar_salmon_details: 'Iceberg lettuce, tomatoes, parmesan, croutons, salmon, quail egg',
  caesar_shrimp_details: 'Iceberg lettuce, shrimp, cherry tomatoes, cucumber, parmesan, sesame, olive oil, lemon',
  warm_salad_details: 'Mixed greens, chicken liver, spices, olive oil',
  veg_assorted_details: 'Tomatoes, cucumbers, pepper, cabbage',

  broth: 'Broth with Noodles',
  broth_details: 'Chicken brisket, vegetables',
  cheese_creamy_soup: 'Cheese Cream Soup with Croutons',
  borsch: 'Borscht',
  cold_soup: 'Cold Summer Soup',
  bograch: 'Bograch',
  mushroom_soup: 'Mushroom Soup',
  minestrone: 'Minestrone',

  barbecue_pork: 'Pork Barbecue',
  barbecue_chicken: 'Chicken Barbecue',
  veg_grill: 'Grilled Vegetables',
  stake_pork: 'Pork Neck Steak',
  stake_chicken: 'Chicken Breast Steak',
  sausage_grill: 'Homemade Grilled Sausage',
  ribs_grill: 'Grilled Ribs',
  potato_grill: 'Grilled Potatoes',
  meat_set: 'Meat Platter for Group (for 6 people)',
  barbecue_chicken_details: 'Chicken thigh',
  potato_grill_details: 'With onions and homemade oil',
  meat_set_details: 'Pork shashlik, chicken shashlik, ribs, sausages',

  eggs: 'Fried Eggs',
  bacon_eggs: 'Fried Eggs with Bacon',
  omelet_cheese: 'Cheese Omelet',
  omelet_veg: 'Omelet with Vegetable Sauté',
  euro: 'European Breakfast',
  euro_details: 'Eggs, sausages, beans, butter, croutons',
  crepes: 'Assorted Crepes',
  oatmeal: 'Oatmeal with Fruits',
  cheesecakes: 'Cheesecakes with Jam/Sour Cream',
  pancakes: 'Pancakes',

  meat_antipasti: 'Meat Antipasti',
  cheese_plate: 'Cheese Plate (for 4 people)',
  cheese_plate_details: 'Camembert, parmesan, mozzarella mini, dorblu, nut',
  trio_salo: 'Trio of Salo',
  salmon_bruschetta: 'Salmon Bruschetta',
  avocado_bruschetta: 'Avocado and Sun-dried Tomato Bruschetta',
  prosciutto_bruschetta: 'Prosciutto and Cream Cheese Bruschetta',

  tenderloin: 'Royal Tenderloin',
  chicken_caprese: 'Caprese Chicken',
  meat_lozzi: 'Lozi Roasted Meat',

  carp: 'Grilled Carp',
  trout: 'Peasant-style Trout (in a Pan)',
  trout_details: 'Onion, corn grits',
  trout_grill: 'Grilled Trout',
  salmon_spinach: 'Salmon Fillet on a Bed of Spinach',
  dorado_grill: 'Grilled Dorado',
  dorado_veg: 'Dorado Fillet with Vegetables and Lime Sauce',

  varenyky_potato: 'Varenyky with Potatoes and Sour Cream',
  varenyky_meat: 'Varenyky with Cracklings and Onions',
  home_dumplings: 'Homemade Dumplings with Butter',
  pasta: 'Pasta with Shrimp and Zucchini',
  spaghetti_carbonara: 'Spaghetti "Carbonara"',
  spaghetti_fettuccine: 'Spaghetti "Fettuccine"',
  spaghetti_carbonara_details: 'Bacon, cream, parmesan',
  spaghetti_fettuccine_details: 'Mushrooms, cream, parmesan',

  deruny_meat: 'Deruny with Meat',
  deruny_cream: 'Deruny with Sour Cream',
  deruny_mushroom: 'Deruny with Mushrooms and Sour Cream',
  risotto_mushroom: 'Mushroom Risotto',
  risotto_shrimp: 'Shrimp Risotto',
  brynza_banosh: 'Banosh with Brynza and Cracklings',
  pan: 'Homestyle Skillet',
  pan_chicken: 'Skillet with Chicken Hearts and Liver',
  pan_details: 'Potatoes, homemade sausage, onion, cheese, eggs, cream',
  pan_chicken_details: 'Hearts, liver, mushrooms, cheese, cream',

  satsebeli: 'Satsebeli Sauce',
  ketchup: 'Ketchup',
  pishta: 'Pishta',

  oatmeal_kids: 'Oatmeal with Butter',
  choco_balls: '"Choco Balls" with Milk',
  buckwheat: 'Buckwheat Porridge with Butter',
  potato_kids: 'Boiled Potatoes with Butter',
  rice_butter: 'Rice with Butter',

  espresso_oriental: 'Espresso "Oriental"',
  americano_oriental: 'Americano "Oriental"',
  viennese_coffee: 'Viennese Coffee',
  cappuccino: 'Natural Cappuccino',
  latte: 'Latte',
  perfetto: 'Perfetto',
  iced_coffee: 'Iced Coffee',
  cacao: 'Cocoa',
  tea: 'Brewed Tea',
  tea_fruit: 'Fruit Tea',
  cappuccino_details: 'Espresso, milk',
  latte_details: 'Espresso, milk',
  perfetto_details: 'Espresso, milk, ice cream, baileys, cream',
  cacao_details: 'Chocolate, whipped milk',

  pilsner: 'Pilsner',
  kellerbier: 'Kellerbier',
  non_alcohol_beer: 'Non-alcoholic Beer',
  staropramen: 'Staropramen',
  heineken: 'Heineken',
  carpathian: 'Carpathian',
  no_filter: 'Unfiltered Beer',
  staropramen_details: 'live fermentation',

  citro: 'Draft Lemonade, Kvass',
  juice: 'Juices',
  hell: 'Hell',
  lu: 'Luzhanska',
  morshyn: 'Morshynska',
  coca: 'Coca-Cola',
  pepsi: 'Pepsi',
  glass: 'glass',

  metaxa_brandy: 'Metaxa',
  old_kakheti_brandy: 'Old Kakheti',
  zakarpatsky_brandy: 'Transcarpathian',
  tisa_brandy: 'Tisa',
  uzhgorod_brandy: 'Uzhgorod',
  carpati_brandy: 'Carpathians',
  atenos_brandy: 'Athens',
  riga_balsam: 'Riga Balsam',
  tequila: 'Tequila',
  absinthe: 'Absinthe',
  becherovka: 'Becherovka',
  campari: 'Campari',
  captain_morgan: 'Captain Morgan',
  jagermaster: 'Jägermeister',
  soplitsa: 'Soplitsa',
  starka_reserv: 'Starka Reserve',
  tyorkina: 'Tyorkina',
  finland: 'Finland',
  zubrovka_charna: 'Zubrovka Black',
  zubrovka: 'Zubrovka',
  lvivska: 'Lvivska',
  lviv_gold: 'Lvoff Gold',
  jameson: 'Jameson',
  chivas_regal: 'Chivas Regal',
  jack_daniels: 'Jack Daniels',
  red_label: 'Red Label',
  sambuca: 'Sambuca',
  baileys: 'Baileys',
  amaretto: 'Amaretto',
  wine_chevalier_de_france: 'Chevalier de France Wine',
  wine_carpathian: 'Carpathian Wine',
  wine_villa_puccini: 'Villa Puccini Wine',
  wine_sarsitano: 'Sarsitano Wine',
  champagne_fragolino: 'Fragolino Champagne',
  champagne_martini_asti: 'Martini Asti Champagne',
  champagne_prosecco: 'Prosecco Champagne',
  pc_3: '3 pc',
  pc_4: '4 pc',
  pc_5: '5 pc',

  mojito_non_alcohol: 'Non-Alcoholic Mojito',
  citro_fruit: 'Fruit Lemonade',
  milk_shake: 'Milkshake',
  in_assortment: 'in assortment',

  pina_collada: 'Pina Colada',
  hirosima: 'Hiroshima',
  sunrise: 'Sunrise',
  envy: 'Envy',
  green_coast: 'Green Coast',
  rome_sunset: 'Rome Sunset',
  cuba_libre: 'Cuba Libre',
  tom_collins: 'Tom Collins',
  pink_cat: 'Pink Cat',
  garibaldi: 'Garibaldi',
  tequila_boom: 'Tequila Boom',
  man_size: 'Man Size',
  mojito: 'Mojito',
  aperol_syringe: 'Aperol Syringe',
  hurricane: 'Hurricane',
  green_fairy: 'Green Fairy',
  long_island: 'Long Island',
  daiquiri_bella_donna: 'Daiquiri Bella Donna',
  pina_collada_details: 'pineapple juice, white rum, coconut liqueur',
  hirosima_details: 'grenadine syrup, sambuca, Baileys liqueur, absinthe',
  sunrise_details: 'orange juice, grenadine syrup, tequila',
  envy_details: 'pineapple juice, tequila, "Blu curacao" liqueur',
  green_coast_details: 'lemon juice, lemon, absinthe, tequila',
  rome_sunset_details: 'cherry juice, rum, orange',
  cuba_libre_details: 'rum, cola, lime',
  tom_collins_details: 'gin, lemon juice, syrup, soda water',
  pink_cat_details: 'pineapple juice, gin, grenadine, grapefruit juice',
  garibaldi_details: 'orange juice, Campari tincture, orange',
  tequila_boom_details: 'tequila sprite',
  man_size_details: 'absinthe, grenadine, pineapple juice, "Pisang ambon" liqueur',
  mojito_details: 'rum, sprite, lime, syrup, mint',
  aperol_syringe_details: 'champagne dry, aperol, sprite, orange',
  hurricane_details: 'white rum, black rum, Campari tincture, juice: lemon, pineapple, orange; syrup',
  green_fairy_details: 'tequila, absinthe, vodka, white rum, blue curacao liqueur, juice, energy drink, melon liqueur',
  long_island_details: 'gin, vodka, white rum, tequila, Triple Sec liqueur, juice, Coca-Cola',
  daiquiri_bella_donna_details: 'Amaretto liqueur, black rum, lemon juice',

  meat_in_french: 'Meat in French',
  chop: 'Chop',
  shank: 'Shank',
  egg: 'Eggs',
  omelet: 'Omelet',

  dumplings: 'Dumplings',
  potato_varenyky: 'Potato Dumplings',
  cheese_crepes: 'Cheese Crepes',
  sweet_crepes: 'Sweet Crepes',
  bread: 'Bread',
  banosh: 'Banosh',

  veg_soup: 'Vegetable soup',
  mug_broth: 'Broth in a mug',
  chicken_broth: 'Chicken fillet broth',
  uha: 'Ukha',
  borshch: 'Borscht',
  solyanka: 'Solyanka',

  assorted_salad: 'Assorted salad',
  caesar: 'Caesar salad',
  salted_cucumbers: 'Salted cucumbers',
  sauerkraut: 'Sauerkraut',

  assorted_meat: 'Assorted meat platter',
  assorted_cheese: 'Assorted cheese platter',
  salo: 'Salo',
  ham: 'Ham',

  potato_french: 'French fries',
  potato_mashed: 'Mashed potatoes',
  potato_salo: 'Potatoes with lard',
  potato_grilled: 'Grilled potatoes',
  rice: 'Rice',
  kremzlyky: 'Cream puffs with sour cream',

  sea_bass_fish: '"Sea bass"',
  dorado_fish: '"Dorado"',
  trout_fish: '"Trout"',
  carp_fish: '"Carp"',
  salmon_stake: 'Salmon steak',
  pork_kebab: 'Pork kebab',
  pork_stake: 'Pork steak',
  pork_rib: 'Pork ribs',
  chicken_kebab: 'Chicken kebab',
  chicken_stake: 'Chicken steak',
  sausages: 'Sausages',
  sturgeon_stake: 'Sturgeon steak',
  sturgeon_fish: '"Sturgeon"',

  kebab_sauce: 'Kebab sauce',
  brynza: 'Brynza cheese',
  mayo: 'Mayonnaise',
  mustard: 'Mustard',

  ice_cream: 'Ice cream',
  fruit_dessert: 'Fruit dessert',
  fruit_assorted: 'Assorted fruit',
  lemon: 'Lemon',

  cheese_pigtail: 'Cheese braid',
  chips: 'Chips',
  salted_nut: 'Salted nuts',

  fanta: 'Fanta',
  sprite: 'Sprite',
  mineral_water_glass: 'Mineral water (glass)',
  sandora_juice: 'Sandora juice',
  uzvar: 'Uzvar',
  sitro: 'Citro',

  espresso: 'Espresso coffee',
  cappuccino_natural: 'Natural cappuccino',
  americano: 'Americano',
  cappuccino_lafesta: 'Cappuccino "LaFesta"',
  coffee_3_in_1: 'Coffee "3 in 1"',
  custard_tea: 'Brewed tea',
  cream: 'Cream',

  brandy_zakarpatsky: '"Zakarpatsky"',
  brandy_shereuli: '"Shereuli"',
  whiskey: 'Whiskey',
  horilka_hemiroff_premium: '"Hemiroff Premium"',
  horilka_davnya_legenda: '"Davnya Legenda"',
  champagne_asti: '"Asti"',
  wina_old_carpatia: '"Old Carpatia"',
  wina_alaverdi: '"Alaverdi"',
  wina_homemade: 'Homemade wine',
  beer_lvivske_svitle: '"Lvivske Svitle"',
  beer_tubourg: '"Tuborg"',
  beer_non_alcohol: 'Non-alcoholic beer',
  beer_carlsberg: '"Carlsberg"',

  taistra_hutsula: 'Taistra hutsula',
  cheese_plateau: 'Cheese Plateau',
  vegetable_palety: 'Vegetable palette',
  meat_taire: 'Meat tair',
  homemade_marinada: 'Homemade marinades',
  croutons_in_transcarpathian: 'Croutons in Transcarpathian style',
  assorted_pickled_mushrooms: 'Assorted pickled mushrooms',
  white_mushroom_with_bowl: 'White mushroom with onions',
  herring_at_home: 'Herring at home',
  machantsi: 'Machantsi',
  tongue_in_cream_sauce: 'Tongue in cream sauce',
  transcarpathian_cabbage_rolls: 'Doves in Transcarpathian style',
  banosh_with_bryndza: 'Banosh with cheese',
  banosh_with_cep_mushrooms: 'Banosh with porcini mushrooms',
  pancakes_with_meat_and_mushroom_sauce: 'Pancakes with meat and mushroom sauce',
  lavash_with_cheese_and_tomatoes: 'Lavash with cheese and tomatoes',
  lavash_with_chicken: 'Lavash with chicken',
  taistra_hutsula_details: 'marinated lard, smoked lard, spread lard, croutons, pickled cucumbers',
  cheese_plateau_details: 'Mozzarella baby cheese, Dutch cheese, Feta cheese, cheese, honey',
  vegetable_palety_details: 'cucumber, tomato, bell pepper, cabbage',
  meat_taire_details: 'beef meat, beef tongue, homemade sausage, horseradish, sauce',
  homemade_marinada_details: 'pickled cucumber, pickled tomato, pickled plum, sauerkraut',
  croutons_in_transcarpathian_details: 'chicken pate, liver pate, hummus, croutons, onion marinade',
  assorted_pickled_mushrooms_details: 'champignons, chanterelles, white mushroom, korban',
  herring_at_home_details: 'potato (100g), herring (100g), onion (30g)',
  machantsi_details: 'white mushrooms in sour cream',
  tongue_in_cream_sauce_details: '100/100g',
  transcarpathian_cabbage_rolls_details: '200/50gr',
  pancakes_with_meat_and_mushroom_sauce_details: '200/50g',

  caesar_default: 'Caesar',
  royal: 'Royal',
  bukovinian: 'In Bukovinian',
  verkhovynskyi: 'Verkhovinsky',
  caprese: 'Caprese',
  salad_with_grilled_vegetables_and_chicken: 'Salad with grilled vegetables and chicken',
  warm_beef_salad: 'Warm salad with veal',
  gracia: 'Grace',
  caesar_default_details: 'salad mix, chicken fillet, tomatoes, Parmesan cheese',
  royal_details: 'champignons, ham, egg, mayonnaise, onion',
  bukovinian_details: 'beetroot, salad mix, feta cheese, pesto sauce, buckwheat-honey dressing',
  verkhovynskyi_details: 'cucumbers, tomatoes, bell pepper, cheese, garlic, sour cream',
  caprese_details: 'tomatoes, Mozzarella cheese, salad mix, Pesto sauce',
  salad_with_grilled_vegetables_and_chicken_details: 'salad mix, grilled vegetables, chicken, honey-mustard sauce',
  warm_beef_salad_details: 'salad mix, roasted bell pepper, veal',
  gracia_details: 'cucumbers, tomatoes, chicken fillet, mushrooms, mayonnaise, walnuts',

  bogrash: 'Bogrash',
  bogrash_in_bread_pot: 'Bogrash in the bread jug',
  fish_soup: 'Fish soup',
  chicken_broth_2: 'Chicken broth',
  carpathian_mushroom_soup: 'Carpathian mushroom soup',

  whisper_of_the_carpathians_with_gnocchi: '"Whisper of the Carpathians" with gnocchi',
  chicken_fillet_in_egg: 'Chicken Fillet in Egg',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce: 'Chicken breast on vegetable pillow with cheese sauce',
  chicken_in_verkhovynskyi_style: 'Chicken in Verkhovynskyi',
  chicken_steak: 'Chicken steak',
  chicken_steak_details: 'by weight',
  medallions_with_mushroom_sauce_and_vegetable_puree: 'Medallions with mushroom sauce and vegetable puree',
  beef_stroganoff: 'Beef Stroganoff',
  veal_with_chanterelles_in_sour_cream_sauce: 'Veal with chanterelles in sour cream sauce',
  cutlet: 'Chop',
  meat_in_poloninsky_style: 'Meat in Poloninsky style',
  cossack_power: '"Cossack power"',
  loci_baked: 'Loci Baked',
  shashlik_by_weight: 'Shashlik by weight',
  gazdivski_ribs_by_weight: 'Gazdivski ribs by weight',
  meat_set_for_company: 'Meat set for company',
  shank_by_weight: 'Shank by weight',
  lamb_leg_by_weight: 'Lamb leg by weight',
  duck_with_apples_and_orange_sauce_by_weight: 'Duck with apples and orange sauce by weight',
  whisper_of_the_carpathians_with_gnocchi_details: 'chicken (150g), mushrooms (100g), cheese (50g)',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce_details: '300/50g',
  chicken_in_verkhovynskyi_style_details: 'chicken fillet, mushrooms, sour cream',
  medallions_with_mushroom_sauce_and_vegetable_puree_details: '150/100/50g',
  beef_stroganoff_details: 'veal (100g), onion and sour cream (75g)',
  veal_with_chanterelles_in_sour_cream_sauce_details: '150/100g',
  cutlet_details: '100/50g',
  meat_in_poloninsky_style_details: 'pork under vegetable and cream sauce, 100/100g',
  cossack_power_details: 'pork steak by weight',
  loci_baked_details: 'pork under onion coat, 100/50gr',
  gazdivski_ribs_by_weight_details: 'pork ribs under sweet and sour sauce',
  meat_set_for_company_details: 'hunting sausages, grilled sausages, pork steak, chicken steak, ribs, baked potatoes, 1000/150gr',
  shank_by_weight_details: 'shank and homemade marinades, 1000/600gr',
  lamb_leg_by_weight_details: 'marinated in herbs and baked in dough',
  duck_with_apples_and_orange_sauce_by_weight_details: '1000/150gr',

  baked_trout_by_weight: 'Trout baked by weight',
  trout_in_cream_sauce_by_weight: 'Trout in cream sauce by weight',

  skillet_with_vegetables_and_chicken: 'Frying pan with vegetables and chicken',
  skillet_with_vegetables_and_veal: 'Frying pan with vegetables and veal',
  vegetable_skillet: 'Vegetable pan',
  skillet_with_homemade_sausage_and_stewed_cabbage: 'Skillet with homemade sausage and braised cabbage',

  kremzlyky_with_sour_cream: 'Kremzlyki with sour cream',
  kremzlyky_with_mushrooms: 'Kremzlyki with mushrooms',
  kremzlyky_hutsul_style: 'Kremzlyky in Hutsul',
  dumplings_with_potatoes_and_sour_cream: 'Dumplings with potatoes and sour cream',
  homemade_dumplings: 'Dumplings at home',
  kremzlyky_with_mushrooms_details: '200/100gr',
  homemade_dumplings_details: '200/50gr',

  mashed_potatoes: 'Mashed potatoes',
  homestyle_potatoes: 'Potatoes at home',
  baked_potatoes_with_bacon_and_brynza: 'Potatoes baked, with lard and cheese',
  creole_potatoes: 'Creole potato',
  broccoli_with_butter: 'Broccoli with butter',
  buckwheat_with_porcini_mushrooms: 'Buckwheat with porcini mushrooms',
  assorted_roasted_vegetables_by_weight: 'Baked vegetables in stock by weight',
  baked_potatoes_with_bacon_and_brynza_details: '200/50gr',

  slalom_pizza: 'Slalom',
  athens_pizza: 'Athenian',
  vegetarian_pizza: 'Vegetarian',
  hawaiian_pizza: 'Hawaiian',
  cheese_pizza: 'Cheese',
  mushroom_pizza: 'Mushroom',
  spicy_pizza: 'Spicy',
  beer_pizza: 'To beer',
  calzone_sausage_pizza: 'Calzone with sausage',
  bechamel_pizza: 'Bechamel',
  carbonara_pizza: 'Carbonara',
  kyiv_pizza: 'Kievskaya',
  cossack_pizza: 'Cossack',
  margherita_pizza: 'Margarita',
  milanese_pizza: 'Milan',
  four_seasons_pizza: '4-seasons',
  meat_pizza: 'Meat',
  pepperoni_pizza: 'Pepperoni',
  assorted_roasted_vegetables_by_weight_details: 'pomigori, pepper, zucchini, cybulia, pecorisi',
  slalom_pizza_details: 'tomato sauce, sire "Mozzarella", pork, cowboy, bugenin, pecoris, egg, cuckrudza, bell pepper, bacon, oregano',
  athens_pizza_details: 'tomato sauce, sire "Mozzarella", sire "Feta", mink, bacon, chicken fillet, salami, pomigdori, pecorisi, chapstick, green',
  vegetarian_pizza_details: 'tomato sauce, sire "Mozzarella", ovochi, oregano',
  hawaiian_pizza_details: 'tomato sauce, sire "Mozzarella", sire "Mozzarella", pineapple, cowboy, splint, corn, oregano',
  cheese_pizza_details: 'tomato sauce, sire "Mozzarella", sire "Parmesan", sire "Roquefort", sire "Mislivsky"',
  mushroom_pizza_details: 'tomato sauce, mozzarella cheese, mushrooms, egg, onion, oregano',
  spicy_pizza_details: 'tomato sauce, mozzarella cheese, cheese, bell pepper, mayonnaise, garlic, chili pepper, salami, mushrooms, marinated onion, oregano',
  beer_pizza_details: 'tomato sauce, mozzarella cheese, oregano',
  calzone_sausage_pizza_details: 'tomato sauce, mozzarella cheese, ham, sausage, tomatoes, corn, oregano',
  bechamel_pizza_details: 'cream sauce, ham, chicken fillet, bacon, pork, champignons, mozzarella cheese',
  carbonara_pizza_details: 'tomato sauce, mozzarella cheese, tomatoes, egg, bacon, oregano',
  kyiv_pizza_details: 'tomato sauce, mozzarella cheese, pork, chicken fillet, bell pepper, tomatoes, mushrooms, oregano',
  cossack_pizza_details: 'tomato sauce, mozzarella cheese, pork, bacon, garlic, mushrooms, corn, greens',
  margherita_pizza_details: 'tomato sauce, mozzarella cheese, tomatoes, oregano',
  milanese_pizza_details: 'tomato sauce, mozzarella cheese, chicken fillet, tomatoes, corn, egg, oregano',
  four_seasons_pizza_details: 'tomato sauce, ham, chicken fillet, bacon, champignons, Pepperoni salami, hunting sausages, Mozzarella cheese',
  meat_pizza_details: 'tomato sauce, Mozzarella cheese, pork, bacon, hunting sausages, bell pepper, corn, oregano',
  pepperoni_pizza_details: 'tomato sauce, mozzarella cheese, pepperoni salami, mushrooms, chili peppers, capers, oregano',

  basturma: 'Basturma',
  mahan: 'Mahan',
  beer_tray: 'Beer tanir',
  spicy_ears: 'Ears piquant',
  wings_in_honey_soy_sauce: 'Wings in honey-soy sauce',
  peanut: 'Peanut',
  beer_tray_details: 'makhan, basturma, golden fillet, pork',

  pancakes_with_creme_and_chocolate: 'Pancakes with cream and chocolate',
  pancakes_with_nuts_and_chocolate: 'Pancakes with nut and chocolate',
  pancakes_with_apples: 'Pancakes with apples',
  pancakes_with_cottage_cheese_and_sour_cream: 'Pancakes with curd and sour cream',
  syrniki_with_jam_and_sour_cream: 'Cheesecakes with jam and sour cream',
  prunes_with_condensed_milk: 'Prunes with condensed milk',
  tiramisu: 'Tiramisu',
  cheesecake: 'Cheesecake',
  chocolate_fondant: 'Chocolate fondant',
  apple_strudel_with_ice_cream: 'Strudel with apple and ice cream',
  jam: 'Jam',
  natural_honey: 'Natural honey',
  ice_cream_cone: 'Cup for ice cream',
  assorted_fruits: 'Fruit in assortment',
  student_chocolate: 'Student chocolate',
  dark_chocolate: 'Dark chocolate',
  pancakes_with_creme_and_chocolate_details: '100/100gr',
  pancakes_with_nuts_and_chocolate_details: '200/50gr',
  pancakes_with_apples_details: '200/50gr',
  pancakes_with_cottage_cheese_and_sour_cream_details: '200/50gr',
  syrniki_with_jam_and_sour_cream_details: '150/50/50gr',
  prunes_with_condensed_milk_details: '150gr',
  tiramisu_details: '150gr',
  cheesecake_details: '150gr',
  chocolate_fondant_details: '100/50gr',
  apple_strudel_with_ice_cream_details: '100/50gr',

  hot_chocolate: 'Hot chocolate',
  milk: 'Milk',
  matcha: 'Match',
  green_tea: 'Green Tea',
  black_tea: 'Black tea',
  herbal_tea: 'Herbal tea',
  fruit_tea: 'Fruit tea',
  tea_with_ginger_and_raspberry: 'Tea with ginger and raspberries',
  tea_with_sea_buckthorn_ginger_and_orange: 'Tea with sea buckthorn, ginger and orange',
  tea_with_blackberry_and_blueberry: 'Tea with blackberries and blueberries',

  chernigivske_bottle: 'Chernihiv bottle',
  stella_bottle: 'Stella bottle',
  stella_non_alcoholic: 'Stella b/a',
  corona_0_3l: 'Crown 0.3 liters',
  stella_draft_0_5l: 'Stella barrel 0.5 liters',

  tequila_sunrise: 'Tequila sunrise',
  long_island_ice_tea: 'Long island ice te',
  sex_on_the_beach: 'Sex on the beach',
  aperol: 'Aperol',
  green_mexican: 'Green Mexican',
  merzavchik: 'Merzavchik',
  meduza: 'Medusa',
  control_shot: 'Control shot',
  northern_hunter: 'Northern Hunter',
  b_52: 'B-52',
  b_53: 'B-53',
  bmw: 'BMW',
  sambuka: 'Sambuka',
  margarita: 'Margarita',
  malibu: 'Malibu',
  whiskey_cola: 'Bitsky-cola',
  gin_tonic: 'Gin-tonik',
  aperol_details: 'aperol, champagne, gas water',
  tequila_sunrise_details: 'tequila, orange cic, grenadine',
  long_island_ice_tea_details: 'gorilka, rum, gin, tequila, trilpsec, cola',
  sex_on_the_beach_details: 'gorilka, liqueur, orange cyk',
  mojito_details_2: 'bil rum, lime, mint, sprite, zukor',
  green_mexican_details: 'pizan, tequila, lemon fresh',
  merzavchik_details: 'gorilka, cyk, grenadine',
  meduza_details: 'malibu, triplsek, brillium rom, bailis',
  control_shot_details: 'kalua, sambuca, absinthe',
  northern_hunter_details: 'kalua, sambuca, hermetic master, mountain',
  b_52_details: 'kalua, baileys, triplsek',
  b_53_details: 'kalua, baileys, absinthe',
  bmw_details: 'baylis, malibu, viiski',
  tequila_boom_details_2: 'tequila, lemon seed, sprite',
  sambuka_details: 'sambuca, cinnamon, kavovi grain',
  margarita_details: 'tequila, triplic, lemon fresh',
  malibu_details: 'malibu, verbs, oranges',
  whiskey_cola_details: 'red label, coca-cola',
  gin_tonic_details: 'gin, tonik, lemon',

  milk_cocktail: 'Milkshake',
  coffee_cocktail: 'Coffee cocktail',
  milk_berry_cocktail: 'Milky berry cocktail',
  banana_cocktail: 'Banana cocktail',

  artemivske_champagne: 'Artemovskoe in assortment.',
  asti_pl_italia_champagne: 'Asti пл. Italia',
  lambrusco_italia_champagne: 'Lambrusco Italia',
  prosecco_champagne: 'Proseco',
  chilean_wine_wine: 'Chilean wine 100g',
  italian_wine_wine: 'Italian wine 100g',
  georgian_wine_wine: 'Georgian wine 100g',
  chateau_chizay_wine: 'Chateau Chizay vitr.100g',
  mulled_wine_wine: 'Mulled wine 200g',
  tisa_cognac: 'Tisa 50g',
  uzhgorod_cognac: 'Uzhgorod 50g',
  karpaty_cognac: 'Carpathians 50g',
  zakarpatskyi_cognac: 'Transcarpathian 50g',
  ararat_cognac: 'Ararat',
  hennessy_cognac: 'Hennessy',
  martell_cognac: 'Martell',
  khortytsia_vodka: 'Khortytsia 50g',
  nemiroff_vodka: 'Nemiroff 50г',
  finlandia_vodka: 'Finland 50g',
  absolut_vodka: 'Absolut 50г',
  lvivska_vodka: 'Lviv 50g',
  baileys_liqueurs: 'Baileys 50г',
  malibu_liqueurs: 'Malibu 50g',
  sheridans_liqueurs: 'Sheridans 50g',
  jim_beam_whiskey: 'Jim Beam 50g',
  jack_daniels_whiskey: 'Jack Daniels 50g',
  jameson_whiskey: 'Jameson 50g',
  medleys_whiskey: 'Medley’s 50g',
  ballantines_whiskey: 'Ballantine’s 50g',
  jagermeister_infusions: 'Hermeister 50g',
  becherovka_infusions: 'Becherovka 50g',
  tequila_infusions: 'Tequila 50g',
  absinthe_infusions: 'Absinth 50g',
  malinivka_infusions: 'Malinivka 50g',
  zubrovka_infusions: 'Grooming 50g',
  kalganyvka_infusions: 'Calganivka 50g',
  khrinivka_infusions: 'Crucifying 50g',
  beefeater_gin: 'Beefeater 50g',
  black_rum: 'Black 50g',
  gold_rum: 'Gold 50g',
  martini_martini: 'Martini 50g',

  mirinda: 'Mirinda',
  seven_up: '7up',
  shweppes: 'Shweppes',
  shepit_karpat_water: 'Water "Whisper of the Carpathians"',
  morshynska: 'Morshynska 1.5l',
  juice_assorted: 'Juice in stock',
  mojito_non_alcoholic: 'Mojito non-alcoholic',
  orange_fresh: 'Orange 200gr',
  carrot_apple_fresh: 'Carrot-apple 200gr',
  apple_fresh: 'Apple 200gr',
  grapefruit_fresh: 'Greyfruit 200gr',
  mix_vegetable_fruit_fresh: 'Mix (vegetables and fruits) 200gr',
  beetroot_fresh: 'Beetroot 200gr',
  lemon_fresh: 'Lemon 200gr',
  lemon_lemonade: 'Lemon 200gr',
  mojito_lemonade: 'Mojito 200gr',
  orange_sea_buckthorn_lemonade: 'Orange-sea buckthorn 200gr',
  forest_berries_lemonade: 'Forest berries 200gr',
  maracuja_strawberry_raspberry_lemonade: 'Passion fruit-strawberry-raspberry 200gr',

  mayonnaise: 'Mayonnaise',
  yerosh_pishto: 'Yerosh Pishto',
  sour_cream: 'Sour cream',
  spicy_garlic: 'Spicy garlic',
  tomatoes: 'Tomatoes',
  mushrooms: 'Mushrooms',
  bell_pepper: 'Bell pepper',
  corn: 'Corn',
  canned_pineapple: 'Pineapple (canned)',
  olives: 'Olives',
  onion: 'Onion',
  chili_pepper: 'Chili pepper',
  garlic: 'Garlic',
  one_egg: 'Egg',
  bacon: 'Bacon',
  buzhenina: 'Buzhenina',
  sausage: 'Sausage',
  hunting_sausages: 'Hunting sausages',
  salami_sausage: 'Sausage "Salami"',
  salami_pepperoni: 'Salami pepperoni',
  chicken_fillet: 'Chicken fillet',
  mozzarella_cheese: 'Cheese "Mozzarella"',
  parmesan_cheese: 'Cheese "Parmesan"',
  feta_cheese: 'Cheese "Feta"',
  roquefort_cheese: 'Cheese "Roquefort"',
  hunting_cheese: 'Cheese "Hunting"',

  homemade_salo: 'Salce homemade',
  sausage_figurines: 'Sausage figli',
  gazda_s_cut: 'Slicing "In Gazdovsky"',
  cheese_cut: 'Cheese slicing',
  herring_do_charochky: 'Herring "To the glass"',
  salted_mushrooms: 'Salt cellar',
  basket_of_mushrooms: 'White Basket',
  gazda_s_cut_details: 'homemade sausage, smoked bacon, baked pork',
  salted_mushrooms_details: 'pickles and cabbage, pickled tomatoes',
  basket_of_mushrooms_details: 'white mushrooms pickled',

  zvernyhora: 'Zvernigora',
  beet_salad: 'Beetroot',
  carrot_salad: 'Carrot',
  zvernyhora_details: 'white cabbage, carrots, greens, oil',

  gutsul_soup: '"Hutsul soup" from white mushrooms',
  zakarpatskyi_bograch: 'Bograch "Transcarpathian"',
  homemade_borscht_with_smetana: 'Home borschik with sour cream',
  kogut_dance: 'Claw Dance',
  vegetable_soup: '"You girls" vegetable soup',
  solyanka_set: 'Solyanka national team',
  white_bread: 'White bread',
  grey_bread: 'Bread gray',
  rye_bread: 'Rye bread',
  kogut_dance_details: 'chicken broth with meat and noodles',
  vegetable_soup_details: 'vegetable soup',

  wild_dances: 'Wild Dances',
  gutsul_taistra: '"Hutsul Taistra"',
  fried_homemade_sausage: 'Fried homemade sausage',
  pork_steak_on_bone: 'Pork loin on the bone',
  bear_paw: 'Bear paw',
  pork_ribs: 'Pork ribs',
  beef_steak_with_mushroom_sauce: 'Beef steak with mushroom sauce',
  chicken_steak_with_tartar_sauce: 'Chicken steak with Tar-Tar sauce',
  pork_shashlik_with_sauce: 'Pork skewers with sauce',
  wild_dances_details: 'roast pork with onions and mushrooms',
  gutsul_taistra_details: 'chicken fillet with mushrooms and cheese',
  bear_paw_details: 'roasted pork in cheese breading',
  pork_shashlik_with_sauce_details: '200/50/50gr',

  baked_trout: 'Trout baked',
  baked_mackerel: 'Mackerel baked on fire',

  dumplings_with_meat_and_mushroom_sauce: 'Nalichniki with meat and mushroom sauce',
  dumplings_with_potatoes_bryndza_and_sour_cream: 'Dumplings with potatoes, cheese, cracklings and sour cream',
  dumplings_with_potatoes_and_butter_with_sour_cream: 'Dumplings with potatoes, butter and sour cream',
  dumplings_with_potatoes_and_mushroom_sauce: 'Dumplings with potatoes and mushroom sauce',
  homemade_dumplings_2: 'Homemade dumplings',
  deruny_with_sour_cream: 'Pancakes with sour cream',
  deruny_with_mushroom_sauce: 'Deruny with mushroom sauce',
  puzo_gutsula: 'Puzo gutsula',
  poloninsky_kulesha: 'Kulesha in Polonynsky',
  banosh_with_bryndza_and_shkvarky: 'Banosh with cheese and cracklings',
  puzo_gutsula_details: 'deruni with a mushroom sauce',
  poloninsky_kulesha_details: 'kukuruzian porridge 3 brinzoe ta pellets',

  dragobrat_potatoes: 'Potato "In Dragobratsky"',
  mashed_potato: 'Mashed potatoes',
  boiled_rice_with_vegetables: 'Rice boiled with vegetable assorted',
  dragobrat_potatoes_details: 'baked slices potatoes with lard and garlic',

  mushroom_sauce: 'Mushroom',
  pepper_sauce: 'Pepper',
  sour_cream_sause: 'Sour cream',
  tartar: 'Tar-tar',
  shashlik_sauce: 'Barbecue',
  horseradish: 'Horseradish',
  mushroom_sauce_details: 'porcini mushrooms in sour cream',
  pepper_sauce_details: 'cream, mixture of peppers',

  pancakes_with_honey: 'Nalichniki with honey',
  pancakes_with_jam: 'Nalichniki with jam',
  pancakes_with_cottage_cheese_and_sour_cream_2: 'Nalistniki with curd and sour cream',
  pancakes_with_chocolate: 'Nalichniki with chocolate',
  pancakes_with_condensed_milk: 'Nalichniki with condensed milk',
  apple_strudel_with_ice_cream_and_caramel: 'Strudel apple with ice cream and caramel',
  ice_cream_with_nuts_and_honey: 'Ice cream with nuts and honey',
  ice_cream_with_nuts_and_chocolate: 'Ice cream with nuts and chocolate',
  ice_cream_with_jam: 'Ice cream with jam',

  mors: 'Mors',

  appetizer_1: 'Salmon Tartlets',
  appetizer_1_details: 'Tartlets, cream cheese, salmon, herbs',
  appetizer_2: 'Cheese Plate',
  appetizer_2_details: 'Mozzarella, Parmesan, Roquefort, grapes, nuts',
  appetizer_3: 'Veal Tartare',
  appetizer_3_details: 'Chopped veal, capers, onions, olive oil',
  appetizer_4: 'Salmon Carpaccio',
  appetizer_4_details: 'Salmon, avocado, lemon, pine nuts',
  appetizer_5: 'Meat Antipasti',
  appetizer_5_details: 'Assorted meats, sun-dried tomatoes, olives',

  salad_1: 'Caesar Salad with Chicken',
  salad_1_details: 'Mixed greens, chicken, cherry tomatoes, Parmesan, Caesar dressing',
  salad_2: 'Nicoise Salad',
  salad_2_details: 'Tuna, potatoes, lettuce, olives, cherry tomatoes, anchovies',
  salad_3: 'Avocado and Shrimp Salad',
  salad_3_details: 'Shrimp, avocado, cherry tomatoes, cucumber, honey mustard dressing',
  salad_4: 'Seafood Salad',
  salad_4_details: 'Baby octopuses, calamari, shrimp, mixed greens',
  salad_5: 'Caesar Salad with Salmon',
  salad_5_details: 'Salmon, mixed greens, cherry tomatoes, Parmesan, Caesar dressing',

  soup_1: 'Chicken Broth with Noodles',
  soup_1_details: 'Chicken breast, noodles, carrots, herbs',
  soup_2: 'Mushroom Cream Soup',
  soup_2_details: 'Champignons, cream, carrots, onions, potatoes, herbs',
  soup_3: 'Cheese Cream Soup',
  soup_3_details: 'Mixed cheeses, cream, carrots, onions, potatoes, herbs',
  soup_4: 'Fish Chowder',
  soup_4_details: 'Dorado, salmon, halibut, carrots, potatoes, onions, herbs',
  soup_5: 'Bouillabaisse',
  soup_5_details: 'Mussels, shrimp, salmon, tomatoes, potatoes, spices',

  meat_1: 'Filet Mignon',
  meat_1_details: 'Veal',
  meat_2: 'Goulash',
  meat_2_details: 'Veal, potatoes, carrots, tomatoes',
  meat_3: 'Grilled Chicken',
  meat_3_details: 'Chicken breast, mashed potatoes',
  meat_4: 'Lamb Shashlik',
  meat_4_details: 'Lamb, onions, bell peppers',
  meat_5: 'Schnitzel',
  meat_5_details: 'Pork, mashed potatoes, mixed greens',

  fish_1: 'Grilled Dorado',
  fish_1_details: 'Dorado, mixed vegetables',
  fish_2: 'Baked Salmon',
  fish_2_details: 'Salmon fillet, mixed vegetables',
  fish_3: 'Fish Roll',
  fish_3_details: 'Salmon, potatoes, mozzarella, carrots, tomatoes',
  fish_4: 'Mussels in White Wine Sauce',
  fish_4_details: 'Mussels, garlic, onions, carrots, white wine sauce',
  fish_5: 'Grilled Halibut',
  fish_5_details: 'Halibut, mixed vegetables',

  flour_1: 'Carbonara Pasta',
  flour_1_details: 'Cream, Parmesan, bacon, egg',
  flour_2: 'Pasta with Shrimp',
  flour_2_details: 'Shrimp, cherry tomatoes, wine sauce, Parmesan',
  flour_3: 'Arrabbiata Pasta',
  flour_3_details: 'Tomatoes, chili peppers, basil, garlic, tomato sauce',
  flour_4: 'Bolognese Pasta',
  flour_4_details: 'Beef, tomatoes, onions, garlic, celery',
  flour_5: 'Salmon Pasta',
  flour_5_details: 'Salmon, cherry tomatoes, Parmesan, creamy sauce',

  sides_1: 'French Fries',
  sides_1_details: 'Potatoes',
  sides_2: 'Mashed Potatoes',
  sides_2_details: 'Potatoes, butter, milk',
  sides_3: 'Rice',
  sides_3_details: 'Rice, butter',
  sides_4: 'Grilled Vegetables',
  sides_4_details: 'Bell peppers, carrots, tomatoes, eggplant, zucchini',
  sides_5: 'Fresh Vegetables',
  sides_5_details: 'Cucumber, bell peppers, tomatoes, carrots, herbs',

  desserts_1: 'Napoleon Cake',
  desserts_1_details: 'Puff pastry, custard',
  desserts_2: 'Tiramisu',
  desserts_2_details: 'Savoiardi cookies soaked in creamy coffee cream',
  desserts_3: 'Tres Leches Cake',
  desserts_3_details: 'Creamy, caramel, and chocolate cream',
  desserts_4: 'Red Velvet Cake',
  desserts_4_details: 'Biscuit soaked in creamy frosting',
  desserts_5: 'Apple Strudel',
  desserts_5_details: 'Non-yeast dough, apples, nuts',

  coffee_1: 'Espresso',
  coffee_1_details: '',
  coffee_2: 'Latte',
  coffee_2_details: '',
  coffee_3: 'Iced Latte',
  coffee_3_details: 'Latte, ice',
  coffee_4: 'Americano',
  coffee_4_details: '',
  coffee_5: 'Flat White',
  coffee_5_details: 'Double espresso with milk foam',

  tea_1: 'Black Assam',
  tea_1_details: '',
  tea_2: 'Bergamot',
  tea_2_details: '',
  tea_3: 'Alpine Meadow',
  tea_3_details: '',
  tea_4: 'Berry Mix',
  tea_4_details: '',
  tea_5: '1001 Nights',
  tea_5_details: '',

  beer_1: 'Corona',
  beer_1_details: '',
  beer_2: 'Stella Artois',
  beer_2_details: '',
  beer_3: 'Estrella',
  beer_3_details: '',
  beer_4: 'Bud',
  beer_4_details: '',
  beer_5: 'Carlsberg',
  beer_5_details: '',

  appetizer_for_vodka: 'Snack to pour',
  cheese_plate_td: 'Cheese plate',
  cheese_plate_td_details: 'self-produced cheeses',
  meat_plate: 'Meat slicing',
  meat_plate_details: 'sausages and meat of own production',
  toast_with_lard: 'Toast with cracklings',
  toast_with_lard_details: 'mangalica',
  assorted_salo: 'Slicing fat',
  lavash: 'Lavash',

  tyranochka: 'Turyanochka',
  tyranochka_details: 'lettuce leaves, self-made cheese, cherry tomatoes, honey mustard dressing',
  caesar_salad_details: 'lettuce leaves, chicken fillet, tomatoes, egg, crackers, sauce',
  fresh_vegetable_plate: 'Fresh vegetable slicing',
  greek_salad_2: 'Greek',

  chicken_broth_homemade: 'Broth of homemade chicken',
  mushroom_soup_td: 'Mushroom soup',

  homemade_potatoes: 'Homemade potatoes with lard and onions',
  baked_potatoes: 'Baked potatoes in Turianski style',
  french_fries: 'French fries',
  assorted_garnish: 'Garnish in stock',

  mangalica_steak: 'Mangalica steak',
  mangalica_steak_details: 'pre-order',
  pork_steak: 'Steak',
  pork_steak_details: 'pork neck',
  pork_shashlik: 'Shish kebab',
  pork_shashlik_details: 'pork neck',
  pork_ribs_td: 'Pig ribs',
  chicken_steak_td: 'Chicken steak',
  chicken_shashlik: 'Chicken shish kebab',
  lamb_leg: 'Leg of the lamb',
  lamb_leg_details: 'pre-order',
  lamb_rack: 'Kare lamb',
  homemade_sausages: 'Sausages of own production',
  grilled_vegetables: 'Vegetables on mangali',

  banosh_with_mushrooms_and_pork_rind: 'With cheese and cracklings',
  banosh_with_mushroom_sauce: 'With mushroom machanka',
  banosh_with_mozzarella_and_greens: 'With mozzarella and greens',
  cremeshnit_from_chef: 'From the chief',
  cremeshnit_with_sour_cream: 'With sour cream',
  hutsul_cremeshnit_with_meat: 'In Hutsul with meat',
  cremeshnit_with_mushrooms: 'With mushrooms',

  fresh_pork: 'Fresh',
  chicken_nuggets: 'Chicken nuggets',
  wings_in_honey_sauce: 'Wings in honey sauce',
  chicken_cutlet: 'Chop chicken',

  grilled_trout: 'Trout on mangali',
  carp_steaks: 'Carp steaks',
  grilled_carp: 'Carp on mangali',

  metanit_dumplings_with_pork_rind_and_sour_cream: 'Methani dumplings with cracklings and sour cream',
  dumplings_with_potato_and_sour_cream: 'Dumplings with potatoes of own production with sour cream',

  turianska_family_pizza: 'Turian family',

  assorted_desserts: 'In assortment',

  cocoa: 'Cocoa',
  cocoa_details: 'cocoa',
  juice_assortment: 'Juices in stock',
  water_assortment: 'Water in assortment',
  mineral_water: 'Mineral water',
  lemonade: 'Lemonade',
};
