export const booking = {
  price_per_day: 'Cena za dzień',
  select_room: 'Wybierz pokój',
  per_night: 'za noc',
  dates: 'Daty',
  guests: 'Goście',
  client_info: 'Informacje o kliencie',
  status_label: 'Status',
  choose_dates: 'Wybierz daty',
  choose_date: 'Wybierz datę',
  name_surname: 'Imię i nazwisko',
  phone_number: 'Numer telefonu',
  phone_number_hint: 'Format: +380931234567',
  email: 'Email',
  guests_amount: 'Liczba gości',
  arrival_time: 'Czas przyjazdu',
  available_date: 'Dostępne',
  unavailable_date: 'Niedostępne',
  book_title: 'Proszę wypełnić formularz, aby zarezerwować pokój',
  your_info: 'Twoje informacje',
  comment_hint: 'Jeśli potrzebujesz dodatkowego łóżka, masz dziecko lub masz dodatkowe potrzeby - napisz więcej w komentarzu poniżej',
  cancel_question: 'Czy na pewno chcesz anulować swoje zapytanie o rezerwację?',
  no_booking_available: 'Niestety, nie ma wolnych pokoi w wybranym okresie',

  status: {
    confirmed: 'Potwierdzone',
    active: 'Potwierdzone',
    pending: 'Oczekujące',
    finished: 'Zakończone',
    cancelled: 'Anulowane',
    checked: 'Zameldowany',
    all: 'Wszystkie',
  },

  room: {
    standard: 'Standard',
    demilux: 'Demilux',
    demilux_plus: 'Demilux +',
    economy: 'Ekonomiczny',
    lux: 'Lux',

    double_room_wooden: 'Pokój dwuosobowy (drewniany)',
    double_room_plus_wooden: 'Pokój dwuosobowy+ (drewniany)',
    triple_room_wooden: 'Pokój trzyosobowy (drewniany)',
    family_room_improved_wooden: 'Pokój rodzinny ulepszony (drewniany)',
    family_room_added_entrance_wooden: 'Pokój rodzinny z dodatkowym wejściem (drewniany)',
    double_room_main: 'Pokój dwuosobowy (budynek główny)',
    double_room_plus_main: 'Pokój dwuosobowy+ (budynek główny)',
    triple_room_main: 'Pokój trzyosobowy (budynek główny)',
    family_room_improved_main: 'Pokój rodzinny ulepszony (nad łazienką)',

    td_cottage_1: 'Domek 1',
    td_cottage_2: 'Domek 2',
    td_cottage_3: 'Domek 3',
    td_cottage_4: 'Domek 4',
    td_cottage_5_1: 'Domek 5. Numer 5/1',
    td_cottage_5_2: 'Domek 5. Numer 5/2',
    td_cottage_5_3: 'Domek 5. Numer 5/3',
    td_cottage_5_5: 'Domek 5. Numer 5/5',
    td_cottage_5_6: 'Domek 5. Numer 5/6',
    td_cottage_5_7: 'Domek 5. Numer 5/7',
    td_cottage_5_8: 'Domek 5. Numer 5/8',

    r_standard: 'Pokój Standardowy',
    r_improved: 'Pokój Superior',
    r_demilux: 'Pokój Junior Suite',
    r_lux: 'Apartament Suite',
    r_junior: 'Pokój Junior Suite',
    r_family: 'Pokój Rodzinny',
    r_business: 'Pokój Biznesowy',
    r_president: 'Apartament Prezydencki',

    mountain_double: 'Pokój dwuosobowy z widokiem na góry',
    mountain_quadruple: 'Pokój czteroosobowy z widokiem na góry',
    mountain_triple: 'Pokój trzyosobowy',
    mountain_large_double: 'Duży pokój dwuosobowy',
  }
};
