export const food = {
  categories_title: 'Ви можете замовити їжу та напої до Вашої кімнати, у ресторані або на виніс.',
  breakfast: 'Сніданок',
  appetizers: 'Закуски',
  salads: 'Салати',
  soups: 'Супи',
  meat: 'М\'ясні страви',
  fish: 'Рибні страви',
  hot: 'Гарячі страви',
  grill: 'Страви на грилі',
  drinks: 'Напої',
  kids: 'Дитяче меню',
  sauces: 'Соуси',
  flour: 'Мучні страви',
  sides: 'Гарніри',
  baked: 'Печене',
  desserts: 'Десерти',
  'to-beer': 'До пива',
  pizza: 'Pizza',
  working_hours: 'Ресторан працює з {from} до {to}',
  details: 'Деталі замовлення',
  order_type: 'Де Ви бажаєте отримати замовлення?',
  in_restaurant: 'В ресторані',
  in_room: 'В кімнату',
  to_go: 'З собою',
  in_bath: 'У баню',
  in_vat: 'У чан',
  in_gazebo: 'В альтанку',
  work_time: '*Доставка "{type}" доступна з {from} до {to}',
  ai_generated: '*Зображення страв були згенеровані за допомогою AI та не відповідають справжньому вигляду страв',
  clear_question: 'Ви впевнені що хочете очистити корзину?',
  items: 'Позицій',
  pizza_supplements: 'Доповнення до піци',
  no_selected_pizza: 'Спочатку додайте піцу до корзини',
  supplements_title: 'Оберіть піцу до якої Ви хочете додати {name}',
  fee: '**Додаткова плата: {fee} {currency}',
  fee_label: 'Доплата',

  title: 'Ви можете замовити їжу та напої {places}.',
  places: {
    in_restaurant: 'у ресторані',
    in_room: 'до Вашої кімнати',
    in_bath: 'у баню',
    in_vat: 'у чан',
    to_go: 'на виніс',
  },
  or: 'або',

  start: {
    title_1: 'Замовити улюблені страви ще ніколи не було так просто!',
    title_2: 'Зробіть кілька легких кроків:',
    step_1: 'Почніть з вибору категорії страв, які вас цікавлять.',
    step_2: 'Оберіть страву зі списку та натисніть "Замовити".',
    step_3: 'Натисніть кнопку "і", щоб прочитати детальну інформацію про страву.',
    step_4: 'Перейдіть на сторінку підтвердження, де ви зможете переглянути та відредагувати кількість обраних позицій.',
  }
};
