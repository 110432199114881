<template>
  <loader-panel v-if="isLoading" />
  <div v-else class="panel">
    <page-header :title="categoryName" :back-link="path.includes('beverage') ? '/menu/beverage' : '/menu'" />
    <p v-if="hasImage" class="panel__help">{{ $t('food.ai_generated') }}</p>
    <div class="panel__content">
      <div v-for="[key, items] in Object.entries(itemsByCategories)" :key="key" class="panel__content-container">
        <p v-if="key > 0" class="panel__content-title">{{ categoryNames[key] }}</p>
        <div class="panel__content-list">
          <div v-for="item in items" :key="item.id" class="panel__item" @click="detailsItem = item">
            <div class="panel__item-top">
              <template v-if="item.image">
                <img class="panel__item-top__image" :src="item.imageSrc" :class="{ loading: !item.imageSrc }"
                     alt="img" />
                <p class="panel__item-top__amount">
                  {{ item.prices[item.priceIndex].quantity }} {{ $t(`unit.${ item.prices[item.priceIndex].unit }`) }}
                </p>
              </template>
              <div class="panel__item-top__info"></div>
            </div>
            <div class="panel__item-name">
              <p>{{ getTranslation(item.name) }}</p>
            </div>
            <div v-if="item.prices?.length > 1" class="panel__item-units" :key="item.priceIndex">
              <p v-for="(price, key) in item.prices" :key="key" class="panel__item-units__item"
                 :class="{'panel__item-units__item--selected': key === item.priceIndex}"
                 @click.stop="onUnitClick(item, key)">
                {{ price.quantity }} {{ $t(`unit.${ price.unit }`) }}
              </p>
            </div>
            <p class="panel__item-price">
              {{ item.prices[item.priceIndex].price }} {{ $t(userStore.currency) }}
              <span v-if="!item.image">
            / {{ item.prices[item.priceIndex].quantity }} {{ $t(`unit.${ item.prices[item.priceIndex].unit }`) }}
          </span>
            </p>
            <item-button v-if="!item.amount" :text="$t('button.order')" @click.stop="onOrderClick(item)" />
            <form-number-buttons v-else :value="item.amount" @plus="onPlusClick(item)" @minus="onMinusClick(item)" />
          </div>
        </div>
      </div>
    </div>
    <base-button :class="{'disabled': !menuCart.length}" :text="$t('button.order')" @click="push('/menu/details')" />
  </div>

  <menu-item-details-popup v-if="detailsItem?.id" :item="detailsItem" @order="onOrderClick(detailsItem)"
                           @close="detailsItem = {} as MenuListItem" @plus="onPlusClick(detailsItem)"
                           @minus="onMinusClick(detailsItem)" />
  <supplements-popup v-if="selectedItem?.id" :item="selectedItem" @close="selectedItem = {} as MenuListItem" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import PageHeader from "@/components/Header/PageHeader.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import MenuItemDetailsPopup from "@/components/Popup/MenuItemDetailsPopup.vue";
import ItemButton from "@/components/Button/ItemButton.vue";
import { MenuListItem } from "@/types/App/Cart";
import FormNumberButtons from "@/components/Form/FormNumberButtons.vue";
import { useHotelSetup } from "@/composable/useHotelSetup";
import SupplementsPopup from "@/components/Popup/SupplementsPopup.vue";
import { useUserStore } from "@/stores/userStore";
import { useMenuDishStore } from "@/stores/menuDishStore";
import LoaderPanel from "@/components/LoaderPanel.vue";
import { useTranslation } from "@/composable/useTranslation";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { MenuCategory } from "@/types/HotelSetup";
import { useApi } from "@/composable/useApi";

const { params, path } = useRoute();
const { push } = useRouter();
const userStore = useUserStore();
const hotelSetupStore = useHotelSetupStore();
const appStore = useAppStore();
const { menuCart } = storeToRefs(appStore);
const menuDishStore = useMenuDishStore();
const { dishes } = storeToRefs(menuDishStore);
const { checkFunction } = useHotelSetup();
const { getTranslation } = useTranslation();
const { getApiUrl } = useApi();

const isLoading = ref(true);
const detailsItem = ref<MenuListItem>({} as MenuListItem);
const selectedItem = ref<MenuListItem>({} as MenuListItem);
const menu = ref<MenuListItem[]>([]);

const categories = computed((): MenuCategory[] => hotelSetupStore.hotelSetup.menuCategories);

const categoryId = computed((): number => +params.categoryId);

const categoryName = computed((): string => {
  const category = categories.value.find(i => i.id === categoryId.value);
  if (category?.id) {
    return getTranslation(category.name);
  } else {
    return '';
  }
});

const categoryNames = computed((): { [key: number]: string } => {
  const names: { [key: number]: string } = {};
  categories.value.map(c => names[c.id] = getTranslation(c.name));
  return names;
});

const hasImage = computed((): boolean => !!dishes.value.find(item => !!item.image));

const itemsByCategories = computed((): { [key: string]: MenuListItem[] } => {
  const itemsByCategories: { [key: number]: MenuListItem[] } = {};
  menu.value.map(item => {
    const cartItem = menuCart.value.find(i => i.id === item.id);
    const data = { ...item, amount: cartItem ? cartItem.amount : 0 };
    const subcategory = item?.subcategoryId ? item.subcategoryId : 0;

    if (!itemsByCategories[subcategory]) {
      itemsByCategories[subcategory] = [];
    }

    itemsByCategories[subcategory].push(data);
  });
  return itemsByCategories;
});

const onOrderClick = (item: MenuListItem) => {
  if (item.category?.signature?.includes('supplements')) { // if pizza supplement category
    selectedItem.value = item;
  } else {
    const index = menuCart.value.findIndex(i => i.id === item.id);
    if (index < 0) {
      menuCart.value.push({
        id: item.id,
        name: getTranslation(item.name),
        price: item.prices[item.priceIndex],
        amount: 1,
        category: item.category
      });
    } else {
      menuCart.value[index].amount++;
    }
  }
};

const onPlusClick = (item: MenuListItem) => {
  const cartItem = menuCart.value.find(i => i.id === item.id);
  cartItem && cartItem.amount++;
};

const onMinusClick = (item: MenuListItem) => {
  const index = menuCart.value.findIndex(i => i.id === item.id);
  if (index >= 0) {
    menuCart.value[index].amount--;
    if (menuCart.value[index].amount === 0) {
      menuCart.value.splice(index, 1);
    }
  }
};

const onUnitClick = (item: MenuListItem, index: number) => {
  const itemIndex = menu.value.findIndex(i => i.id === item.id);
  if (itemIndex >= 0) {
    menu.value[itemIndex].priceIndex = index;
  }

  const cartItem = menuCart.value.find(i => i.id === item.id);
  if (cartItem) {
    cartItem.price = item.prices[item.priceIndex];
  }
};

onMounted(async () => {
  checkFunction('food');
  dishes.value = [];

  await menuDishStore.getMenuDishes(categoryId.value);
  menu.value = dishes.value.map(d => ({ ...d, priceIndex: 0, amount: 0 }));
  menu.value.map(i => {
    if (i.image) {
      const image = new Image();
      image.src = getApiUrl() + i.image;
      image.addEventListener('load', () => {
        i.imageSrc = image.getAttribute('src') ?? '';
      });
    }
  });
  isLoading.value = false;
});
</script>

<style scoped lang="scss">
.panel {
  &__help {
    margin-bottom: 1rem;
    @include font(12px, 14px, white, 600);
  }

  &__content {
    @extend .flex-row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 1rem;

    &:not(:has(.panel__item-top__image)) {
      .panel__item-price {
        @include font(16px, 22px, black, 500);
      }

      .panel__item-name {
        height: 3rem;
        margin-bottom: 0.5rem;

        p {
          @include font(18px, 22px, black, 600, center);
        }
      }

      .panel__item-top__info {
        top: -8px;
        right: -8px;
        border-radius: 0 0 0 45%;
      }
    }

    &-title {
      margin-bottom: 0.75rem;
      @include font(18px, 22px, white, 600);
    }

    &-list {
      @extend .flex-row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
    }
  }

  $size: calc((100dvw - 1rem * 2 - 1rem) / 2);

  &__item {
    @extend .col-container;
    flex: 1;
    min-width: $size;
    max-width: $size;
    height: max-content;
    padding: 0.5rem;
    background-color: #FFFFFFCC;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;

    &:has(.panel__item-top__info) .panel__item-name {
      max-width: calc(100% - 2rem);
    }

    &-top {
      position: relative;
      width: 100%;

      &__image {
        width: calc(#{$size} - 1rem);
        min-width: calc(#{$size} - 1rem);
        height: calc(#{$size} * 0.9);
        min-height: calc(#{$size} * 0.9);
        margin-bottom: 0.5rem;
        object-fit: cover;
        border-radius: 4px;

        &.loading {
          background-image: url('@/assets/preload.png');
          background-size: 40%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &__amount {
        position: absolute;
        bottom: 1rem;
        left: 0.25rem;
        padding: 0.25rem;
        @include font(14px, 18px, black, 500, center);
        background-color: #FFFFFF66;
        backdrop-filter: blur(2px);
        border-radius: 4px;
      }

      &__info {
        @extend .circle;
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        @include icon($menu-info, 1.5rem, 1.5rem);
        background-color: #FFFFFF66;
      }
    }

    &-name {
      @extend .row-container;
      height: 2.5rem;
      max-width: 95%;
      margin-bottom: 0.25rem;

      p {
        @extend .clip-line--2;
        @include font(16px, 20px, black, $align: center);
        word-break: break-word;
      }
    }

    &-price {
      margin-bottom: 0.5rem;
      @include font(18px, 22px, black, 500, center);
    }

    &-units {
      @extend .row-container;
      flex-wrap: wrap;
      gap: 6px 12px;
      justify-content: center;
      margin-bottom: 0.5rem;

      &__item {
        padding: 0 6px;
        @include font(14px, 30px, #686868, 500, center);
        border: 1.5px solid #FFFFFF80;
        border-radius: 4px;

        &--selected {
          background-color: white;
          border-color: white;
          color: black;
        }
      }
    }
  }
}
</style>
