import { MenuItemPrintData } from "@/types/App/Menu";
import { useI18n } from "vue-i18n";
import { MenuCartItem } from "@/types/App/Cart";
import { useUserStore } from "@/stores/userStore";
import { HotelSetupFoodDelivery } from "@/types/HotelSetup";

export const useMenu = () => {
  const i18n = useI18n();
  const userStore = useUserStore();

  const getMenuPrintItems = (orderType: HotelSetupFoodDelivery, requestItems: MenuCartItem[]) => {
    let total = 0;
    const items: MenuItemPrintData[] = [{
      name: i18n.t('summary.article'),
      amount: i18n.t('summary.amount'),
      price: i18n.t('summary.price'),
      totalPrice: i18n.t('summary.total'),
    }];

    requestItems.map((item: MenuCartItem) => {
      const nameTexts = [
        i18n.t(`menu.${ item.name }`),
        `(${ item.price.quantity }`,
        `${ i18n.t(`unit.${ item.price.unit }`) })`
      ];
      if (item.index !== undefined && item.index >= 0) {
        nameTexts.splice(1, 0, `№${ item.index + 1 }`);
      }

      let itemPrice = item.price.price;
      if (item.supplements?.length) {
        item.supplements.map(s => itemPrice += s.prices[s.priceIndex].price * s.amount)
      }

      items.push({
        name: nameTexts.join(' '),
        amount: `${ item.amount }`,
        price: `${ item.price.price }`,
        totalPrice: `${ item.amount * itemPrice } ${ i18n.t(userStore.currency) }`
      });

      if (item.supplements?.length) {
        item.supplements.map(s => {
          items.push({
            name: '+ ' + i18n.t(`menu.${ s.name }`),
            amount: `${ s.amount }`,
            price: `${ s.prices[s.priceIndex].price }`,
            totalPrice: `${ item.amount * s.prices[s.priceIndex].price } ${ i18n.t(userStore.currency) }`
          });
        });
      }

      total += item.amount * itemPrice;
    });

    if (orderType.fee) {
      const fee = +(orderType.fee ?? 0);
      total += fee;
      items.push({
        name: i18n.t('food.fee_label'),
        amount: '',
        price: '',
        totalPrice: `${ fee } ${ i18n.t(userStore.currency) }`
      });
    }

    items.push({ name: '', amount: '', price: '', totalPrice: `${ total } ${ i18n.t(userStore.currency) }` });

    return items;
  }

  return { getMenuPrintItems };
}
