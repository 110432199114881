<template>
  <div class='field' :class="`field--${color}`">
    <p v-if="label" class="field__label">{{ label }}</p>
    <div class='field__input select' ref='item' :class='selectClass' @click.stop='onClick'>
      <p v-if="modelValue || (isIndex && modelValue >= 0)" class='select__value'>{{ selectedOptionLabel }}</p>
      <p v-else-if="placeholder" class='field__placeholder'>{{ placeholder }}</p>
      <div class='select__arrow' :class="{'select__arrow--turned': isActive}"></div>
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
    <transition :name="`show-${direction}`">
      <div v-if='isActive' class='select__options' v-bind='optionsParams'>
        <div class='select__options-list'>
          <div v-for='(option, key) in options' :key='key' class='select__options-item' @click='onChange(option.value)'>
            <p class='select__options-item__text'>{{ option.label }}</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, PropType, ref } from 'vue';
import { OptionParams, SelectOption } from "@/types/App/Form";
import { useFormItem } from "@/composable/useFormItem";

const props = defineProps({
  label: String,
  placeholder: String,
  hint: String,
  options: {
    type: Array as PropType<SelectOption[]>,
    default: () => ([]),
  },
  modelValue: {
    type: [Number, String],
    default: null,
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
  canCancel: Boolean,
  isIndex: Boolean,
});
const emits = defineEmits(['update:modelValue']);

const { isActive, item, getFloatingPosition, setListeners, deleteListeners } = useFormItem();

const direction = ref('down');
const optionsParams = ref<OptionParams>({} as OptionParams);

const selectClass = computed((): { [key: string]: boolean } => {
  return {
    'select--active': isActive.value, // || !!props.selectedOption,
  };
});

const selectedOptionLabel = computed((): string => {
  return props.options.find(item => item.value === props.modelValue)?.label ?? '';
});

const onClick = () => {
  optionsParams.value = getFloatingPosition(Math.min(props.options.length, 6), 36);
  direction.value = optionsParams.value?.class ? optionsParams.value.class : direction.value;
  isActive.value = !isActive.value;
};

const onChange = (value: string | number) => {
  if (props.canCancel) {
    emits('update:modelValue', props.modelValue === value ? '' : value);
  } else {
    emits('update:modelValue', value);
  }
  isActive.value = false;
};

onMounted(setListeners);
onUnmounted(deleteListeners);
</script>

<style lang='scss' scoped>
@import "@/assets/styles/components/form/select";
</style>
