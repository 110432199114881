import moment from "moment/moment";
import { ref } from "vue";
import { useTime } from "@/composable/useTime";

export interface CalendarItem {
  day: string;
  date: number;
  fullDate: string;
  status: 'active' | 'inactive';
  isFilled?: boolean;
}

export const useCalendar = () => {
  const { currentLocale } = useTime();

  const days = ref<string[]>([]);
  const dates = ref<CalendarItem[][]>([]);

  const setDates = (selectedDate: string) => {
    days.value = [];
    dates.value = [];

    for (let i = 1; i <= 7; i++) {
      days.value.push(moment(selectedDate).locale(currentLocale.value).day(i).format('dd'));
    }

    const current = moment(selectedDate);
    const items: CalendarItem[][] = [[]];
    for (let i = 1; i <= current.daysInMonth(); i++) {
      const date = moment(selectedDate).date(i);
      items[items.length - 1].push({
        day: date.format('dd'),
        date: i,
        status: 'active',
        fullDate: date.format('YYYY-MM-DD')
      });
      if (i !== current.daysInMonth() && date.day() === 0) {
        items.push([]);
      }
    }

    if (items[0].length < 7) {
      const newItems: CalendarItem[] = [];
      for (let j = 7 - items[0].length - 1; j >= 0; j--) {
        const date = moment(selectedDate).date(-j);
        newItems.push({
          day: date.format('dd'),
          date: date.date(),
          status: 'inactive',
          fullDate: date.format('YYYY-MM-DD')
        });
      }

      items[0].splice(0, 0, ...newItems);
    }

    if (items[items.length - 1].length < 7) {
      const newItems: CalendarItem[] = [];
      for (let j = 0; j < 7 - items[items.length - 1].length; j++) {
        const date = moment(selectedDate).date(current.daysInMonth() + j + 1);
        newItems.push({
          day: date.format('dd'),
          date: date.date(),
          status: 'inactive',
          fullDate: date.format('YYYY-MM-DD')
        });
      }

      items[items.length - 1].push(...newItems);
    }

    dates.value = items;
  };

  return { days, dates, setDates };
}
