export const notification = {
  active: {
    title: 'Zaakceptowane żądanie',
    text: 'Twoje żądanie zostało zaakceptowane',
    details: 'Szczegóły',
  },
  cancelled: {
    title: 'Odrzucone żądanie',
    text: 'Niestety, Twoje żądanie zostało odrzucone.',
    details: 'Powód',
  },
  finished: {
    title: 'Zakończone żądanie',
    text: 'Twoje żądanie zostało zakończone',
    details: 'Szczegóły',
  }
};
