<template>
  <div class="panel__content">
    <p class="panel__title">{{ $t('hotel') }} {{ getTranslation(client.organization?.name) }}</p>
    <p class="panel__subtitle">{{ getTranslation(room.type?.name) }}</p>
    <p class="panel__price">{{ room.type?.price }} {{ $t(userStore.currency) }} {{ $t('booking.per_night') }}</p>
    <div class="panel__info">
      <p class="panel__info-label">{{ $t('booking.dates') }}:</p>
      <p class="panel__info-value">
        {{ moment(data.dateFrom).format('DD.MM.YY') }} - {{ moment(data.dateTo).format('DD.MM.YY') }}
      </p>
    </div>
    <div class="panel__info">
      <p class="panel__info-label">{{ $t('booking.your_info') }}:</p>
      <p class="panel__info-value">{{ data.name }}</p>
      <p class="panel__info-value">{{ data.email }}</p>
      <p class="panel__info-value">{{ data.phone }}</p>
    </div>
    <div v-if="data.comment" class="panel__info">
      <p class="panel__info-label">{{ $t('comment') }}:</p>
      <p class="panel__info-value">{{ data.comment }}</p>
    </div>
    <div class="panel__total">
      <p class="panel__total-text">{{ $t('summary.total') }}:</p>
      <p class="panel__total-text">
        {{ room.type?.price * moment(data.dateTo).diff(moment(data.dateFrom), 'days') }} {{ $t(userStore.currency) }}
      </p>
    </div>
  </div>
  <base-button :text="$t('button.reserve')" @click="onReserveClick" />
  <error-popup v-if="isError" :text="bookingStore.error" @close="isError = false" />
</template>

<script setup lang="ts">
import { useRoomStore } from "@/stores/roomStore";
import { storeToRefs } from "pinia";
import { PropType, ref } from "vue";
import { BookingCreateData } from "@/types/Booking";
import { useUserStore } from "@/stores/userStore";
import BaseButton from "@/components/Button/BaseButton.vue";
import moment from "moment";
import { useBookingStore } from "@/stores/bookingStore";
import { useRender } from "@/composable/useRender";
import { useRouter } from "vue-router";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useTime } from "@/composable/useTime";
import { useTranslation } from "@/composable/useTranslation";

const props = defineProps({
  data: {
    type: Object as PropType<BookingCreateData>,
    default: () => ({})
  }
});

const { push } = useRouter();
const userStore = useUserStore();
const { client } = storeToRefs(userStore);
const roomStore = useRoomStore();
const { room } = storeToRefs(roomStore);
const bookingStore = useBookingStore();
const { renderNotification } = useRender();
const { getTranslation } = useTranslation();

const isError = ref(false);

const onReserveClick = async () => {
  await bookingStore.createBooking(props.data);
  if (!bookingStore.error) {
    setTimeout(() => renderNotification('booking_success'), 100);
    push('/');
  } else {
    isError.value = true;
  }
};
</script>

<style scoped lang="scss">
.panel {
  &__content {
    gap: 0 !important;
  }

  &__title {
    margin-bottom: 0.5rem;
    @include font(24px, 30px, white, 600);
  }

  &__subtitle {
    margin-bottom: 0.5rem;
    @include font(18px, 22px, white, 600);
  }

  &__price {
    margin-bottom: 1.5rem;
    @include font(18px, 22px, white, 400);
  }

  &__info {
    @extend .flex-col;
    margin-bottom: 14px;
    padding-bottom: 2px;
    border-bottom: 1px solid white;

    &-label {
      margin-bottom: 0.75rem;
      @include font(18px, 22px, white, 400);
    }

    &-value {
      margin-bottom: 0.75rem;
      @include font(18px, 22px, white, 500);
    }
  }

  &__total {
    @extend .flex-row;
    justify-content: space-between;

    &-text {
      @include font(22px, 26px, white, 600);
    }
  }
}
</style>
