export const beverage = {
  title: 'Напитки',
  coffee_tea: 'Кофе и чай',
  coffee: 'Кофе',
  tea: 'Чай',
  beer: 'Пиво',
  cocktails: 'Коктейли',
  non_alcohol_cocktails: 'Безалкогольные коктейли',
  alcohol: 'Алкогольные напитки',
  non_alcohol_drinks: 'Безалкогольные напитки',
  bottle: 'Бутылочное',
  glass: 'На розлив',
  brandy: 'Коньяк',
  horilka: 'Водка',
  whiskey: 'Виски',
  liqueur: 'Ликер',
  wine: 'Вино',
  champagne: 'Шампанское',
  other: 'Другое',
  hot_drinks: 'Горячие напитки',
  cold_drinks: 'Прохлаждающие напитки',
  long_drink: 'Long Drink',
  shooters: 'Shooters',
  extreme: 'Extreme',
  short_drink: 'Short Drink',
  cognac: 'Коньяк',
  vodka: 'Водка',
  liqueurs: 'Ликеры',
  infusions: 'Настойки',
  gin: 'Джин',
  rum: 'Ром',
  martini: 'Мартини',
  soft_drinks: 'Безалкогольные напитки',
  fresh: 'Фреши',
  lemonades: 'Лимонады'
};
