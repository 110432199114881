export const menu = {
  //subcategories
  cold_appetizers: 'Холодні закуски',
  hot_appetizers: 'Гарячі закуски',
  poultry_dishes: 'Страви з птиці',
  veal_dishes: 'Страви з телятини',
  pork_dishes: 'Страви з свинини',
  chief_specialties: 'Фірмові страви від шефа',
  ust_cold_appetizers: '"Куркульські запаси" (холодні закуски)',
  ust_salads: '"Від душі"',
  ust_soups: '"Полуденок опришка" (перші страви)',
  ust_bread: 'Хлібний кошик',
  ust_main_dishes: '"З пилу-жару" (другі страви)',
  ust_caucasian_guest: '"Кавказький гість"',
  ust_fish: '"Зі ставу до столу"',
  ust_hot: '"Рідненьке"',
  ust_sides: '"Не м\'ясом єдиним"',
  ust_dessert_with_tea: '"Біля чаю не скучаю..."',
  ust_homemade_pastry: '"Випічка домашня"',
  ust_cold_desserts: '"Солодка прохолода"',
  banosh_with_cream: 'Банош на вершках',
  cremeshnit: 'Кремзлики',

  // supplement categories
  sauces_supp: 'Соуси',
  vegetables_supp: 'Овочеві',
  meats_supp: 'М\'ясні',
  cheeses_supp: 'Сирні',

  caesar_chicken: 'Салат "Цезар" з куркою',
  caesar_salmon: 'Салат "Цезар" з лососем',
  caesar_shrimp: 'Салат "Цезар" з креветками',
  warm_salad: 'Теплий салат з курячою печінкою ',
  cabbage_salad: 'Салат з капусти',
  veg_assorted: 'Асорті овочеве',
  greek_salad: 'Салат "Грецький"',
  marinated_assorted: 'Мариноване асорті',
  caesar_chicken_details: 'Салат айсберг, філе курки, перепелине яйце, помідори, пармезан, сухарики',
  caesar_salmon_details: 'Салат айсберг, помідори, пармезан, сухарики,лосось, перепелине яйце',
  caesar_shrimp_details: 'Салат айсберг, креветки, чері, огірок, пармезан, кунжут, оливкова олія, лимон',
  warm_salad_details: 'Мікс салатів, печінка куряча, спеції, оливкова олія',
  veg_assorted_details: 'Помідори, огірки, перець, капуста',

  broth: 'Бульйон з локшиною',
  broth_details: 'Куряча грудинка, овочі',
  cheese_creamy_soup: 'Сирний крем суп з грінками',
  borsch: 'Борщ',
  cold_soup: 'Холодний літній суп',
  bograch: 'Бограч',
  mushroom_soup: 'Грибова юшка',
  minestrone: 'Мінестроне',

  barbecue_pork: 'Шашлик зі свинини',
  barbecue_chicken: 'Шашлик з курятини',
  veg_grill: 'Овочі печені на грилі',
  stake_pork: 'Стейк із свинячого ошийка',
  stake_chicken: 'Стейк з курячої грудинки',
  sausage_grill: 'Ковбаска домашня запечена',
  ribs_grill: 'Ребра запечені',
  potato_grill: 'Картопля печена на плоті',
  meat_set: 'М′ясна дошка для гурту (на 6 осіб)',
  barbecue_chicken_details: 'Куряче бедро',
  potato_grill_details: 'З цибулею та домашньою олією',
  meat_set_details: 'Шашлик зі свинини, шашлик з курятини, ребра, ковбаски',

  eggs: 'Яєчня',
  bacon_eggs: 'Яєчня з беконом',
  omelet_cheese: 'Омлет з сиром',
  omelet_veg: 'Омлет з овочевим соте',
  euro: 'Європейський сніданок',
  euro_details: 'Яйця, сосиски, квасоля, масло, грінки',
  crepes: 'Млинці в асортименті',
  oatmeal: 'Вівсяна каша з фруктами',
  cheesecakes: 'Сирники з повидлом\\сметаною',
  pancakes: 'Панкейки',

  meat_antipasti: 'М\'ясне антипасті',
  cheese_plate: 'Сирна тарілка (на 4 осіб)',
  cheese_plate_details: 'Камамбер, пармезан, моцарелла міні, дорблю, горіховий',
  trio_salo: 'Тріо сала',
  salmon_bruschetta: 'Брускети з лососем',
  avocado_bruschetta: 'Брускети з авокадо та в\'яленим томатом',
  prosciutto_bruschetta: 'Брускети з прошуто та крем-сиром',

  tenderloin: 'Вирізка по "Королівськи"',
  chicken_caprese: 'Курка "Капрезе"',
  meat_lozzi: 'М\'ясо "Лоці печене"',

  carp: 'Короп смажений на мангалі',
  trout: 'Форель по-селянськи (на пательні)',
  trout_details: 'Цибуля, кукурудзяна крупа',
  trout_grill: 'Форель на грилі',
  salmon_spinach: 'Філе лосося на шпинатній подушці',
  dorado_grill: 'Дорадо на грилі',
  dorado_veg: 'Філе дорадо з овочами та лаймовим соусом',

  varenyky_potato: 'Вареники з картоплею та сметаною',
  varenyky_meat: 'Вареники з шкварками та цибулею',
  home_dumplings: 'Пельмені по-домашньому з маслом',
  pasta: 'Паста з креветками та цукіні',
  spaghetti_carbonara: 'Спагетті "Карбонара"',
  spaghetti_fettuccine: 'Спагетті "Фетучіні"',
  spaghetti_carbonara_details: 'Бекон, вершки, пармезан',
  spaghetti_fettuccine_details: 'Гриби, вершки, пармезан',

  deruny_meat: 'Деруни з м′ясом',
  deruny_cream: 'Деруни з сметаною',
  deruny_mushroom: 'Деруни з грибами та сметаною',
  risotto_mushroom: 'Різотто з грибами',
  risotto_shrimp: 'Різотто з креветками',
  brynza_banosh: 'Банош з бринзою та шкварками',
  pan: 'Сковорідка по-домашньому',
  pan_chicken: 'Сковорідка з курячими сердечками та печінкою',
  pan_details: 'Картопля, ковбаса домашня, цибуля, сир, яйця, вершки',
  pan_chicken_details: 'Сердечка, печінка, печериці, сир, вершки',

  satsebeli: 'Соус сацебелі',
  ketchup: 'Кетчуп',
  pishta: 'Пішта',

  oatmeal_kids: 'Вівсяна каша з маслом',
  choco_balls: '"ШоКо кульки" з молоком',
  buckwheat: 'Каша гречана з маслом',
  potato_kids: 'Картопля відварна з вершковим маслом',
  rice_butter: 'Рис з маслом',

  espresso_oriental: 'Еспресо "Oriental"',
  americano_oriental: 'Американо "Oriental"',
  viennese_coffee: 'Кава по-віденськи',
  cappuccino: 'Капучіно натуральне',
  latte: 'Лате',
  perfetto: 'Перфетто',
  iced_coffee: 'Ice coffee',
  cacao: 'Какао',
  tea: 'Чай заварний',
  tea_fruit: 'Чай фруктовий',
  cappuccino_details: 'Еспресо, молоко',
  latte_details: 'Еспресо, молоко',
  perfetto_details: 'Еспресо, молоко, морозиво, бейліз, вершки',
  cacao_details: 'Шоколад, збите молоко',

  pilsner: 'Pilsner',
  kellerbier: 'Kellerbier',
  non_alcohol_beer: 'Безалкогольне пиво',
  staropramen: 'Staropramen',
  heineken: 'Heineken',
  carpathian: 'Закарпатське',
  no_filter: 'Пиво нефільтроване',
  staropramen_details: 'ж\\б',

  citro: 'Розливний лимонад, квас',
  juice: 'Соки',
  hell: 'Hell',
  lu: 'Лужанська',
  morshyn: 'Моршинська',
  coca: 'Кола',
  pepsi: 'Пепсі',
  glass: 'скло',

  metaxa_brandy: '"Метакса"',
  old_kakheti_brandy: '"Старий Кахеті"',
  zakarpatsky_brandy: '"Закарпатський"',
  tisa_brandy: '"Тиса"',
  uzhgorod_brandy: '"Ужгород"',
  carpati_brandy: '"Карпати"',
  atenos_brandy: '"Атенос"',
  riga_balsam: 'Бальзам "Ріга"',
  tequila: 'Напій "Текіла"',
  absinthe: 'Напій "Абсент"',
  becherovka: 'Напій "Бехеровка"',
  campari: 'Напій "Кампарі"',
  captain_morgan: 'Ром "Captain Morgan"',
  jagermaster: 'Бальзам "Єгермастер"',
  soplitsa: 'Настоянка "Сопліца"',
  starka_reserv: 'Настоянка "Starka Reserv"',
  tyorkina: 'Наливка "Тьоркіна"',
  finland: 'Фінляндія',
  zubrovka_charna: 'Зубровка Чарна',
  zubrovka: '"Зубровка"',
  lvivska: '"Львівська"',
  lviv_gold: '"Lvoff Gold"',
  jameson: '"Джеймсон"',
  chivas_regal: '"Чівас Регал"',
  jack_daniels: '"Джек Деніелс"',
  red_label: '"Ред Лейбл"',
  sambuca: '"Самбука"',
  baileys: '"Бейліс"',
  amaretto: '"Амаретто"',
  wine_chevalier_de_france: '"Chevalier de France"',
  wine_carpathian: '"Закарпатське"',
  wine_villa_puccini: '"Villa Puccini"',
  wine_sarsitano: '"Сарсітано"',
  champagne_fragolino: '"Фраголіно"',
  champagne_martini_asti: '"Мартіні Асті"',
  champagne_prosecco: '"Proseco"',
  pc_3: '3 шт',
  pc_4: '4 шт',
  pc_5: '5 шт',

  mojito_non_alcohol: 'Мохіто',
  citro_fruit: 'Лимонад фруктовий',
  milk_shake: 'Мілк шейк',
  in_assortment: 'в асортименті',

  pina_collada: 'Піна Колада',
  hirosima: 'Хіросіма',
  sunrise: 'Схід',
  envy: 'Заздрість',
  green_coast: 'Зелений берег',
  rome_sunset: 'Ромовий захід',
  cuba_libre: 'Куба лібре',
  tom_collins: 'Том Коллінз',
  pink_cat: 'Рожева кішечка',
  garibaldi: 'Гарибальді',
  tequila_boom: 'Текіла бум',
  man_size: 'Чоловічий розмір',
  mojito: 'Мохіто',
  aperol_syringe: 'Апероль Шприц',
  hurricane: 'Ураган',
  green_fairy: 'Зелена фея',
  long_island: 'Лонг айленд',
  daiquiri_bella_donna: 'Дайкірі Белла Донна',
  pina_collada_details: 'ананасовий сік, білий ром, лікер кокосовий',
  hirosima_details: 'сироп гренадін, самбука, лікер "Бейліс", абсент',
  sunrise_details: 'сік апельсиновий, сироп гренадін, текіла',
  envy_details: 'ананасовий сік, текіла, лікер "Blu curacao"',
  green_coast_details: 'лимонний сік, лимон, абсент, текіла',
  rome_sunset_details: 'вишневий сік, ром, апельсин',
  cuba_libre_details: 'ром, кола, лайм',
  tom_collins_details: 'джин, сік лимонний, сироп, содова вода',
  pink_cat_details: 'ананасовий сік, джин, гренадін, грейпфрутовий сік',
  garibaldi_details: 'апельсиновий сік, настоянка "Кампарі", апельсин',
  tequila_boom_details: 'текіла, спрайт',
  man_size_details: 'абсент, гренадін, ананасовий сік, лікер "Pisang ambon"',
  mojito_details: 'ром, спрайт, лайм, сироп, м\'ята',
  aperol_syringe_details: 'шампанське сухе, апероль, спрайт, апельсин',
  hurricane_details: 'ром білий, ром чорний, настоянка "Кампарі", сік: лимон, ананас, апельсин; сироп',
  green_fairy_details: 'текіла, абсент, горілка, ром білий, лікер "blue curacao", сік, енергетик, лікер диня',
  long_island_details: 'джин, горілка, ром білий, текіла, лікер тріпл-сек, сік, кока-кола',
  daiquiri_bella_donna_details: 'лікер "Амаретто", ром чорний, сік лимонний',

  meat_in_french: 'М\'ясо по-французьки',
  chop: 'Відбивна',
  shank: 'Голяшка',
  egg: 'Яєшня',
  omelet: 'Омлет',

  dumplings: 'Пельмені',
  potato_varenyky: 'Вареники із картоплею',
  cheese_crepes: 'Млинці з творогом',
  sweet_crepes: 'Млинці із згущеним молоком',
  bread: 'Хліб',
  banosh: 'Банош',

  veg_soup: 'Суп овочевий',
  mug_broth: 'Бульйон у кружці',
  chicken_broth: 'Бульйон з курячого філе',
  uha: 'Уха',
  borshch: 'Борщ',
  solyanka: 'Солянка',

  assorted_salad: 'Салат "Асорті"',
  caesar: 'Салат "Цезар"',
  salted_cucumbers: 'Солені огірки',
  sauerkraut: 'Квашена капуста',

  assorted_meat: 'М\'ясна нарізка',
  assorted_cheese: 'Сирна нарізка',
  salo: 'Сало',
  ham: 'Шинка',

  potato_french: 'Картопля фрі',
  potato_mashed: 'Картопляне пюре',
  potato_salo: 'Картопля з салом',
  potato_grilled: 'Картопля на мангалі',
  rice: 'Рис',
  kremzlyky: 'Кремзлики зі сметаною',

  sea_bass_fish: '"Сібас"',
  dorado_fish: '"Дорадо"',
  trout_fish: '"Форель"',
  carp_fish: '"Короп"',
  salmon_stake: 'Стейк лосося',
  pork_kebab: 'Шашлик свинячий',
  pork_stake: 'Стейк свинячий',
  pork_rib: 'Ребра свинячі',
  chicken_kebab: 'Шашлик курячий',
  chicken_stake: 'Стейк курячий',
  sausages: 'Ковбаски',
  sturgeon_stake: 'Стейк осетра',
  sturgeon_fish: '"Осетр"',

  kebab_sauce: 'Соус до шашлику',
  brynza: 'Бринза',
  mayo: 'Майонез',
  mustard: 'Гірчиця',

  ice_cream: 'Морозиво',
  fruit_dessert: 'Десерт фруктовий',
  fruit_assorted: 'Фруктова нарізка',
  lemon: 'Лимон',

  cheese_pigtail: 'Сир коса',
  chips: 'Чипси',
  salted_nut: 'Горішки солоні',

  fanta: 'Фанта',
  sprite: 'Спрайт',
  mineral_water_glass: 'Мінеральна вода (скло)',
  sandora_juice: 'Сік "Сандора"',
  uzvar: 'Узвар',
  sitro: 'Ситро',

  espresso: 'Кава еспресо',
  cappuccino_natural: 'Капучино натуральне',
  americano: 'Американо',
  cappuccino_lafesta: 'Капучино "Лафеста',
  coffee_3_in_1: 'Кава "3 в 1"',
  custard_tea: 'Чай заварний',
  cream: 'Вершки',

  brandy_zakarpatsky: '"Закарпатський"',
  brandy_shereuli: '"Шереулі"',
  whiskey: 'Віскі',
  horilka_hemiroff_premium: '"Німіров Преміум"',
  horilka_davnya_legenda: '"Давня Легенда"',
  champagne_asti: '"Асті"',
  wina_old_carpatia: '"Олд Карпатія"',
  wina_alaverdi: '"Алаверді"',
  wina_homemade: 'Вино домашнє',
  beer_lvivske_svitle: '"Львівське Світле"',
  beer_tubourg: '"Туборг"',
  beer_non_alcohol: 'Безалкогольне',
  beer_carlsberg: '"Карлсберг"',

  taistra_hutsula: 'Тайстра гуцула',
  cheese_plateau: 'Сирне плато',
  vegetable_palety: 'Овочева палітра',
  meat_taire: 'М\'ясний таїр',
  homemade_marinada: 'Домашні маринади',
  croutons_in_transcarpathian: 'Грінки по-закарпатськи',
  assorted_pickled_mushrooms: 'Асорті маринованих грибів',
  white_mushroom_with_bowl: 'Гриб білий з цибулею',
  herring_at_home: 'Оселедець по-домашньому',
  machantsi: 'Мачанці',
  tongue_in_cream_sauce: 'Язик у вершковому соусі',
  transcarpathian_cabbage_rolls: 'Голубці по-закарпатськи',
  banosh_with_bryndza: 'Банош з бринзою',
  banosh_with_cep_mushrooms: 'Банош з білими грибами',
  pancakes_with_meat_and_mushroom_sauce: 'Млинці з м\'ясом та грибним соусом',
  lavash_with_cheese_and_tomatoes: 'Лаваш із сиром та помідорами',
  lavash_with_chicken: 'Лаваш з куркою',
  taistra_hutsula_details: 'сало мариноване, сало копчене, сало поприкаж, грінки, огірки мариновані',
  cheese_plateau_details: 'сир «Моцарела бейбі», сир «Голандський», сир «Фета», бринза, мед',
  vegetable_palety_details: 'огірок, помідор, болгарський перець, капуста',
  meat_taire_details: 'буженина, язик яловичий, домашня ковбаса, хрін, соус',
  homemade_marinada_details: 'маринований огірок, маринований помідор, маринована слива, квашена капуста',
  croutons_in_transcarpathian_details: 'паштет курячий, паштет печінковий, хумус, грінки, цибулевий маринад',
  assorted_pickled_mushrooms_details: 'опеньки, лисички, білий гриб, корбан',
  herring_at_home_details: 'картопля (100гр), оселедець (100гр), цибуля (30гр)',
  machantsi_details: 'білі гриби у сметані',
  tongue_in_cream_sauce_details: '100/100гр',
  transcarpathian_cabbage_rolls_details: '200/50гр',
  pancakes_with_meat_and_mushroom_sauce_details: '200/50гр',

  caesar_default: 'Цезар',
  royal: 'Королівський',
  bukovinian: 'По-буковинськи',
  verkhovynskyi: 'Верховинський',
  caprese: 'Капрезе',
  salad_with_grilled_vegetables_and_chicken: 'Салат з овочами гриль та курятиною',
  warm_beef_salad: 'Теплий салат з телятиною',
  gracia: 'Грація',
  caesar_default_details: 'мікс салату, куряче філе, помідори, сир «Пармезан»',
  royal_details: 'опеньки, шинка, яйце, майонез, цибуля',
  bukovinian_details: 'буряк, мікс салату, сир «Фета», соус «Песто», грічично-медова заправка',
  verkhovynskyi_details: 'огірки, помідори, перець болгарський, бринза, часник, сметана',
  caprese_details: 'помідори, сир «Моцарела», мікс салату, соус «Песто»',
  salad_with_grilled_vegetables_and_chicken_details: 'мікс салату, овочі гриль, курятина, медово-гірчичний соус',
  warm_beef_salad_details: 'мікс салату, смажений болгарський перець, телятина',
  gracia_details: 'огірки, помідори, куряче філе, шампіньйони, майонез, грецький горіх',

  bogrash: 'Бограш',
  bogrash_in_bread_pot: 'Бограш в хлібному глечику',
  fish_soup: 'Юшка рибна',
  chicken_broth_2: 'Бульйон курячий',
  carpathian_mushroom_soup: 'Карпатська грибна юшка',

  whisper_of_the_carpathians_with_gnocchi: '"Шепіт Карпат" з ньокі',
  chicken_fillet_in_egg: 'Куряче філе в яйці',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce: 'Куряча грудка на овочевій подушці під сирним соусом',
  chicken_in_verkhovynskyi_style: 'Чірке по-верховинськи',
  chicken_steak: 'Курячий стейк',
  chicken_steak_details: 'на вагу',
  medallions_with_mushroom_sauce_and_vegetable_puree: 'Медальйони з грибним соусом та овочевим пюре',
  beef_stroganoff: 'Бефстроганов',
  veal_with_chanterelles_in_sour_cream_sauce: 'Телятина з лисичками в сметанному соусі',
  cutlet: 'Відбивна',
  meat_in_poloninsky_style: 'М\'ясо по-полонинськи',
  cossack_power: '"Козацька сила"',
  loci_baked: 'Лоці печене',
  shashlik_by_weight: 'Шашлик на вагу',
  gazdivski_ribs_by_weight: 'Газдівські ребра на вагу',
  meat_set_for_company: 'М\'ясний сет на компанію',
  shank_by_weight: 'Голяшка на вагу',
  lamb_leg_by_weight: 'Бараняча ніжка на вагу',
  duck_with_apples_and_orange_sauce_by_weight: 'Качка з яблуками та апельсиновим соусом на вагу',
  whisper_of_the_carpathians_with_gnocchi_details: 'курка (150гр), гриби (100гр), сир (50гр)',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce_details: '300/50гр',
  chicken_in_verkhovynskyi_style_details: 'куряче філе, гриби, сметана',
  medallions_with_mushroom_sauce_and_vegetable_puree_details: '150/100/50гр',
  beef_stroganoff_details: 'телятина (100гр), цибуля та сметана (75гр)',
  veal_with_chanterelles_in_sour_cream_sauce_details: '150/100гр',
  cutlet_details: '100/50гр',
  meat_in_poloninsky_style_details: 'свинина під овочево-вершковим соусом, 100/100гр',
  cossack_power_details: 'стейк свинний на вагу',
  loci_baked_details: 'свинина під цибулевою шубою, 100/50гр',
  gazdivski_ribs_by_weight_details: 'свинні ребра під кисло-солодким соусом',
  meat_set_for_company_details: 'мисливські ковбаски, ковбаски гриль, стейк свинний, стейк курячий, ребра, запечена картопля, 1000/150гр',
  shank_by_weight_details: 'голяшка та домашні маринади, 1000/600гр',
  lamb_leg_by_weight_details: 'маринована в травах та запечена у тісті',
  duck_with_apples_and_orange_sauce_by_weight_details: '1000/150гр',

  baked_trout_by_weight: 'Форель запечена на вагу',
  trout_in_cream_sauce_by_weight: 'Форель у вершковому соусі на вагу',

  skillet_with_vegetables_and_chicken: 'Сковорідка з овочами та куркою',
  skillet_with_vegetables_and_veal: 'Сковорідка з овочами та телятиною',
  vegetable_skillet: 'Сковорідка овочева',
  skillet_with_homemade_sausage_and_stewed_cabbage: 'Сковорідка з домашньою ковбасою та тушкованою капустою',

  kremzlyky_with_sour_cream: 'Кремзлики з сметаною',
  kremzlyky_with_mushrooms: 'Кремзлики з грибами',
  kremzlyky_hutsul_style: 'Кремзлики по-гуцульськи',
  dumplings_with_potatoes_and_sour_cream: 'Вареники з картоплею та сметаною',
  homemade_dumplings: 'Пельмені по-домашньому',
  kremzlyky_with_mushrooms_details: '200/100гр',
  homemade_dumplings_details: '200/50гр',

  mashed_potatoes: 'Картопля пюре',
  homestyle_potatoes: 'Картопля по-домашньому',
  baked_potatoes_with_bacon_and_brynza: 'Картопля запечена, з салом та бринзою',
  creole_potatoes: 'Картопля креольська',
  broccoli_with_butter: 'Брокколі з маслом',
  buckwheat_with_porcini_mushrooms: 'Гречка з білими грибами',
  assorted_roasted_vegetables_by_weight: 'Печені овочі в асортименті на вагу',
  baked_potatoes_with_bacon_and_brynza_details: '200/50гр',

  slalom_pizza: 'Slalom',
  athens_pizza: 'Афінська',
  vegetarian_pizza: 'Вегетаріанська',
  hawaiian_pizza: 'Гавайська',
  cheese_pizza: 'Сирна',
  mushroom_pizza: 'Грибна',
  spicy_pizza: 'Гостра',
  beer_pizza: 'До пива',
  calzone_sausage_pizza: 'Кальцоне з ковбасою',
  bechamel_pizza: 'Бешамель',
  carbonara_pizza: 'Карбонара',
  kyiv_pizza: 'Київська',
  cossack_pizza: 'Козацька',
  margherita_pizza: 'Маргарита',
  milanese_pizza: 'Міланська',
  four_seasons_pizza: '4-сезони',
  meat_pizza: 'М\'ясна',
  pepperoni_pizza: 'Пепероні',
  assorted_roasted_vegetables_by_weight_details: 'помідори, перець, цукіні, цибуля, печериці',
  slalom_pizza_details: 'томатний соус, сир "Моцарелла", свинина, ковбаса, буженина, печериці, яйце, кукурудза, перець болгарський, бекон, орегано',
  athens_pizza_details: 'томатний соус, сир "Моцарелла", сир "Фета", шинка, бекон, куряче філе, салямі, помідори, печериці, часник, зелень',
  vegetarian_pizza_details: 'томатний соус, сир "Моцарелла", овочі, орегано',
  hawaiian_pizza_details: 'томатний соус, сир "Моцарелла", сир "Моцарелла", ананаси, ковбаса, шинка, кукурудза, орегано',
  cheese_pizza_details: 'томатний соус, сир "Моцарелла", сир "Пармезан", сир "Рокфор", сир "Мисливський"',
  mushroom_pizza_details: 'томатний соус, сир "Моцарелла", печериці, яйце, цибуля, орегано',
  spicy_pizza_details: 'томатний соус, сир "Моцарелла", сир, перець болгарський, майонез, часник, перець чилі, салямі, печериці, цибуля маринована, орегано',
  beer_pizza_details: 'томатний соус, сир "Моцарелла", орегано',
  calzone_sausage_pizza_details: 'томатний соус, сир "Моцарелла", шинка, ковбаса, помідори, кукурудза, орегано',
  bechamel_pizza_details: 'вершковий соус, шинка, філе куряче, бекон, буженина, шампіньйони, сир "Моцарела"',
  carbonara_pizza_details: 'томатний соус, сир "Моцарелла", помідори, яйце, бекон, орегано',
  kyiv_pizza_details: 'томатний соус, сир "Моцарелла", свинина, куряче філе, перець болгарський, помідори, печериці, орегано',
  cossack_pizza_details: 'томатний соус, сир "Моцарелла", буженина, бекон, часник, печериці, кукурудза, зелень',
  margherita_pizza_details: 'томатний соус, сир "Моцарелла", помідори, орегано',
  milanese_pizza_details: 'томатний соус, сир "Моцарелла", куряче філе, помідори, кукурудза, яйце, орегано',
  four_seasons_pizza_details: 'томатний соус, шинка, куряче філе, бекон, шампіньони, салямі "Пепероні", ковбаски мисливські, сир "Моцарелла"',
  meat_pizza_details: 'томатний соус, сир "Моцарелла", буженина, бекон, ковбаски мисливські, перець болгарський, кукурудза, орегано',
  pepperoni_pizza_details: 'томатний соус, сир "Моцарелла", салямі пепероні, печериці, перець чилі, каперси, орегано',

  basturma: 'Бастурма',
  mahan: 'Махан',
  beer_tray: 'Пивний танір',
  spicy_ears: 'Вуха пікантні',
  wings_in_honey_soy_sauce: 'Крильця в медово-соєвому соусі',
  peanut: 'Арахіс',
  beer_tray_details: 'махан, бастурма, філе золотисте, буженина',

  pancakes_with_creme_and_chocolate: 'Млинці з вершками та шоколадом',
  pancakes_with_nuts_and_chocolate: 'Млинці з горіхом та шоколадом',
  pancakes_with_apples: 'Млинці з яблуками',
  pancakes_with_cottage_cheese_and_sour_cream: 'Млинці з творогом та сметаною',
  syrniki_with_jam_and_sour_cream: 'Сирники з джемом та сметаною',
  prunes_with_condensed_milk: 'Чорнослив із згущеним молоком',
  tiramisu: 'Тірамісу',
  cheesecake: 'Чізкейк',
  chocolate_fondant: 'Шоколадний фондан',
  apple_strudel_with_ice_cream: 'Штрудель з яблуком та морозивом',
  jam: 'Варення',
  natural_honey: 'Мед натуральний',
  ice_cream_cone: 'Стаканчик до морозива',
  assorted_fruits: 'Фрукти в асортименті',
  student_chocolate: 'Студентська шоколадка',
  dark_chocolate: 'Чорний шоколад',
  pancakes_with_creme_and_chocolate_details: '100/100гр',
  pancakes_with_nuts_and_chocolate_details: '200/50гр',
  pancakes_with_apples_details: '200/50гр',
  pancakes_with_cottage_cheese_and_sour_cream_details: '200/50гр',
  syrniki_with_jam_and_sour_cream_details: '150/50/50гр',
  prunes_with_condensed_milk_details: '150гр',
  tiramisu_details: '150гр',
  cheesecake_details: '150гр',
  chocolate_fondant_details: '100/50гр',
  apple_strudel_with_ice_cream_details: '100/50гр',

  hot_chocolate: 'Гарячий шоколад',
  milk: 'Молоко',
  matcha: 'Матча',
  green_tea: 'Зелений чай',
  black_tea: 'Чорний чай',
  herbal_tea: 'Трав\'яний чай',
  fruit_tea: 'Фруктовий чай',
  tea_with_ginger_and_raspberry: 'Чай з імбиром та малиною',
  tea_with_sea_buckthorn_ginger_and_orange: 'Чай з обліпихою, імбирем та апельсином',
  tea_with_blackberry_and_blueberry: 'Чай з ожиною та чорницею',

  chernigivske_bottle: 'Чернігівське пляшка',
  stella_bottle: 'Стелла пляшка',
  stella_non_alcoholic: 'Стелла б/а',
  corona_0_3l: 'Корона 0.3 л',
  stella_draft_0_5l: 'Стела бочка 0.5 л',

  tequila_sunrise: 'Текіла санрайз',
  long_island_ice_tea: 'Лонг айленд айс ті',
  sex_on_the_beach: 'Секс на пляжі',
  aperol: 'Апероль',
  green_mexican: 'Зелений мексиканець',
  merzavchik: 'Мерзавчик',
  meduza: 'Медуза',
  control_shot: 'Контрольний вистріл',
  northern_hunter: 'Північний мисливець',
  b_52: 'Б-52',
  b_53: 'Б-53',
  bmw: 'BMW',
  sambuka: 'Самбука',
  margarita: 'Маргарита',
  malibu: 'Малібу',
  whiskey_cola: 'Віскі-кола',
  gin_tonic: 'Джин-тонік',
  aperol_details: 'апероль, шампанське, газована вода',
  tequila_sunrise_details: 'текіла, апельсиновий сік, гренадін',
  long_island_ice_tea_details: 'горілка, ром, джин, текіла, тріпл-сек, кола',
  sex_on_the_beach_details: 'горілка, персиковий лікер, апельсиновий сік',
  mojito_details_2: 'білий ром, лайм, м’ята, спрайт, цукор',
  green_mexican_details: 'пізан, текіла, лимонний фреш',
  merzavchik_details: 'горілка, сік, гренадін',
  meduza_details: 'малібу, тріпл-сек, білий ром, бейліс',
  control_shot_details: 'калуа, самбука, абсент',
  northern_hunter_details: 'калуа, самбука, єгермейстер, горілка',
  b_52_details: 'калуа, бейліс, тріпл-сек',
  b_53_details: 'калуа, бейліс, абсент',
  bmw_details: 'бейліс, малібу, віскі',
  tequila_boom_details_2: 'текіла, лимонний сік, спрайт',
  sambuka_details: 'самбука, кориця, кавові зерна',
  margarita_details: 'текіла, тріпл-сек, лимонний фреш',
  malibu_details: 'малібу, вершки, апельсиновий сік',
  whiskey_cola_details: 'ред лейбл, кока-кола',
  gin_tonic_details: 'джин, тонік, лимон',

  milk_cocktail: 'Молочний коктейль',
  coffee_cocktail: 'Кавовий коктейль',
  milk_berry_cocktail: 'Молочно-ягідний коктейль',
  banana_cocktail: 'Банановий коктейль',

  artemivske_champagne: 'Артемівське в асорт.',
  asti_pl_italia_champagne: 'Asti пл. Italia',
  lambrusco_italia_champagne: 'Lambrusco Italia',
  prosecco_champagne: 'Proseco',
  chilean_wine_wine: 'Чілійське вино 100г',
  italian_wine_wine: 'Італійське вино 100г',
  georgian_wine_wine: 'Грузинське вино 100г',
  chateau_chizay_wine: 'Шато Чизай витр.100г',
  mulled_wine_wine: 'Глінтвейн 200г',
  tisa_cognac: 'Тиса 50г',
  uzhgorod_cognac: 'Ужгород 50г',
  karpaty_cognac: 'Карпати 50г',
  zakarpatskyi_cognac: 'Закарпатський 50г',
  ararat_cognac: 'Арарат',
  hennessy_cognac: 'Hennessy',
  martell_cognac: 'Martell',
  khortytsia_vodka: 'Хортиця 50г',
  nemiroff_vodka: 'Nemiroff 50г',
  finlandia_vodka: 'Фінляндія 50г',
  absolut_vodka: 'Absolut 50г',
  lvivska_vodka: 'Львівська 50г',
  baileys_liqueurs: 'Baileys 50г',
  malibu_liqueurs: 'Malibu 50г',
  sheridans_liqueurs: 'Sheridans 50г',
  jim_beam_whiskey: 'Jim Beam 50г',
  jack_daniels_whiskey: 'Jack Daniels 50г',
  jameson_whiskey: 'Jameson 50г',
  medleys_whiskey: 'Medley’s  50г',
  ballantines_whiskey: 'Ballantine’s 50г',
  jagermeister_infusions: 'Єгермейстер 50г',
  becherovka_infusions: 'Бехеровка 50г',
  tequila_infusions: 'Текіла 50г',
  absinthe_infusions: 'Абсент 50г',
  malinivka_infusions: 'Малинівка 50г',
  zubrovka_infusions: 'Зубрівка 50г',
  kalganyvka_infusions: 'Калганівка 50г',
  khrinivka_infusions: 'Хрінівка 50г',
  beefeater_gin: 'Beefeater 50г',
  black_rum: 'Black 50г',
  gold_rum: 'Gold 50г',
  martini_martini: 'Мартіні 50г',

  mirinda: 'Мірінда',
  seven_up: '7up',
  shweppes: 'Швепс',
  shepit_karpat_water: 'Вода "Шепіт Карпат"',
  morshynska: 'Моршинська 1.5л',
  juice_assorted: 'Сік в асортименті',
  mojito_non_alcoholic: 'Мохіто безалкогольний',
  orange_fresh: 'Апельсиновий 200гр',
  carrot_apple_fresh: 'Моркв\'яно-яблучний 200гр',
  apple_fresh: 'Яблучний 200гр',
  grapefruit_fresh: 'Грейфруктовий 200гр',
  mix_vegetable_fruit_fresh: 'Мікс (овочі-фрукти) 200гр',
  beetroot_fresh: 'Буряковий 200гр',
  lemon_fresh: 'Лимонний 200гр',
  lemon_lemonade: 'Лимонний 200гр',
  mojito_lemonade: 'Мохіто 200гр',
  orange_sea_buckthorn_lemonade: 'Апельсин-обліпиха 200гр',
  forest_berries_lemonade: 'Лісові ягоди 200гр',
  maracuja_strawberry_raspberry_lemonade: 'Маракуя-полуниця-малина 200гр',

  mayonnaise: 'Майонез',
  yerosh_pishto: 'Єрош Пішто',
  sour_cream: 'Сметанний',
  spicy_garlic: 'Гострий часниковий',
  tomatoes: 'Помідори',
  mushrooms: 'Печериці',
  bell_pepper: 'Перець болгарський',
  corn: 'Кукурудза',
  canned_pineapple: 'Ананас (консерв.)',
  olives: 'Маслини',
  onion: 'Цибуля',
  chili_pepper: 'Перець чилі',
  garlic: 'Часник',
  one_egg: 'Яйце',
  bacon: 'Бекон',
  buzhenina: 'Буженина',
  sausage: 'Ковбаса',
  hunting_sausages: 'Мисливські ковбаски',
  salami_sausage: 'Ковбаса "Салямі"',
  salami_pepperoni: 'Салямі пепероні',
  chicken_fillet: 'Філе куряче',
  mozzarella_cheese: 'Сир "Моцарелла"',
  parmesan_cheese: 'Сир "Пармезан"',
  feta_cheese: 'Сир "Фета"',
  roquefort_cheese: 'Сир "Рокфор"',
  hunting_cheese: 'Сир "Мисливський"',

  homemade_salo: 'Сальце домашнє',
  sausage_figurines: '"Ковбасні фіглі"',
  gazda_s_cut: 'Нарізка "По-газдівськи"',
  cheese_cut: 'Сирна нарізка',
  herring_do_charochky: 'Оселедець "До чарочки"',
  salted_mushrooms: '"Солоний погрібок"',
  basket_of_mushrooms: '"Кошик білих"',
  gazda_s_cut_details: 'ковбаса домашня, сало копчене, свинина запечена',
  salted_mushrooms_details: 'солоні огірочки та капуста, мариновані помідори',
  basket_of_mushrooms_details: 'білі гриби мариновані',

  zvernyhora: 'Звернигора',
  beet_salad: 'Бурячок',
  carrot_salad: 'Моркв\'яний',
  zvernyhora_details: 'капуста білокачанна, морква, зелень, олія',

  gutsul_soup: '"Гуцульська юшка" з білих грибів',
  zakarpatskyi_bograch: 'Бограч "Закарпатський"',
  homemade_borscht_with_smetana: 'Домашній борщик зі сметаною',
  kogut_dance: '"Когутячий танець"',
  vegetable_soup: '"Вам дівчата" овочевий супчик',
  solyanka_set: 'Солянка збірна',
  white_bread: 'Хліб білий',
  grey_bread: 'Хліб сірий',
  rye_bread: 'Хліб житній',
  kogut_dance_details: 'курячий бульйон з м’ясом та локшиною',
  vegetable_soup_details: 'овочевий супчик',

  wild_dances: '"Дикі танці"',
  gutsul_taistra: '"Гуцульська тайстра"',
  fried_homemade_sausage: 'Смажена домашня ковбаска',
  pork_steak_on_bone: 'Свинна корейка на кістці',
  bear_paw: '"Ведмежа лапа"',
  pork_ribs: 'Свинні реберця',
  beef_steak_with_mushroom_sauce: 'Стейк яловичий з грибним соусом',
  chicken_steak_with_tartar_sauce: 'Курячий стейк з соусом "Тар-тар"',
  pork_shashlik_with_sauce: 'Шашлик свиний із соусом',
  wild_dances_details: 'піджарка зі свинини з цибулею та грибами',
  gutsul_taistra_details: 'філе курки з грибами та сиром',
  bear_paw_details: 'свинина обсмажена в сирній паніровці',
  pork_shashlik_with_sauce_details: '200/50/50гр',

  baked_trout: 'Форель запечена',
  baked_mackerel: 'Скумбрія запечена на вогні',

  dumplings_with_meat_and_mushroom_sauce: 'Налисники з м’ясом та грибним соусом',
  dumplings_with_potatoes_bryndza_and_sour_cream: 'Вареники з картоплею, бринзою, шкварками та сметаною',
  dumplings_with_potatoes_and_butter_with_sour_cream: 'Вареники з картоплею, маслом та сметаною',
  dumplings_with_potatoes_and_mushroom_sauce: 'Вареники з картоплею та грибним соусом',
  homemade_dumplings_2: 'Пельмені домашні',
  deruny_with_sour_cream: 'Деруни зі сметаною',
  deruny_with_mushroom_sauce: 'Деруни з грибним соусом',
  puzo_gutsula: '"Пузо гуцула"',
  poloninsky_kulesha: 'Кулеша по-полонинськи',
  banosh_with_bryndza_and_shkvarky: 'Банош з бринзою та шкварками',
  puzo_gutsula_details: 'деруни з м’ясом і грибним соусом',
  poloninsky_kulesha_details: 'кукурудзяна каша з бринзою та шкварками',

  dragobrat_potatoes: 'Картопля "По-Драгобратськи"',
  mashed_potato: 'Картопляне пюре',
  boiled_rice_with_vegetables: 'Рис відварений з овочевим асорті',
  dragobrat_potatoes_details: 'запечена дольками картопля з салом та часником',

  mushroom_sauce: 'Грибний',
  pepper_sauce: 'Перцевий',
  sour_cream_sause: 'Сметана',
  tartar: 'Тар-тар',
  shashlik_sauce: 'Шашличний',
  horseradish: 'Хрін',
  mushroom_sauce_details: 'білі гриби в сметані',
  pepper_sauce_details: 'вершки, суміш перців',

  pancakes_with_honey: 'Налисники з медом',
  pancakes_with_jam: 'Налисники з джемом',
  pancakes_with_cottage_cheese_and_sour_cream_2: 'Налисники з творогом та сметаною',
  pancakes_with_chocolate: 'Налисники з шоколадом',
  pancakes_with_condensed_milk: 'Налисники з згущеним молоком',
  apple_strudel_with_ice_cream_and_caramel: 'Штрудель яблучний з морозивом і карамеллю',
  ice_cream_with_nuts_and_honey: 'Морозиво з горішками та медом',
  ice_cream_with_nuts_and_chocolate: 'Морозиво з горішками та шоколадом',
  ice_cream_with_jam: 'Морозиво з джемом',

  mors: 'Морс',

  appetizer_1: 'Тарталетки з лососем',
  appetizer_1_details: 'Тарталетки, крем-сир, лосось, зелень',
  appetizer_2: 'Сирна тарілка',
  appetizer_2_details: 'Моцарела, пармезан, дорблю, виноград, горіхи',
  appetizer_3: 'Тартар з телятини',
  appetizer_3_details: 'Рублена телятина, каперси, цибуля, оливкова олія',
  appetizer_4: 'Карпачо з лосося',
  appetizer_4_details: 'Лосось, авокадо, лимон, кедровий горіх',
  appetizer_5: 'Антипасти м’ясні',
  appetizer_5_details: 'Асорті м’яса, в’ялені помідори, оливки',

  salad_1: 'Цезар з куркою',
  salad_1_details: 'Мікс салату, курка, помідори черрі, пармезан, соус Цезар',
  salad_2: 'Салат Нісуаз',
  salad_2_details: 'Тунець, картопля, лист салату, оливки, помідори черрі, анчоуси',
  salad_3: 'Салат з авокадо та креветками',
  salad_3_details: 'Креветки, авокадо, помідори черрі, огірок, гірчично-медова заправка',
  salad_4: 'Салат з морепродуктами',
  salad_4_details: 'Бейбі восьминоги, кальмари, креветки, мікс салату',
  salad_5: 'Цезар з лососем',
  salad_5_details: 'Лосось, мікс салату, помідори черрі, пармезан, соус Цезар',

  soup_1: 'Курячий бульйон з локшиною',
  soup_1_details: 'Куряче філе, локшина, морква, зелень',
  soup_2: 'Грибний крем-суп',
  soup_2_details: 'Шампіньйони, вершки, морква, цибуля, картопля, зелень',
  soup_3: 'Сирний крем-суп',
  soup_3_details: 'Мікс сирів, вершки, морква, цибуля, картопля, зелень',
  soup_4: 'Рибна юшка',
  soup_4_details: 'Дорадо, лосось, палтус, морква, картопля, цибуля, зелень',
  soup_5: 'Буябез',
  soup_5_details: 'Мідії, креветки, лосось, томати, картопля, спеції',

  meat_1: 'Філе Міньйон',
  meat_1_details: 'Яловичина',
  meat_2: 'Гуляш',
  meat_2_details: 'Яловичина, картопля, морква, помідори',
  meat_3: 'Курка-гриль',
  meat_3_details: 'Куряче філе, картопляне пюре',
  meat_4: 'Шашлик з баранини',
  meat_4_details: 'Баранина, цибуля, болгарський перець',
  meat_5: 'Шніцель',
  meat_5_details: 'Свинина, картопляне пюре, мікс салатів',

  fish_1: 'Грильований Дорадо',
  fish_1_details: 'Дорадо, мікс овочів',
  fish_2: 'Запечений лосось',
  fish_2_details: 'Філе лосося, мікс овочів',
  fish_3: 'Рибний рулет',
  fish_3_details: 'Лосось, картопля, моцарела, морква, помідори',
  fish_4: 'Мідії у винному соусі',
  fish_4_details: 'Мідії, часник, цибуля, морква, винний соус',
  fish_5: 'Грильований палтус',
  fish_5_details: 'Палтус, мікс овочів',

  flour_1: 'Паста Карбонара',
  flour_1_details: 'Вершки, Пармезан, бекон, яйце',
  flour_2: 'Паста з креветками',
  flour_2_details: 'Креветки, червоні помідори, винний соус, Пармезан',
  flour_3: 'Паста Арабіата',
  flour_3_details: 'Помідори, перець, базилік, часник, томатний соус',
  flour_4: 'Паста Болоньєзе',
  flour_4_details: 'Яловичина, помідори, цибуля, часник, селера',
  flour_5: 'Паста з Лососем',
  flour_5_details: 'Лосось, червоні помідори, Пармезан, вершковий соус',

  sides_1: 'Картопля фрі',
  sides_1_details: 'Картопля',
  sides_2: 'Картопляне пюре',
  sides_2_details: 'Картопля, вершкове масло, молоко',
  sides_3: 'Рис',
  sides_3_details: 'Рис, вершкове масло',
  sides_4: 'Овочі на грилі',
  sides_4_details: 'Болгарський перець, морква, помідори, баклажан, цукіні',
  sides_5: 'Свіжі овочі',
  sides_5_details: 'Огірок, перець, помідори, морква, зелень',

  desserts_1: 'Торт "Наполеон"',
  desserts_1_details: 'Листкове тісто, заварний крем',
  desserts_2: 'Тірамісу',
  desserts_2_details: 'Печиво савоярді, крем з кави',
  desserts_3: 'Три молока',
  desserts_3_details: 'Вершковий, карамельний та шоколадний креми',
  desserts_4: 'Торт "Червоний Бархат"',
  desserts_4_details: 'Бісквіт, просочений вершковим кремом',
  desserts_5: 'Штрудель з яблуками',
  desserts_5_details: 'Тісто без дріжджів, яблука, горіхи',

  coffee_1: 'Еспресо',
  coffee_1_details: '',
  coffee_2: 'Латте',
  coffee_2_details: '',
  coffee_3: 'Айс-Латте',
  coffee_3_details: 'Латте, лід',
  coffee_4: 'Американо',
  coffee_4_details: '',
  coffee_5: 'Флет Уайт',
  coffee_5_details: 'Подвійний еспресо з молочною пінкою',

  tea_1: 'Чорний Ассам',
  tea_1_details: '',
  tea_2: 'Бергамот',
  tea_2_details: '',
  tea_3: 'Альпійська Луговина',
  tea_3_details: '',
  tea_4: 'Ягідний Мікс',
  tea_4_details: '',
  tea_5: '1001 Ніч',
  tea_5_details: '',

  beer_1: 'Corona',
  beer_1_details: '',
  beer_2: 'Stella Artois',
  beer_2_details: '',
  beer_3: 'Estrella',
  beer_3_details: '',
  beer_4: 'Bud',
  beer_4_details: '',
  beer_5: 'Carlsberg',
  beer_5_details: '',

  appetizer_for_vodka: 'Закуска до наливочки',
  cheese_plate_td: 'Сирна тарілка',
  cheese_plate_td_details: 'сири власного виробництва',
  meat_plate: 'М’ясна нарізка',
  meat_plate_details: 'ковбаски та м’ясо власного виробництва',
  toast_with_lard: 'Тости з шкварками',
  toast_with_lard_details: 'мангалиця',
  assorted_salo: 'Нарізка сала',
  lavash: 'Лаваш',

  tyranochka: 'Туряночка',
  tyranochka_details: 'листя салату, сир власного виробництва, помідори чері, медово-гірчична заправка',
  caesar_salad_details: 'листя салату, куряче філе, помідори, яйце, сухарики, соус',
  fresh_vegetable_plate: 'Свіжа овочева нарізка',
  greek_salad_2: 'Грецький',

  chicken_broth_homemade: 'Бульйон з домашньої курки',
  mushroom_soup_td: 'Грибна юшка',

  homemade_potatoes: 'Картопля по-домашньому з салом і цибулею',
  baked_potatoes: 'Печена картопля по-Турянськи',
  french_fries: 'Картопля фрі',
  assorted_garnish: 'Гарнір в асортименті',

  mangalica_steak: 'Стейк з мангалиці',
  mangalica_steak_details: 'попереднє замовлення',
  pork_steak: 'Стейк',
  pork_steak_details: 'свинячий ошийок',
  pork_shashlik: 'Шашлик',
  pork_shashlik_details: 'свинячий ошийок',
  pork_ribs_td: 'Ребра свині',
  chicken_steak_td: 'Стейк курячий',
  chicken_shashlik: 'Шашлик курячий',
  lamb_leg: 'Ніжка ягня',
  lamb_leg_details: 'попереднє замовлення',
  lamb_rack: 'Каре ягня',
  homemade_sausages: 'Ковбаски власного виробництва',
  grilled_vegetables: 'Овочі на мангалi',

  banosh_with_mushrooms_and_pork_rind: 'З бринзою і шкварками',
  banosh_with_mushroom_sauce: 'З грибною мачанкою',
  banosh_with_mozzarella_and_greens: 'З моцарелою і зеленню',
  cremeshnit_from_chef: 'Від шефа',
  cremeshnit_with_sour_cream: 'Зі сметаною',
  hutsul_cremeshnit_with_meat: 'По-Гуцульськи з мясом',
  cremeshnit_with_mushrooms: 'З грибами',

  fresh_pork: 'Свіжина',
  chicken_nuggets: 'Нагетси курячі',
  wings_in_honey_sauce: 'Крила в медовому соусі',
  chicken_cutlet: 'Відбивна куряча',

  grilled_trout: 'Форель на мангалi',
  carp_steaks: 'Стейки коропа',
  grilled_carp: 'Короп на мангалi',

  metanit_dumplings_with_pork_rind_and_sour_cream: 'Метанi галушки з шкварками і сметаною',
  dumplings_with_potato_and_sour_cream: 'Вареники з картоплею власного виробництва з сметаною',

  turianska_family_pizza: 'Турянська сімейна',

  assorted_desserts: 'В асортиментi',

  cocoa: 'Какао',
  cocoa_details: 'какао',
  juice_assortment: 'Соки в асортименті',
  water_assortment: 'Вода в асортименті',
  mineral_water: 'Мінеральна вода',
  lemonade: 'Лимонад',
};
