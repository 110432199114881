export const taxi = {
  location: 'Pick up location',
  where_to: 'Where to go?',
  when: 'When?',
  choose_time: 'Choose time',
  regular: 'Regular',
  comfort: 'Comfort',
  comment: 'If you have some additional request (car seat, van, bus etc.), leave a comment:',
  order_guide: 'If you need to order a transfer/taxi, please contact by this number.',
  from: 'From',
  to: 'To',
  type: 'Type',
};
