<template>
  <div class="amount" :class="`amount--${color}`">
    <div class="amount__minus" :class="{'disabled': minValue !== null && value <= minValue}"
         @click.stop="onMinusClick"></div>
    <p class="amount__text">{{ value }}</p>
    <div class="amount__plus" :class="{'disabled': maxValue !== null && value <= maxValue}"
         @click.stop="onPlusClick"></div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  value: Number,
  minValue: {
    type: Number,
    default: null,
  },
  maxValue: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'main',
    enum: ['main', 'white']
  }
});
const emits = defineEmits(['plus', 'minus']);

const onPlusClick = () => emits('plus');

const onMinusClick = () => emits('minus');
</script>

<style scoped lang="scss">
.amount {
  @extend .row-container;
  justify-content: center;
  height: 2.5rem;

  &--main .amount {
    &__plus, &__minus {
      background-color: $primary !important;
    }
  }

  &--white .amount {
    &__plus, &__minus {
      background-color: white !important;
    }
  }

  &__plus, &__minus {
    border-radius: 3px;

    &.disabled {
      pointer-events: none;
      opacity: 0.25;
    }
  }

  &__plus {
    @include icon($plus, 2.25rem, 1.5rem);
  }

  &__minus {
    @include icon($minus, 2.25rem, 1.5rem);
  }

  &__text {
    width: 2rem;
    margin: 0 0.25rem;
    @include font(18px, 22px, black, 600, center);
  }
}
</style>
