export const beverage = {
  title: 'Напої',
  coffee_tea: 'Кава та чай',
  coffee: 'Кава',
  tea: 'Чай',
  beer: 'Пиво',
  cocktails: 'Коктейлі',
  non_alcohol_cocktails: 'Безалкогольні коктейлі',
  alcohol: 'Алкогольні напої',
  non_alcohol_drinks: 'Безалкогольні напої',
  bottle: 'Пляшкове',
  glass: 'На розлив',
  brandy: 'Коньяк',
  horilka: 'Горілка',
  whiskey: 'Віскі',
  liqueur: 'Лікер',
  wine: 'Вино',
  champagne: 'Шампанське',
  other: 'Інше',
  hot_drinks: 'Гарячі напої',
  cold_drinks: 'Прохолоджуючі напої',
  long_drink: 'Long Drink',
  shooters: 'Shooters',
  extreme: 'Extreme',
  short_drink: 'Short Drink',
  cognac: 'Коньяк',
  vodka: 'Горілка',
  liqueurs: 'Лікери',
  infusions: 'Настойки',
  gin: 'Джин',
  rum: 'Ром',
  martini: 'Мартіні',
  soft_drinks: 'Безалкогольні напої',
  fresh: 'Фреші',
  lemonades: 'Лимонади'
};
