<template>
  <div class="panel__content">
    <p class="panel__title">{{ $t('booking.book_title') }} <b><i>"{{ getTranslation(room.type?.name) }}"</i></b></p>
    <form-calendar-range :min="moment().format('YYYY-MM-DD')" :exclude="excludedDates" :key="calendarKey"
                         :initial="{ dateFrom: data.dateFrom ?? '', dateTo: data.dateTo ?? ''}"
                         @update:modelValue="setDates" />
    <form-select v-model="data.guests" :label="$t('booking.guests_amount')" :options="guestsOptions"
                 :class="{'disabled': !data.dateFrom || !data.dateTo}" />
    <form-time v-model="data.arrivalTime" :label="$t('booking.arrival_time')" :initial-date="null"
               :selected="data.arrivalTime" :class="{'disabled': !data.dateFrom || !data.dateTo}" />
    <form-input v-model="data.email" :label="$t('booking.email')" :regexp="emailRegex"
                :class="{'disabled': !data.dateFrom || !data.dateTo}" />
    <form-input v-model="data.phone" :label="$t('booking.phone_number')" :regexp="phoneRegex"
                :class="{'disabled': !data.dateFrom || !data.dateTo}" />
    <p class="panel__hint">{{ $t('booking.comment_hint') }}</p>
    <form-textarea v-model="data.comment" :label="$t('comment')"
                   :class="{'disabled': !data.dateFrom || !data.dateTo}" />
  </div>
  <base-button :class="{'disabled': !isButtonActive}" :text="$t('button.continue')" @click="$emit('submit', data)" />
</template>

<script setup lang="ts">
import moment from "moment/moment";
import FormSelect from "@/components/Form/FormSelect.vue";
import FormTime from "@/components/Form/FormTime.vue";
import FormInput from "@/components/Form/FormInput.vue";
import FormCalendarRange from "@/components/Form/FormCalendarRange.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { computed, onMounted, ref } from "vue";
import { FormPeriod, SelectOption } from "@/types/App/Form";
import { useRegex } from "@/composable/useRegex";
import { useRoomStore } from "@/stores/roomStore";
import { storeToRefs } from "pinia";
import { useBookingStore } from "@/stores/bookingStore";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import { BookingCreateData } from "@/types/Booking";
import { useUserStore } from "@/stores/userStore";
import { useRender } from "@/composable/useRender";
import { useTranslation } from "@/composable/useTranslation";

const props = defineProps({
  initial: {
    type: Object,
    default: () => ({})
  }
});
defineEmits(['submit']);

const userStore = useUserStore();
const { client } = storeToRefs(userStore);
const roomStore = useRoomStore();
const { room } = storeToRefs(roomStore);
const bookingStore = useBookingStore();
const { bookingSlots } = storeToRefs(bookingStore);
const { phoneRegex, emailRegex } = useRegex();
const { renderNotification } = useRender();
const { getTranslation } = useTranslation();

const data = ref<BookingCreateData>({
  roomId: 0,
  name: client.value.name,
  phone: userStore.client.organization?.settings?.countryCode ?? '+380',
  email: '',
  dateFrom: '',
  dateTo: '',
  guests: 0,
  comment: '',
  arrivalTime: '',
  dateOfBirth: '',
});
const guestsOptions = ref<SelectOption[]>([]);
const calendarKey = ref(0);

const isButtonActive = computed((): boolean => {
  const { name, phone/*, email*/, dateFrom, dateTo, guests } = data.value;
  return !!(name && phone /*&& email*/ && dateFrom && dateTo && guests);
});

const roomSlots = computed((): string[][] => {
  if (room.value.id > 0) {
    const dates: string[][] = [];
    Object.values(bookingSlots.value).map(roomSlots => {
      dates.push([]);
      roomSlots.map(s => {
        for (let i = 0; i <= moment(s.dateTo).diff(moment(s.dateFrom), 'days'); i++) {
          dates[dates.length - 1].push(moment(s.dateFrom).add(i, 'days').format('YYYY-MM-DD'));
        }
      });
    });
    return dates;
  }

  return [];
});

const excludedDates = computed((): string[] => {
  return roomSlots.value.reduce((acc, curr) => acc.filter(date => curr.includes(date)));
});

const isOutsidePeriod = (target: FormPeriod, period: FormPeriod): boolean => {
  return new Date(target.dateTo) < new Date(period.dateFrom) || new Date(target.dateFrom) > new Date(period.dateTo);
}

const setDates = (value: FormPeriod) => {
  for (const key in bookingSlots.value) {
    if (bookingSlots.value[key].every(period => isOutsidePeriod(value, period))) {
      data.value.dateFrom = value.dateFrom;
      data.value.dateTo = value.dateTo;
      data.value.roomId = +key;
      return;
    }
  }

  data.value.dateFrom = '';
  data.value.dateTo = '';
  data.value.roomId = 0;
  calendarKey.value++;
  setTimeout(() => renderNotification('booking.no_booking_available'), 0);
};

onMounted(() => {
  data.value = {
    ...data.value,
    ...props.initial,
  };

  for (let i = 1; i <= room.value.type?.guests; i++) {
    guestsOptions.value.push({ value: i, label: i.toString() });
  }
});
</script>

<style scoped lang="scss">
.panel__title {
  @include font(18px, 22px, white, 500);
}

.panel__hint {
  margin: 1rem 0 0;
  @include font(16px, 20px, white, 500);
}

.field.disabled {
  pointer-events: none;
  opacity: 0.75;
}
</style>
