<template>
  <div class='field' :class="`field--${color}`">
    <div class="field__container">
      <p v-if="label" class="field__label">{{ label }}</p>
      <form-number-buttons :value="value" :min-value="minValue" :max-value="maxValue" :color="color" @plus="onPlusClick"
                           @minus="onMinusClick" />
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
  </div>
</template>

<script lang='ts' setup>
import { ref, watchEffect } from 'vue';
import FormNumberButtons from "@/components/Form/FormNumberButtons.vue";

const props = defineProps({
  modelValue: Number,
  label: String,
  hint: String,
  minValue: {
    type: Number,
    default: null,
  },
  maxValue: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const value = ref(props.modelValue ?? 0);

const onPlusClick = () => {
  value.value++;
  emits('update:modelValue', value.value);
};

const onMinusClick = () => {
  value.value--;
  emits('update:modelValue', value.value);
};

watchEffect(() => value.value = props.modelValue ?? 0);
</script>

<style lang='scss' scoped>
@import "src/assets/styles/components/form/form";

.field {
  &__container {
    @extend .row-container;
    justify-content: space-between;
    width: 100%;
  }

  &:deep(.amount__text) {
    color: white;
  }
}
</style>
