import { RouteRecordRaw } from "vue-router";
import MenuView from "@/views/Menu/MenuView.vue";
import BeverageListView from "@/views/Menu/BeverageListView.vue";
import MenuListView from "@/views/Menu/MenuListView.vue";
import MenuDetailsView from "@/views/Menu/MenuDetailsView.vue";
import MenuStartView from "@/views/Menu/MenuStartView.vue";

export const menuRoutes: RouteRecordRaw = {
  path: '/menu',
  children: [{
    path: '',
    name: 'menu',
    component: MenuView
  }, {
    path: 'beverage',
    name: 'menu-beverage',
    component: BeverageListView
  }, {
    path: 'beverage/:categoryId',
    name: 'menu-beverage-list',
    component: MenuListView
  }, {
    path: 'list/:categoryId',
    name: 'menu-list',
    component: MenuListView
  }, {
    path: 'details',
    name: 'menu-details',
    component: MenuDetailsView
  }, {
    path: 'start',
    name: 'menu-start',
    component: MenuStartView
  }],
};
