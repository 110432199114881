import { useRouter } from "vue-router";
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";
import { FormButton } from "@/types/App/Form";

export const useHotelSetup = () => {
  const { push } = useRouter();
  const userStore = useUserStore();
  const hotelSetupStore = useHotelSetupStore();
  const { hotelSetup } = storeToRefs(hotelSetupStore);

  const selectedTab = ref('about');

  const onButtonClick = (button: FormButton) => {
    if (button.linkType === 'inner') {
      push(button.link);
    } else {
      window.open(button.link, '_blank');
    }
  };

  const checkFunction = (name: string) => {
    console.log(userStore.linkType)
    if (userStore.linkType === 'menu' && name !== 'food') {
      push('/not-allowed');
    }

    if (name === 'booking-history') {
      return;
    } else if (name === 'reservations') {
      return;
    } else if (Object.keys(hotelSetup.value).includes(name) && getServiceState(name)) {
      return;
    }

    push('/not-allowed');
  };

  const getServiceState = (name: string) => {
    if (name === 'cleaning') {
      return hotelSetup.value.cleaning?.isActive;
    } else if (name === 'taxi') {
      return hotelSetup.value.taxi?.isActive;
    } else if (name === 'reception') {
      return hotelSetup.value.reception?.isActive;
    } else if (name === 'food') {
      return hotelSetup.value.food?.isActive;
    } else if (name === 'booking') {
      return hotelSetup.value.booking?.isActive;
    } else if (name === 'information') {
      return hotelSetup.value.information?.isActive;
    }

    return false;
  };

  return {
    selectedTab,
    onButtonClick, checkFunction, getServiceState
  }
}
