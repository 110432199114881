import { button } from "@/locales/en/button";
import { service } from "@/locales/en/service";
import { cleaning } from "@/locales/en/services/cleaning";
import { taxi } from "@/locales/en/services/taxi";
import { desk } from "@/locales/en/services/desk";
import { info } from "@/locales/en/pages/info";
import { laundry } from "@/locales/en/services/laundry";
import { food } from "@/locales/en/pages/food";
import { beverage } from "@/locales/en/pages/beverage";
import { menu } from "@/locales/en/services/menu";
import { sauna } from "@/locales/en/services/sauna";
import { gazebo } from "@/locales/en/services/gazebo";
import { advert } from "@/locales/en/advert";
import { error } from "@/locales/en/error";
import { notification } from "@/locales/en/notification";
import { messages } from "@/locales/en/messages";
import { infoPriceList } from "@/locales/en/pages/info_price_list";
import { booking } from "@/locales/en/services/booking";
import { chorna_skelya } from "@/locales/en/hotels/chorna_skelya";
import { bath } from "@/locales/en/services/bath";

export const en = {
  room: 'Room',
  from: 'from',
  from_price: 'from',
  till: 'till',
  or: 'or',
  today: 'Today',
  tomorrow: 'Tomorrow',
  asap: 'As soon as possible',
  uah: 'uah',
  usd: '$',
  notifications: 'Notifications',
  empty_notifications: 'The are no notifications yet',
  per_hour: 'per hour',
  reservation: 'Reservation',
  date: 'Date',
  new_messages: 'new messages',
  duration: 'Duration',
  minutes: 'minutes',
  current: 'Current',
  finished: 'Finished',
  time: 'Time',
  room_number: 'Room number',
  placed_time: 'Request placed',
  taken_time: 'Request taken',
  preference: 'Preference',
  no_clean: 'No need in cleaning service',
  comment: 'Comment',
  cancel_reason_label: 'Enter the reason of cancellation',
  cancel_title: 'Cancel request',
  tech_cancel_message: 'Your request was cancelled due to technical reason. You can choose other date and time or contact administrator',
  message_404: 'This page does not exist',
  request_empty: 'You have not made requests and reservations yet',
  oops: 'Oops',
  not_allowed: 'You do not have access to this page',
  free_time: 'Free time',
  reserve_time: 'Reserve time',
  hotel: 'Hotel',
  service_name: 'Service',
  people: 'People',

  ...messages,
  price_list: infoPriceList,
  button,
  service,
  cleaning,
  taxi,
  desk,
  booking,
  info,
  laundry,
  food,
  beverage,
  menu,
  sauna, // contains texts for sauna, vat and bath services
  gazebo,
  bath,
  advert,
  error,
  notification,

  fishing: {
    fishing_1: 'Fishing',
    work_time: 'Fishing is available from 9:00 to 22:00 without limitation on the duration of the reservation',
    choose_date: 'Specify the time during which you want to fish',
    people_amount: 'Specify the number of people',
  },

  spa: {
    spa_1: 'Classic full body massage with oils',
    spa_2: 'Neck massage',
    spa_3: 'Chocolate Wrapping',
    choose_date: 'Specify the date and time you want to use the SPA',
  },

  form: {
    enter_comment: 'Enter comment',
    enter_text: 'Enter text',
    enter_address: 'Enter address',
  },

  adverts: {
    title: 'Find the best way to spend your vacation',
  },

  qr_room: {
    ust_table_1: 'Table 1',
    ust_table_2: 'Table 2',
    ust_table_3: 'Table 3',
    ust_table_4: 'Table 4',
    ust_table_5: 'Table 5',
    ust_table_6: 'Table 6',
    ust_table_7: 'Table 7',
    ust_table_8: 'Table 8',
    ust_table_9: 'Table 9',
    ust_table_10: 'Table 10',
    td_gazebo_1: 'Gazebo 1',
    td_gazebo_2: 'Gazebo 2',
    td_gazebo_3: 'Gazebo 3',
    td_gazebo_4: 'Gazebo 4',
    td_gazebo_5: 'Gazebo 5',
    td_gazebo_6: 'Gazebo 6',
    td_gazebo_7: 'Gazebo 7',
    td_gazebo_8: 'Gazebo 8',
    td_gazebo_9: 'Gazebo 9',
    td_gazebo_10: 'Gazebo 10',
    td_bath_1: 'Bath 1',
    td_vat_1: 'Vat 1',
    td_vat_3: 'Vat 2',
  },

  summary: {
    title: 'Summary',
    article: 'Article',
    amount: 'Amount',
    price: 'Price',
    total: 'Total',
    hours: 'Hours',
    h: 'Hours',
    m: 'Minutes',
    d: 'Days',
    u: 'Amount',
  },

  payment: {
    title: 'Payment',
    details: 'Payment Details',
    choose_method: 'Choose the Payment Method',
    apple: 'Apple Pay',
    google: 'Google Pay',
    card_number: 'Card Number',
    card_date: 'Month/Year',
    card_cvv: 'CVV',
    cvv_hint: 'Code on the back of your card',
    pay_success: 'Thank you, we received your order!\nYour invoice is right here.',
    download_invoice: 'Download invoice',
    payment_info: '*The payment will be done at the place.',
  },

  status: {
    new: 'Waiting for Confirmation',
    active: 'Confirmed',
    cancelled: 'Cancelled',
    finished: 'Finished',
  },

  event: {
    success_title: 'Congrats!',
    error_title: 'Something went wrong',
    cancel_title: 'Your request was denied',
    tech_cancel_title: 'Your request was cancelled due to technical reason. See more...',
    taken: 'We started to perform your {type} request!',
    cancelled: 'Your request {type} was cancelled. See more...',
    finished: 'Your request {type} was finished!',
  },

  unit: {
    gr: 'g',
    prt: 'prt',
    pc: 'pc',
    l: 'l',
    ml: 'ml',
    bottle: 'bottle',
  },

  data: {
    cancel_reason_1: 'Service is unavailable',
    cancel_reason_2: 'Required staff is unavailable',
    cancel_reason_3: 'Required equipment is unavailable',
    cancel_reason_4: 'Have no free time',
    cancel_reason_5: 'No available tables',
    tech: 'Technical work'
  },

  // hotels' texts
  ch_sk: chorna_skelya,
};
