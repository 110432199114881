import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from "@/composable/useApi";
import { useUserStore } from "@/stores/userStore";
import { RoomType } from "@/types/RoomType";
import { request } from "@/stores/request";

export const useRoomTypeStore = defineStore('roomTypeStore', () => {
  const userStore = useUserStore();
  const { getErrorMessage, getApiUrl } = useApi();

  const roomTypes = ref<RoomType[]>([]);
  const roomType = ref<RoomType>({} as RoomType);
  const error = ref('');

  const getRoomType = async (id: number) => {
    try {
      roomType.value = await request({ url: `room-type/${ id }`, method: 'get' });
      roomType.value.imagesData = [];
      roomType.value?.images?.map((path, index) => {
        const image = new Image();
        image.src = getApiUrl() + path;
        image.addEventListener('load', () => {
          roomType.value.imagesData[index] = image.getAttribute('src') ?? '';
        });
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getRoomTypes = async () => {
    try {
      roomTypes.value = await request({ url: `room-type/org/${ userStore.client.organizationId }`, method: 'get' });
      roomTypes.value.map(async (roomType) => {
        if (roomType.images?.length) {
          const image = new Image();
          image.src = getApiUrl() + roomType.images[roomType.mainImage];
          image.addEventListener('load', () => {
            roomType.imagesData = [image.getAttribute('src') ?? ''];
          });
        }
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    roomTypes, roomType, error,
    getRoomTypes, getRoomType
  };
});
