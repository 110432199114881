<template>
  <div class="loader">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M42.1855 11.092C40.5219 5.59689 44.6747 0.0678962 50.4156 0.134383C56.2052 0.201432 60.2334 5.91311 58.3531 11.3892L52.975 27.0521C52.1578 30.0568 47.9109 30.099 47.0343 27.1112L46.9966 26.9829L42.1855 11.092Z" fill="#FFE500"/>
        <path opacity="0.95" d="M62.4903 12.3461C63.7882 6.75328 70.1448 4.03126 75.088 6.95151C80.0732 9.8964 80.7169 16.8559 76.3565 20.6652L63.8849 31.5607C61.6784 33.7575 57.976 31.6765 58.7058 28.6495L58.7372 28.5195L62.4903 12.3461Z" fill="#FFE600"/>
        <path opacity="0.9" d="M79.6225 23.7189C83.5364 19.5182 90.4037 20.3285 93.2324 25.3248C96.0849 30.3633 93.1725 36.7168 87.4934 37.8443L71.25 41.0694C68.2418 41.8734 66.0704 38.2234 68.2124 35.9636L68.3044 35.8666L79.6225 23.7189Z" fill="#FFE500"/>
        <path opacity="0.8" d="M88.6881 41.7716C94.1635 40.0445 99.7403 44.133 99.7403 49.8744C99.7403 55.6643 94.0757 59.7584 88.5781 57.9417L72.8539 52.7453C69.84 51.9629 69.7487 47.7169 72.726 46.8056L72.8539 46.7664L88.6881 41.7716Z" fill="#FFE500"/>
        <path opacity="0.7" d="M87.6036 62.2189C93.2104 63.4548 96.0025 69.7809 93.137 74.7562C90.2475 79.7736 83.2954 80.4942 79.4382 76.176L68.4057 63.8255C66.1844 61.6435 68.2245 57.9184 71.2593 58.6147L71.3896 58.6447L87.6036 62.2189Z" fill="#FFE500"/>
        <path opacity="0.6" d="M76.3978 79.4209C80.6416 83.2881 79.9073 90.1637 74.9426 93.0475C69.9358 95.9557 63.5506 93.1137 62.3604 87.4473L58.9559 71.2405C58.1186 68.2414 61.7444 66.0297 64.0278 68.1466L64.1259 68.2376L76.3978 79.4209Z" fill="#FFE500"/>
        <path opacity="0.5" d="M57.9478 88.7621C59.6537 94.2443 55.5438 99.8052 49.8025 99.783C44.0124 99.7607 39.9405 94.0802 41.7785 88.5898L47.0354 72.8858C47.8293 69.875 52.0757 69.7999 52.9755 72.7809L53.0142 72.9088L57.9478 88.7621Z" fill="#FFE500"/>
        <path opacity="0.4" d="M37.4853 87.5839C36.2287 93.1862 29.8923 95.955 24.9276 93.0712C19.921 90.1632 19.2261 83.2085 23.5583 79.3672L35.9492 68.3803C38.1395 66.1672 41.8571 68.2209 41.1496 71.2531L41.1193 71.3833L37.4853 87.5839Z" fill="#FFE500"/>
        <path opacity="0.35" d="M20.3621 76.3471C16.4793 80.5766 9.60629 79.8168 6.74086 74.8416C3.85121 69.8243 6.71673 63.4494 12.3874 62.2801L28.6067 58.9353C31.6088 58.1092 33.807 61.743 31.6817 64.0186L31.5905 64.1163L20.3621 76.3471Z" fill="#FFE500"/>
        <path opacity="0.3" d="M11.0787 57.8388C5.59003 59.5238 0.0450497 55.3925 0.0893753 49.6512C0.134075 43.8613 5.83015 39.8111 11.3135 41.6703L26.9971 46.9879C30.0048 47.7935 30.0633 52.0402 27.079 52.9284L26.951 52.9665L11.0787 57.8388Z" fill="#FFE500"/>
        <path opacity="0.25" d="M12.2768 37.4862C6.67922 36.209 3.93382 29.8625 6.83584 24.9085C9.76238 19.9125 16.7195 19.2432 20.5449 23.5895L31.486 36.021C33.691 38.2193 31.6238 41.9294 28.5942 41.2108L28.464 41.1799L12.2768 37.4862Z" fill="#FFE500"/>
        <path opacity="0.2" d="M23.5447 20.3582C19.3297 16.4599 20.1146 9.58974 25.1005 6.74267C30.1283 3.87152 36.4926 6.76051 37.6411 12.4354L40.9259 28.6669C41.7411 31.672 38.0992 33.857 35.8315 31.7232L35.7341 31.6317L23.5447 20.3582Z" fill="#FFE500"/>
      </g>
    </svg>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.loader {
  @extend .row-container;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

@for $i from 1 through 12 {
  path:nth-child(#{$i}) {
    animation: loaderRotate 1.25s infinite;
    animation-delay: calc(100ms * #{$i - 1});
  }
}

@keyframes loaderRotate {
  0% {
    opacity: 1;
  }
  8.3% {
    opacity: 0.95;
  }
  16.6% {
    opacity: 0.9;
  }
  24.9% {
    opacity: 0.8;
  }
  33.2% {
    opacity: 0.7;
  }
  41.5% {
    opacity: 0.6;
  }
  49.8% {
    opacity: 0.5;
  }
  58.1% {
    opacity: 0.4;
  }
  66.4% {
    opacity: 0.35;
  }
  74.7% {
    opacity: 0.3;
  }
  83% {
    opacity: 0.25;
  }
  91.3% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
