<template>
  <teleport to=".container">
    <div class="notification" ref="element" :id="`notification-${id}`">
      <p class="notification__text">{{ text }}</p>
    </div>
  </teleport>
</template>

<script lang='ts' setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

defineProps({
  text: String,
});

const id = document.getElementsByClassName('notification').length;
const element = ref();
const timer = ref();

const removePopup = () => {
  clearTimeout(timer.value);
  element.value && element.value?.parentNode?.removeChild(element.value);
};

onMounted(() => {
  timer.value = setTimeout(removePopup, 3500);
  document.addEventListener('click', removePopup);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', removePopup);
  clearTimeout(timer.value);
});
</script>

<style lang='scss' scoped>
.notification {
  @extend .row-container, .rounded;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100vw - 1rem * 4);
  height: max-content;
  padding: 0.75rem 1rem;
  margin: auto;
  background-color: #{$primary}66;
  backdrop-filter: blur(4px);
  animation: notificationAnimation 3.5s;
  z-index: 1000;

  &__text {
    @include font(18px, 22px, black, 500, center);
    text-wrap: balance;
  }
}

@keyframes notificationAnimation {
  0% {
    opacity: 0;
    scale: 0.5;
  }
  15% {
    opacity: 1;
    scale: 1;
  }
  80% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0.5;
  }
}
</style>
