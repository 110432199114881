<template>
  <popup-container :title="$t('food.supplements_title', { name: getTranslation(item.name) }) + ':'"
                   @close="$emit('close')">
    <div class="panel__list">
      <div v-for="item in pizzas" :key="item.id" class="panel__list-item">
        <p class="panel__list-item__name">
          {{ item.name }}
          <span v-if="item.hasDuplicates"> №{{ item.index + 1 }}</span>
        </p>
        <form-number-buttons color="white" :key="suppKey" :value="getAmount(item)" :min-value="0"
                             @plus="onPlusClick(item)" @minus="onMinusClick(item)" />
      </div>
    </div>
    <base-button :text="$t('button.continue')" @click="onContinueClick" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { computed, PropType, ref } from "vue";
import { MenuCartItem, MenuListItem, MenuListSupplement } from "@/types/App/Cart";
import BaseButton from "@/components/Button/BaseButton.vue";
import FormNumberButtons from "@/components/Form/FormNumberButtons.vue";
import { useAppStore } from "@/stores/appStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";

interface PizzaItem extends MenuCartItem {
  index: number;
  hasDuplicates: boolean;
}

const props = defineProps({
  item: {
    type: Object as PropType<MenuListItem>,
    default: () => ({})
  },
});
const emits = defineEmits(['close']);

const appStore = useAppStore();
const { menuCart } = storeToRefs(appStore);
const { getTranslation } = useTranslation();

const suppKey = ref(0);

const pizzas = computed((): PizzaItem[] => {
  const list = menuCart.value.filter(item => item.category?.signature === 'pizza');
  const pizzas: PizzaItem[] = [];
  list.map(item => {
    if (item.amount > 1) {
      for (let i = 0; i < item.amount; i++) {
        const supplement = item?.supplements?.find(s => s.itemIndex === i);
        pizzas.push({
          ...item,
          index: i,
          hasDuplicates: true,
          amount: 1,
          supplements: (supplement ? [supplement] : []) as MenuListSupplement[]
        });
      }
    } else {
      pizzas.push({ ...item, index: 0, hasDuplicates: false });
    }
  });

  return pizzas;
});

const getAmount = (item: PizzaItem) => {
  return item?.supplements?.find(i => i.id === props.item.id)?.amount ?? 0;
};

const onPlusClick = (item: PizzaItem) => {
  const supp = item?.supplements?.find(i => i.id === props.item.id);
  if (supp) {
    supp.amount++;
  } else {
    if (!item?.supplements) {
      item.supplements = [];
    }

    item.supplements.push({ ...props.item, amount: 1, itemIndex: item.index });
  }
  suppKey.value++;
};

const onMinusClick = (item: PizzaItem) => {
  const supp = item?.supplements?.find(i => i.id === props.item.id);
  if (supp) {
    supp.amount--;

    if (supp.amount <= 0) {
      item.supplements = item.supplements?.filter(i => i.id !== props.item.id);
    }
  }
  suppKey.value++;
};

const onContinueClick = () => {
  pizzas.value.map(item => {
    if (item.supplements?.length) {
      const menuItem = menuCart.value.find(i => i.id === item.id);
      if (menuItem) {
        if (!menuItem.supplements) {
          menuItem.supplements = [];
        }

        if (item.index > 0) {
          menuItem.supplements.push(...item.supplements);
        } else {
          menuItem.supplements = item.supplements;
        }
      }
    }
  });
  emits('close');
};
</script>

<style scoped lang="scss">
.panel__list {
  @extend .flex-col, .custom-scroll, .custom-scroll-container;
  gap: 1rem;
  max-height: 65dvh;
  margin-bottom: 1.5rem;
  overflow-y: auto;

  &-item {
    @extend .row-container;
    justify-content: space-between;
    gap: 1rem;

    &__name {
      @include font(18px, 22px, black, 600);
    }
  }
}
</style>
