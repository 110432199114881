import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { ReservationSlots } from "@/types/Slot";
import { request } from "@/stores/request";
import { Reservation, ReservationDetails } from "@/types/Reservation";
import { useUserStore } from "@/stores/userStore";

export const useReservationStore = defineStore('reservationStore', () => {
  const { getErrorMessage } = useApi();
  const userStore = useUserStore();

  const reservationSlots = ref<ReservationSlots>({});
  const reservations = ref<Reservation[]>([]);
  const error = ref('');

  const createReservation = async (data: Partial<Reservation>) => {
    try {
      await request({
        url: 'reservation',
        method: 'post',
        data: { ...data, organizationId: userStore.clientLinkData.organizationId }
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const cancelReservation = async (reservationId: number, data: ReservationDetails) => {
    try {
      await request({ url: `reservation/cancel/${ reservationId }`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getCustomerReservations = async (linkId: number) => {
    try {
      reservations.value = await request({ url: `reservation/customer/${ linkId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getReservationSlots = async (id: number) => {
    try {
      reservationSlots.value = await request({
        url: `reservation/slots/${ userStore.clientLinkData.organizationId }/${ id }`,
        method: 'get',
      });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    error, reservations, reservationSlots,
    createReservation, getCustomerReservations, cancelReservation, getReservationSlots
  };
});
