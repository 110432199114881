export const messages = {
  request_success: 'The request was successfully sent',
  reservation_received: 'Thank you, we received your reservation! You will get a confirmation soon.',
  reservation_accepted: 'You reservation was accepted',
  success_cancel: 'Your request was successfully cancelled',
  restaurant_repair: 'Ordering food is not available. The restaurant is under repair',
  booking_success: 'Booking request successfully sent! Further details will be sent to the specified email',
  review_success: 'Thank you! Your review has been successfully sent!',
  booking_cancel_success: 'You booking was successfully cancelled!',
};
