export const advert = {
  chorna_skelya_sauna: {
    title: 'Сауна з елементами гімалайської солі',
    text: 'Вважається надзвичайно корисною, так як при нагріванні приміщення повітря насичується наявними в солі барієм, залізом, магнієм, йодом, кальцієм, марганцем і ін. корисними мікроелементами. ',
  },
  chorna_skelya_gazebo: {
    title: 'Сумісний відпочинок з родиною і друзями в Альтанці',
    text: 'Відпочинок з друзями і родиною в альтанці комплексу "Чорна Скеля" - це чудова можливість провести час разом, насолоджуючись атмосферою природи та затишком цього мальовничого місця.',
  },
  chorna_skelya_vat: {
    title: 'Карпатський чан з травами',
    text: 'Відчуйте користь від оздоровчого чана, дозволяючи гідромасажу розслабити ваші м\'язи, покращити кровообіг та відновити енергію, щоб насолоджуватися повним відчуттям благополуччя та відпочинку разом з друзями і родиною.',
  },

  teremok_info: {
    title: 'Приватна садиба "Теремок"',
    text: 'Унікальний котеджний комплекс "Теремок" - затишні котеджі, мангал, баня, мальовничі краєвиди - усе на території комплексу для вас.',
  },

  astur_quad: {
    title: 'Катання на квадроциклах разом з Astur Spa',
    text: 'Відчуйте бурхливі емоції та перепливайте природні перешкоди нашими гірськими маршрутами на квадроциклах. '
  },
  astur_bicycle: {
    title: 'Оренда велосипедів в Готелі Astur',
    text: 'Оренда велосипедів в готелі АСТУР - це відмінна можливість розширити свої можливості для відпочинку та відкриття нових горизонтів'
  },
  astur_spa: {
    title: 'SPA - комплекс разом із Astur SPA',
      text: 'Тут ви знайдете все необхідне для максимального розслаблення: великий басейн з джерельною водою, дитячий басейн, сауну та джакузі.'
  },

  shepit_carpat_salt_room: {
    title: 'Соляна кімната',
    text: 'Зануртеся у атмосферу чистоти та відновлення, де мільйони мікроскопічних часток солі розпорошуються у повітрі та надають вам можливість відчути справжній відпочинок легенів та шкіри.',
  },
  shepit_carpat_bath: {
    title: 'Релакс у Бані',
    text: 'Відчуйте атмосферу справжнього розслаблення та відновлення сил у нашій сауні з дерев\'яними стінами та природними ароматами.',
  },
  shepit_carpat_ski_eq: {
    title: 'Гірськолижне спорядження',
    text: 'Готельний комплекс "Шепіт Карпат" радий пропонувати своїм гостям зручну послугу оренди гірськолижного спорядження.',
  },
  shepit_carpat_rooms: {
    title: 'Ваш найкращий відпочинок чекає на вас',
    text: 'Насолоджуйтеся неймовірним відпочинком у нашому комфортному готелі преміум класу.',
  },
  shepit_carpat_spa: {
    title: 'СПА центр - ваш шлях до гармонії душі та тіла',
    text: 'Насолоджуйтеся спа процедурами в нашому комфортному готелі преміум класу.',
  },

  u_stozi_tours_1: {
    title: 'Походи в гори разом з "У Стозі"',
    text: 'Насолоджуйтесь неймовірною природою Українських Карпат завдяки різноманіттю наших екскурсій.',
  },
  u_stozi_tours_2: {
    title: 'Неймовірні пригоди разом з "У Стозі"',
    text: 'Підкорюйте вершини гір, зустрічайте світанок та прогуляйтесь до високогірного озера разом з нами.',
  },

  tur_dvir_gazebo: {
    title: 'Зумісний відпочинок з родиною і друзями в Альтанці',
    text: 'Відпочинок з друзями і родиною в альтанці - це чудова можливість провести час разом, насолоджуючись атмосферою природи та затишком цього мальовничого місця.',
  },
  tur_dvir_vat: {
    title: 'Неповторний досвід у наших лікувальних Печеринських чанах',
    text: 'Ці чани, сповнені чистої гірської води та спеціально підготовленими Карпатськими травами та секретними домішками, обіцяють вам незабутні відчуття розслаблення та оздоровлення.',
  },
  tur_dvir_fishing: {
    title: 'Риболовля у "Турянському Дворі"',
    text: 'Риболовля - чудова можливість провести час з рідними та друзями, ділитися враженнями та спільно насолоджуватися природою.',
  },
};
