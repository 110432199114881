import { useRouter } from "vue-router";
import { useRender } from "@/composable/useRender";
import { useUserStore } from "@/stores/userStore";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";

export const useButton = () => {
  const { push } = useRouter();
  const { renderNotification } = useRender();
  const userStore = useUserStore();
  const hotelSetupStore = useHotelSetupStore();
  const { hotelSetup } = storeToRefs(hotelSetupStore);

  const sendRequest = () => {
    setTimeout(() => renderNotification('request_success'), 100);
    push('/');
  };

  const redirectToTg = () => window.open('https://t.me/umtrnBot', '_blank');

  const redirectToAdminTg = () => {
    if (userStore.client.organizationId === 8) {
      window.open(`https://t.me/+380638215345`, '_blank');
    } else if (hotelSetup.value.adminPhone) {
      window.open(`https://t.me/${hotelSetup.value.adminPhone}`, '_blank');
    } else {
      redirectToTg();
    }
  }

  return { sendRequest, redirectToTg, redirectToAdminTg };
};
