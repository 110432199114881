export const food = {
  categories_title: 'Вы можете заказать еду и напитки в вашу комнату, в ресторане или на вынос.',
  breakfast: 'Завтра',
  appetizers: 'Закуски',
  salads: 'Салаты',
  soups: 'Супы',
  meat: 'Мясные блюда',
  fish: 'Рыбные блюда',
  hot: 'Горячие блюда',
  grill: 'Блюда на гриле',
  drinks: 'Напитки',
  kids: 'Детское меню',
  sauces: 'Соусы',
  flour: 'Мучные изделия',
  sides: 'Гарниры',
  baked: 'Запеченные блюда',
  desserts: 'Десерты',
  'to-beer': 'К пиву',
  pizza: 'Пицца',
  working_hours: 'Ресторан работает з {from} до {to}',
  details: 'Детали заказа',
  order_type: 'Где вы желаете получить заказ?',
  in_restaurant: 'В ресторане',
  in_room: 'В комнату',
  to_go: 'На вынос',
  in_bath: 'В баню',
  in_vat: 'В чан',
  in_gazebo: 'В альтанку',
  work_time: '*Доставка "{type}" доступна с {from} до {to}',
  ai_generated: '*Изображения блюд были сгенерированы с помощью AI и не отвечают действительному виду блюд',
  clear_question: 'Вы уверены что хотите очистить корзину?',
  items: 'Позиций',
  pizza_supplements: 'Дополнения к пицце',
  no_selected_pizza: 'Вначале добавьте пиццу в корзину',
  supplements_title: 'Выберите пиццу к которой Вы хотите добавить {name}',
  fee: '**Дополнительная плата: {fee} {currency}',
  fee_label: 'Доплата',

  title: 'Вы можете заказать еду и напитки {places}.',
  places: {
    in_restaurant: 'в ресторане',
    in_room: 'в свой номер',
    in_bath: 'в баню',
    in_vat: 'в чан',
    to_go: 'на вынос',
  },
  or: 'или',

  start: {
    title_1: 'Заказать любимые блюда еще никогда не было так просто!',
    title_2: 'Сделайте несколько легких шагов:',
    step_1: 'Начните с выбора категории интересующих вас блюд.',
    step_2: 'Выберите блюдо из списка и нажмите кнопку "Заказать".',
    step_3: 'Нажмите кнопку "i", чтобы прочитать подробную информацию о блюде.',
    step_4: 'Перейдите на страницу подтверждения, где можно просмотреть и изменить количество выбранных позиций.',
  }
};
