import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { Link } from "@/types/Link";
import { useUserStore } from "@/stores/userStore";

export const useLinkStore = defineStore('linkStore', () => {
  const { getErrorMessage } = useApi();
  const userStore = useUserStore();

  const links = ref<Link[]>([]);
  const error = ref('');

  const getLinks = async (userId: number) => {
    try {
      links.value = await request({ url: `link/list/${ userId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getLink = async (hash: string) => {
    try {
      userStore.clientLinkData = await request({ url: `link/item/${ hash.slice(1) }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const updateLink = async (data: Partial<Link> & { isAgreed?: boolean }) => {
    try {
      userStore.clientLinkData = await request({ url: `link/${ userStore.clientLinkData.id }`, method: 'put', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    links, error,
    getLinks, getLink, updateLink
  };
});
