export interface Survey {
  id: number;
  translations: SurveyTranslations;
  questions: Question[];
  defaultLanguage: string;
}

export type Translations<T> = { [key: string]: T };

export type SurveyKeys = { title: string; startText: string; endText: string; };
export type SurveyTranslations = Translations<SurveyKeys>;

export type QuestionKeys = { title: string; text: string; };
export type QuestionTranslations = Translations<QuestionKeys>;

export interface Question {
  id: number;
  type: QuestionType;
  translations: QuestionTranslations;
  required?: boolean;
}

export enum QuestionType {
  PLAIN_TEXT = 1,
  QUESTION_TEXT = 2,
  YES_NO = 3,
  RATE_5 = 4,
  RATE_10 = 5,
}
