<template>
  <div class='field' :class="`field--${color}`">
    <p class="field__label">{{ label ?? $t('booking.choose_date') }}</p>
    <div class='field__input' @click="isActive = true">
      <p class="field__input-value">{{ value ? moment(value).format('DD.MM.YYYY') : '' }}</p>
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>

    <form-custom-calendar v-if="isActive" :min="min" :max="max" :exclude="exclude" :initial-date="value"
                          :title="$t('booking.choose_date')" @close="isActive = false"
                          @update:modelValue="v => onChange(v)" />
  </div>
</template>

<script lang='ts' setup>
import { PropType, ref } from 'vue';
import moment from "moment";
import FormCustomCalendar from "@/components/Form/FormCustomCalendar.vue";

const props = defineProps({
  modelValue: String,
  label: String,
  placeholder: String,
  hint: String,
  max: String,
  min: String,
  exclude: {
    type: Array as PropType<string[]>,
    default: () => ([])
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const value = ref(props.modelValue ?? '');
const isActive = ref(false);

const onChange = (v: string) => {
  emits('update:modelValue', v);
  value.value = v;
  isActive.value = false;
};
</script>

<style lang='scss' scoped>
@import "src/assets/styles/components/form/calendar";
</style>
