<template>
  <div class="panel">
    <page-header :has-title="false" />
    <div class="panel__content">
      <p class="panel__title">{{ $t('food.start.title_1') }}</p>
      <p class="panel__title">{{ $t('food.start.title_2') }}</p>
      <p class="panel__text">{{ $t('food.start.step_1') }}</p>
      <p class="panel__text">{{ $t('food.start.step_2') }}</p>
      <p class="panel__text">{{ $t('food.start.step_3') }}</p>
      <p class="panel__text">{{ $t('food.start.step_4') }}</p>
    </div>
    <base-button :text="$t('button.continue')" @click="push('/menu')"/>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useHotelSetup } from "@/composable/useHotelSetup";
import BaseButton from "@/components/Button/BaseButton.vue";

const { push } = useRouter();
const { checkFunction } = useHotelSetup();

onMounted(() => {
  checkFunction('food');
});
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/list";

.panel {
  &__content {
    @extend .col-container;
  }
  &__title {
    margin-bottom: 1rem;
    @include font(18px, 22px, white, 600, center);
  }

  &__text {
    @extend .rounded;
    position: relative;

    padding: 6px 12px;
    @include font(18px, 22px, white, 500);
    background-color: #{$primary}33;

    &:not(:last-of-type){
      margin-bottom: 2rem;
      &:after {
        position: absolute;
        bottom: -1.75rem;
        left: calc(50% - 1.5rem / 2);
        @include before(1.5rem, 1.5rem);
        @include svgBg($select-arrow);
      }

    }
  }
}
</style>
