<template>
  <popup-container :title="$t(getTranslation(booking.room?.type?.name))" @close="$emit('close')">
    <div class="popup__content">
      <p class="popup__header-subtitle">
        {{ booking.room?.type?.price }} {{ $t(userStore.currency).toUpperCase() }} {{ $t('booking.per_night') }}
      </p>
      <div class="panel__content">
        <div class="panel__block">
          <p class="panel__title">{{ $t('booking.dates') }}:</p>
          <p class="panel__text">
            {{ moment(booking.link?.dateFrom).format('DD.MM.YY') }} -
            {{ moment(booking.link?.dateTo).format('DD.MM.YY') }}
          </p>
          <!--            <p class="panel__title">{{ $t('booking.guests') }}:</p>
                      <p class="panel__text">{{ booking.room?.personAmount }}</p>-->
          <p class="panel__title">{{ $t('summary.total') }}:</p>
          <p class="panel__text">{{ totalPrice }} {{ $t(userStore.currency) }}</p>
        </div>
        <div class="panel__block">
          <p class="panel__title">{{ $t('booking.client_info') }}:</p>
          <p v-if="booking.link?.user?.name" class="panel__text">{{ booking.link.user.name }}</p>
          <p v-if="booking.link?.user?.email" class="panel__text">{{ booking.link.user.email }}</p>
          <p v-if="booking.link?.userInfo?.phone" class="panel__text">{{ booking.link.userInfo.phone }}</p>
        </div>
        <div v-if="details?.guests >= 0 || details?.arrivalTime || details?.comment" class="panel__block">
          <template v-if="details.guests >= 0">
            <p class="panel__title">{{ $t('booking.guests') }}:</p>
            <p class="panel__text">{{ details.guests }}</p>
          </template>
          <template v-if="details.arrivalTime">
            <p class="panel__title">{{ $t('booking.arrival_time') }}:</p>
            <p class="panel__text">{{ details.arrivalTime }}</p>
          </template>
          <template v-if="details.comment">
            <p class="panel__title">{{ $t('comment') }}:</p>
            <p class="panel__text">{{ details.comment }}</p>
          </template>
        </div>
        <p class="panel__title">{{ $t('booking.status_label') }}:</p>
        <div class="panel__status">
          <p class="panel__text">{{ $t(`booking.status.${ booking.status }`) }}</p>
          <div class="status-badge" :class="booking.status"></div>
        </div>
      </div>

      <div class="popup__buttons">
        <base-button v-if="hotelSetup.adminPhone" :text="$t('button.contact')" color="white" @click="onContactClick" />
        <base-button v-if="booking.status === 'pending' || booking.status === 'confirmed'"
                     :text="$t('button.cancel')" @click="isCancel = true" />
      </div>
    </div>
  </popup-container>
  <cancel-booking-popup v-if="isCancel" @close="isCancel = false" @submit="onCancel" />
  <error-popup v-if="isError" :text="bookingStore.error" @close="isError = false" />
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { Booking, BookingDetails } from "@/types/Booking";
import moment from "moment";
import BaseButton from "@/components/Button/BaseButton.vue";
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { useUserStore } from "@/stores/userStore";
import CancelBookingPopup from "@/components/Popup/CancelBookingPopup.vue";
import { useBookingStore } from "@/stores/bookingStore";
import { useRender } from "@/composable/useRender";
import ErrorPopup from "@/components/Popup/ErrorPopup.vue";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { storeToRefs } from "pinia";
import { useTranslation } from "@/composable/useTranslation";

const props = defineProps({
  booking: {
    type: Object as PropType<Booking>,
    default: () => ({})
  },
});
const emits = defineEmits(['close']);

const userStore = useUserStore();
const bookingStore = useBookingStore();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const { renderNotification } = useRender();
const { getTranslation } = useTranslation();

const isCancel = ref(false);
const isError = ref(false);

const totalPrice = computed((): number => {
  const dateFrom = moment(props.booking?.link?.dateFrom);
  const dateTo = moment(props.booking?.link?.dateTo);
  return dateTo.diff(dateFrom, 'days') * (props.booking?.room?.type?.price ?? 0);
});

const details = computed((): BookingDetails => props.booking?.details ?? {});

const onContactClick = () => window.open(`https://t.me/${ hotelSetup.value.adminPhone }`);

const onCancel = async () => {
  await bookingStore.updateBooking(props.booking?.id, { status: 'cancelled' });
  if (bookingStore.error) {
    isError.value = true;
  } else {
    renderNotification('booking_cancel_success');
    emits('close', true);
  }
};
</script>

<style scoped lang="scss">
.popup {
  &__content {
    @extend .flex-col;
    width: 100%;
    height: 100%;
    max-height: calc(100dvh - 8rem);
  }

  &__header {
    @extend .flex-col;
    position: relative;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    &-title {
      @include font(22px, 26px, black, 600);
    }

    &-subtitle {
      margin: -1rem 0 1rem;
      @include font(20px, 24px, black, 600);
    }

    &:has(.popup__close) .popup__header-title {
      max-width: calc(100% - 2rem);
    }
  }

  &__buttons {
    @extend .flex-row;
    gap: 0.75rem;

    .base-button {
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 18px !important;
      font-weight: 500;
    }
  }
}

.panel {
  &__content {
    @extend .flex-col, .custom-scroll, .custom-scroll-container;
    gap: 1rem;
    height: 100%;
  }

  &__block {
    @extend .flex-col;
    gap: 1rem;
    margin-bottom: -8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #C3C3C3;
  }

  &__title {
    @include font(18px, 22px, black);
    margin-bottom: -0.25rem;
  }

  &__text {
    @include font(18px, 22px, black, 500);

    &--last {
      padding-bottom: 12px;
      border-bottom: 1px solid #C3C3C3;
    }
  }

  &__status {
    @extend .row-container;
    gap: 0.5rem;
  }
}

:deep(.popup__title) {
  max-width: 90%;
}
</style>
