<template>
  <div class='field' :class="[$attrs.class, `field--${color}`]">
    <p class="field__label">{{ label ?? $t('booking.choose_dates') }}</p>
    <div class='field__input' @click="isActive = true">
      <p v-if="value.dateFrom && value.dateTo" class="field__input-value">
        {{ moment(value.dateFrom).format('DD.MM.YYYY') }} - {{ moment(value.dateTo).format('DD.MM.YYYY') }}
      </p>
    </div>
    <p v-if="hint" class="field__hint">{{ hint }}</p>
  </div>

  <form-custom-calendar v-if="isActive" :min="min" :max="max" :exclude="exclude" :initial-range="value"
                        :title="$t('booking.choose_dates')" :is-range="true" @close="isActive = false"
                        @update:modelValue="v => onChange(v)" />
</template>

<script lang='ts' setup>
import { onMounted, PropType, ref } from 'vue';
import { FormPeriod } from "@/types/App/Form";
import FormCustomCalendar from "@/components/Form/FormCustomCalendar.vue";
import moment from "moment/moment";

const props = defineProps({
  initial: {
    type: Object as PropType<FormPeriod>,
    default: () => ({})
  },
  label: String,
  placeholder: String,
  hint: String,
  min: String,
  max: String,
  exclude: {
    type: Array as PropType<string[]>,
    default: () => ([])
  },
  color: {
    type: String,
    default: 'white',
    enum: ['white', 'black', 'grey']
  },
});
const emits = defineEmits(['update:modelValue']);

const value = ref<FormPeriod>({
  dateFrom: '',
  dateTo: '',
});
const isActive = ref(false);

const onChange = (v: FormPeriod) => {
  emits('update:modelValue', v);
  value.value = v;
  isActive.value = false;
};

onMounted(() => {
  value.value = props.initial ?? { dateFrom: '', dateTo: '' };
});
</script>

<style lang='scss' scoped>
@import "src/assets/styles/components/form/calendar";
</style>
