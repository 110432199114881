export const notification = {
  active: {
    title: 'Запит отримано',
    text: 'Ваш запит було прийнято до опрацювання',
    details: 'Деталі',
  },
  cancelled: {
    title: 'Запит скасовано',
    text: 'На жаль, Ваш запит було скасовано',
    details: 'Причина',
  },
  finished: {
    title: 'Запит виконано',
    text: 'Ваш запит виконано',
    details: 'Деталі',
  }
};
