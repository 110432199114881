import BookingHistoryView from "@/views/Booking/BookingHistoryView.vue";
import BookingRoomListView from "@/views/Booking/BookingRoomListView.vue";
import BookingRoomInfoView from "@/views/Booking/BookingRoomInfoView.vue";
import BookingRoomReserveView from "@/views/Booking/BookingRoomReserveView.vue";

export const bookingRoutes = {
  path: '/booking',
  children: [{
    path: 'history',
    name: 'info-booking-history',
    component: BookingHistoryView
  }, {
    path: 'rooms',
    name: 'info-booking-rooms',
    component: BookingRoomListView
  }, {
    path: 'rooms/:roomTypeId',
    name: 'info-booking-room-info',
    component: BookingRoomInfoView
  }, {
    path: 'rooms/:roomTypeId/reserve',
    name: 'info-booking-room-reserve',
    component: BookingRoomReserveView
  }]
}
