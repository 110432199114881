<template>
  <div
      :class="['banner', `banner--${advert.type}`, { loading:!advert.imageSrc }]"
      @click="advert?.link && push(advert.link)"
      :style="`background-image: url('${advert.imageSrc}')`"

  >
    <svg :width="bannerSize" :height="bannerSize * 0.515" :viewBox="`0 0 ${bannerSize} ${bannerSize * 0.515}`"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path :d="path" fill="white" fill-opacity="0.8" />
      </g>
    </svg>
    <div class="banner__left">
      <p class="banner__left-title">{{ getTranslation(advert.title) }}</p>
      <p class="banner__left-text">{{ getTranslation(advert.text) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType,ref } from "vue";
import { useRouter } from "vue-router";
import { useTranslation } from "@/composable/useTranslation";
import { Banner } from "@/types/Banner";

defineProps({
  advert: {
    type: Object as PropType<Banner>,
    default: () => ({})
  }
});

const { push } = useRouter();
const { getTranslation } = useTranslation();


const bannerSize = document.documentElement.clientWidth - 32;

const path = computed((): string => {
  return `M 0 0 H ${ bannerSize } C ${ bannerSize } 0 ${ bannerSize * 0.962 } ${ bannerSize * 0.069 } ${ bannerSize * 0.901 } ${ bannerSize * 0.110 } C ${ bannerSize * 0.816 } ${ bannerSize * 0.166 } ${ bannerSize * 0.721 } ${ bannerSize * 0.095 } ${ bannerSize * 0.640 } ${ bannerSize * 0.155 } C ${ bannerSize * 0.567 } ${ bannerSize * 0.209 } ${ bannerSize * 0.619 } ${ bannerSize * 0.298 } ${ bannerSize * 0.554 } ${ bannerSize * 0.359 } C ${ bannerSize * 0.492 } ${ bannerSize * 0.418 } ${ bannerSize * 0.417 } ${ bannerSize * 0.377 } ${ bannerSize * 0.357 } ${ bannerSize * 0.437 } C ${ bannerSize * 0.303 } ${ bannerSize * 0.492 } ${ bannerSize * 0.230 } ${ bannerSize * 0.420 } ${ bannerSize * 0.177 } ${ bannerSize * 0.476 } C ${ bannerSize * 0.100 } ${ bannerSize * 0.559 } 0 ${ bannerSize * 0.553 } 0 ${ bannerSize * 0.553 } V 0 Z`
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/banner";


.banner {

  &.loading {
    background-image: url('@/assets/preload.png');
    background-size:40%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &__left {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    align-items: flex-start;
    width: 54%;
    z-index: 10;

    &-title {
      @include font(18px, 22px, black, 700);
    }

    &-text {
      @include font(12px, 14px, black);
      text-wrap: balance;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
</style>
