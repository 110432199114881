<template>
  <div class="panel">
    <page-header back-link="/" />
    <div class="panel__content">
      <div class="panel__fields">
        <form-input v-if="serviceSetup.from" v-model="data.location" :label="$t('taxi.location')"
                    :placeholder="$t('form.enter_address')" />
        <form-input v-if="serviceSetup.to" v-model="data.destination" :label="$t('taxi.where_to')"
                    :placeholder="$t('form.enter_address')" />
        <form-radio v-if="serviceSetup.taxiType" v-model="data.type" :options="types" />
        <form-time v-if="serviceSetup.time" :class="{'disabled': data.isAsap}" v-model="data.datetime"
                   :selected="data.datetime" :label="$t('taxi.when')" :placeholder="$t('taxi.choose_time')" />
        <form-toggle v-if="serviceSetup.asap" v-model="data.isAsap" :label="$t('asap')" />
      </div>
      <template v-if="serviceSetup.comment">
        <p class="label">{{ $t('taxi.comment') }}</p>
        <form-textarea v-model="data.comment" :placeholder="$t('form.enter_comment')" />
      </template>
      <base-button :class="{'disabled': !isButtonActive}" :text="$t('button.send_request')" @click="onSendClick" />

      <banners-panel />
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeader from "@/components/Header/PageHeader.vue";
import BannersPanel from "@/components/Banner/BannersPanel.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import { computed, onMounted, ref } from "vue";
import FormTextarea from "@/components/Form/FormTextarea.vue";
import FormRadio from "@/components/Form/FormRadio.vue";
import { FormTimeValue, SelectOption } from "@/types/App/Form";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useRequestStore } from "@/stores/requestStore";
import { storeToRefs } from "pinia";
import { useRender } from "@/composable/useRender";
import FormToggle from "@/components/Form/FormToggle.vue";
import FormTime from "@/components/Form/FormTime.vue";
import { useUserStore } from "@/stores/userStore";
import { useHotelSetup } from "@/composable/useHotelSetup";
import { useTranslation } from "@/composable/useTranslation";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { HotelSetupTaxi } from "@/types/HotelSetup";

const i18n = useI18n();
const { push } = useRouter();
const hotelSetupStore = useHotelSetupStore();
const { hotelSetup } = storeToRefs(hotelSetupStore);
const userStore = useUserStore();
const requestStore = useRequestStore();
const { error } = storeToRefs(requestStore);
const { renderNotification } = useRender();
const { checkFunction } = useHotelSetup();
const { getTranslation } = useTranslation();

const data = ref({
  location: '',
  destination: '',
  type: 'regular',
  comment: '',
  datetime: {} as FormTimeValue,
  isAsap: false,
});

const types = ref<SelectOption[]>([
  { value: 'regular', label: i18n.t('taxi.regular') },
  { value: 'comfort', label: i18n.t('taxi.comfort') },
]);

const times = ref<SelectOption[]>([]);

const serviceSetup = computed((): HotelSetupTaxi => hotelSetup.value.taxi);

const isButtonActive = computed((): boolean => {
  const { location, destination, type, datetime } = data.value;
  const isLocation = !serviceSetup.value.from || !!location;
  const isDestination = !serviceSetup.value.to || !!destination;
  const isType = !serviceSetup.value.taxiType || !!type;
  const isDatetime = !serviceSetup.value.time || !!datetime;
  return isLocation && isDestination && isType && isDatetime;
});

const onSendClick = async () => {
  /*const details: RequestDetails = {
    pickup: data.value.location,
    destination: data.value.destination,
    carType: data.value.type,
    isAsap: data.value.isAsap,
  };
  if (!data.value.isAsap) {
    details.wantedTime = `0${ data.value.datetime?.hours }`.slice(-2) + ':' + `0${ data.value.datetime?.minutes }`.slice(-2);
  }
*/
  const { comment, ...details } = data.value;
  await requestStore.createRequest({
    type: 'taxi',
    linkId: userStore.clientLinkData.id,
    comment,
    details
  });
  if (!error.value) {
    setTimeout(() => renderNotification('request_success'), 100);
    push('/');
  }
};

onMounted(() => {
  checkFunction('taxi');

  if (userStore.clientLinkData?.organization?.name) {
    data.value.location = getTranslation(userStore.clientLinkData.organization.name);
  }

  times.value = [{ value: 'asap', label: i18n.t('asap') }];
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/info";

.panel__fields {
  @extend .flex-col;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.panel__text {
  font-weight: 500;
}

.base-button {
  margin: 1.5rem 0;
}

.label {
  margin-bottom: 0.5rem;
}
</style>
