<template>
  <div
      :class="['banner', `banner--${advert.type}`, { loading: !advert.imageSrc }]"
      @click="advert?.link && push(advert.link)"
      :style="`background-image: url('${advert.imageSrc}')`">
    <svg :width="bannerSize * 0.51" :height="bannerSize * 0.515"
         :viewBox="`0 0 ${bannerSize * 0.51} ${bannerSize * 0.515}`" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter)">
        <path :d="path" fill="white" fill-opacity="0.7" />
      </g>
      <defs>
        <filter id="filter" x="-7.99364" y="-8" width="201.993" height="201" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect_backgroundBlur" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect_backgroundBlur" result="shape" />
        </filter>
      </defs>
    </svg>
    <div class="banner__inner">
      <div class="banner__left">
        <p class="banner__left-title">{{ getTranslation(advert.title) }}</p>
        <p class="banner__left-text">{{ getTranslation(advert.text) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { useTranslation } from "@/composable/useTranslation";
import { Banner } from "@/types/Banner";

defineProps({
  advert: {
    type: Object as PropType<Banner>,
    default: () => ({})
  }
});

const { push } = useRouter();
const { getTranslation } = useTranslation();

const bannerSize = document.documentElement.clientWidth - 32;

const path = computed((): string => {
  return `M ${ bannerSize * 0.26 } 0 H ${ bannerSize * 0.51 } V ${ bannerSize * 0.515 } H ${ bannerSize * 0.26 } ` +
      `C ${ bannerSize * 0.26 } ${ bannerSize * 0.51 } 0 ${ bannerSize * 0.45 } 0 ${ bannerSize * 0.25 } C 0 ` +
      `${ bannerSize * 0.05 } ${ bannerSize * 0.26 } 0 ${ bannerSize * 0.26 } 0 Z`;
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/banner";

.banner {
  padding: 0;

  &.loading {
    background-image: url('@/assets/preload.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__inner {
    width: 50.5%;
    padding-left: calc(min(1.5rem, 3.5vw));
    border-radius: 0;
    background-color: #FFFFFF33;
  }

  &__left {
    &-title {
      font-size: 16px;
      line-height: 20px;
    }

    &-text {
      @include font(8px, 10px, black, 600);
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
