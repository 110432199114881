export const chorna_skelya = {
  massage_title_1: 'Класичний масаж',
  massage_text_1: 'Якщо ви відчуваєте біль, сильну напругу в спині або інших частинах тіла, постійний дискомфорт - загальний класичний масаж тіла - відмінний варіант!',
  massage_title_2: 'Лімфодренажний масаж',
  massage_text_2: 'Допомагає вивести зайву рідину з організму. Це спеціальна техніка масажу, що покращує кровообіг та запускає процеси в організмі, що відповідають за обмін речовин, виведення токсинів тощо. Це ефективна процедура для схуднення, боротьби з целюлітом, покращення обміну речовин, та зміцненню імунітету.',
  massage_title_3: 'Антицелюлітний масаж',
  massage_text_3: 'Перевірений спосіб боротьби з целюлітом. Завдяки цьому масажу покращується кровообіг, активізується рух лімфи та рідини у тканинах. В результаті з організму швидше виводяться токсини, шкідливі речовини, зайва рідина, а апельсинова скоринка розгладжується.',
  massage_title_4: 'Оздоровчий масаж',
  massage_text_4: 'Це чудовий засіб відновлення всього організму. Він значно покращує самопочуття, дарує заряд бадьорості, благотворно впливає на стан судин, м\'язів, внутрішніх органів.',
  massage_title_5: 'Релаксуючий масаж',
  massage_text_5: 'Дуже приємна м`яка процедура, яка допомагає зняти психофізіологічне напруження і є засобом проти головного болю.<br/>Також рекомендується виконувати цю процедуру при затяжних депресіях і проблеми зі сном.',
  massage_title_6: 'Моделюючий масаж обличчя MIOGYM',
  massage_text_6: 'Техніка масажу обличчя, спрямована на зміцнення і підвищення тонусу лицевих м\'язів, уповільнення процесу м\'язової атрофії, розгладження зморшок і корекцію овалу обличчя.',
  massage_title_7: 'Кінезіотейпування',
  massage_text_7: 'Ефективний метод, що забезпечує фіксацію м\'язів, сухожиль і суглобів, зменшити больові відчуття, знизити ймовірність травмування. Кінезіотейп сприяє зменшенню болючих відчуттів, згасання запальних процесів, розслабленню затиснутих і перенапружених м\'язів.<br/>Також виконує лімфодренажний та антицелюлітний ефекти.',
  massage_title_8: 'Масаж спини та шийно-комірцевої зони',
  massage_text_8: '- знімає м\'язово-тонічний синдром комірної ділянки;<br/>- покращує поставу;<br/>- нормалізує роботу органів дихання;<br/>- покращує кровопостачання, тим самим насичуючи мозок киснем, а також усуває головний біль.',
}
