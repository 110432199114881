<template>
  <popup-container :title="$t('service.menu')" @close="onClose">
    <div class="list-item__left">
      <p class="list-item__info">
        <span>{{ $t('date') }}:</span>
        <span>{{ moment(request.datetime).format('DD.MM.YYYY') }}</span>
      </p>

      <table>
        <thead>
        <tr>
          <td>{{ $t(`summary.article`) }}</td>
          <td>{{ $t(`summary.amount`) }}</td>
          <td>{{ $t(`summary.price`) }}</td>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in request.details?.items" :key="item.id">
          <tr v-if="item?.amount">
            <td>
              <p>
                {{ $t(`menu.${ item.name }`) }}
                <span v-if="item?.index >= 0">№{{ item.index + 1 }}</span>
              </p>
            </td>
            <td>{{ item.amount }}</td>
            <td>{{ item?.price?.price }} {{ $t(userStore.currency) }}</td>
          </tr>
          <tr v-for="supp in item?.supplements" :key="supp.id" class="panel__summary-supp">
            <td>
              <p>+ {{ $t(`menu.${ supp.name }`) }}
                ({{ supp.prices[supp.priceIndex].quantity }} {{ $t(`unit.${ supp.prices[supp.priceIndex].unit }`) }})
              </p>
            </td>
            <td>{{ supp.amount }}</td>
            <td>{{ supp.prices[supp.priceIndex].price }} {{ $t(userStore.currency) }}</td>
          </tr>
        </template>
        </tbody>
      </table>

      <p class="list-item__info">
        <span>{{ $t('placed_time') }}:</span>
        <span>{{ moment(request.datetime).format('HH:mm') }}</span>
      </p>
      <p v-if="request.details?.startDate" class="list-item__info">
        <span>{{ $t('taken_time') }}:</span>
        <span>{{ moment(request.details?.startDate).format('HH:mm') }}</span>
      </p>
      <p v-if="request.status === 'cancelled'" class="list-item__info">
        <span>{{ $t('cancel_reason') }}:</span>
        <span class="flex-col">
            <strong v-if="request.details?.cancelCategory">{{ $t(`data.${ request.details.cancelCategory }`) }}</strong>
            <small v-if="request.details?.cancelReason">{{ request.details.cancelReason }}</small>
          </span>
      </p>
    </div>
    <template v-if="request.status !== 'cancelled'">
      <base-button :text="$t('button.cancel')" @click="$emit('cancel')" />
      <base-button :text="$t('info.contact_admin')" @click="redirectToAdminTg" />
    </template>
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { PropType } from "vue";
import moment from "moment";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useButton } from "@/composable/useButton";
import { Request } from "@/types/Request";
import { useUserStore } from "@/stores/userStore";

defineProps({
  request: {
    type: Object as PropType<Request>,
    default: () => ({})
  },
});
const emits = defineEmits(['close', 'cancel']);

const userStore = useUserStore();
const { redirectToAdminTg } = useButton();

const onClose = () => emits('close');
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve-list";
@import "src/assets/styles/components/food";
@import "../../../assets/styles/components/details-popup";

.list-item {
  &__info:has(+ table) {
    margin-bottom: 0.5rem;
  }

  table + &__info {
    margin-top: 0.5rem;
  }
}

table {
  &:after {
    position: absolute;
    bottom: -10px;
    @include before(100%, 0);
    border-bottom: 1px solid white;
  }

  &:before {
    position: absolute;
    top: -16px;
    @include before(100%, 0);
    border-bottom: 1px solid white;
  }
}
</style>
