<template>
  <div
      class="banner"
      :class="[`banner--${advert.type}`, { loading: !advert.image }]"
      :style="`background-image: url('${advert.imageSrc}')`"
      @click="advert?.link && push(advert.link)"
  >
    <div class="banner__inner">
      <div class="banner__left">
        <p class="banner__left-title">{{ getTranslation(advert.title) }}</p>
        <p class="banner__left-text">{{ getTranslation(advert.text) }}</p>
      </div>
      <div class="banner__right">
        <img class="banner__right-image" :class="{ loading: !advert.imageSrc }" :src="advert.imageSrc" alt="img" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { useRouter } from "vue-router";
import { useTranslation } from "@/composable/useTranslation";
import { Banner } from "@/types/Banner";

defineProps({
  advert: {
    type: Object as PropType<Banner>,
    default: () => ({})
  }
});

const { push } = useRouter();
const { getTranslation } = useTranslation();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/banner";
banner {
  &.loading .banner__right-image {
    display: none;
  }

  &.loading {
    background-image: url('@/assets/preload.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
