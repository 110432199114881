<template>
  <popup-container @close="$emit('close')">
    <img v-if="item.image" class="item__image" :src="item.imageSrc" alt="img" />
    <div class="item__content">
      <p class="item__name">{{ getTranslation(item.name) }}</p>
      <p v-if="hasTranslation(item.details)" class="item__details">{{ getTranslation(item.details) }}</p>
      <div class="item__info">
        <p class="item__info-units">
          {{ item.prices[item.priceIndex].quantity }} {{ $t(`unit.${ item.prices[item.priceIndex].unit }`) }}
        </p>
        <p>/</p>
        <p class="item__info-price">{{ item.prices[item.priceIndex].price }} {{ $t(userStore.currency) }}</p>
      </div>
    </div>
    <item-button v-if="!item.amount" :text="$t('button.order')" @click.stop="$emit('order')" />
    <form-number-buttons v-else :value="item.amount" @plus="$emit('plus')" @minus="$emit('minus')" />
  </popup-container>
</template>

<script setup lang="ts">
import PopupContainer from "@/components/Popup/PopupContainer.vue";
import { PropType } from "vue";
import { MenuListItem } from "@/types/App/Cart";
import ItemButton from "@/components/Button/ItemButton.vue";
import FormNumberButtons from "@/components/Form/FormNumberButtons.vue";
import { useUserStore } from "@/stores/userStore";
import { useTranslation } from "@/composable/useTranslation";

defineProps({
  item: {
    type: Object as PropType<MenuListItem>,
    default: () => ({})
  }
});
defineEmits(['close', 'order', 'plus', 'minus']);

const userStore = useUserStore();
const { getTranslation, hasTranslation } = useTranslation();
</script>

<style scoped lang="scss">
.item {
  &__image {
    width: 58vw;
    height: 44vw;
    margin-top: -1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    object-fit: cover;
  }

  &__content {
    max-width: calc(58vw - 0.5rem * 2);
    min-height: 6.5rem;
  }

  &__name {
    margin-bottom: 0.5rem;
    @include font(16px, 20px, black, $align: center);
  }

  &__details {
    margin-bottom: 1rem;
    @include font(12px, 14px, black, $align: center);
  }

  &__info {
    @extend .row-container;
    justify-content: center;
    margin-bottom: 0.5rem;
    @include font(14px, 17px, black, 500);

    &-price {
      @include font(18px, 22px, black, 500);
    }

    p:nth-child(2) {
      margin: 0 0.25rem;
    }
  }
}

.popup__content {
  &:not(:has(.item__image)) {
    .item__content {
      align-content: center;
    }

    .item__info {
      @include font(18px, 22px, black, 500);
    }

    .item__name {
      @include font(20px, 24px, black, 600, center);
    }
  }
}

:deep(.popup) {
  width: max-content;
  min-width: 200px;
  padding: 0.5rem;
}

:deep(.popup__close) {
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  background-color: #FFFFFF99;
  border-radius: 4px;
}
</style>
