export const menu = {
  //subcategories
  cold_appetizers: 'Zimne przekąski',
  hot_appetizers: 'Gorące przekąski',
  poultry_dishes: 'Dania z drobiu',
  veal_dishes: 'Dania cielęce',
  pork_dishes: 'Dania wieprzowe',
  chief_specialties: 'Specjały szefa kuchni',
  ust_cold_appetizers: '"Zapasy kulaka" (zimne przekąski)',
  ust_salads: 'Z serca',
  ust_soups: '"Południowa opryśka" (zupy)',
  ust_bread: 'Koszyk z chlebem',
  ust_main_dishes: '"Z kurzu-ognia" (dania główne)',
  ust_caucasian_guest: 'Gość kaukaski',
  ust_fish: '"Z stawu na stół"',
  ust_hot: '"Rodzime"',
  ust_sides: 'Nie tylko mięso',
  ust_dessert_with_tea: '"Nie omijam herbaty..."',
  ust_homemade_pastry: 'Wypieki domowe',
  ust_cold_desserts: 'Słodka chłodność',
  banosh_with_cream: 'Banosh na nitkach',
  cremeshnit: 'Kremesznyt',

  // supplement categories
  sauces_supp: 'Sosy',
  vegetables_supp: 'Warzywa',
  meats_supp: 'Mięsa',
  cheeses_supp: 'Ser',

  caesar_chicken: 'Sałatka Cezar z Kurczakiem',
  caesar_salmon: 'Sałatka Cezar z Łososiem',
  caesar_shrimp: 'Sałatka Cezar z Krewetkami',
  warm_salad: 'Ciepła Sałatka z Wątrobą Kurczaka',
  cabbage_salad: 'Sałatka Kapuściana',
  veg_assorted: 'Asortyment Warzywny',
  greek_salad: 'Sałatka Grecka',
  marinated_assorted: 'Asortyment Marynowany',
  caesar_chicken_details: 'Sałata lodowa, filet z kurczaka, jajko przepiórcze, pomidory, parmezan, grzanki',
  caesar_salmon_details: 'Sałata lodowa, pomidory, parmezan, grzanki, łosoś, jajko przepiórcze',
  caesar_shrimp_details: 'Sałata lodowa, krewetki, pomidorki cherry, ogórek, parmezan, sezam, oliwa z oliwek, cytryna',
  warm_salad_details: 'Mieszane zioła, wątróbka kurczaka, przyprawy, oliwa z oliwek',
  veg_assorted_details: 'Pomidory, ogórki, papryka, kapusta',

  broth: 'Rosół z Makaronem',
  broth_details: 'Pierś z kurczaka, warzywa',
  cheese_creamy_soup: 'Zupa Serowa Kremowa z Grzankami',
  borsch: 'Barszcz',
  cold_soup: 'Zupa Letnia',
  bograch: 'Bograch',
  mushroom_soup: 'Zupa Grzybowa',
  minestrone: 'Minestrone',

  barbecue_pork: 'Barbecue Wieprzowe',
  barbecue_chicken: 'Barbecue z Kurczaka',
  veg_grill: 'Grillowane Warzywa',
  stake_pork: 'Stek z Szyi Wieprzowej',
  stake_chicken: 'Stek z Piersi Kurczaka',
  sausage_grill: 'Domowa Grillowana Kiełbasa',
  ribs_grill: 'Grillowane Żeberka',
  potato_grill: 'Grillowane Ziemniaki',
  meat_set: 'Talerz Mięsny dla Grupy (dla 6 osób)',
  barbecue_chicken_details: 'Udko z kurczaka',
  potato_grill_details: 'Z cebulą i domowym olejem',
  meat_set_details: 'Szaszłyki wieprzowe, szaszłyki z kurczaka, żeberka, kiełbasy',

  eggs: 'Jajka Smażone',
  bacon_eggs: 'Jajka Smażone z Bekonem',
  omelet_cheese: 'Omlet Serowy',
  omelet_veg: 'Omlet z Sauté Warzywnym',
  euro: 'Śniadanie Europejskie',
  euro_details: 'Jajka, kiełbaski, fasola, masło, grzanki',
  crepes: 'Naleśniki Asortymentowe',
  oatmeal: 'Owsianka z Owocami',
  cheesecakes: 'Serniczki z Dżemem/Śmietaną',
  pancakes: 'Naleśniki',

  meat_antipasti: 'Antipasti Mięsne',
  cheese_plate: 'Talerz Serów (dla 4 osób)',
  cheese_plate_details: 'Camembert, parmezan, mini mozzarella, dorblu, orzechy',
  trio_salo: 'Trio Salo',
  salmon_bruschetta: 'Bruschetta z Łososiem',
  avocado_bruschetta: 'Bruschetta z Awokado i Suszonymi Pomidorami',
  prosciutto_bruschetta: 'Bruschetta z Prosciutto i Serem Śmietankowym',

  tenderloin: 'Royal Tenderloin',
  chicken_caprese: 'Kurczak Caprese',
  meat_lozzi: 'Mięso Rożnione Lozi',

  // additional items
  carp: 'Grillowany Karaś',
  trout: 'Pstrąg Wiejski (na patelni)',
  trout_details: 'Cebula, kasza kukurydziana',
  trout_grill: 'Grillowany Pstrąg',
  salmon_spinach: 'Filet z Łososia na Łóżku ze Szpinaku',
  dorado_grill: 'Grillowany Dorado',
  dorado_veg: 'Filet z Dorado z Warzywami i Sosem Limonkowym',

  varenyky_potato: 'Wareniki z Ziemniakami i Śmietaną',
  varenyky_meat: 'Wareniki z Słoniną i Cebulą',
  home_dumplings: 'Domowe Pierogi z Masłem',
  pasta: 'Pasta z Krewetkami i Cukinią',
  spaghetti_carbonara: 'Spaghetti "Carbonara"',
  spaghetti_fettuccine: 'Spaghetti "Fettuccine"',
  spaghetti_carbonara_details: 'Boczek, śmietana, parmezan',
  spaghetti_fettuccine_details: 'Grzyby, śmietana, parmezan',

  deruny_meat: 'Deruny z Mięsem',
  deruny_cream: 'Deruny ze Śmietaną',
  deruny_mushroom: 'Deruny z Grzybami i Śmietaną',
  risotto_mushroom: 'Risotto Grzybowe',
  risotto_shrimp: 'Risotto z Krewetkami',
  brynza_banosh: 'Banosh z Bryndzą i Słoniną',
  pan: 'Patelnia Domowa',
  pan_chicken: 'Patelnia z Serduszkami i Wątróbką z Kurczaka',
  pan_details: 'Ziemniaki, domowa kiełbasa, cebula, ser, jajka, śmietana',
  pan_chicken_details: 'Serduszka, wątróbka, grzyby, ser, śmietana',

  satsebeli: 'Sos Satsebeli',
  ketchup: 'Ketchup',
  pishta: 'Pishta',

  oatmeal_kids: 'Owsianka z Masłem',
  choco_balls: '"Choco Balls" z Mlekiem',
  buckwheat: 'Kasza Gryczana z Masłem',
  potato_kids: 'Gotowane Ziemniaki z Masłem',
  rice_butter: 'Ryż z Masłem',

  espresso_oriental: 'Espresso "Oriental"',
  americano_oriental: 'Americano "Oriental"',
  viennese_coffee: 'Kawa Wiedeńska',
  cappuccino: 'Cappuccino Naturalne',
  latte: 'Latte',
  perfetto: 'Perfetto',
  iced_coffee: 'Kawa Mrożona',
  cacao: 'Kakao',
  tea: 'Herbata Parzona',
  tea_fruit: 'Herbata Owocowa',
  cappuccino_details: 'Espresso, mleko',
  latte_details: 'Espresso, mleko',
  perfetto_details: 'Espresso, mleko, lody, Baileys, śmietana',
  cacao_details: 'Czekolada, ubite mleko',

  pilsner: 'Pilsner',
  kellerbier: 'Kellerbier',
  non_alcohol_beer: 'Piwo Bezalkoholowe',
  staropramen: 'Staropramen',
  heineken: 'Heineken',
  carpathian: 'Karpackie',
  no_filter: 'Piwo Niefiltrowane',
  staropramen_details: 'Fermentacja na żywo',

  citro: 'Lemoniada na Kranie, Kwas',
  juice: 'Soki',
  hell: 'Hell',
  lu: 'Luzhanska',
  morshyn: 'Morszyńska',
  coca: 'Coca-Cola',
  pepsi: 'Pepsi',
  glass: 'szklanka',

  metaxa_brandy: 'Metaxa',
  old_kakheti_brandy: 'Stara Kakheti',
  zakarpatsky_brandy: 'Zakarpacka',
  tisa_brandy: 'Tisa',
  uzhgorod_brandy: 'Użhorod',
  carpati_brandy: 'Karpaty',
  atenos_brandy: 'Ateny',
  riga_balsam: 'Balsam Ryski',
  tequila: 'Tequila',
  absinthe: 'Absynt',
  becherovka: 'Becherovka',
  campari: 'Campari',
  captain_morgan: 'Captain Morgan',
  jagermaster: 'Jägermeister',
  soplitsa: 'Soplitsa',
  starka_reserv: 'Starka Reserve',
  tyorkina: 'Tyorkina',
  finland: 'Finlandia',
  zubrovka_charna: 'Zubrovka Black',
  zubrovka: 'Zubrovka',
  lvivska: 'Lwowska',
  lviv_gold: 'Lvoff Gold',
  jameson: 'Jameson',
  chivas_regal: 'Chivas Regal',
  jack_daniels: 'Jack Daniels',
  red_label: 'Red Label',
  sambuca: 'Sambuca',
  baileys: 'Baileys',
  amaretto: 'Amaretto',
  wine_chevalier_de_france: 'Wino Chevalier de France',
  wine_carpathian: 'Wino Karpackie',
  wine_villa_puccini: 'Wino Villa Puccini',
  wine_sarsitano: 'Wino Sarsitano',
  champagne_fragolino: 'Szampan Fragolino',
  champagne_martini_asti: 'Szampan Martini Asti',
  champagne_prosecco: 'Szampan Prosecco',
  pc_3: '3 szt.',
  pc_4: '4 szt.',
  pc_5: '5 szt.',

  mojito_non_alcohol: 'Mojito Bezalkoholowe',
  citro_fruit: 'Lemoniada Owocowa',
  milk_shake: 'Milkshake',
  in_assortment: 'w asortymencie',

  pina_collada: 'Pina Colada',
  hirosima: 'Hiroshima',
  sunrise: 'Sunrise',
  envy: 'Envy',
  green_coast: 'Green Coast',
  rome_sunset: 'Rome Sunset',
  cuba_libre: 'Cuba Libre',
  tom_collins: 'Tom Collins',
  pink_cat: 'Pink Cat',
  garibaldi: 'Garibaldi',
  tequila_boom: 'Tequila Boom',
  man_size: 'Man Size',
  mojito: 'Mojito',
  aperol_syringe: 'Aperol Syringe',
  hurricane: 'Hurricane',
  green_fairy: 'Green Fairy',
  long_island: 'Long Island',
  daiquiri_bella_donna: 'Daiquiri Bella Donna',
  pina_collada_details: 'sok ananasowy, biały rum, likier kokosowy',
  hirosima_details: 'syrop grenadynowy, sambuca, likier Baileys, absynt',
  sunrise_details: 'sok pomarańczowy, syrop grenadynowy, tequila',
  envy_details: 'sok ananasowy, tequila, likier "Blu curacao"',
  green_coast_details: 'sok cytrynowy, cytryna, absynt, tequila',
  rome_sunset_details: 'sok wiśniowy, rum, pomarańcza',
  cuba_libre_details: 'rum, cola, limonka',
  tom_collins_details: 'gin, sok cytrynowy, syrop, woda gazowana',
  pink_cat_details: 'sok ananasowy, gin, grenadyna, sok grejpfrutowy',
  garibaldi_details: 'sok pomarańczowy, nalewka Campari, pomarańcza',
  tequila_boom_details: 'tequila sprite',
  man_size_details: 'absynt, grenadyna, sok ananasowy, likier "Pisang ambon"',
  mojito_details: 'rum, sprite, limonka, syrop, mięta',
  aperol_syringe_details: 'szampan dry, aperol, sprite, pomarańcza',
  hurricane_details: 'biały rum, czarny rum, nalewka Campari, soki: cytryna, ananas, pomarańcza; syrop',
  green_fairy_details: 'tequila, absynt, wódka, biały rum, likier blue curacao, sok, napój energetyczny, likier melonowy',
  long_island_details: 'gin, wódka, biały rum, tequila, likier Triple Sec, sok, Coca-Cola',
  daiquiri_bella_donna_details: 'likier Amaretto, czarny rum, sok cytrynowy',

  // additional items
  meat_in_french: 'Mięso po Francusku',
  chop: 'Kotlet',
  shank: 'Giczka',
  egg: 'Jajka',
  omelet: 'Omlet',

  dumplings: 'Pierogi',
  potato_varenyky: 'Pierogi z Ziemniakami',
  cheese_crepes: 'Naleśniki z Serem',
  sweet_crepes: 'Naleśniki Słodkie',
  bread: 'Chleb',
  banosh: 'Banosh',

  veg_soup: 'Zupa Warzywna',
  mug_broth: 'Rosół w Kubku',
  chicken_broth: 'Rosół z Filetem z Kurczaka',
  uha: 'Ukha',
  borshch: 'Barszcz',
  solyanka: 'Solyanka',

  assorted_salad: 'Sałatka Asortymentowa',
  caesar: 'Sałatka Cezar',
  salted_cucumbers: 'Ogórki Kwaszone',
  sauerkraut: 'Kapusta Kiszona',

  assorted_meat: 'Talerz Mięs Asortymentowych',
  assorted_cheese: 'Talerz Serów Asortymentowych',
  salo: 'Słonina',
  ham: 'Szynka',

  potato_french: 'Frytki',
  potato_mashed: 'Puree Ziemniaczane',
  potato_salo: 'Ziemniaki ze Słoniną',
  potato_grilled: 'Grillowane Ziemniaki',
  rice: 'Ryż',
  kremzlyky: 'Kremówki ze Śmietaną',

  sea_bass_fish: 'Bass Morski',
  dorado_fish: 'Dorado',
  trout_fish: 'Pstrąg',
  carp_fish: 'Karaś',
  salmon_stake: 'Stek z Łososia',
  pork_kebab: 'Kebab Wieprzowy',
  pork_stake: 'Stek Wieprzowy',
  pork_rib: 'Żeberka Wieprzowe',
  chicken_kebab: 'Kebab z Kurczaka',
  chicken_stake: 'Stek z Kurczaka',
  sausages: 'Kiełbaski',
  sturgeon_stake: 'Stek z Jazia',
  sturgeon_fish: 'Jaz',

  kebab_sauce: 'Sos Kebabowy',
  brynza: 'Ser Bryndza',
  mayo: 'Majonez',
  mustard: 'Musztarda',

  ice_cream: 'Lody',
  fruit_dessert: 'Deser Owocowy',
  fruit_assorted: 'Owoce Asortymentowe',
  lemon: 'Cytryna',

  cheese_pigtail: 'Ser Warkocz',
  chips: 'Chipsy',
  salted_nut: 'Słone Orzechy',

  fanta: 'Fanta',
  sprite: 'Sprite',
  mineral_water_glass: 'Woda Mineralna (szklanka)',
  sandora_juice: 'Sok Sandora',
  uzvar: 'Uzvar',
  sitro: 'Citro',

  espresso: 'Kawa Espresso',
  cappuccino_natural: 'Cappuccino Naturalne',
  americano: 'Americano',
  cappuccino_lafesta: 'Cappuccino "LaFesta"',
  coffee_3_in_1: 'Kawa "3 w 1"',
  custard_tea: 'Herbata Parzona',
  cream: 'Śmietana',

  brandy_zakarpatsky: 'Brandy "Zakarpatsky"',
  brandy_shereuli: 'Brandy "Shereuli"',
  whiskey: 'Whisky',
  horilka_hemiroff_premium: 'Horilka "Hemiroff Premium"',
  horilka_davnya_legenda: 'Horilka "Davnya Legenda"',
  champagne_asti: 'Szampan "Asti"',
  wina_old_carpatia: 'Wino "Old Carpatia"',
  wina_alaverdi: 'Wino "Alaverdi"',
  wina_homemade: 'Wino Domowe',
  beer_lvivske_svitle: 'Piwo "Lwowskie Świetlne"',
  beer_tubourg: 'Piwo "Tuborg"',
  beer_non_alcohol: 'Piwo Bezalkoholowe',
  beer_carlsberg: 'Piwo "Carlsberg"',

  taistra_hutsula: 'Taistra Huculska',
  cheese_plateau: 'Talerz Serów',
  vegetable_palety: 'Paleta Warzyw',
  meat_taire: 'Mięso Taire',
  homemade_marinada: 'Domowe Marynaty',
  croutons_in_transcarpathian: 'Grzanki w Stylu Zakarpackim',
  assorted_pickled_mushrooms: 'Asortyment Kwaszonych Grzybów',
  white_mushroom_with_bowl: 'Grzyb Biały z Cebulą',
  herring_at_home: 'Śledź Domowy',
  machantsi: 'Machanci',
  tongue_in_cream_sauce: 'Język w Sosie Śmietanowym',
  transcarpathian_cabbage_rolls: 'Gołąbki Zakarpackie',
  banosh_with_bryndza: 'Banosh z Bryndzą',
  banosh_with_cep_mushrooms: 'Banosh z Grzybami Borowikami',
  pancakes_with_meat_and_mushroom_sauce: 'Naleśniki z Mięsem i Sosem Grzybowym',
  lavash_with_cheese_and_tomatoes: 'Lawasz z Serem i Pomidorami',
  lavash_with_chicken: 'Lawasz z Kurczakiem',
  taistra_hutsula_details: 'marynowana słonina, wędzona słonina, smalec, grzanki, ogórki kiszone',
  cheese_plateau_details: 'ser mozzarella, ser holenderski, ser feta, ser, miód',
  vegetable_palety_details: 'ogórek, pomidor, papryka, kapusta',
  meat_taire_details: 'mięso wołowe, język wołowy, domowa kiełbasa, chrzan, sos',
  homemade_marinada_details: 'ogórek kiszony, pomidor kiszony, śliwka kiszona, kapusta kiszona',
  croutons_in_transcarpathian_details: 'pasztet drobiowy, pasztet wątróbkowy, hummus, grzanki, marynata cebulowa',
  assorted_pickled_mushrooms_details: 'pieczarki, kurki, grzyb biały, korban',
  herring_at_home_details: 'ziemniaki (100g), śledź (100g), cebula (30g)',
  machantsi_details: 'białe grzyby w śmietanie',
  tongue_in_cream_sauce_details: '100/100g',
  transcarpathian_cabbage_rolls_details: '200/50g',
  pancakes_with_meat_and_mushroom_sauce_details: '200/50g',

  // additional items
  caesar_default: 'Cezar',
  royal: 'Royal',
  bukovinian: 'Bukowiniański',
  verkhovynskyi: 'Werchowiński',
  caprese: 'Caprese',
  salad_with_grilled_vegetables_and_chicken: 'Sałatka z Grillowanymi Warzywami i Kurczakiem',
  warm_beef_salad: 'Ciepła Sałatka z Wołowiną',
  gracia: 'Gracja',
  caesar_default_details: 'mieszanka sałat, filet z kurczaka, pomidory, ser Parmesan',
  royal_details: 'pieczarki, szynka, jajko, majonez, cebula',
  bukovinian_details: 'buraki, mieszanka sałat, ser feta, sos pesto, dressing gryczano-miodowy',
  verkhovynskyi_details: 'ogórki, pomidory, papryka, ser, czosnek, śmietana',
  caprese_details: 'pomidory, ser Mozzarella, mieszanka sałat, sos Pesto',
  salad_with_grilled_vegetables_and_chicken_details: 'mieszanka sałat, grillowane warzywa, kurczak, sos miodowo-musztardowy',
  warm_beef_salad_details: 'mieszanka sałat, pieczona papryka, wołowina',
  gracia_details: 'ogórki, pomidory, filet z kurczaka, grzyby, majonez, orzechy włoskie',

  bogrash: 'Bogracz',
  bogrash_in_bread_pot: 'Bogracz w Chlebie',
  fish_soup: 'Zupa Rybna',
  chicken_broth_2: 'Rosół z Kurczaka',
  carpathian_mushroom_soup: 'Zupa Grzybowa Karpacka',

  whisper_of_the_carpathians_with_gnocchi: '"Szept Karpat" z Gnocchi',
  chicken_fillet_in_egg: 'Filet z Kurczaka w Jajku',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce: 'Pierś z Kurczaka na Warzywnym Poduszce z Sosem Serowym',
  chicken_in_verkhovynskyi_style: 'Kurczak w Stylu Werchowińskim',
  chicken_steak: 'Stek z Kurczaka',
  chicken_steak_details: 'według wagi',
  medallions_with_mushroom_sauce_and_vegetable_puree: 'Medaliony z Sosem Grzybowym i Puree Warzywnym',
  beef_stroganoff: 'Wołowina Stroganoff',
  veal_with_chanterelles_in_sour_cream_sauce: 'Cielęcina z Kurkami w Sosie Śmietanowym',
  cutlet: 'Kotlet',
  meat_in_poloninsky_style: 'Mięso w Stylu Polonińskim',
  cossack_power: 'Moc Kozacka',
  loci_baked: 'Loci Pieczone',
  shashlik_by_weight: 'Szaszłyk według wagi',
  gazdivski_ribs_by_weight: 'Żeberka Gazdowskie według wagi',
  meat_set_for_company: 'Zestaw Mięs dla Firmy',
  shank_by_weight: 'Giczka według wagi',
  lamb_leg_by_weight: 'Noga Jagnięca według wagi',
  duck_with_apples_and_orange_sauce_by_weight: 'Kaczka z Jabłkami i Sosem Pomarańczowym według wagi',
  whisper_of_the_carpathians_with_gnocchi_details: 'kurczak (150g), grzyby (100g), ser (50g)',
  chicken_breast_on_vegetable_pillow_with_cheese_sauce_details: '300/50g',
  chicken_in_verkhovynskyi_style_details: 'filet z kurczaka, grzyby, śmietana',
  medallions_with_mushroom_sauce_and_vegetable_puree_details: '150/100/50g',
  beef_stroganoff_details: 'cielęcina (100g), cebula i śmietana (75g)',
  veal_with_chanterelles_in_sour_cream_sauce_details: '150/100g',
  cutlet_details: '100/50g',
  meat_in_poloninsky_style_details: 'wieprzowina pod warzywnym i śmietanowym sosem, 100/100g',
  cossack_power_details: 'stek wieprzowy według wagi',
  loci_baked_details: 'wieprzowina pod cebulową skorupą, 100/50g',
  gazdivski_ribs_by_weight_details: 'żeberka wieprzowe pod słodko-kwaśnym sosem',
  meat_set_for_company_details: 'kiełbaski myśliwskie, kiełbaski grillowane, stek wieprzowy, stek z kurczaka, żeberka, ziemniaki pieczone, 1000/150g',
  shank_by_weight_details: 'giczka i domowe marynaty, 1000/600g',
  lamb_leg_by_weight_details: 'marynowana w ziołach i pieczona w cieście',
  duck_with_apples_and_orange_sauce_by_weight_details: '1000/150g',

  baked_trout_by_weight: 'Pstrąg pieczony według wagi',
  trout_in_cream_sauce_by_weight: 'Pstrąg w sosie śmietanowym według wagi',

  skillet_with_vegetables_and_chicken: 'Patelnia z Warzywami i Kurczakiem',
  skillet_with_vegetables_and_veal: 'Patelnia z Warzywami i Wołowiną',
  vegetable_skillet: 'Patelnia Warzywna',
  skillet_with_homemade_sausage_and_stewed_cabbage: 'Patelnia z Domową Kiełbasą i Duszoną Kapustą',

  kremzlyky_with_sour_cream: 'Kremzlyki ze Śmietaną',
  kremzlyky_with_mushrooms: 'Kremzlyki z Grzybami',
  kremzlyky_hutsul_style: 'Kremzlyki Huculskie',
  dumplings_with_potatoes_and_sour_cream: 'Pierogi z Ziemniakami i Śmietaną',
  homemade_dumplings: 'Pierogi Domowe',
  kremzlyky_with_mushrooms_details: '200/100g',
  homemade_dumplings_details: '200/50g',

  mashed_potatoes: 'Puree Ziemniaczane',
  homestyle_potatoes: 'Ziemniaki Domowe',
  baked_potatoes_with_bacon_and_brynza: 'Ziemniaki Pieczone z Boczek i Bryndzą',
  creole_potatoes: 'Ziemniaki Kreolskie',
  broccoli_with_butter: 'Brokuły z Masłem',
  buckwheat_with_porcini_mushrooms: 'Kasza Gryczana z Borowikami',
  assorted_roasted_vegetables_by_weight: 'Pieczone Warzywa według wagi',
  baked_potatoes_with_bacon_and_brynza_details: '200/50g',

  // additional pizza items
  slalom_pizza: 'Slalom',
  athens_pizza: 'Ateński',
  vegetarian_pizza: 'Wegetariańska',
  hawaiian_pizza: 'Hawajska',
  cheese_pizza: 'Serowa',
  mushroom_pizza: 'Grzybowa',
  spicy_pizza: 'Ostra',
  beer_pizza: 'Do piwa',
  calzone_sausage_pizza: 'Calzone z kiełbasą',
  bechamel_pizza: 'Bechamel',
  carbonara_pizza: 'Carbonara',
  kyiv_pizza: 'Kijowska',
  cossack_pizza: 'Kozacka',
  margherita_pizza: 'Margherita',
  milanese_pizza: 'Mediolańska',
  four_seasons_pizza: '4 pory roku',
  meat_pizza: 'Mięsna',
  pepperoni_pizza: 'Pepperoni',
  assorted_roasted_vegetables_by_weight_details: 'pomidory, papryka, cukinia, cebula, pecorino',
  slalom_pizza_details: 'sos pomidorowy, ser Mozzarella, wieprzowina, cowboy, bugenina, pecorino, jajko, ogórek, papryka, boczek, oregano',
  athens_pizza_details: 'sos pomidorowy, ser Mozzarella, ser Feta, mięso mielone, boczek, filet z kurczaka, salami, pomidory, pecorino, papryka, zielona',
  vegetarian_pizza_details: 'sos pomidorowy, ser Mozzarella, warzywa, oregano',
  hawaiian_pizza_details: 'sos pomidorowy, ser Mozzarella, ser Mozzarella, ananas, boczek, szynka, kukurydza, oregano',
  cheese_pizza_details: 'sos pomidorowy, ser Mozzarella, ser Parmesan, ser Roquefort, ser Mislivsky',
  mushroom_pizza_details: 'sos pomidorowy, ser Mozzarella, grzyby, jajko, cebula, oregano',
  spicy_pizza_details: 'sos pomidorowy, ser Mozzarella, ser, papryka, majonez, czosnek, chili, salami, grzyby, marynowana cebula, oregano',
  beer_pizza_details: 'sos pomidorowy, ser Mozzarella, oregano',
  calzone_sausage_pizza_details: 'sos pomidorowy, ser Mozzarella, szynka, kiełbasa, pomidory, kukurydza, oregano',
  bechamel_pizza_details: 'sos śmietanowy, szynka, filet z kurczaka, boczek, wieprzowina, pieczarki, ser Mozzarella',
  carbonara_pizza_details: 'sos pomidorowy, ser Mozzarella, pomidory, jajko, boczek, oregano',
  kyiv_pizza_details: 'sos pomidorowy, ser Mozzarella, wieprzowina, filet z kurczaka, papryka, pomidory, grzyby, oregano',
  cossack_pizza_details: 'sos pomidorowy, ser Mozzarella, wieprzowina, boczek, czosnek, grzyby, kukurydza, zioła',
  margherita_pizza_details: 'sos pomidorowy, ser Mozzarella, pomidory, oregano',
  milanese_pizza_details: 'sos pomidorowy, ser Mozzarella, filet z kurczaka, pomidory, kukurydza, jajko, oregano',
  four_seasons_pizza_details: 'sos pomidorowy, szynka, filet z kurczaka, boczek, pieczarki, salami Pepperoni, kiełbaski myśliwskie, ser Mozzarella',
  meat_pizza_details: 'sos pomidorowy, ser Mozzarella, wieprzowina, boczek, kiełbaski myśliwskie, papryka, kukurydza, oregano',
  pepperoni_pizza_details: 'sos pomidorowy, ser Mozzarella, salami Pepperoni, grzyby, chili, kapary, oregano',

  // additional dishes
  basturma: 'Basturma',
  mahan: 'Mahan',
  beer_tray: 'Taca piwna',
  spicy_ears: 'Ostre uszy',
  wings_in_honey_soy_sauce: 'Skrzydełka w sosie miodowo-sojowym',
  peanut: 'Orzeszki',
  beer_tray_details: 'basturma, makhan, filet z kurczaka, wieprzowina',

  // additional desserts
  pancakes_with_creme_and_chocolate: 'Naleśniki z kremem i czekoladą',
  pancakes_with_nuts_and_chocolate: 'Naleśniki z orzechami i czekoladą',
  pancakes_with_apples: 'Naleśniki z jabłkami',
  pancakes_with_cottage_cheese_and_sour_cream: 'Naleśniki z twarogiem i śmietaną',
  syrniki_with_jam_and_sour_cream: 'Serniczki z dżemem i śmietaną',
  prunes_with_condensed_milk: 'Śliwki z mlekiem skondensowanym',
  tiramisu: 'Tiramisu',
  cheesecake: 'Sernik',
  chocolate_fondant: 'Fondant czekoladowy',
  apple_strudel_with_ice_cream: 'Strudel z jabłkami i lodami',
  jam: 'Dżem',
  natural_honey: 'Miód naturalny',
  ice_cream_cone: 'Rogal lodowy',
  assorted_fruits: 'Owoce w asortymencie',
  student_chocolate: 'Czekolada studencka',
  dark_chocolate: 'Czekolada gorzka',
  pancakes_with_creme_and_chocolate_details: '100/100g',
  pancakes_with_nuts_and_chocolate_details: '200/50g',
  pancakes_with_apples_details: '200/50g',
  pancakes_with_cottage_cheese_and_sour_cream_details: '200/50g',
  syrniki_with_jam_and_sour_cream_details: '150/50/50g',
  prunes_with_condensed_milk_details: '150g',
  tiramisu_details: '150g',
  cheesecake_details: '150g',
  chocolate_fondant_details: '100/50g',
  apple_strudel_with_ice_cream_details: '100/50g',

  // additional beverages
  hot_chocolate: 'Gorąca czekolada',
  milk: 'Mleko',
  matcha: 'Matcha',
  green_tea: 'Zielona herbata',
  black_tea: 'Herbata czarna',
  herbal_tea: 'Herbata ziołowa',
  fruit_tea: 'Herbata owocowa',
  tea_with_ginger_and_raspberry: 'Herbata z imbirem i malinami',
  tea_with_sea_buckthorn_ginger_and_orange: 'Herbata z rokitnikiem, imbirem i pomarańczą',
  tea_with_blackberry_and_blueberry: 'Herbata z jeżynami i borówkami',

  // additional alcohol
  chernigivske_bottle: 'Butelka Czernihowskiego',
  stella_bottle: 'Butelka Stelli',
  stella_non_alcoholic: 'Stella bezalkoholowe',
  corona_0_3l: 'Corona 0,3l',
  stella_draft_0_5l: 'Stella beczkowa 0,5l',

  tequila_sunrise: 'Tequila Sunrise',
  long_island_ice_tea: 'Long Island Ice Tea',
  sex_on_the_beach: 'Sex on the Beach',
  aperol: 'Aperol',
  green_mexican: 'Zielony Meksykanin',
  merzavchik: 'Merzavchik',
  meduza: 'Meduza',
  control_shot: 'Control Shot',
  northern_hunter: 'Północny Łowca',
  b_52: 'B-52',
  b_53: 'B-53',
  bmw: 'BMW',
  sambuka: 'Sambuka',
  margarita: 'Margarita',
  malibu: 'Malibu',
  whiskey_cola: 'Whiskey Cola',
  gin_tonic: 'Gin Tonic',
  aperol_details: 'aperol, szampan, woda gazowana',
  tequila_sunrise_details: 'tequila, sok pomarańczowy, grenadyna',
  long_island_ice_tea_details: 'wódka, rum, gin, tequila, triple sec, cola',
  sex_on_the_beach_details: 'wódka, likier, sok pomarańczowy',
  mojito_details_2: 'białe rum, limonka, mięta, sprite, cukier',
  green_mexican_details: 'tequila, sok cytrynowy, świeża limonka',
  merzavchik_details: 'wódka, sok, grenadyna',
  meduza_details: 'Malibu, triple sec, rum, Baileys',
  control_shot_details: 'Kahlua, sambuka, absynt',
  northern_hunter_details: 'Kahlua, sambuka, herbatnik, górska',
  b_52_details: 'Kahlua, Baileys, triple sec',
  b_53_details: 'Kahlua, Baileys, absynt',
  bmw_details: 'Baileys, Malibu, whiskey',
  tequila_boom_details_2: 'tequila, nasiona cytryny, sprite',
  sambuka_details: 'sambuka, cynamon, ziarna kawy',
  margarita_details: 'tequila, triple sec, świeża limonka',
  malibu_details: 'Malibu, sok, pomarańcze',
  whiskey_cola_details: 'Red Label, Coca-Cola',
  gin_tonic_details: 'gin, tonik, cytryna',

  // cocktails
  milk_cocktail: 'Milkshake',
  coffee_cocktail: 'Koktajl kawowy',
  milk_berry_cocktail: 'Koktajl mleczno-owocowy',
  banana_cocktail: 'Koktajl bananowy',

  // champagne
  artemivske_champagne: 'Artemowskie (w asortymencie)',
  asti_pl_italia_champagne: 'Asti пл. Italia',
  lambrusco_italia_champagne: 'Lambrusco Italia',
  prosecco_champagne: 'Prosecco',

  // wine
  chilean_wine_wine: 'Wino chilijskie 100g',
  italian_wine_wine: 'Wino włoskie 100g',
  georgian_wine_wine: 'Wino gruzińskie 100g',
  chateau_chizay_wine: 'Chateau Chizay vit. 100g',
  mulled_wine_wine: 'Wino grzane 200g',

  // cognac
  tisa_cognac: 'Tisa 50g',
  uzhgorod_cognac: 'Użhorod 50g',
  karpaty_cognac: 'Karpaty 50g',
  zakarpatskyi_cognac: 'Zakarpacie 50g',
  ararat_cognac: 'Ararat',
  hennessy_cognac: 'Hennessy',
  martell_cognac: 'Martell',

  // vodka
  khortytsia_vodka: 'Khortytsia 50g',
  nemiroff_vodka: 'Nemiroff 50g',
  finlandia_vodka: 'Finlandia 50g',
  absolut_vodka: 'Absolut 50g',
  lvivska_vodka: 'Lwowska 50g',

  // liqueurs
  baileys_liqueurs: 'Baileys 50g',
  malibu_liqueurs: 'Malibu 50g',
  sheridans_liqueurs: 'Sheridans 50g',

  // whiskey
  jim_beam_whiskey: 'Jim Beam 50g',
  jack_daniels_whiskey: 'Jack Daniels 50g',
  jameson_whiskey: 'Jameson 50g',
  medleys_whiskey: 'Medley’s 50g',
  ballantines_whiskey: 'Ballantine’s 50g',

  // infusions
  jagermeister_infusions: 'Jagermeister 50g',
  becherovka_infusions: 'Becherovka 50g',
  tequila_infusions: 'Tequila 50g',
  absinthe_infusions: 'Absynt 50g',
  malinivka_infusions: 'Malinivka 50g',
  zubrovka_infusions: 'Żubrówka 50g',
  kalganyvka_infusions: 'Kalganywka 50g',
  khrinivka_infusions: 'Chrzanówka 50g',

  // gin and rum
  beefeater_gin: 'Beefeater 50g',
  black_rum: 'Rum czarny 50g',
  gold_rum: 'Rum złoty 50g',
  martini_martini: 'Martini 50g',

  // soft drinks and juices
  mirinda: 'Mirinda',
  seven_up: '7up',
  shweppes: 'Shweppes',
  shepit_karpat_water: 'Woda "Szept Karpat"',
  morshynska: 'Morshynska 1.5l',
  juice_assorted: 'Sok (asortyment)',
  mojito_non_alcoholic: 'Mojito bezalkoholowe',
  orange_fresh: 'Pomarańcza 200g',
  carrot_apple_fresh: 'Marchew-jabłko 200g',
  apple_fresh: 'Jabłko 200g',
  grapefruit_fresh: 'Grejpfrut 200g',
  mix_vegetable_fruit_fresh: 'Mix (warzywa i owoce) 200g',
  beetroot_fresh: 'Burak 200g',
  lemon_fresh: 'Cytryna 200g',
  lemon_lemonade: 'Cytrynowa lemoniada 200g',
  mojito_lemonade: 'Lemoniada Mojito 200g',
  orange_sea_buckthorn_lemonade: 'Pomarańczowo-rokitnikowa lemoniada 200g',
  forest_berries_lemonade: 'Lemoniada leśna 200g',
  maracuja_strawberry_raspberry_lemonade: 'Lemoniada marakuja-truskawka-malina 200g',

  // ingredients
  mayonnaise: 'Majonez',
  yerosh_pishto: 'Yerosh Pishto',
  sour_cream: 'Śmietana',
  spicy_garlic: 'Pikantny czosnek',
  tomatoes: 'Pomidory',
  mushrooms: 'Grzyby',
  bell_pepper: 'Papryka',
  corn: 'Kukurydza',
  canned_pineapple: 'Ananas (konserwowy)',
  olives: 'Oliwki',
  onion: 'Cebula',
  chili_pepper: 'Papryczka chili',
  garlic: 'Czosnek',
  one_egg: 'Jajko',
  bacon: 'Boczek',
  buzhenina: 'Buzhenina',
  sausage: 'Kiełbasa',
  hunting_sausages: 'Kiełbaski myśliwskie',
  salami_sausage: 'Kiełbasa "Salami"',
  salami_pepperoni: 'Salami pepperoni',
  chicken_fillet: 'Filet z kurczaka',
  mozzarella_cheese: 'Ser Mozzarella',
  parmesan_cheese: 'Ser Parmesan',
  feta_cheese: 'Ser Feta',
  roquefort_cheese: 'Ser Roquefort',
  hunting_cheese: 'Ser myśliwski',

  // snacks and cuts
  homemade_salo: 'Salo domowe',
  sausage_figurines: 'Figurki kiełbasy',
  gazda_s_cut: 'Krojenie "W Gazdowskim stylu"',
  cheese_cut: 'Krojenie sera',
  herring_do_charochky: 'Śledź "Do szklanki"',
  salted_mushrooms: 'Grzyby solone',
  basket_of_mushrooms: 'Koszyk grzybów',
  gazda_s_cut_details: 'kiełbasa domowa, boczek wędzony, pieczona wieprzowina',
  salted_mushrooms_details: 'marynowane ogórki i kapusta, pomidory marynowane',
  basket_of_mushrooms_details: 'marynowane grzyby białe',

  // salads and soups
  zvernyhora: 'Zвернигора',
  beet_salad: 'Sałatka z buraków',
  carrot_salad: 'Sałatka marchewkowa',
  zvernyhora_details: 'biała kapusta, marchew, zioła, olej',

  // main dishes
  gutsul_soup: '"Zupa Huculska" z grzybami białymi',
  zakarpatskyi_bograch: 'Bograch "Zakarpacki"',
  homemade_borscht_with_smetana: 'Domowy barszcz z kwaśną śmietaną',
  kogut_dance: 'Taniec Kurczaka',
  vegetable_soup: '"Wy dziewczyny" zupa warzywna',
  solyanka_set: 'Solyanka drużynowa',
  white_bread: 'Chleb biały',
  grey_bread: 'Chleb szary',
  rye_bread: 'Chleb żytni',
  kogut_dance_details: 'rosół z kurczaka z mięsem i makaronem',
  vegetable_soup_details: 'zupa warzywna',

  // hot dishes
  wild_dances: 'Dziki Taniec',
  gutsul_taistra: '"Huculska Taistra"',
  fried_homemade_sausage: 'Smażona kiełbasa domowa',
  pork_steak_on_bone: 'Stek wieprzowy na kości',
  bear_paw: 'Łapa niedźwiedzia',
  pork_ribs: 'Żeberka wieprzowe',
  beef_steak_with_mushroom_sauce: 'Stek wołowy w sosie grzybowym',
  chicken_steak_with_tartar_sauce: 'Stek z kurczaka w sosie Tar-Tar',
  pork_shashlik_with_sauce: 'Szaszłyki wieprzowe z sosem',
  wild_dances_details: 'pieczona wieprzowina z cebulą i grzybami',
  gutsul_taistra_details: 'filet z kurczaka z grzyb',

  // fish dishes
  baked_trout: 'Pstrąg pieczony',
  baked_mackerel: 'Makrela pieczona na ogniu',

  // dumplings
  dumplings_with_meat_and_mushroom_sauce: 'Pierogi z mięsem i sosem grzybowym',
  dumplings_with_potatoes_bryndza_and_sour_cream: 'Pierogi z ziemniakami, bryndzą i śmietaną',
  dumplings_with_potatoes_and_butter_with_sour_cream: 'Pierogi z ziemniakami, masłem i śmietaną',
  dumplings_with_potatoes_and_mushroom_sauce: 'Pierogi z ziemniakami i sosem grzybowym',
  homemade_dumplings_2: 'Pierogi domowe',
  deruny_with_sour_cream: 'Placki ziemniaczane ze śmietaną',
  deruny_with_mushroom_sauce: 'Placki ziemniaczane z sosem grzybowym',
  puzo_gutsula: 'Puzo huculskie',
  poloninsky_kulesha: 'Kulesza poloninska',
  banosh_with_bryndza_and_shkvarky: 'Banosh z bryndzą i skwarkami',
  puzo_gutsula_details: 'deruny z sosem grzybowym',
  poloninsky_kulesha_details: 'kasza kukurydziana 3 brinzoe i kulki',

  // side dishes
  dragobrat_potatoes: 'Ziemniaki "W Dragobrackich"',
  mashed_potato: 'Puree ziemniaczane',
  boiled_rice_with_vegetables: 'Ryż gotowany z warzywami',
  dragobrat_potatoes_details: 'pieczone plastry ziemniaków z boczkiem i czosnkiem',

  // sauces
  mushroom_sauce: 'Sos grzybowy',
  pepper_sauce: 'Sos paprykowy',
  sour_cream_sause: 'Sos śmietanowy',
  tartar: 'Sos Tar-Tar',
  shashlik_sauce: 'Sos barbecue',
  horseradish: 'Chrzan',
  mushroom_sauce_details: 'sos z grzybów leśnych w śmietanie',
  pepper_sauce_details: 'śmietana, mieszanka papryki',

  // desserts
  pancakes_with_honey: 'Nalistniki z miodem',
  pancakes_with_jam: 'Nalistniki z dżemem',
  pancakes_with_cottage_cheese_and_sour_cream_2: 'Nalistniki z twarogiem i śmietaną',
  pancakes_with_chocolate: 'Nalistniki z czekoladą',
  pancakes_with_condensed_milk: 'Nalistniki z mlekiem skondensowanym',
  apple_strudel_with_ice_cream_and_caramel: 'Strudel jabłkowy z lodami i karmelem',
  ice_cream_with_nuts_and_honey: 'Lody z orzechami i miodem',
  ice_cream_with_nuts_and_chocolate: 'Lody z orzechami i czekoladą',
  ice_cream_with_jam: 'Lody z dżemem',

  // beverages
  mors: 'Mors',

  // appetizers
  appetizer_1: 'Tartaletki z łososiem',
  appetizer_1_details: 'Tartaletki, ser śmietankowy, łosoś, zioła',
  appetizer_2: 'Talerz serów',
  appetizer_2_details: 'Mozzarella, Parmesan, Roquefort, winogrona, orzechy',
  appetizer_3: 'Tatar z cielęciny',
  appetizer_3_details: 'Posiekana cielęcina, kapary, cebula, oliwa z oliwek',
  appetizer_4: 'Carpaccio z łososia',
  appetizer_4_details: 'Łosoś, awokado, cytryna, orzeszki piniowe',
  appetizer_5: 'Antipasti mięsne',
  appetizer_5_details: 'Asortyment mięs, suszone pomidory, oliwki',

  // salads
  salad_1: 'Sałatka Cezar z kurczakiem',
  salad_1_details: 'Mieszanka sałat, kurczak, pomidorki cherry, Parmesan, dressing Cezar',
  salad_2: 'Sałatka Nicejska',
  salad_2_details: 'Tuńczyk, ziemniaki, sałata, oliwki, pomidorki cherry, anchovies',
  salad_3: 'Sałatka z awokado i krewetkami',
  salad_3_details: 'Krewetki, awokado, pomidorki cherry, ogórek, dressing musztardowy miodowy',
  salad_4: 'Sałatka owoców morza',
  salad_4_details: 'Małe ośmiornice, kalmary, krewetki, mieszanka sałat',
  salad_5: 'Sałatka Cezar z łososiem',
  salad_5_details: 'Łosoś, mieszanka sałat, pomidorki cherry, Parmesan, dressing Cezar',

  // soups
  soup_1: 'Rosół z makaronem',
  soup_1_details: 'Pierś kurczaka, makaron, marchew, zioła',
  soup_2: 'Krem z grzybów',
  soup_2_details: 'Pieczarki, śmietana, marchew, cebula, ziemniaki, zioła',
  soup_3: 'Krem serowy',
  soup_3_details: 'Mieszanka serów, śmietana, marchew, cebula, ziemniaki, zioła',
  soup_4: 'Zupa rybna',
  soup_4_details: 'Dorado, łosoś, halibut, marchew, ziemniaki, cebula, zioła',
  soup_5: 'Bouillabaisse',
  soup_5_details: 'Małże, krewetki, łosoś, pomidory, ziemniaki, przyprawy',

  // meats
  meat_1: 'Filet Mignon',
  meat_1_details: 'Cielęcina',
  meat_2: 'Gulasz',
  meat_2_details: 'Cielęcina, ziemniaki, marchew, pomidory',
  meat_3: 'Kurczak grillowany',
  meat_3_details: 'Pierś kurczaka, puree ziemniaczane',
  meat_4: 'Shashlik z jagnięciny',
  meat_4_details: 'Jagnięcina, cebula, papryka',
  meat_5: 'Schnitzel',
  meat_5_details: 'Wieprzowina, puree ziemniaczane, mieszanka sałat',

  // fish
  fish_1: 'Grillowane Dorado',
  fish_1_details: 'Dorado, mieszanka warzyw',
  fish_2: 'Pieczony Łosoś',
  fish_2_details: 'Filet z łososia, mieszanka warzyw',
  fish_3: 'Roladki rybne',
  fish_3_details: 'Łosoś, ziemniaki, mozzarella, marchew, pomidory',
  fish_4: 'Małże w sosie białego wina',
  fish_4_details: 'Małże, czosnek, cebula, marchew, sos białego wina',
  fish_5: 'Grillowany Halibut',
  fish_5_details: 'Halibut, mieszanka warzyw',

  // pasta
  flour_1: 'Pasta Carbonara',
  flour_1_details: 'Śmietana, Parmesan, boczek, jajko',
  flour_2: 'Pasta z Krewetkami',
  flour_2_details: 'Krewetki, pomidorki cherry, sos winny, Parmesan',
  flour_3: 'Pasta Arrabbiata',
  flour_3_details: 'Pomidory, papryczki chili, bazylia, czosnek, sos pomidorowy',
  flour_4: 'Pasta Bolognese',
  flour_4_details: 'Wołowina, pomidory, cebula, czosnek, seler',
  flour_5: 'Pasta z Łososiem',
  flour_5_details: 'Łosoś, pomidorki cherry, Parmesan, sos śmietanowy',

  // sides
  sides_1: 'Frytki',
  sides_1_details: 'Ziemniaki',
  sides_2: 'Puree ziemniaczane',
  sides_2_details: 'Ziemniaki, masło, mleko',
  sides_3: 'Ryż',
  sides_3_details: 'Ryż, masło',
  sides_4: 'Grillowane warzywa',
  sides_4_details: 'Papryka, marchew, pomidory, bakłażan, cukinia',
  sides_5: 'Świeże warzywa',
  sides_5_details: 'Ogórek, papryka, pomidory, marchew, zioła',

  // desserts
  desserts_1: 'Ciasto Napoleon',
  desserts_1_details: 'Ciasto francuskie, budyń',
  desserts_2: 'Tiramisu',
  desserts_2_details: 'Ciastka Savoiardi namoczone w kremie kawowym',
  desserts_3: 'Ciasto Tres Leches',
  desserts_3_details: 'Kremowe, karmelowe i czekoladowe kremy',
  desserts_4: 'Ciasto Red Velvet',
  desserts_4_details: 'Biszkopt namoczony w kremowym lukrze',
  desserts_5: 'Strudel jabłkowy',
  desserts_5_details: 'Ciasto bez drożdży, jabłka, orzechy',

  // coffee
  coffee_1: 'Espresso',
  coffee_1_details: '',
  coffee_2: 'Latte',
  coffee_2_details: '',
  coffee_3: 'Latte na zimno',
  coffee_3_details: 'Latte, lód',
  coffee_4: 'Americano',
  coffee_4_details: '',
  coffee_5: 'Flat White',
  coffee_5_details: 'Podwójne espresso z pianką mleczną',

  // tea
  tea_1: 'Czarna Assam',
  tea_1_details: '',
  tea_2: 'Bergamotka',
  tea_2_details: '',
  tea_3: 'Łąka alpejska',
  tea_3_details: '',
  tea_4: 'Mieszanka owocowa',
  tea_4_details: '',
  tea_5: '1001 Nocy',
  tea_5_details: '',

  // beer
  beer_1: 'Corona',
  beer_1_details: '',
  beer_2: 'Stella Artois',
  beer_2_details: '',
  beer_3: 'Estrella',
  beer_3_details: '',
  beer_4: 'Bud',
  beer_4_details: '',
  beer_5: 'Carlsberg',
  beer_5_details: '',

  // appetizers
  appetizer_for_vodka: 'Przekąska do wódki',
  cheese_plate_td: 'Talerz serów',
  cheese_plate_td_details: 'Sery własnej produkcji',
  meat_plate: 'Wędliny',
  meat_plate_details: 'Kiełbasy i mięsa własnej produkcji',
  toast_with_lard: 'Tost z łojem',
  toast_with_lard_details: 'Mangalica',
  assorted_salo: 'Krojenie słoniny',
  lavash: 'Lawasz',

  // salads
  tyranochka: 'Turyanochka',
  tyranochka_details: 'Liście sałaty, ser własnej produkcji, pomidorki cherry, dressing miodowo-musztardowy',
  caesar_salad_details: 'Liście sałaty, filet z kurczaka, pomidory, jajko, grzanki, sos',
  fresh_vegetable_plate: 'Talerz świeżych warzyw',
  greek_salad_2: 'Grecka',

  // soups
  chicken_broth_homemade: 'Rosół z domowego kurczaka',
  mushroom_soup_td: 'Zupa grzybowa',

  // sides
  homemade_potatoes: 'Ziemniaki domowej roboty z łojem i cebulą',
  baked_potatoes: 'Pieczone ziemniaki w stylu Turiansk',
  french_fries: 'Frytki',
  assorted_garnish: 'Garnitur w magazynie',

  // meats
  mangalica_steak: 'Stek z Mangalicy',
  mangalica_steak_details: 'Na zamówienie',
  pork_steak: 'Stek wieprzowy',
  pork_steak_details: 'Siekanka wieprzowa',
  pork_shashlik: 'Shish kebab z wieprzowiny',
  pork_shashlik_details: 'Siekanka wieprzowa',
  pork_ribs_td: 'Żeberka wieprzowe',
  chicken_steak_td: 'Stek z kurczaka',
  chicken_shashlik: 'Shish kebab z kurczaka',
  lamb_leg: 'Noga jagnięca',
  lamb_leg_details: 'Na zamówienie',
  lamb_rack: 'Kotlet jagnięcy',
  homemade_sausages: 'Kiełbasy własnej produkcji',
  grilled_vegetables: 'Warzywa na mangali',

  // specialties
  banosh_with_mushrooms_and_pork_rind: 'Banosh z serem i skwarkami',
  banosh_with_mushroom_sauce: 'Banosh z sosem grzybowym',
  banosh_with_mozzarella_and_greens: 'Banosh z mozzarellą i ziołami',
  cremeshnit_from_chef: 'Od szefa',
  cremeshnit_with_sour_cream: 'Cremeshnit ze śmietaną',
  hutsul_cremeshnit_with_meat: 'W Huculski z mięsem',
  cremeshnit_with_mushrooms: 'Cremeshnit z grzybami',

  // additional dishes
  fresh_pork: 'Świeża wieprzowina',
  chicken_nuggets: 'Nuggetsy z kurczaka',
  wings_in_honey_sauce: 'Skrzydełka w sosie miodowym',
  chicken_cutlet: 'Kotlet z kurczaka',

  grilled_trout: 'Troć na mangali',
  carp_steaks: 'Steki z karpia',
  grilled_carp: 'Karp na mangali',

  metanit_dumplings_with_pork_rind_and_sour_cream: 'Pierogi Methani z skwarkami i śmietaną',
  dumplings_with_potato_and_sour_cream: 'Pierogi z ziemniakami własnej produkcji ze śmietaną',

  turianska_family_pizza: 'Pizza rodzinna Turiańska',

  assorted_desserts: 'W asortymencie',

  // beverages
  cocoa: 'Kakao',
  cocoa_details: 'kakao',
  juice_assortment: 'Soki w asortymencie',
  water_assortment: 'Woda w asortymencie',
  mineral_water: 'Woda mineralna',
  lemonade: 'Lemoniada',
};
