import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { ref } from 'vue';
import { request } from "@/stores/request";
import { Booking, BookingCreateData, BookingSlot } from "@/types/Booking";

export const useBookingStore = defineStore('bookingStore', () => {
  const { getErrorMessage } = useApi();

  const bookingList = ref<Booking[]>([]);
  const booking = ref<Booking>({} as Booking);
  const bookingSlots = ref<BookingSlot>({});
  const error = ref('');

  const getBookingByLink = async (linkId: number) => {
    try {
      booking.value = await request({ url: `booking/link/${ linkId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  /*const getRoomBookingList = async (roomId: number) => {
    try {
      bookingList.value = await request({ url: `booking/room/${ roomId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };*/

  const getRoomBookingSlots = async (roomTypeId: number) => {
    try {
      bookingSlots.value = await request({ url: `booking/room/type/${ roomTypeId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getLinksBookingList = async (links: number[]) => {
    try {
      bookingList.value = await request({ url: `booking/link`, method: 'post', data: links });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const createBooking = async (data: BookingCreateData) => {
    try {
      await request({ url: 'booking/client/create', method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const updateBooking = async (id: number, data: Partial<Booking>) => {
    try {
      await request({ url: `booking/${id}`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    bookingList, booking, error, bookingSlots,
    getBookingByLink, createBooking, getRoomBookingSlots, getLinksBookingList, updateBooking
  };
});
