export const service = {
  title: 'Ваш портативний сервіс',
  cleaning: 'Прибирання',
  taxi: 'Таксі',
  desk: 'Ресепшн',
  menu: 'Їжа та напої',
  booking: 'Бронювання',
  info: 'Інформація',
  chat: 'Чат 24/7',
  gazebo: 'Альтанка',
  laundry: 'Пральня',
  sauna: 'Сауна',
  bath: 'Баня',
  fishing: 'Риболовля',
  spa: 'СПА',

  quad_instructor: 'Катання по горах з інструктором',
  bicycle_rent: 'Оренда велосипеду',
  cottage_3_room: 'Котедж 3-кімнатний',
  cottage_4_room: 'Котедж 4-кімнатний',

  hookah_classic: 'Classic',
  hookah_hard: 'Hard',
  hookah_extra_hard: 'Extra Hard',
  hookah_patriot: 'Патріот (Авторський кальян, у колбі цитрусовий сік. Частина коштів перераховується на потреби ЗСУ!)',
  hookah_death: 'Смерть ворогам (Авторський кальян, у колбі червоне вино. Частина коштів перераховується на потреби ЗСУ!)',
  hookah_orange_cup: 'Orange Cup',
  hookah_pina_colada: 'Pina Colada',
  hookah_add_1: 'Додаток в колбу',
  hookah_add_2: 'Подушка',
};
