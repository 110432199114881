<template>
  <div class="panel">
    <page-header :title="infoPage.title ? getTranslation(infoPage?.title) : ''"
                 :back-link="infoPage.pageLink ?? '/info'" />
    <loader-panel v-if="isLoading" />
    <div v-else class="panel__content reserve">
      <images-panel v-if="infoPage.images?.length" :images="imagesSrc" />

      <p v-if="hasTranslation(infoPage.reserveSetup?.title)" v-html="getTranslation(infoPage.reserveSetup.title)"
         class="reserve__title" />
      <p v-if="hasTranslation(infoPage.reserveSetup?.subtitle)" v-html="getTranslation(infoPage.reserveSetup.subtitle)"
         class="reserve__subtitle" />

      <table>
        <tbody>
        <tr v-for="service in services" :key="service.id">
          <td>{{ getTranslation(service.name) }}</td>
          <td>{{ service.price }} {{ $t(userStore.currency) }} / {{ $t(`price_list.${ service.timeUnit }`) }}</td>
          <td v-if="services.length > 1">
            <item-button
                :text="data.serviceId > 0 ? (service.id === data.serviceId ? $t('button.selected') : $t('button.select')) : $t('button.select')"
                :class="{'disabled': data.serviceId && service.id !== data.serviceId}"
                @click="data.serviceId = service.id" />
          </td>
        </tr>
        </tbody>
      </table>

      <p class="reserve__title" :class="{'disabled': data.serviceId < 1}">
        {{ $t('info.choose_date') }}{{ selectedService?.id >= 1 ? '' : ':' }}
        <i v-if="selectedService?.id >= 1">"{{ getTranslation(selectedService.name) }}":</i>
      </p>
      <div class="reserve__fields" :class="{'disabled': data.serviceId < 1}">
        <form-select v-if="infoPage.reserveSetup?.params?.date" v-model="data.date" :key="data.serviceId"
                     :options="dates" :label="$t('date')" :class="{'disabled': data.serviceId < 1}" />
        <p v-if="availableSlots.length && infoPage.reserveSetup?.isAvailableSlotsShown" class="reserve__time">
          <span>{{ $t('free_time') }}:</span><br /><br />
          <span>{{ availableSlots }}</span>
        </p>
        <div v-if="infoPage.reserveSetup?.params?.time" class="reserve__fields-row">
          <form-select v-model="data.from" :label="$t('info.time_from')" :options="timesFrom"
                       :class="{'disabled': !data.date}" />
          <form-select v-model="data.to" :class="{'disabled': !data.from}" :label="$t('info.time_to')"
                       :options="timesTo" />
        </div>
        <form-number v-if="infoPage.reserveSetup?.params?.people" v-model="data.people" :min-value="1"
                     :label="$t('info.people_amount')" />
      </div>

      <cart-summary v-if="isButtonActive && summaryData" :title="$t('summary.title')" :items="[summaryData]"
                    :cols="['article', selectedService?.timeUnit ?? 'hours', 'price']" :is-name-translated="true"
                    :key="summaryKey" :is-amount-multiplies="!!infoPage.reserveSetup?.priceDependency?.duration" />
      <p v-if="hasTranslation(infoPage.reserveSetup?.bottomText)" class="reserve__help">
        {{ getTranslation(infoPage.reserveSetup.bottomText) }}
      </p>
    </div>
    <base-button :class="{'disabled': !isButtonActive}" :text="$t('button.reserve')" @click="onReserveClick" />
  </div>
</template>

<script setup lang="ts">
import LoaderPanel from "@/components/LoaderPanel.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import CartSummary from "@/components/CartSummary.vue";
import ImagesPanel from "@/components/ImagesPanel.vue";
import ItemButton from "@/components/Button/ItemButton.vue";
import PageHeader from "@/components/Header/PageHeader.vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { MenuCartItem } from "@/types/App/Cart";
import { SelectOption } from "@/types/App/Form";
import moment from "moment/moment";
import { useReservationStore } from "@/stores/reservationStore";
import { storeToRefs } from "pinia";
import { useRender } from "@/composable/useRender";
import { Service } from "@/types/Service";
import { useUserStore } from "@/stores/userStore";
import { useTime } from "@/composable/useTime";
import { useTranslation } from "@/composable/useTranslation";
import { ReservationSlot } from "@/types/Slot";
import { useHotelSetupStore } from "@/stores/hotelSetupStore";
import { useServiceStore } from "@/stores/serviceStore";
import { useApi } from "@/composable/useApi";
import FormNumber from "@/components/Form/FormNumber.vue";

const route = useRoute();
const { push } = useRouter();
const hotelSetupStore = useHotelSetupStore();
const { infoPage } = storeToRefs(hotelSetupStore);
const userStore = useUserStore();
const serviceStore = useServiceStore();
const { services } = storeToRefs(serviceStore);
const reservationStore = useReservationStore();
const { reservationSlots, error } = storeToRefs(reservationStore);
const { renderNotification } = useRender();
const { getTimePeriodStr, getTimeCombinations } = useTime();
const { getTranslation, hasTranslation } = useTranslation();
const { getApiUrl } = useApi();

const imagesSrc = ref<string[]>([]);
const data = ref({
  serviceId: 0,
  date: '',
  from: '',
  to: '',
  people: 1,
});
const dates = ref<SelectOption[]>([]);
const timesFrom = ref<SelectOption[]>([]);
const timesTo = ref<SelectOption[]>([]);
const isLoading = ref(true);
const summaryKey = ref(0);

const isButtonActive = computed((): boolean => {
  const { date, time, people } = infoPage.value.reserveSetup?.params ?? {};
  let result = data.value.serviceId > 0;
  result &&= !date || !!data.value.date;
  result &&= !time || !!(data.value.from && data.value.to);
  result &&= !people || data.value.people > 0;
  return result;
});

const selectedService = computed((): Service => services.value?.find(item => item.id === data.value.serviceId) as Service);

const minHours = computed((): number => infoPage.value?.reserveSetup?.minReserveHours ?? 0);

const summaryData = computed((): MenuCartItem | null => {
  if (isButtonActive.value && selectedService.value?.id) {
    const { from, to, people } = data.value;
    return {
      id: selectedService.value.id,
      name: getTranslation(selectedService.value.name),
      amount: infoPage.value.reserveSetup?.params?.time ? +to.slice(0, 2) - +from.slice(0, 2) : 1,
      price: { price: selectedService.value.price, quantity: 1, unit: 'none' },
      multiplier: infoPage.value.reserveSetup?.params?.people ? people : 1,
    };
  } else {
    return null;
  }
});

const combinations = computed((): number[][] => {
  const slots = reservationSlots.value[data.value.date?.slice(0, 10)][data.value.serviceId];
  const comb = getTimeCombinations(slots);
  return comb.filter(item => item.length >= minHours.value).map(item => [...item, item[item.length - 1] + 1]);
});

const availableSlots = computed((): string => {
  if (data.value.serviceId && data.value.date) {
    return combinations.value.map(item => getTimePeriodStr(item[0], item[item.length - 1])).join('; ');
  }
  return '';
});

const setDates = () => {
  dates.value = [];
  Object.entries(reservationSlots.value).map(([key, value]) => {
    if (Object.entries(value as ReservationSlot).find(([k, v]) => +k === data.value.serviceId && v.length > 0)) {
      const date = `${ key }T00:00:00.000Z`;
      dates.value.push({
        value: date,
        label: moment(date).format('DD.MM.YYYY')
      });
    }
  });
};

const setTimesFrom = () => {
  timesFrom.value = [];
  combinations.value.map(item => {
    for (let i = 0; i < item.length - minHours.value; i++) {
      const value = moment().hours(item[i]).minutes(0).seconds(0).format('HH:mm');
      timesFrom.value.push({ value, label: value });
    }
  });
};

const setTimesTo = () => {
  timesTo.value = [];
  const fromValue = +data.value.from.slice(0, 2);
  combinations.value.map(item => {
    if (item.includes(fromValue)) {
      for (let i = minHours.value - 1; i < item.length; i++) {
        if (item[i] > fromValue + minHours.value - 1) {
          const value = moment().hours(item[i]).minutes(0).seconds(0).format('HH:mm');
          timesTo.value.push({ value, label: value });
        }
      }
    }
  });
};

const onReserveClick = async () => {
  const { serviceId, date, from, to } = data.value;
  await reservationStore.createReservation({
    linkId: userStore.clientLinkData.id,
    serviceId,
    date: dates.value.find(item => item.value === date)?.value as string,
    duration: infoPage.value.reserveSetup?.params?.time ? +to.slice(0, 2) - +from.slice(0, 2) : 1,
    time: infoPage.value.reserveSetup?.params?.time ? `${ from }-${ to }` : '-',
    details: {}
  });
  if (!error.value) {
    setTimeout(() => renderNotification('reservation_received'), 100);
    push('/');
  }
};

onMounted(async () => {
  await hotelSetupStore.getInfoPage(+route.params.pageId);
  infoPage.value.images?.map(async (path) => {
    const image = new Image();
    image.src = getApiUrl() + path;
    image.addEventListener('load', () => {
      const src = image.getAttribute('src');
      src && imagesSrc.value.push(src);
    });
  });

  if (infoPage.value?.priceList?.services?.length) {
    await serviceStore.getServices(infoPage.value.priceList.services);
    if (services.value.length === 1) {
      data.value.serviceId = services.value[0].id;
    }

    await reservationStore.getReservationSlots(+route.params.pageId);
  }

  isLoading.value = false;
});

watch(() => data.value.serviceId, () => {
  setDates();
  data.value.date = '';
  data.value.from = '';
  data.value.to = '';
});

watch(() => data.value.date, () => {
  setTimesFrom();
  data.value.from = '';
  data.value.to = '';
});

watch(() => data.value.from, () => setTimesTo());

watch(() => summaryData.value, () => summaryKey.value++, { deep: true });
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/reserve";

table {
  margin-bottom: 1.75rem;

  tbody tr td:last-child {
    min-width: 6rem !important;
  }

  tbody tr td:nth-child(2) {
    padding: 0 0.5rem;
  }
}

.reserve {
  &__fields {
    margin-bottom: 2.25rem;
  }

  &__help {
    margin-bottom: 1.5rem;
  }
}

.item-button {
  width: max-content;
  margin-left: auto;
  padding: 0 10px;
  font-weight: 600;
  transition-duration: 0.2s;

  &.disabled {
    background-color: $grey;
    pointer-events: all !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.75;
}
</style>
