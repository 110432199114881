<template>
  <div class="field">
    <div v-for="option in options" :key="option.value" class="field__radio"
         :class="{'field__radio--selected': option.value === modelValue}"
         @click="() => onOptionClick(option.value)">
      <div class="field__radio-icon"></div>
      <p class="field__radio-text">{{ option.label }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { SelectOption } from "@/types/App/Form";

const props = defineProps({
  options: {
    type: Array as PropType<SelectOption[]>,
    default: () => ([]),
  },
  modelValue: {
    type: [Number, String],
    default: null,
  },
});
const emits = defineEmits(['update:modelValue']);

const onOptionClick = (value: number | string) => emits('update:modelValue', value);
</script>

<style scoped lang="scss">
@import "../../assets/styles/components/form/radio";
</style>
